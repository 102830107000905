import { NetworkStatus, useLazyQuery } from '@apollo/client';
import {
	CurrencyFilterDefinitionType,
	FilterMenuOutputType,
	Table,
	TableColumnType,
	TableOrderByType,
	useTable,
} from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import DownloadTransactionsButton from './DownloadThirdPartiesButton';
import LinkIcon from '../../../../components/LinkIcon';
import { CustomerFileType } from '../../../../types/customerFile';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { FETCH_THIRD_PARTIES, FetchThirdPartiesType } from '../../api/thirdParty';
import { ThirdPartyType, ThirdPartyTypeEnum } from '../../types/thirdParty';
import { formatCurrency } from '../../utils/formatCurrency';

type ThirdPartyFilterType = {
	balance: CurrencyFilterDefinitionType;
};

const columns: TableColumnType<ThirdPartyType>[] = [
	{
		field: 'code',
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libelle',
		width: 150,
	},
	{
		align: 'right',
		field: 'balance',
		key: 'balance',
		render: ({ balance }) => <FormattedNumber value={+balance} style="currency" currency="EUR" />,
		sortable: true,
		title: 'Solde',
		width: 100,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type ThirdPartiesTableType = {
	hasTransaction: boolean;
	selectedFiscalYear?: FiscalYearType;
	selectedDateRange?: [Date, Date];
	selectedType: ThirdPartyTypeEnum;
	balanceFilter?: CurrencyFilterDefinitionType['value'];
	onBalanceFilterChange: (value?: CurrencyFilterDefinitionType['value']) => void;
};

const ThirdPartiesTable = ({
	hasTransaction,
	selectedFiscalYear,
	selectedDateRange,
	selectedType,
	onBalanceFilterChange,
	balanceFilter,
}: ThirdPartiesTableType) => {
	const tableInstance = useTable();
	const { push, query } = useRouter();
	const { dossier: queryCustomerFileCode } = query || {};

	const [totalBalance, setTotalBalance] = useState('0');
	const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
	const [downloadSearch, setDownloadSearch] = useState<string>();

	const downloadFilter = useMemo(() => (balanceFilter ? { balance: balanceFilter } : {}), [balanceFilter]);
	const filters: ThirdPartyFilterType = useMemo(
		() => ({
			balance: {
				label: 'Solde',
				type: 'currency',
				value: balanceFilter,
			},
		}),
		[balanceFilter],
	);

	const [fetchThirdParties, { networkStatus }] = useLazyQuery<FetchThirdPartiesType>(FETCH_THIRD_PARTIES, {
		notifyOnNetworkStatusChange: true,
	});
	const accountingDate = useMemo(
		() =>
			selectedDateRange?.length === 2
				? {
						min: selectedDateRange[0],
						max: selectedDateRange[1],
				  }
				: null,
		[selectedDateRange],
	);

	const thirdPartiesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<ThirdPartyType>,
		): Promise<{
			count: number;
			items: ThirdPartyType[];
		}> => {
			if (!selectedFiscalYear || !selectedType) {
				return {
					count: 0,
					items: [],
				};
			}

			const { field, order } = orderBy || {};

			const { data, error } = await fetchThirdParties({
				variables: {
					accountingDate,
					...(balanceFilter && {
						filter: { balance: balanceFilter },
					}),
					fiscalYear: selectedFiscalYear.id,
					hasTransaction,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					type: selectedType,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				accounting_thirdParties: { count = 0, items = [], totalBalance: queryTotalBalance = '0' },
			} = data ?? {
				accounting_thirdParties: {},
			};

			setIsDownloadButtonDisabled(count === 0);
			setTotalBalance(queryTotalBalance);
			setDownloadSearch(search);

			return {
				count,
				items,
			};
		},
		[selectedFiscalYear, selectedType, fetchThirdParties, accountingDate, balanceFilter, hasTransaction],
	);

	const handleRowClick = useCallback(
		({ id, code, name }: ThirdPartyType) => {
			push(
				{
					pathname: 'thirdParties/transactions',
					query: {
						...(queryCustomerFileCode && { dossier: queryCustomerFileCode }),
						id,
						code,
						name,
						selectedFiscalYear: JSON.stringify(selectedFiscalYear),
						selectedDateRange: JSON.stringify(selectedDateRange),
					},
				},
				`thirdParties/transactions${
					queryCustomerFileCode ? `?dossier=${queryCustomerFileCode as CustomerFileType['code']}` : ''
				}`,
			).catch((e) => {
				throw e;
			});
		},
		[queryCustomerFileCode, push, selectedDateRange, selectedFiscalYear],
	);

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	const onFilterSubmit = (submittedFilters?: FilterMenuOutputType<ThirdPartyFilterType>) =>
		onBalanceFilterChange(submittedFilters?.balance);

	return (
		<Stack height="100%" width="100%" flex={3}>
			<Table<ThirdPartyType, ThirdPartyFilterType>
				table={tableInstance}
				columns={columns}
				dataSource={thirdPartiesDataSource}
				onFilterSubmit={onFilterSubmit}
				filters={filters}
				footer={[
					{ key: 'name', value: 'TOTAL' },
					{
						key: 'balance',
						value: formatCurrency(totalBalance),
					},
				]}
				onRowClick={handleRowClick}
				style={{
					height: '100%',
				}}
				title="Tiers"
				enableSearch
			/>

			<Stack alignSelf="flex-end">
				<DownloadTransactionsButton
					disabled={isDownloadButtonDisabled}
					downloadFilter={downloadFilter}
					downloadSearch={downloadSearch}
					hasTransaction={hasTransaction}
					selectedFiscalYear={selectedFiscalYear}
					selectedType={selectedType}
					accountingDate={accountingDate}
				/>
			</Stack>
		</Stack>
	);
};

export default ThirdPartiesTable;
