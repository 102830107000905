import { FilterMenuOutputType, Table, TableColumnType, TableInstance, TableOrderByType } from '@elipssolution/harfang';
import { useMemo } from 'react';

import useTemplateDataSource from './hooks/useTemplateDataSource';
import useVoucherUserDataSource from './hooks/useVoucherUserDataSource';
import { CustomerFileTemplateType } from '../../types/template';
import { VoucherFilterType, VoucherType } from '../../types/voucher';

type VoucherTableProps = {
	columns: TableColumnType<VoucherType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<VoucherType>,
		filters?: FilterMenuOutputType<VoucherFilterType>,
	) => Promise<{ items: VoucherType[]; count: number }>;
	onVoucherSelection: (voucher: VoucherType) => void;
	onTemplateFilterChange?: (filteredTemplate?: CustomerFileTemplateType) => void;
	tableInstance: TableInstance;
	filteredTemplate?: CustomerFileTemplateType;
	title: string;
};

const VoucherTable = ({
	columns,
	dataSource,
	onTemplateFilterChange,
	onVoucherSelection,
	tableInstance,
	filteredTemplate,
	title,
}: VoucherTableProps) => {
	const { dataSource: templatesDataSource } = useTemplateDataSource();

	const { dataSource: voucherUsersDataSource } = useVoucherUserDataSource();

	const filters: VoucherFilterType = useMemo(
		() => ({
			template: {
				label: 'Modèle',
				type: 'autocomplete',
				dataSource: templatesDataSource,
				getOptionLabel: ({ name }) => name,
				...(filteredTemplate && {
					value: { eq: filteredTemplate },
				}),
			},
			accountingDate: {
				label: 'Période de comptabilisation',
				type: 'period',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
			createdBy: {
				label: 'Créé par',
				type: 'autocomplete',
				dataSource: voucherUsersDataSource,
				getOptionLabel: ({ email }) => email,
			},
		}),
		[templatesDataSource, voucherUsersDataSource, filteredTemplate],
	);

	const handleFilterSubmit = (submittedFilters?: FilterMenuOutputType<VoucherFilterType>) => {
		const { template } = submittedFilters ?? {};

		onTemplateFilterChange?.(template?.eq);
	};

	return (
		<Table<VoucherType, VoucherFilterType>
			columns={columns}
			dataSource={dataSource}
			filters={filters}
			onFilterSubmit={handleFilterSubmit}
			onRowClick={onVoucherSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onTemplateFilterChange}
		/>
	);
};

export default VoucherTable;
