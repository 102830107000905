import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { SettingsGroup, SettingsItemSwitch } from '@elipssolution/harfang';
import { useCallback, useMemo, useState } from 'react';

import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import modulesConfig from '../../../../modules/modules';
import { CustomerFileType } from '../../../../types/customerFile';
import { ModuleAvailabilityType } from '../../../../types/module';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_MODULES } from '../../../api/module';
import {
	FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE,
	FetchSettingModulesAvailabilityByCustomerFileType,
	UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY,
	UpdateSettingCustomerFileModuleAvailabilityType,
} from '../../../api/settingModuleAvailability';

type ModuleAvailabilityWithNameType = ModuleAvailabilityType & {
	moduleName: string;
};

type ModulesProps = {
	customerFileId: CustomerFileType['id'];
};

const Modules = ({ customerFileId }: ModulesProps) => {
	const [errors, setErrors] = useState<Map<string, string>>(new Map());

	const { data: settingModulesAvailabilityByCustomerFileData } =
		useQuery<FetchSettingModulesAvailabilityByCustomerFileType>(FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE, {
			skip: !customerFileId,
			variables: {
				customerFileId,
			},
		});

	const modulesAvailabilityByCustomerFile = useMemo(() => {
		const { settingModuleAvailabilityByCustomerFile: fetchedModulesAvailabilityByCustomerFile = [] } =
			settingModulesAvailabilityByCustomerFileData || {};

		return fetchedModulesAvailabilityByCustomerFile.reduce((acc, moduleAvailability) => {
			const { moduleCode: moduleAvailabilityCode } = moduleAvailability;
			const moduleConfig = modulesConfig.find(({ code: configCode }) => configCode === moduleAvailabilityCode);

			if (!moduleConfig) {
				return acc;
			}

			return [
				...acc,
				{
					...moduleAvailability,
					moduleName: moduleConfig.name,
				},
			];
		}, [] as ModuleAvailabilityWithNameType[]);
	}, [settingModulesAvailabilityByCustomerFileData]);

	const [updateModuleAvailabilityByCustomerFile] = useMutation<UpdateSettingCustomerFileModuleAvailabilityType>(
		UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY,
		{
			refetchQueries: [FETCH_MODULES, FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE],
		},
	);

	const changeModuleAvailabilityStatus = useCallback(
		(isEnabled: boolean, moduleId: string) => {
			updateModuleAvailabilityByCustomerFile({
				variables: {
					customerFileUpdateModuleAvailabilityInput: {
						customerFileId,
						moduleId,
						isEnabled,
					},
				},
			}).catch((queryError: ApolloError) => {
				setErrors((prevErrors) =>
					prevErrors.set(
						moduleId,
						generateErrorInformations({
							error: queryError,
							resource: 'updateModuleAvailabilityByCustomerFile',
						}).message,
					),
				);

				throw queryError;
			});
		},
		[customerFileId, updateModuleAvailabilityByCustomerFile],
	);

	const moduleItemsSwitchRenderer = useMemo(
		() =>
			modulesAvailabilityByCustomerFile.map(({ isEnabled, moduleId, moduleName }) => (
				<SettingsItemSwitch
					key={moduleId}
					checked={isEnabled}
					description={`Permet d'activer le module ${moduleName} pour le dossier.`}
					errorMessage={errors.get(moduleId)}
					label={moduleName}
					onChange={(checked) => changeModuleAvailabilityStatus(checked, moduleId)}
				/>
			)),
		[changeModuleAvailabilityStatus, errors, modulesAvailabilityByCustomerFile],
	);

	return (
		<SettingsDialogPage title="Configuration des modules">
			<SettingsGroup>{moduleItemsSwitchRenderer}</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default Modules;
