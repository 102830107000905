import { useLazyQuery } from '@apollo/client';
import { TableOrderByType } from '@elipssolution/harfang';
import { useCallback, useState } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchSubmittedOrValidatedChecksType, FETCH_SUBMITTED_OR_VALIDATED_CHECKS } from '../../../api/check';
import { CheckType } from '../../../types/check';

const useSubmittedOrValidatedChecksDataSource = () => {
	const [hasItems, setHasItems] = useState(true);

	const [fetchSubmittedOrValidatedChecks, { networkStatus }] = useLazyQuery<FetchSubmittedOrValidatedChecksType>(
		FETCH_SUBMITTED_OR_VALIDATED_CHECKS,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CheckType>,
		): Promise<DataSourceResultFormatType<CheckType>> => {
			const { field, order } = orderBy ?? {};

			const { data, error } = await fetchSubmittedOrValidatedChecks({
				variables: {
					...(orderBy && {
						orderBy: {
							field,
							order,
						},
					}),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_submittedOrValidatedChecks: { count = 0, items = [] },
			} = data ?? {
				quickentry_submittedOrValidatedChecks: {},
			};

			setHasItems(count > 0);

			return {
				count,
				items: items.map(({ documentDate, ...rest }) => ({
					...rest,
					documentDate: new Date(documentDate),
				})),
			};
		},
		[fetchSubmittedOrValidatedChecks],
	);

	return { dataSource, hasItems, networkStatus };
};

export default useSubmittedOrValidatedChecksDataSource;
