import { Stack } from '@mui/material';

import AlertSection from './AlertSection';
import ColorsSection from './ColorsSection';
import LogosSection from './LogosSection';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { PermissionEnum } from '../../../types/permission';

const General = () => (
	<SettingsDialogPage title="Général">
		<PermissionWall permissionCodes={[PermissionEnum.THEME_MANAGE]}>
			<Stack gap={2}>
				<LogosSection />
				<ColorsSection />
				<AlertSection />
			</Stack>
		</PermissionWall>
	</SettingsDialogPage>
);

export default General;
