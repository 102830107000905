import { useSession as useNextAuthSession } from 'next-auth/react';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { WidgetType } from '../../../../types/widget';
import { useSession } from '../../../components/SessionProvider';
import { deleteFile, getFileBlob, uploadFileWithFormData } from '../../../utils/file';

type WidgetImageType = {
	action: 'none' | 'add' | 'remove';
	fileName: string;
	blob: Blob;
};

type WidgetImageContextType = {
	/** Get image from store, fetch it if necessary */
	getBlob: (fileName: string) => Promise<Blob>;
	/** Add images to store and mark it to add */
	addImages: (imagesToAdd: { fileName: string; blob: Blob }[]) => void;
	/** Remove image from store if not persisted, otherise mark it for remove */
	removeImages: (imageFileNamesToRemove: string[]) => void;
	/** Remove every image from a widget */
	removeWidgetImages: (widget: WidgetType) => void;
	/** Apply all changes store */
	saveImages: () => Promise<void>;
	/** Clear the store  */
	clearImages: () => void;
};

export const WidgetImagesContext = createContext<WidgetImageContextType | undefined>(undefined);

export const useWidgetImages = () => {
	const context = useContext(WidgetImagesContext);

	if (context === undefined) {
		throw new Error('WidgetImageContext should be used within a Provider');
	}

	return context;
};

const WidgetImagesProvider = ({ children }: { children: ReactNode }) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();

	const [images, setImages] = useState<WidgetImageType[]>([]);

	const getBlob = useCallback(
		async (fileName: string) => {
			const storedImage = images.find(({ fileName: storedFileName }) => storedFileName === fileName);

			if (storedImage) {
				return storedImage.blob;
			}

			const blob = await getFileBlob(`/widget/images?fileName=${fileName}`);

			setImages((prevImages) => {
				prevImages.push({
					fileName,
					blob,
					action: 'none',
				});

				return prevImages;
			});

			return blob;
		},
		[images],
	);

	const addImages = useCallback((imagesToAdd: Required<Omit<WidgetImageType, 'action'>>[]) => {
		setImages((prevImages) => [
			...imagesToAdd.map(({ fileName, blob }) => ({
				fileName,
				blob,
				action: 'add' as const,
			})),
			...prevImages.filter(
				({ fileName }) => !imagesToAdd.some(({ fileName: storedFileName }) => storedFileName === fileName),
			),
		]);
	}, []);

	const removeImages = useCallback(
		(imageFileNames: string[]) => {
			const imagesToRemove = images.filter(({ fileName }) => imageFileNames.includes(fileName));

			setImages((prevImages) => [
				...prevImages.filter(({ fileName }) => !imageFileNames.includes(fileName)),
				...imagesToRemove.flatMap(({ fileName, blob, action }) =>
					action === 'none'
						? [
								{
									fileName,
									blob,
									action: 'remove' as const,
								},
						  ]
						: [],
				),
			]);
		},
		[images],
	);

	const removeWidgetImages = useCallback(
		(widget: WidgetType) => {
			if (!widget.configuration) return;

			const { applications } = JSON.parse(widget.configuration) as {
				applications: { key: string; specific: string }[];
			};

			removeImages(
				applications.flatMap(({ specific }) => {
					if (!specific) {
						return [];
					}

					const { image: imageFileName } = JSON.parse(specific) as { image: string };
					return imageFileName ? [imageFileName] : [];
				}),
			);
		},
		[removeImages],
	);

	const clearImages = useCallback(() => setImages([]), []);

	const saveImages = useCallback(async () => {
		if (!access_token || !sessionCustomerFile?.id) return;

		const imagesPromises = images.flatMap(({ fileName, blob, action }) => {
			if (action === 'add') {
				return uploadFileWithFormData(
					access_token,
					new File([blob], `${fileName}`, { type: blob.type }),
					sessionCustomerFile.id,
					`/widget/images?fileName=${fileName}`,
				);
			}

			if (action === 'remove') {
				return deleteFile(`/widget/images?fileName=${fileName}`, access_token);
			}

			return [];
		});

		await Promise.all(imagesPromises);

		setImages((prevImages) =>
			prevImages.flatMap(({ fileName, blob, action }) => {
				if (action === 'add') {
					return [{ fileName, blob, action: 'none' }];
				}
				if (action === 'remove') {
					return [];
				}
				return [{ fileName, blob, action }];
			}),
		);
	}, [access_token, images, sessionCustomerFile]);

	const contextValue = useMemo(
		() => ({
			getBlob,
			addImages,
			removeImages,
			removeWidgetImages,
			clearImages,
			saveImages,
		}),
		[addImages, clearImages, getBlob, removeImages, removeWidgetImages, saveImages],
	);

	return <WidgetImagesContext.Provider value={contextValue}>{children}</WidgetImagesContext.Provider>;
};

export default WidgetImagesProvider;
