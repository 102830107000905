import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { CHECK_ACCOUNT_TYPE_FIELDS } from './checkAccountType';
import { CheckType } from '../types/check';
import { JournalType } from '../types/journal';
import { PaymentModeType } from '../types/paymentMode';

// QUERIES

export type FetchSynchronizedChecksType = {
	quickentry_synchronizedChecks: {
		count: number;
		items: CheckType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SYNCHRONIZED_CHECKS = gql`
	query Quickentry_synchronizedChecks($filter: quickentry_CheckFilterInput, $orderBy: OrderBy, $page: Page, $search: String) {
		quickentry_synchronizedChecks(filter: $filter, orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				id
				account {
					${ACCOUNT_FIELDS}
					masterAccount {
						${ACCOUNT_FIELDS}
					}
				}
				amount
				bank {
					id
					name
				}
				checkAccountType {
					${CHECK_ACCOUNT_TYPE_FIELDS}
				}
				documentDate
				documentNumber
				name
			}
			limit
			offset
		}
	}
`;

export type FetchSubmittedOrValidatedChecksType = {
	quickentry_submittedOrValidatedChecks: {
		count: number;
		items: CheckType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUBMITTED_OR_VALIDATED_CHECKS = gql`
	query Quickentry_submittedOrValidatedChecks($orderBy: OrderBy, $page: Page) {
		quickentry_submittedOrValidatedChecks(orderBy: $orderBy, page: $page) {
			count
			items {
				id
				account {
					${ACCOUNT_FIELDS}
					masterAccount {
						${ACCOUNT_FIELDS}
					}
				}
				amount
				bank {
					id
					name
				}
				checkAccountType {
					${CHECK_ACCOUNT_TYPE_FIELDS}
				}
				documentDate
				documentNumber
				name
			}
			limit
			offset
		}
	}
`;

export type FetchNextCheckDocumentNumberValueType = {
	quickentry_nextCheckDocumentNumberValue: string;
};

export const FETCH_NEXT_CHECK_DOCUMENT_NUMBER_VALUE = gql`
	query Quickentry_nextCheckDocumentNumberValue($id: ID!) {
		quickentry_nextCheckDocumentNumberValue(id: $id)
	}
`;

export type FetchCheckConfigurationsType = {
	quickentry_checkConfigurations: {
		journalCode: JournalType['code'];
		paymentModeCode: PaymentModeType['code'];
	};
};

export const FETCH_CHECK_CONFIGURATIONS = gql`
	query Quickentry_checkConfigurations {
		quickentry_checkConfigurations {
			journalCode
			paymentModeCode
		}
	}
`;

// MUTATIONS

export type CreateCheckType = {
	quickentry_createCheck: CheckType;
};

export const CREATE_CHECK = gql`
	mutation Quickentry_createCheck($createCheckInput: quickentry_CreateCheckInput!) {
		quickentry_createCheck(createCheckInput: $createCheckInput) {
			id
		}
	}
`;

export type UpdateCheckType = {
	quickentry_updateCheck: CheckType;
};

export const UPDATE_CHECK = gql`
	mutation Quickentry_updateCheck($updateCheckInput: quickentry_UpdateCheckInput!) {
		quickentry_updateCheck(updateCheckInput: $updateCheckInput) {
			id
		}
	}
`;

export type ValidateCheckType = {
	quickentry_validateCheck: CheckType;
};

export const VALIDATE_CHECK = gql`
	mutation Quickentry_validateCheck($id: ID!) {
		quickentry_validateCheck(id: $id) {
			id
		}
	}
`;

export type RemoveCheckType = {
	quickentry_removeCheck: {
		id: CheckType['id'];
	};
};

export const REMOVE_CHECK = gql`
	mutation Quickentry_removeCheck($id: ID!) {
		quickentry_removeCheck(id: $id) {
			id
		}
	}
`;
