import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchCheckAccountTypesType, FETCH_CHECK_ACCOUNT_TYPES } from '../../../api/checkAccountType';
import { CheckAccountTypeType } from '../../../types/checkAccountType';

const useCheckAccountTypesDataSource = () => {
	const [fetchCheckAccountTypes] = useLazyQuery<FetchCheckAccountTypesType>(FETCH_CHECK_ACCOUNT_TYPES);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<DataSourceResultFormatType<CheckAccountTypeType>> => {
			const { data, error: queryError } = await fetchCheckAccountTypes({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				quickentry_checkAccountTypes: { count = 0, items = [] },
			} = data ?? {
				quickentry_checkAccountTypes: {},
			};

			return { count, items };
		},
		[fetchCheckAccountTypes],
	);

	return { dataSource };
};

export default useCheckAccountTypesDataSource;
