import { useLazyQuery } from '@apollo/client';
import { SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';

import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import {
	CHECK_CUSTOMER_FILE_CODE_AVAILABILITY,
	CheckCustomerFileCodeAvailabilityType,
} from '../../../api/customerFile';

let availabilityTimer: ReturnType<typeof setTimeout>;

type FormType = Omit<CustomerFileWithDomainType, 'id'>;

type CustomerFileCodeFieldProps = {
	control: Control<FormType>;
	disabled: boolean;
	onError: (error?: string) => void;
};

const CustomerFileCodeField = ({ control, disabled, onError }: CustomerFileCodeFieldProps) => {
	const [checkCustomerFileCodeAvailability] = useLazyQuery<CheckCustomerFileCodeAvailabilityType>(
		CHECK_CUSTOMER_FILE_CODE_AVAILABILITY,
	);

	const checkCodeAvailability = useCallback(
		(customerFileCode: string) => {
			clearTimeout(availabilityTimer);

			availabilityTimer = setTimeout(() => {
				checkCustomerFileCodeAvailability({ variables: { code: customerFileCode } })
					.then(({ data }) => {
						const { checkCustomerFileCodeAvailability: isCustomerFileCodeAvailable } = data || {};

						!isCustomerFileCodeAvailable && onError('Code de dossier client déjà utilisé');

						return isCustomerFileCodeAvailable;
					})
					.catch((e) => {
						throw e;
					});
			}, 300);

			return undefined;
		},
		[checkCustomerFileCodeAvailability, onError],
	);

	return (
		<Controller
			control={control}
			name="code"
			render={({ field: { onBlur, onChange, ...field }, fieldState: { error } }) => {
				const handleChange = (value?: string) => {
					onError(undefined);
					value && checkCodeAvailability(value);
					onChange(value);
				};

				return (
					<SettingsItemTextField
						{...field}
						description="Code du dossier client."
						disabled={disabled}
						helperText={error?.message ?? ' '}
						invalid={!!error}
						label="Code"
						onChange={handleChange}
						required
					/>
				);
			}}
			rules={{ required: 'Champ requis' }}
		/>
	);
};

export default CustomerFileCodeField;
