import { useLazyQuery } from '@apollo/client';
import { TableOrderByType } from '@elipssolution/harfang';
import { useCallback, useState } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchSubmittedOrValidatedVouchersType, FETCH_SUBMITTED_OR_VALIDATED_VOUCHERS } from '../../../api/voucher';
import { VoucherType } from '../../../types/voucher';

const useSubmittedOrValidatedVouchersDataSource = () => {
	const [hasItems, setHasItems] = useState(true);

	const [fetchSubmittedOrValidatedVouchers, { networkStatus }] = useLazyQuery<FetchSubmittedOrValidatedVouchersType>(
		FETCH_SUBMITTED_OR_VALIDATED_VOUCHERS,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<VoucherType>,
		): Promise<DataSourceResultFormatType<VoucherType>> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSubmittedOrValidatedVouchers({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_submittedOrValidatedVouchers: { count = 0, items = [] },
			} = data ?? {
				quickentry_submittedOrValidatedVouchers: {},
			};

			setHasItems(count > 0);

			return {
				count,
				items: items.map(({ accountingDate, ...rest }) => ({
					...rest,
					accountingDate: new Date(accountingDate),
				})),
			};
		},
		[fetchSubmittedOrValidatedVouchers],
	);

	return { dataSource, hasItems, networkStatus };
};

export default useSubmittedOrValidatedVouchersDataSource;
