import { FileUpload } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { WidgetComponentProps } from '../../../types/widget';
import DocumentUploadDialog from '../components/DocumentUploadDialog';

const MAX_FILES = 50;
const MAX_FILE_SIZE = 20; // Mo

const Wrapper = styled('div')({
	flex: 1,

	height: '100%',
	width: '100%',

	'&.readOnly': {
		pointerEvents: 'none',
	},

	'& > div > div': {
		padding: 0,
	},
});

const WidgetDocumentUpload = ({ readOnly }: WidgetComponentProps) => {
	const [isDocumentUploadDialogOpen, setIsDocumentUploadDialogOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);

	const openDocumentUploadDialog = useCallback(() => setIsDocumentUploadDialogOpen(true), []);
	const closeDocumentUploadDialog = useCallback(() => {
		setIsDocumentUploadDialogOpen(false);
		setUploadedFiles(null);
	}, []);

	const handleFileUpload = useCallback(
		(files: File[]) => {
			setUploadedFiles(files);

			openDocumentUploadDialog();
		},
		[openDocumentUploadDialog],
	);

	return (
		<Wrapper className={clsx({ readOnly })}>
			<FileUpload maxFiles={MAX_FILES} maxFileSize={MAX_FILE_SIZE} onChange={handleFileUpload} showInformations />

			<DocumentUploadDialog
				maxFiles={MAX_FILES}
				maxFileSize={MAX_FILE_SIZE}
				onClose={closeDocumentUploadDialog}
				open={isDocumentUploadDialogOpen}
				uploadedFiles={uploadedFiles}
			/>
		</Wrapper>
	);
};

export default WidgetDocumentUpload;
