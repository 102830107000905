import { SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

const StyledImagePlaceholder = styled(Typography)(({ theme }) => ({
	flex: 1,
	borderWidth: 2,
	borderStyle: 'solid',
	borderColor: theme.palette.primary.main,
	borderRadius: 16,
	color: theme.palette.primary.main,
}));

type FinthesisSpecificType = {
	organisation: string;
	project: string;
};

type FormType = {
	name: string;
	isExternal: boolean;
	isInternal: boolean;
} & FinthesisSpecificType;

type FinthesisFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const FinthesisForm = ({ application, isNew, onSubmit, onRemove }: FinthesisFormProps) => {
	const {
		key,
		type,
		name: initialName,
		specific,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
	} = application;
	const jsonSpecific = specific ? (JSON.parse(specific) as FinthesisSpecificType) : undefined;
	const { organisation: initialOrganisation, project: initialProject } = jsonSpecific ?? {};

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(application);

	const handleSubmitInternal = ({ name, organisation, project, isInternal, isExternal }: FormType) => {
		const stringSpecific = JSON.stringify({ organisation, project });

		onSubmit({
			key: key ?? uuid(),
			type,
			name,
			isInternal,
			isExternal,
			specific: stringSpecific,
		});
	};

	useEffect(
		() =>
			reset({
				name: initialName,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
				organisation: initialOrganisation,
				project: initialProject,
			}),
		[initialName, initialOrganisation, initialProject, isExternalInitial, isInternalInitial, reset],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="organisation"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							label="Organisation"
							description="Configuration de l'organisation de l'analyse financière."
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="project"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							label="Projet"
							description="Configuration du project de l'organisation de l'analyse financière."
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type FinthesisApplicationProps = {
	name: string;
	specific?: string;
	readOnly: boolean;
};

const FinthesisApplication = ({ name, specific, readOnly }: FinthesisApplicationProps) => {
	const jsonSpecific = specific ? (JSON.parse(specific) as FinthesisSpecificType) : undefined;
	const { organisation = '', project = '' } = jsonSpecific ?? {};

	const handleClick = () =>
		window.open(`https://user-dashboard.io/organisations/${organisation}/projects/${project}`, '_blank');

	return (
		<ApplicationCard
			name={name}
			image={
				<StyledImagePlaceholder variant="h3" display="flex" alignItems="center" justifyContent="center">
					{name.charAt(0)}
				</StyledImagePlaceholder>
			}
			onClick={handleClick}
			readOnly={readOnly}
		/>
	);
};

export default FinthesisApplication;
