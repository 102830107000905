import { Icon } from '@elipssolution/harfang';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';
import { ButtonBase, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';

import { PermissionEnum } from '../../types/permission';
import { SessionPermissionType } from '../types/session';

export type SettingsModuleType = {
	id?: string;
	isDisabled?: boolean;
	code: string;
	label: string;
	permissionCodes: PermissionEnum[];
	component: ReactNode;
};

const Wrapper = styled('div')(({ theme }) => ({
	minWidth: 150,
	width: 200,

	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(2),

	padding: theme.spacing(1),

	borderRight: `1px solid ${theme.palette.divider}`,

	overflowY: 'auto',

	'& > p': {
		paddingLeft: theme.spacing(1),

		margin: theme.spacing(1, 0),
	},
}));

const SectionWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(1),

	'& > div:first-of-type': {
		cursor: 'pointer',
	},
}));

const SettingsItem = styled(ButtonBase)(({ theme }) => ({
	height: 40,

	padding: theme.spacing(1),

	borderRadius: theme.shape.borderRadius * 2,

	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: theme.spacing(1.5),

	willChange: 'background-color',
	transition: theme.transitions.create('background-color', {
		duration: theme.transitions.duration.shortest,
	}),

	cursor: 'pointer',

	textDecoration: 'none',

	color: theme.palette.text.primary,

	'& > span': {
		fontFamily: theme.typography.fontFamily,
		fontSize: '1rem',
		lineHeight: 1.5,

		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},

	'&:hover:not(.active)': {
		backgroundColor: theme.palette.action.hover,
	},

	'&.active': {
		cursor: 'default',
		backgroundColor: theme.palette.action.focus,
	},
}));

const ModuleDropdown = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: spacing(0.5),

	paddingLeft: spacing(0.5),

	userSelect: 'none',
}));

export type SettingsItemType = {
	id: string;
	label: string;
	permissionCodes?: SessionPermissionType['code'][];
};

export type SettingsSectionType = {
	id: string;
	label?: string;
	items: SettingsItemType[];
	permissionCodes?: SessionPermissionType['code'][];
};

type SettingsNavbarItemProps = {
	active?: boolean;
	label: string;
	onClick: () => void;
};

const SettingsNavbarItem = ({ active = false, label, onClick }: SettingsNavbarItemProps) => {
	return (
		<SettingsItem onClick={onClick} className={clsx({ active })}>
			<span title={label.length > 20 ? label : undefined}>{label}</span>
		</SettingsItem>
	);
};

type SettingsModuleNavbarSectionProps = {
	label?: string;
	activeModule?: SettingsModuleType;
	modules: SettingsModuleType[];
	onModuleClick: (module: SettingsModuleType) => void;
};

const SettingsModuleNavbarSection = ({
	label,
	activeModule,
	onModuleClick,
	modules,
}: SettingsModuleNavbarSectionProps) => {
	const [isModulesMenuOpen, setIsModulesMenuOpen] = useState(true);

	if (modules.length === 0) return null;

	const updateIsModulesMenuOpen = () => setIsModulesMenuOpen((prevValue) => !prevValue);

	return (
		<SectionWrapper>
			{label && (
				<ModuleDropdown onClick={updateIsModulesMenuOpen}>
					<Icon color="primary" path={isModulesMenuOpen ? mdiMenuUp : mdiMenuDown} size="small" />
					<Typography color="primary" variant="body2">
						{label}
					</Typography>
				</ModuleDropdown>
			)}

			{isModulesMenuOpen &&
				modules.map((module) => {
					const { code, label: moduleLabel } = module;

					return (
						<SettingsNavbarItem
							label={moduleLabel}
							key={code}
							active={activeModule?.code === code}
							onClick={() => onModuleClick(module)}
						/>
					);
				})}
		</SectionWrapper>
	);
};

type SettingsModuleNavbarProps = {
	activeModule?: SettingsModuleType;
	generalModules: SettingsModuleType[];
	modules: SettingsModuleType[];
	onChangeActiveModule: (module: SettingsModuleType) => void;
};

const SettingsModuleNavbar = ({
	activeModule,
	generalModules,
	modules,
	onChangeActiveModule,
}: SettingsModuleNavbarProps) => (
	<Wrapper>
		<SettingsModuleNavbarSection
			activeModule={activeModule}
			onModuleClick={onChangeActiveModule}
			modules={generalModules}
		/>
		<SettingsModuleNavbarSection
			label="Modules"
			activeModule={activeModule}
			onModuleClick={onChangeActiveModule}
			modules={modules}
		/>
	</Wrapper>
);

export default SettingsModuleNavbar;
