import { Button, ButtonProps } from '@elipssolution/harfang';
import { useState } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

type ConfirmationButtonProps = {
	/** Title of dialog */
	title: string;
	/** Content of dialog */
	message?: string;
	/** The label of button confirm */
	confirmLabel: string;
	/**
	 * Variant of button.
	 * @default contained
	 */
	variant?: ButtonProps['variant'];
	/**
	 * Color of button.
	 * @default primary
	 */
	color?: ButtonProps['color'];
	/**
	 * The button is disabled.
	 * @default false
	 */
	disabled?: boolean;
	/**
	 * The action triggered on confirm.
	 * @returns The promise of action if it is async.
	 */
	onConfirm: () => Promise<void> | void;
};

const ConfirmationButton = ({
	title,
	message,
	confirmLabel = 'Confirmer',
	variant = 'contained',
	color,
	disabled,
	onConfirm,
}: ConfirmationButtonProps) => {
	const [isDialogOpen, setDialogOpen] = useState(false);

	const openDialog = () => {
		setDialogOpen(true);
	};

	const closeDialog = () => {
		setDialogOpen(false);
	};

	return (
		<>
			<Button variant={variant} color={color} disabled={disabled} onClick={openDialog}>
				{confirmLabel}
			</Button>

			<ConfirmationDialog
				open={isDialogOpen}
				title={title}
				message={message}
				confirmLabel={confirmLabel}
				cancelLabel="Annuler"
				onCancel={closeDialog}
				onConfirm={onConfirm}
			/>
		</>
	);
};

export default ConfirmationButton;
