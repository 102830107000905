import { gql } from '@apollo/client';

import { ConnectorType } from '../types/connector';

// QUERIES

export type FetchConnectorsType = {
	document_connectors: ConnectorType[];
};

export const FETCH_CONNECTORS = gql`
	query Document_connectors($hasRead: Boolean, $hasWrite: Boolean) {
		document_connectors(hasRead: $hasRead, hasWrite: $hasWrite) {
			code
			parameters {
				code
				isRequired
				name
				type
			}
			folders {
				code
				name
				isRequired
				variables {
					code
					name
					color
				}
			}
		}
	}
`;
