import { gql } from '@apollo/client';
import { AccountWithTransactionType } from '../types/account';

export const ACCOUNT_WITH_TRANSACTION_FIELDS = `
    code
    id
    name
`;

// QUERIES

export type FetchPreviousAndNextAccountsWithTransactionType = {
	accounting_getPreviousAndNextAccountsWithTransactionBySelectedAccount: {
		previous: AccountWithTransactionType;
		next: AccountWithTransactionType;
	};
};

export const FETCH_PREVIOUS_AND_NEXT_ACCOUNTS_WITH_TRANSACTION = gql`
	query Accounting_getPreviousAndNextAccountsWithTransactionBySelectedAccount(
		$selectedId: ID!
		$fiscalYearId: ID!
		$accountingDate: BetweenDate
		$filter: accounting_AccountByAnalyticalSectionLevelFilterInput
	) {
		accounting_getPreviousAndNextAccountsWithTransactionBySelectedAccount(
			selectedId: $selectedId
			fiscalYearId: $fiscalYearId
			accountingDate: $accountingDate
			filter: $filter
		) {
			next {
				${ACCOUNT_WITH_TRANSACTION_FIELDS}
			}
			previous {
				${ACCOUNT_WITH_TRANSACTION_FIELDS}
			}
		}
	}
`;
