import { gql } from '@apollo/client';

import { GROUP_PERMISSION_FIELDS, GROUP_PERMISSION_MODULE_FIELDS } from './permission';
import { GroupType } from '../types/group';

const INTERNAL_GROUP_BASE_FIELDS = `
	id
	isAdministrator
	isDefault
	name
`;

export const INTERNAL_GROUP_FIELDS = `
	${INTERNAL_GROUP_BASE_FIELDS}
	permissionModules {
		${GROUP_PERMISSION_MODULE_FIELDS}
		permissions {
			${GROUP_PERMISSION_FIELDS}
		}
	}
`;

// QUERIES

export type FetchInternalGroupType = {
	internalGroup: GroupType;
};

export const FETCH_INTERNAL_GROUP = gql`
	query InternalGroup($internalGroupId: ID!) {
		internalGroup(id: $internalGroupId) {
			${INTERNAL_GROUP_FIELDS}
		}
	}
`;

export type FetchInternalGroupByDefaultType = {
	internalGroupByDefault: GroupType;
};

export const FETCH_INTERNAL_GROUP_BY_DEFAULT = gql`
	query InternalGroupByDefault {
		internalGroupByDefault {
			${INTERNAL_GROUP_FIELDS}
		}
	}
`;

export type FetchInternalGroupsType = {
	internalGroups: {
		items: GroupType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_GROUPS = gql`
	query InternalGroups($search: String, $orderBy: OrderBy, $page: Page!) {
		internalGroups(search: $search, orderBy: $orderBy, page: $page) {
			count
			items {
				${INTERNAL_GROUP_BASE_FIELDS}
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateInternalGroupType = {
	createInternalGroup: GroupType;
};

export const CREATE_INTERNAL_GROUP = gql`
	mutation CreateInternalGroup($createInternalGroupInput: CreateInternalGroupInput!) {
		createInternalGroup(createInternalGroupInput: $createInternalGroupInput) {
			${INTERNAL_GROUP_FIELDS}
		}
	}
`;

export type UpdateInternalGroupType = {
	updateInternalGroup: GroupType;
};

export const UPDATE_INTERNAL_GROUP = gql`
	mutation UpdateInternalGroup($updateInternalGroupInput: UpdateInternalGroupInput!) {
		updateInternalGroup(updateInternalGroupInput: $updateInternalGroupInput) {
			${INTERNAL_GROUP_FIELDS}
		}
	}
`;

export const REMOVE_INTERNAL_GROUP = gql`
	mutation RemoveInternalGroup($removeInternalGroupId: ID!) {
		removeInternalGroup(id: $removeInternalGroupId) {
			id
		}
	}
`;
