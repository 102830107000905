import { ApolloQueryResult, OperationVariables, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CustomerFileType, CustomerFileWithGroup } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_EXTERNAL_GROUPS, FetchExternalGroupsType } from '../../../api/externalGroup';
import {
	FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
	FetchExternalUserCustomerFileRelationByCustomerFileAndUserType,
	FetchExternalUserCustomerFileRelationsByUserType,
	REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION,
	RemoveExternalUserCustomerFileRelationType,
	UPDATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION,
	UpdateExternalUserCustomerFileRelationType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import { ExternalUserCustomerFileRelationType } from '../../../types/relation';
import {
	AddExternalUserRelationType,
	AddExternalUserRelationWithCustomerFilesType,
	ExternalUserType,
	UserTypeEnum,
} from '../../../types/user';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

type FormType = {
	customerFile?: CustomerFileType;
	group?: GroupType;
};

type RelationCustomerFileFormProps = {
	disabled: boolean;
	user: ExternalUserType;
	customerFileUserRelation: Partial<ExternalUserCustomerFileRelationType>;
	onError: (error: string) => void;
	onRelationRefetch: (
		variables?: Partial<OperationVariables> | undefined,
	) => Promise<ApolloQueryResult<FetchExternalUserCustomerFileRelationByCustomerFileAndUserType>>;
	onRemove: () => void;
	onSelectedCustomerFileChange: (newSelectedCustomerFile: CustomerFileType) => void;
};

const RelationCustomerFileForm = ({
	disabled,
	user,
	customerFileUserRelation,
	onError,
	onRelationRefetch,
	onRemove,
	onSelectedCustomerFileChange,
}: RelationCustomerFileFormProps) => {
	const {
		user: { type },
	} = useSession();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const {
		control,
		handleSubmit,
		formState: { isDirty, isValid },
		reset,
		setValue,
	} = useForm<FormType>();

	const { customerFile, group, id: customerFileUserRelationId } = customerFileUserRelation;

	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();

	const handleSelectedCustomerFile = (newSelectedCustomerFile?: CustomerFileType) =>
		newSelectedCustomerFile && onSelectedCustomerFileChange(newSelectedCustomerFile);

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);

	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const [fetchExternalCustomerFileRelation] = useLazyQuery<FetchExternalUserCustomerFileRelationsByUserType>(
		FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
	);

	const [fetchExternalGroups] = useLazyQuery<FetchExternalGroupsType>(FETCH_EXTERNAL_GROUPS);

	const [updateExternalUserCustomerFileRelation, { loading: isUpdateExternalUserCustomerFileRelationLoading }] =
		useMutation<UpdateExternalUserCustomerFileRelationType>(UPDATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION, {
			onCompleted: ({
				updateExternalUserCustomerFileRelation: {
					group: updatedGroup,
					externalUser: { id: externalUserId },
					customerFile: { id: customerFileId },
				},
			}) => {
				if (userCustomerFileRelations.length > 0) {
					setUserCustomerFileRelations((prevValues) => {
						if ('user' in prevValues[0] && 'group' in prevValues[0]) {
							return (prevValues as AddExternalUserRelationType[]).map(({ user: prevUser, group: prevGroup }) => ({
								user: prevUser,
								group: prevUser.id === externalUserId && customerFile?.id === customerFileId ? updatedGroup : prevGroup,
							}));
						}

						if ('customerFile' in prevValues[0] && 'group' in prevValues[0]) {
							return (prevValues as CustomerFileWithGroup[]).map(
								({ customerFile: prevCustomerFile, group: prevGroup }) => ({
									customerFile: prevCustomerFile,
									group:
										user.id === externalUserId && prevCustomerFile.id === customerFileId ? updatedGroup : prevGroup,
								}),
							);
						}

						return (prevValues as AddExternalUserRelationWithCustomerFilesType[]).map(
							({ user: prevUser, customerFiles: prevCustomerFiles }) => ({
								user: prevUser,
								customerFiles:
									prevUser.id === externalUserId
										? prevCustomerFiles.map(({ customerFile: prevCustomerFile, group: prevGroup }) => ({
												customerFile: prevCustomerFile,
												group: prevCustomerFile.id === customerFileId ? updatedGroup : prevGroup,
										  }))
										: prevCustomerFiles,
							}),
						);
					});
				}

				reset({ group: updatedGroup });
			},
			onError: (error) =>
				onError(generateErrorInformations({ error, resource: 'updateExternalUserCustomerFileRelation' }).message),
		});

	const [removeExternalUserCustomerFileRelation, { loading: isRemoveExternalUserCustomerFileRelationLoading }] =
		useMutation<RemoveExternalUserCustomerFileRelationType>(REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION, {
			onCompleted: () => {
				if (userCustomerFileRelations.length > 0) {
					setUserCustomerFileRelations((prevValues) => {
						if ('user' in prevValues[0] && 'group' in prevValues[0]) {
							return (prevValues as AddExternalUserRelationType[]).filter(
								({ user: { id: prevUserId } }) => user.id !== prevUserId,
							);
						}

						if ('customerFile' in prevValues[0] && 'group' in prevValues[0]) {
							return (prevValues as CustomerFileWithGroup[]).filter(
								({ customerFile: { id: prevCustomerFileId } }) => prevCustomerFileId !== customerFile?.id,
							);
						}

						return (prevValues as AddExternalUserRelationWithCustomerFilesType[]).map(
							({ user: prevUser, customerFiles: prevCustomerFiles }) => ({
								user: prevUser,
								customerFiles: prevCustomerFiles.filter(
									({ customerFile: { id: prevCustomerFileId } }) => prevCustomerFileId !== customerFile?.id,
								),
							}),
						);
					});
				}
				onRemove();
			},
			onError: (mutationError) =>
				setConfirmationDialogError(
					generateErrorInformations({ error: mutationError, resource: 'removeExternalUserCustomerFileRelation' })
						.message,
				),
		});

	const handleRemove = useCallback(
		() => removeExternalUserCustomerFileRelation({ variables: { id: customerFileUserRelationId } }),
		[removeExternalUserCustomerFileRelation, customerFileUserRelationId],
	);

	const customerFilesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: CustomerFileType[];
		}> => {
			const { data, error } = await fetchExternalCustomerFileRelation({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
					userId: user.id,
				},
			});

			if (error) {
				throw error;
			}

			const {
				externalUserCustomerFileRelationsByUser: { count = 0, items = [] },
			} = data ?? {
				externalUserCustomerFileRelationsByUser: {},
			};

			return {
				count,
				items: items.map(({ customerFile: fetchedCustomerFile }) => fetchedCustomerFile),
			};
		},
		[fetchExternalCustomerFileRelation, user],
	);

	const externalGroupsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: GroupType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchExternalGroups({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				externalGroups: { count = 0, items = [] },
			} = data ?? {
				externalGroups: {},
			};

			!customerFileUserRelation &&
				setValue(
					'group',
					items.find(({ isDefault }) => isDefault),
				);

			return {
				count,
				items,
			};
		},
		[customerFileUserRelation, fetchExternalGroups, setValue],
	);

	const isLoading = useMemo(
		() => isUpdateExternalUserCustomerFileRelationLoading || isRemoveExternalUserCustomerFileRelationLoading,
		[isUpdateExternalUserCustomerFileRelationLoading, isRemoveExternalUserCustomerFileRelationLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => disabled || isLoading || !isDirty || !isValid,
		[disabled, isLoading, isDirty, isValid],
	);

	const onSubmit = useCallback(
		({ group: formGroup }: FormType) =>
			updateExternalUserCustomerFileRelation({
				variables: {
					updateExternalUserCustomerFileRelationInput: {
						id: customerFileUserRelationId,
						groupId: formGroup?.id,
					},
				},
			}).then(onRelationRefetch),
		[updateExternalUserCustomerFileRelation, customerFileUserRelationId, onRelationRefetch],
	);

	// Set form initial values
	useEffect(() => reset({ group, customerFile }), [reset, group, customerFile]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveExternalUserCustomerFileRelationLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveExternalUserCustomerFileRelationLoading,
				persistantErrorMessage: confirmationDialogError,

				color: 'error',
				label: 'Supprimer',
				onClick: handleRemove,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemove, confirmationDialogError, isRemoveExternalUserCustomerFileRelationLoading],
	);

	return (
		<>
			<SettingsGroup>
				<SettingsItemAutocomplete<CustomerFileType>
					dataSource={customerFilesDataSource}
					description="Dossier attaché à l'utilisateur."
					getOptionLabel={({ name }) => name ?? ''}
					label="Dossier"
					value={customerFile}
					onChange={handleSelectedCustomerFile}
					disableClearable
				/>
				{type === UserTypeEnum.INTERNAL && (
					<Controller
						control={control}
						render={({ field }) => (
							<SettingsItemAutocomplete<GroupType>
								{...field}
								dataSource={externalGroupsDataSource}
								disabled={disabled}
								description="Groupe attaché à l'utilisateur."
								getOptionLabel={({ name }) => name ?? ''}
								label="Groupe"
								disableClearable
							/>
						)}
						name="group"
					/>
				)}
			</SettingsGroup>

			<Stack flexDirection="row" justifyContent="space-between">
				<Tooltip content={disabled ? 'Un utilisateur ne peut pas supprimer la relation lui même' : undefined}>
					<div>
						<Button color="error" disabled={disabled || isLoading} onClick={openConfirmationDialog} variant="outlined">
							Supprimer
						</Button>
					</div>
				</Tooltip>

				{type === UserTypeEnum.INTERNAL && (
					<Tooltip content={disabled ? 'Un utilisateur ne peut pas modifier la relation lui même' : undefined}>
						<div>
							<Button
								disabled={isMutationButtonDisabled}
								onClick={handleSubmit(onSubmit)}
								startIcon={<Icon path={mdiPencil} />}
								variant="contained"
							>
								Modifier
							</Button>
						</div>
					</Tooltip>
				)}
			</Stack>

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				open={isConfirmationDialogOpen}
				onClose={closeConfirmationDialog}
				title={`Êtes-vous sûr de vouloir supprimer ${`${user.firstName ?? ''} ${
					user.lastName
				}`.trim()} de l'entreprise ${customerFile?.name || ''} ?`}
			/>
		</>
	);
};

export default RelationCustomerFileForm;
