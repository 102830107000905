import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { FetchBillExchangeConfigurationsType, FETCH_BILL_EXCHANGE_CONFIGURATIONS } from '../../../api/billExchange';
import { BankType } from '../../../types/bank';

const useBillExchangeConfigurations = ({ bankId }: { bankId?: BankType['id'] }) => {
	const [error, setError] = useState<string>();

	const { loading } = useQuery<FetchBillExchangeConfigurationsType>(FETCH_BILL_EXCHANGE_CONFIGURATIONS, {
		onCompleted: ({ quickentry_billExchangeConfigurations: { journalCode, paymentModeCode } }) => {
			let missingConfigurations = '';

			if (!journalCode) {
				missingConfigurations += 'journal';
			}

			if (!paymentModeCode) {
				missingConfigurations += `${missingConfigurations.length > 0 ? ' et' : ''} mode de paiement`;
			}

			if (missingConfigurations) {
				setError(
					`Aucun ${missingConfigurations} configuré pour la saisie de LCR sur ce dossier. Veuillez contacter votre responsable paramétrage.`,
				);
			}
		},
		skip: !bankId,
	});

	return { error, loading };
};

export default useBillExchangeConfigurations;
