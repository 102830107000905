import { useTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ConsultVoucherDialog from './dialog/ConsultVoucherDialog';
import EditVoucherDialog from './dialog/EditVoucherDialog';
import SubmittedOrValidatedVoucherTable from './SubmittedOrValidatedVoucherTable';
import SynchronizedVoucherTable from './SynchronizedVoucherTable';
import TemplatesCarousel from './TemplatesCarousel';
import PermissionWall from '../../../../components/PermissionWall';
import { useSession } from '../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../types/permission';
import { CustomerFileTemplateType } from '../../types/template';
import { VoucherType } from '../../types/voucher';

const Voucher = () => {
	const { push } = useRouter();
	const { permissions } = useSession();

	const submittedOrValidatedVoucherTableInstance = useTable();
	const synchronizedVoucherTableInstance = useTable();

	const [selectedTemplate, setSelectedTemplate] = useState<CustomerFileTemplateType>();
	const [selectedVoucherId, setSelectedVoucherId] = useState<VoucherType['id']>();
	const [isConsultDialogOpen, setIsConsultDialogOpen] = useState(false);
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

	const handleVoucherDialogClose = () => {
		setSelectedTemplate(undefined);
		setSelectedVoucherId(undefined);
		setIsEditDialogOpen(false);
		setIsConsultDialogOpen(false);
	};

	const handleTemplateSelection = (template: CustomerFileTemplateType) => {
		setIsEditDialogOpen(true);
		setSelectedTemplate(template);
	};

	const handleVoucherSelection = (voucherId: VoucherType['id']) => {
		setSelectedVoucherId(voucherId);
	};

	const handleSubmittedOrValidatedVoucherSelection = (voucherId: VoucherType['id']) => {
		setIsEditDialogOpen(true);
		handleVoucherSelection(voucherId);
	};

	const handleSynchronizedVoucherSelection = (voucherId: VoucherType['id']) => {
		setIsConsultDialogOpen(true);
		handleVoucherSelection(voucherId);
	};

	const handleVoucherEdit = () => submittedOrValidatedVoucherTableInstance.reload();

	const handleVoucherValidation = () => {
		submittedOrValidatedVoucherTableInstance.reload();
		synchronizedVoucherTableInstance.reload();
	};

	useEffect(() => {
		const hasUserPermissions = [PermissionEnum.QUICKENTRY_WRITE, PermissionEnum.QUICKENTRY_READ].some(
			(permission) => permissions.find(({ code }) => permission === code)?.value,
		);

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	return (
		<>
			<Stack flex={1} gap={2}>
				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_WRITE]}>
					<TemplatesCarousel onTemplateSelection={handleTemplateSelection} selectedTemplate={selectedTemplate} />
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_VALIDATE, PermissionEnum.QUICKENTRY_WRITE]}>
					<SubmittedOrValidatedVoucherTable
						onVoucherSelection={handleSubmittedOrValidatedVoucherSelection}
						onVoucherValidation={handleVoucherValidation}
						tableInstance={submittedOrValidatedVoucherTableInstance}
					/>
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_READ]}>
					<SynchronizedVoucherTable
						onVoucherSelection={handleSynchronizedVoucherSelection}
						selectedTemplate={selectedTemplate}
						tableInstance={synchronizedVoucherTableInstance}
					/>
				</PermissionWall>
			</Stack>

			{isEditDialogOpen && (
				<EditVoucherDialog
					open={isEditDialogOpen}
					onClose={handleVoucherDialogClose}
					templateId={selectedTemplate?.id}
					voucherId={selectedVoucherId}
					onVoucherEdit={handleVoucherEdit}
				/>
			)}

			{isConsultDialogOpen && selectedVoucherId && (
				<ConsultVoucherDialog
					open={isConsultDialogOpen}
					onClose={handleVoucherDialogClose}
					voucherId={selectedVoucherId}
				/>
			)}
		</>
	);
};

export default Voucher;
