import { gql } from '@apollo/client';

import { CUSTOMER_FILE_FIELDS } from './customerFile';
import { ExternalUserType } from '../types/user';

export const EXTERNAL_USER_FIELDS = `
	email
	lastName
	firstName
	id
`;

// QUERIES

export type FetchExternalUsersByUserCustomerFilesType = {
	externalUsersByUserCustomerFiles: {
		count: number;
		items: ExternalUserType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_EXTERNAL_USERS_BY_USER_CUSTOMER_FILES = gql`
	query ExternalUsersByUserCustomerFiles($page: Page!, $orderBy: OrderBy, $search: String) {
		externalUsersByUserCustomerFiles(page: $page, orderBy: $orderBy, search: $search) {
			count
			items {
				${EXTERNAL_USER_FIELDS}
				customerFiles {
					${CUSTOMER_FILE_FIELDS}
				}
			}
			limit
			offset
		}
	}
`;

export type FetchExternalUsersWithoutCustomerFileRelationType = {
	externalUsersWithoutCustomerFileRelation: {
		count: number;
		items: ExternalUserType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_EXTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION = gql`
	query ExternalUsersWithoutCustomerFileRelation($customerFileId: ID!, $page: Page!, $search: String) {
		externalUsersWithoutCustomerFileRelation(customerFileId: $customerFileId, page: $page, search: $search) {
			count
			items {
				id
				lastName
				firstName
				email
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type UpdateExternalUserType = {
	updateExternalUser: ExternalUserType & {
		__typename: 'ExternalUser';
	};
};

export const UPDATE_EXTERNAL_USER = gql`
	mutation UpdateExternalUser($updateExternalUserInput: UpdateExternalUserInput!) {
		updateExternalUser(updateExternalUserInput: $updateExternalUserInput) {
			${EXTERNAL_USER_FIELDS}
		}
	}
`;

export const REMOVE_EXTERNAL_USER = gql`
	mutation RemoveExternalUser($id: ID!) {
		removeExternalUser(id: $id) {
			id
		}
	}
`;

export const SEND_EXTERNAL_USER_ACCOUNT_CONFIRMATION_MAIL = gql`
	mutation SendExternalUserAccountConfirmationMail($externalUserId: ID!) {
		sendExternalUserAccountConfirmationMail(id: $externalUserId)
	}
`;
