import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { Avatar, styled } from '@mui/material';
import { useCallback } from 'react';

import ContactForm from './ContactForm';
import LinkIcon from '../../../components/LinkIcon';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { FETCH_SETTING_CONTACTS, FetchSettingContactsType } from '../../api/contact';
import { ContactType } from '../../types/contact';

const AVATAR_SIZE = 36;

const TableAvatar = styled(Avatar)(({ theme: { typography } }) => ({
	height: AVATAR_SIZE,
	width: AVATAR_SIZE,
	fontSize: typography.body1.fontSize,
}));

const columns: TableColumnType<ContactType>[] = [
	{
		key: 'picture',
		render: ({ id, name, hasPicture }) => (
			<TableAvatar alt={name} src={hasPicture ? `/contact/images?contactId=${id}` : ''}>
				{name
					.split(' ')
					.slice(0, 2)
					.map((word) => word.charAt(0))
					.join('')}
			</TableAvatar>
		),
		flexGrow: 0,
		width: AVATAR_SIZE,
	},
	{
		field: 'name',
		key: 'name',
		title: 'Nom',
		width: 150,
	},
	{
		field: 'email',
		key: 'email',
		title: 'Email',
		width: 150,
	},
	{
		field: 'job',
		key: 'job',
		render: ({ job }) => job ?? '',
		title: 'Fonction',
		width: 100,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const Contacts = () => {
	const { push } = useSettingsDialog();

	const [fetchSettingContacts] = useLazyQuery<FetchSettingContactsType>(FETCH_SETTING_CONTACTS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<ContactType>,
		): Promise<{
			count: number;
			items: ContactType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingContacts({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				settingContacts: { count = 0, items = [] },
			} = data ?? {
				settingContacts: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingContacts],
	);

	const navigateToCustomerFileSynchronization = (contact: ContactType) => {
		push(<ContactForm contact={contact} />);
	};

	return (
		<SettingsDialogPage title="Contacts">
			<SettingsTable<ContactType>
				onRowClick={navigateToCustomerFileSynchronization}
				dataSource={dataSource}
				tableColumns={columns}
				title=""
			/>
		</SettingsDialogPage>
	);
};

export default Contacts;
