import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Icon, SettingsGroup, SettingsItemAutocomplete, useAutocomplete } from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddedCustomerFileUsersTableSection from './AddedCustomerFileUsersTableSection';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType, CustomerFileWithGroup } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER,
	FetchCustomerFilesWithoutRelationWithUser,
} from '../../../api/customerFile';
import { FETCH_EXTERNAL_GROUPS, FetchExternalGroupsType } from '../../../api/externalGroup';
import {
	CREATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION,
	CreateExternalUserCustomerFileRelationType,
	REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
	RemoveExternalUserCustomerFileRelationByCustomerFileAndUserType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import { ExternalUserType, UserTypeEnum } from '../../../types/user';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const FormWrapper = styled('form')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),
}));

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',
	display: 'grid',
	placeItems: 'center',
	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

type FormType = {
	customerFile?: CustomerFileType;
	group?: GroupType;
};

type AddUserCustomerFileRelationProps = {
	user: ExternalUserType;
};

const AddUserCustomerFileRelationForm = ({ user }: AddUserCustomerFileRelationProps) => {
	const { back } = useSettingsDialog();
	const {
		user: { type },
	} = useSession();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const groupAutocomplete = useAutocomplete();
	const customerFilesAutocomplete = useAutocomplete();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const [error, setError] = useState<string>();
	const [customerFilesToAdd, setCustomerFilesToAdd] = useState(userCustomerFileRelations as CustomerFileWithGroup[]);

	const [fetchExternalGroup] = useLazyQuery<FetchExternalGroupsType>(FETCH_EXTERNAL_GROUPS);

	const [fetchCustomerFilesWithoutRelation] = useLazyQuery<FetchCustomerFilesWithoutRelationWithUser>(
		FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER,
	);

	const customerFilesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchCustomerFilesWithoutRelation({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
					userId: user.id,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				customerFilesWithoutRelationWithUser: { count = 0, items = [] },
			} = data ?? {
				customerFilesWithoutRelationWithUser: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFilesWithoutRelation, user.id],
	);

	const externalGroupsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: GroupType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchExternalGroup({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			if (!data)
				return {
					count: 0,
					items: [],
				};

			const {
				externalGroups: { count, items },
			} = data;

			return {
				count,
				items,
			};
		},
		[fetchExternalGroup],
	);

	const [createExternalUserCustomerFileRelation, { loading: isCreateExternalUserCustomerFileRelationLoading }] =
		useMutation<CreateExternalUserCustomerFileRelationType>(CREATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION, {
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createExternalUserCustomerFileRelation' }).message,
				),
		});

	const [removeExternalUserCustomerFileRelation] =
		useMutation<RemoveExternalUserCustomerFileRelationByCustomerFileAndUserType>(
			REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
			{
				onError: (mutationError) =>
					setError(
						generateErrorInformations({ error: mutationError, resource: 'removeExternalUserCustomerFileRelation' })
							.message,
					),
			},
		);

	const handleFormReset = useCallback(() => {
		reset({
			customerFile: undefined,
			group: undefined,
		});

		groupAutocomplete.reload();
		customerFilesAutocomplete.reload();
	}, [customerFilesAutocomplete, groupAutocomplete, reset]);

	const onSubmit = useCallback(
		({ customerFile, group }: FormType) =>
			customerFile &&
			createExternalUserCustomerFileRelation({
				variables: {
					createExternalUserCustomerFileRelationInput: {
						groupId: group?.id,
						customerFileId: customerFile.id,
						userId: user.id,
					},
				},
			}).then(() => {
				setCustomerFilesToAdd((prevCustomerFiles) => [...prevCustomerFiles, { customerFile, group }]);
				handleFormReset();
			}),
		[createExternalUserCustomerFileRelation, handleFormReset, user.id],
	);

	const removeCustomerFileAdd = useCallback(
		async (customerFileId: CustomerFileWithGroup['customerFile']['id']) => {
			await removeExternalUserCustomerFileRelation({
				variables: { customerFileId, userId: user.id },
			});

			customerFilesAutocomplete.reload();

			setCustomerFilesToAdd((prevCustomerFiles) => {
				const customerFilesToAddFiltered = prevCustomerFiles.filter(
					({ customerFile: { id } }) => id !== customerFileId,
				);

				setUserCustomerFileRelations(customerFilesToAddFiltered);

				return customerFilesToAddFiltered;
			});
		},
		[customerFilesAutocomplete, removeExternalUserCustomerFileRelation, setUserCustomerFileRelations, user.id],
	);

	const handleBack = useCallback(() => {
		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
		back();
	}, [back, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	useEffect(() => {
		if (customerFilesToAdd.length > 0) {
			setUserCustomerFileRelations(customerFilesToAdd);
		}
	}, [customerFilesToAdd, setUserCustomerFileRelations]);

	return (
		<SettingsDialogPage
			title={`Ajout de dossiers - ${`${user.firstName ?? ''} ${user.lastName}`.trim()}`}
			onBack={handleBack}
		>
			<FormWrapper>
				<SettingsGroup>
					<Controller
						control={control}
						name="customerFile"
						render={({ field }) => (
							<SettingsItemAutocomplete<CustomerFileType>
								{...field}
								autocomplete={customerFilesAutocomplete}
								dataSource={customerFilesDataSource}
								description="Dossier client à ajouter."
								getOptionLabel={(option) => {
									const { code, name } = option ?? {};

									return code ? `${code} - ${name}` : '';
								}}
								label="Entreprise"
								disableClearable
								required
							/>
						)}
						rules={{ required: true }}
					/>
					{type === UserTypeEnum.INTERNAL && (
						<Controller
							control={control}
							name="group"
							render={({ field }) => (
								<SettingsItemAutocomplete<GroupType>
									{...field}
									autocomplete={groupAutocomplete}
									dataSource={externalGroupsDataSource}
									description="Groupe attaché à l'utilisateur."
									getOptionLabel={(option) => {
										const { name } = option ?? {};

										return name ?? '';
									}}
									label="Groupe"
									disableClearable
									required
								/>
							)}
							rules={{ required: true }}
						/>
					)}
				</SettingsGroup>
			</FormWrapper>

			<Stack alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
				<Button
					disabled={isCreateExternalUserCustomerFileRelationLoading || !isDirty || !isValid}
					startIcon={<Icon path={mdiPlus} />}
					onClick={handleSubmit(onSubmit)}
					variant="contained"
				>
					Ajouter
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</Stack>

			<AddedCustomerFileUsersTableSection
				customerFiles={customerFilesToAdd}
				onRemoveCustomerFile={removeCustomerFileAdd}
				user={user}
			/>
		</SettingsDialogPage>
	);
};

export default AddUserCustomerFileRelationForm;
