import { gql } from '@apollo/client';

import { ModuleAvailabilityType, SettingCustomerFilesModulesEnabledByModule } from '../../types/module';

const MODULE_AVAILABILITY_FIELDS = `
	isEnabled
	moduleCode
	moduleId
`;

const UPDATED_MODULE_AVAILABILITY_FIELDS = `
	isEnabled
	moduleId
`;

// QUERIES

export type FetchSettingModulesAvailabilityByCustomerFileType = {
	settingModuleAvailabilityByCustomerFile: ModuleAvailabilityType[];
};

export const FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE = gql`
	query SettingModuleAvailabilityByCustomerFile($customerFileId: ID!) {
		settingModuleAvailabilityByCustomerFile(customerFileId: $customerFileId) {
			${MODULE_AVAILABILITY_FIELDS}
		}
	}
`;

export type FetchSettingModulesAvailabilityByDomainType = {
	settingCustomerFileModuleAvailabilityByDomain: {
		items: {
			customerFile: {
				id: string;
				code: string;
				name: string;
			};
			moduleAvailabilities: ModuleAvailabilityType[];
		}[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_MODULES_AVAILABILITY_BY_DOMAIN = gql`
	query SettingCustomerFileModuleAvailabilityByDomain($domainId: ID!, $page: Page!, $search: String) {
		settingCustomerFileModuleAvailabilityByDomain(domainId: $domainId, page: $page, search: $search) {
			count
			items {
				customerFile {
					id
					code
					name
				}
				moduleAvailabilities {
					${MODULE_AVAILABILITY_FIELDS}
				}
			}
			limit
			offset
		}
	}
`;

export type FetchSettingCustomerFilesModulesEnabledByDomainType = {
	settingCustomerFilesModulesEnabledByDomain: SettingCustomerFilesModulesEnabledByModule[];
};

export const FETCH_SETTING_CUSTOMER_FILES_MODULES_ENABLED_BY_DOMAIN = gql`
	query SettingCustomerFilesModulesEnabledByDomain($domainId: ID!) {
		settingCustomerFilesModulesEnabledByDomain(domainId: $domainId) {
			moduleId
			customerFileIds
		}
	}
`;

// MUTATIONS

export type UpdateSettingCustomerFileModuleAvailabilityType = {
	updateSettingCustomerFileModuleAvailability: ModuleAvailabilityType;
};

export const UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY = gql`
	mutation UpdateSettingCustomerFileModuleAvailability(
		$customerFileUpdateModuleAvailabilityInput: UpdateSettingCustomerFileModuleAvailabilityInput!
	) {
		updateSettingCustomerFileModuleAvailability(
			customerFileUpdateModuleAvailabilityInput: $customerFileUpdateModuleAvailabilityInput
		) {
			${UPDATED_MODULE_AVAILABILITY_FIELDS}
		}
	}
`;

export type UpdateSettingCustomerFileModuleAvailabilityByDomainType = {
	updateSettingCustomerFileModuleAvailabilityByDomain: ModuleAvailabilityType[];
};

export const UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY_BY_DOMAIN = gql`
	mutation UpdateSettingCustomerFileModuleAvailabilityByDomain(
		$updateSettingCustomerFileModuleAvailabilityByDomainInput: [UpdateSettingCustomerFileModuleAvailabilityInput!]!
	) {
		updateSettingCustomerFileModuleAvailabilityByDomain(
			updateSettingCustomerFileModuleAvailabilityByDomainInput: $updateSettingCustomerFileModuleAvailabilityByDomainInput
		) {
			customerFileId
			${UPDATED_MODULE_AVAILABILITY_FIELDS}
		}
	}
`;
