import { SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';

// "import type" was made to avoid dependency cycle
import type { FormType } from './WidgetRevenueConfiguration';

type SettingsItemFormulaFieldProps = {
	control: Control<FormType>;
	initialValue?: string;
};

const SettingsItemFormulaField = ({ control, initialValue }: SettingsItemFormulaFieldProps) => {
	const checkUniqueAccounts = useCallback(
		(formula: string) => {
			if (initialValue === formula) {
				return undefined;
			}

			const accountsInFormula = formula.split(/[+-]/);
			const isEveryAccountUnique = accountsInFormula.length === new Set(accountsInFormula).size;

			return isEveryAccountUnique || 'Les comptes doivent être uniques';
		},
		[initialValue],
	);

	return (
		<Controller
			name="formula"
			control={control}
			defaultValue={initialValue}
			render={({ field, fieldState: { error } }) => (
				<SettingsItemTextField
					{...field}
					description="Formule de calcul."
					helperText={error?.message || ' '}
					invalid={!!error}
					label="Formule"
					required
				/>
			)}
			rules={{
				pattern: {
					value: /(^[+-]?)([a-zA-Z0-9]+[+-])+([a-zA-Z0-9]+)$/,
					message: 'Formule invalide',
				},
				validate: checkUniqueAccounts,
				required: true,
			}}
		/>
	);
};
export default SettingsItemFormulaField;
