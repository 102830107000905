import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';
import { ReactElement, useEffect } from 'react';

import { useSession } from './SessionProvider';

type PublicRuntimeConfigType = {
	publicRuntimeConfig: {
		appVersion: string;
		bugsnagApiKey: string;
		bugsnagReleaseStage: string;
		nodeEnv: string;
	};
};

const BugSnagProvider = ({ children }: { children: ReactElement }) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { appVersion, bugsnagApiKey, bugsnagReleaseStage, nodeEnv } = publicRuntimeConfig;

	const { user, impersonatedUser, customerFile } = useSession();

	useEffect(() => {
		if (
			nodeEnv === 'production' &&
			typeof window !== 'undefined' &&
			bugsnagApiKey &&
			bugsnagReleaseStage &&
			!Bugsnag.isStarted()
		) {
			const commonBugsnagOptions = {
				apiKey: bugsnagApiKey,
				releaseStage: bugsnagReleaseStage,
				appVersion,
			};

			Bugsnag.start({
				...commonBugsnagOptions,
				endpoints: {
					sessions: '/api/bugsnag?type=sessions',
					notify: '/api/bugsnag?type=notify',
				},
				plugins: [new BugsnagPluginReact()],
				onError: (event) => {
					const { email, isInternalAdministrator, type } = user;

					event.setUser('0', email);

					event.addMetadata('userInfos', {
						...(impersonatedUser && {
							impersonatedUser: impersonatedUser.email,
						}),
						isInternalAdministrator,
						type,
					});

					if (customerFile) {
						const { id: customerFileId, code: customerFileCode, name: customerFileName } = customerFile;

						event.addMetadata('customerFile', {
							id: customerFileId,
							code: customerFileCode,
							name: customerFileName,
						});
					}
				},
			});

			BugsnagPerformance.start({ ...commonBugsnagOptions, endpoint: '/api/bugsnag?type=performance' });
		}
	}, [
		appVersion,
		bugsnagApiKey,
		bugsnagReleaseStage,
		customerFile,
		impersonatedUser,
		nodeEnv,
		publicRuntimeConfig,
		user,
	]);

	return children;
};

export default BugSnagProvider;
