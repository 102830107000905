import { useMutation } from '@apollo/client';
import { SettingsGroup, SettingsItemColor } from '@elipssolution/harfang';
import { useTheme } from '@mui/material';

import { UPDATE_THEME, UpdateThemeType } from '../../api/theme';

declare module '@mui/material/styles' {
	interface Palette {
		tertiary: Palette['primary'];
	}

	interface PaletteOptions {
		tertiary: PaletteOptions['primary'];
	}
}

const ColorsSection = () => {
	const { palette } = useTheme();

	const [updateTheme] = useMutation<UpdateThemeType>(UPDATE_THEME);

	const handleColorChange = (colorName: string, code?: string) => {
		if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(code ?? '')) return;

		updateTheme({
			variables: {
				updateThemeInput: {
					primaryColor: colorName === 'primary' ? code : palette.primary.main,
					secondaryColor: colorName === 'secondary' ? code : palette.secondary.main,
					tertiaryColor: colorName === 'tertiary' ? code : palette.tertiary.main,
				},
			},
			refetchQueries: ['Theme'],
		}).catch((error) => {
			throw error;
		});
	};

	return (
		<SettingsGroup label="Couleurs">
			<SettingsItemColor
				label="Couleur primaire"
				description="Boutons et titres"
				onChange={(code) => handleColorChange('primary', code)}
				selectedColorCode={palette.primary.main}
			/>
			<SettingsItemColor
				label="Couleur secondaire"
				description="Boutons du menu"
				onChange={(code) => handleColorChange('secondary', code)}
				selectedColorCode={palette.secondary.main}
			/>
			<SettingsItemColor
				label="Couleur tertiaire"
				description="Couleur de fond du menu"
				onChange={(code) => handleColorChange('tertiary', code)}
				selectedColorCode={palette.tertiary.main}
			/>
		</SettingsGroup>
	);
};

export default ColorsSection;
