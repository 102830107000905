import { useLazyQuery } from '@apollo/client';
import { TableColumnType } from '@elipssolution/harfang';
import { Avatar, styled } from '@mui/material';
import { useCallback } from 'react';

import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import SettingsTable from '../../../../components/SettingsTable';
import { CustomerFileType } from '../../../../types/customerFile';
import { FETCH_CONTACTS_BY_CUSTOMER_FILE, FetchContactsByCustomerFileType } from '../../../api/contact';
import { ContactType } from '../../../types/contact';

const AVATAR_SIZE = 36;

type TableContactType = Pick<ContactType, 'id' | 'job' | 'name' | 'hasPicture'>;

const TableAvatar = styled(Avatar)(({ theme: { typography } }) => ({
	height: AVATAR_SIZE,
	width: AVATAR_SIZE,

	fontSize: typography.body1.fontSize,
}));

const columns: TableColumnType<TableContactType>[] = [
	{
		key: 'picture',
		render: ({ id, name, hasPicture }) => (
			<TableAvatar alt={name} src={hasPicture ? `/contact/images?contactId=${id}` : ''}>
				{name
					.split(' ')
					.slice(0, 2)
					.map((word) => word.charAt(0))
					.join('')}
			</TableAvatar>
		),
		flexGrow: 0,
		width: AVATAR_SIZE,
	},
	{
		field: 'name',
		key: 'name',
		title: 'Nom',
		width: 150,
	},
	{
		field: 'job',
		key: 'job',
		render: ({ job }) => job ?? '',
		title: 'Fonction',
		width: 150,
	},
];

type ContactsProps = {
	customerFile: CustomerFileType;
};

const Contacts = ({ customerFile }: ContactsProps) => {
	const { id: customerFileId } = customerFile;

	const [fetchContactsByCustomerFile] = useLazyQuery<FetchContactsByCustomerFileType>(FETCH_CONTACTS_BY_CUSTOMER_FILE);

	const dataSource = useCallback(
		async (limit: number, offset: number, search?: string) => {
			const { data, error } = await fetchContactsByCustomerFile({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				contactsByCustomerFile: { items = [], count = 0 },
			} = data ?? {
				contactsByCustomerFile: {},
			};

			return { items, count };
		},
		[fetchContactsByCustomerFile, customerFileId],
	);

	return (
		<SettingsDialogPage title="Configuration des contacts">
			<SettingsTable<TableContactType> dataSource={dataSource} tableColumns={columns} title="Contacts" />
		</SettingsDialogPage>
	);
};

export default Contacts;
