import { SettingsGroup, SettingsItemImage } from '@elipssolution/harfang';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useState } from 'react';

import { useLogo } from '../../components/LogoProvider';
import { useSession } from '../../components/SessionProvider';

const LogosSection = () => {
	const { data } = useNextAuthSession();
	const { access_token } = data ?? {};
	const { customerFile } = useSession();

	const { favicon, logo, logoCollapsed, setFavicon, setLogo, setLogoCollapsed } = useLogo();

	const [isFaviconLoading, setFaviconLoading] = useState(false);
	const [isLogoLoading, setLogoLoading] = useState(false);
	const [isLogoCollapsedLoading, setLogoCollapsedLoading] = useState(false);

	const [faviconError, setFaviconError] = useState<string>();
	const [logoError, setLogoError] = useState<string>();
	const [logoCollapsedError, setLogoCollapsedError] = useState<string>();

	const uploadFile = useCallback(
		async (uri: string, blob: Blob) => {
			if (!access_token) return;

			const headers = new Headers();
			headers.append('Authorization', `Bearer ${access_token}`);
			if (customerFile) {
				headers.append('customer-file-id', customerFile.id);
			}

			const body = new FormData();
			body.append('file', blob);

			const response = await fetch(uri, {
				method: 'POST',
				headers,
				body,
			});

			if (!response.ok) {
				throw new Error('Unable to upload file');
			}
		},
		[access_token, customerFile],
	);

	const handleChangeLogo = useCallback(
		(file?: File) => {
			if (!file) return;

			(async () => {
				try {
					setLogoLoading(true);
					setLogoError(undefined);

					try {
						await uploadFile('/logo', file);
						setLogo(file);
					} catch (e) {
						setLogoError("Impossible d'envoyer le logo");
						throw e;
					}
				} finally {
					setLogoLoading(false);
				}
			})().catch((e) => {
				throw e;
			});
		},
		[setLogo, uploadFile],
	);

	const handleChangeLogoCollapsed = useCallback(
		(file?: File) => {
			if (!file) return;

			(async () => {
				try {
					setLogoCollapsedLoading(true);
					setLogoCollapsedError(undefined);

					try {
						await uploadFile('/logoCollapsed', file);
						setLogoCollapsed(file);
					} catch (e) {
						setLogoCollapsedError("Impossible d'envoyer le logo miniature");
						throw e;
					}
				} finally {
					setLogoCollapsedLoading(false);
				}
			})().catch((e) => {
				throw e;
			});
		},
		[setLogoCollapsed, uploadFile],
	);

	const handleChangeFavicon = useCallback(
		(file?: File) => {
			if (!file) return;

			(async () => {
				try {
					setFaviconLoading(true);
					setFaviconError(undefined);

					try {
						await uploadFile('/favicon', file);
						setFavicon(file);
					} catch (e) {
						setFaviconError("Impossible d'envoyer le favicon");
						throw e;
					}
				} finally {
					setFaviconLoading(false);
				}
			})().catch((e) => {
				throw e;
			});
		},
		[setFavicon, uploadFile],
	);

	return (
		<SettingsGroup label="Logos">
			<SettingsItemImage
				acceptedTypes={{
					'image/*': [],
				}}
				description="Logo affiché lorsque la barre de navigation est ouverte. La résolution optimale est 200x50px."
				image={logo && new File([logo], 'logo')}
				label="Logo"
				onChange={handleChangeLogo}
				previewWidth={400}
				previewHeight={100}
				errorMessage={logoError}
				loading={isLogoLoading}
			/>
			<SettingsItemImage
				acceptedTypes={{
					'image/*': [],
				}}
				description="Logo affiché lorsque la barre de navigation est fermée. La résolution optimale est 46x46px."
				image={logoCollapsed && new File([logoCollapsed], 'logoCollapsed')}
				label="Logo miniature"
				onChange={handleChangeLogoCollapsed}
				previewHeight={92}
				previewWidth={92}
				errorMessage={logoCollapsedError}
				loading={isLogoCollapsedLoading}
			/>
			<SettingsItemImage
				acceptedTypes={{
					'image/*': [],
				}}
				description="Logo affiché dans l'onglet du navigateur. La résolution optimale est 32x32px. (Rafraichîssement nécessaire)"
				image={favicon && new File([favicon], 'favicon')}
				label="Favicon"
				onChange={handleChangeFavicon}
				previewHeight={64}
				previewWidth={64}
				errorMessage={faviconError}
				loading={isFaviconLoading}
			/>
		</SettingsGroup>
	);
};

export default LogosSection;
