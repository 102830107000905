import { useLazyQuery } from '@apollo/client';
import { CurrencyFilterDefinitionType, FilterMenuOutputType, TableColumnType, TreeTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import DownloadAccountsButton from './DownloadAccountsButton';
import LinkIcon from '../../../../components/LinkIcon';
import { CustomerFileType } from '../../../../types/customerFile';
import { FiscalYearType } from '../../../../types/fiscalYear';
import {
	FETCH_ACCOUNTING_ACCOUNTS_LEVELS,
	FetchAccountingAccountsLevelType,
	FetchAccountingSearchAccountsLevelType,
	SEARCH_ACCOUNTING_ACCOUNT_FIELDS,
} from '../../api/accountingFile';
import { accounting_AccountLevel } from '../../types/account';
import { AccountingType } from '../../types/accounting';
import { TableTreeSearchResult } from '../../types/table';
import { formatCurrency } from '../../utils/formatCurrency';
import { transformSearchedResultsForTreeTable } from '../../utils/transformSearchedResultsForTreeTable';

type AccountingTableProps = {
	selectedFiscalYear?: FiscalYearType;
	selectedDateRange?: [Date, Date];
	onBalanceFilterChange: (value?: CurrencyFilterDefinitionType['value']) => void;
	balanceFilter?: CurrencyFilterDefinitionType['value'];
	accountingType: AccountingType;
	hasTransaction: boolean;
	expandedLevels: string[][];
	onToggleRows: (expandedLevels: string[][]) => void;
};

type AccountFilterType = {
	balance: CurrencyFilterDefinitionType;
};

const columns: TableColumnType<accounting_AccountLevel>[] = [
	{ key: 'code', width: 150, title: 'compte', field: 'code' },
	{
		key: 'name',
		width: 150,
		title: 'libelle',
		field: 'name',
	},
	{
		key: 'debit',
		width: 100,
		align: 'right',
		title: 'debit',
		render: ({ debitAmount }) => <FormattedNumber value={+debitAmount} style="currency" currency="EUR" />,
	},
	{
		key: 'credit',
		width: 100,
		align: 'right',
		title: 'credit',
		render: ({ creditAmount }) => <FormattedNumber value={+creditAmount} style="currency" currency="EUR" />,
	},
	{
		key: 'balance',
		width: 100,
		align: 'right',
		title: 'solde',
		render: ({ balance }) => <FormattedNumber value={+balance} style="currency" currency="EUR" />,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: ({ level }) => level === 3 && <LinkIcon />,
		width: 40,
	},
];

const AccountingTable = ({
	selectedFiscalYear,
	selectedDateRange,
	balanceFilter,
	onBalanceFilterChange,
	accountingType,
	hasTransaction,
	expandedLevels,
	onToggleRows,
}: AccountingTableProps) => {
	const { push, query } = useRouter();
	const { dossier: queryCustomerFileCode } = query || {};

	const [totalCredit, setTotalCredit] = useState('0');
	const [totalDebit, setTotalDebit] = useState('0');
	const [totalBalance, setTotalBalance] = useState('0');
	const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);

	const downloadFilter = useMemo(() => (balanceFilter ? { balance: balanceFilter } : {}), [balanceFilter]);
	const filters: AccountFilterType = useMemo(
		() => ({
			balance: {
				label: 'Solde',
				type: 'currency',
				value: balanceFilter,
			},
		}),
		[balanceFilter],
	);

	const [fetchAccountingAccountsLevels] = useLazyQuery<FetchAccountingAccountsLevelType>(
		FETCH_ACCOUNTING_ACCOUNTS_LEVELS,
	);
	const accountingDate = useMemo(
		() =>
			selectedDateRange?.length === 2
				? {
						min: selectedDateRange[0],
						max: selectedDateRange[1],
				  }
				: null,
		[selectedDateRange],
	);

	const getSourceData = useCallback(
		async (
			level: number,
			previousNodeLevels?: string[],
		): Promise<{
			items: accounting_AccountLevel[];
		}> => {
			if (!selectedFiscalYear || !accountingType) {
				return {
					items: [],
				};
			}

			const { data, error } = await fetchAccountingAccountsLevels({
				variables: {
					accountingDate,

					fiscalYearId: selectedFiscalYear.id,
					type: accountingType,
					level,
					...(previousNodeLevels && { parentCode: previousNodeLevels[previousNodeLevels.length - 1] }),
					filter: { balance: balanceFilter },
					hasTransaction,
				},
			});

			if (error) {
				throw error;
			}

			const {
				accounting_accountLevels: {
					items = [],
					totalBalance: queryTotalBalance = '0.00',
					totalDebit: queryTotalDebit = '0.00',
					totalCredit: queryTotalCredit = '0.00',
				},
			} = data ?? {
				accounting_accountLevels: {},
			};

			setTotalBalance(queryTotalBalance);
			setTotalCredit(queryTotalCredit);
			setTotalDebit(queryTotalDebit);
			setIsDownloadButtonDisabled(items.length === 0);

			return { items };
		},
		[selectedFiscalYear, accountingType, fetchAccountingAccountsLevels, accountingDate, balanceFilter, hasTransaction],
	);

	const [fetchAccountingSearchAccountLevels] = useLazyQuery<FetchAccountingSearchAccountsLevelType>(
		SEARCH_ACCOUNTING_ACCOUNT_FIELDS,
	);

	const getSearchDataSource = useCallback(
		async (search: string): Promise<TableTreeSearchResult[]> => {
			if (!selectedFiscalYear || !accountingType) {
				return [];
			}

			const { data, error } = await fetchAccountingSearchAccountLevels({
				variables: {
					accountingDate,
					...(balanceFilter && {
						filter: { balance: balanceFilter },
					}),
					fiscalYearId: selectedFiscalYear.id,
					type: accountingType,
					hasTransaction,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const { accounting_searchAccountLevels: searched = [] } = data ?? { accounting_searchAccountLevels: [] };

			return transformSearchedResultsForTreeTable(searched);
		},
		[
			selectedFiscalYear,
			accountingType,
			fetchAccountingSearchAccountLevels,
			accountingDate,
			balanceFilter,
			hasTransaction,
		],
	);

	const handleRowClick = useCallback(
		(accountlevel: accounting_AccountLevel) => {
			push(
				{
					pathname: 'accounts/transactions',
					query: {
						...(queryCustomerFileCode && { dossier: queryCustomerFileCode }),
						id: accountlevel.accountId,
						code: accountlevel.code,
						name: accountlevel.name,
						selectedFiscalYear: JSON.stringify(selectedFiscalYear),
						selectedDateRange: JSON.stringify(selectedDateRange),
					},
				},
				`accounts/transactions${
					queryCustomerFileCode ? `?dossier=${queryCustomerFileCode as CustomerFileType['code']}` : ''
				}`,
			).catch((e) => {
				throw e;
			});
		},
		[queryCustomerFileCode, push, selectedDateRange, selectedFiscalYear],
	);

	const onFilterSubmit = (submittedFilters?: FilterMenuOutputType<AccountFilterType>) =>
		onBalanceFilterChange(submittedFilters?.balance);

	return (
		<Stack height="100%" width="0" flex={3}>
			<TreeTable<accounting_AccountLevel, AccountFilterType>
				onRowClick={handleRowClick}
				onFilterSubmit={onFilterSubmit}
				filters={filters}
				depth={4}
				dataKey="code"
				columns={columns}
				dataSource={getSourceData}
				searchDataSource={getSearchDataSource}
				footerData={[
					{ key: 'name', value: 'TOTAL' },
					{
						key: 'credit',
						value: formatCurrency(totalCredit),
					},
					{
						key: 'debit',
						value: formatCurrency(totalDebit),
					},
					{
						key: 'balance',
						value: formatCurrency(totalBalance),
					},
				]}
				title="Comptes"
				defaultExpandedRows={expandedLevels}
				onToggleRows={onToggleRows}
			/>

			<Stack alignSelf="flex-end" sx={{ marginTop: '20px' }}>
				<DownloadAccountsButton
					disabled={isDownloadButtonDisabled}
					downloadFilter={downloadFilter}
					hasTransaction={hasTransaction}
					selectedFiscalYear={selectedFiscalYear}
					selectedAccountingType={accountingType}
					accountingDate={accountingDate}
				/>
			</Stack>
		</Stack>
	);
};
export default AccountingTable;
