import { isAfter, isBefore } from 'date-fns';
import { FiscalYearType } from '../types/fiscalYear';

export const findMinMaxDates = (fiscalYears?: FiscalYearType[]): { minDate: Date; maxDate: Date } | undefined =>
	fiscalYears?.reduce(
		(acc, range) => {
			if (!acc.minDate || isBefore(range.startDate, acc.minDate)) {
				acc.minDate = range.startDate;
			}

			if (!acc.maxDate || isAfter(range.endDate, acc.maxDate)) {
				acc.maxDate = range.endDate;
			}

			return acc;
		},
		{
			minDate: fiscalYears && fiscalYears.length > 0 ? fiscalYears[0].startDate : new Date(),
			maxDate: fiscalYears && fiscalYears.length > 0 ? fiscalYears[0].endDate : new Date(),
		},
	);
