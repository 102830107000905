import { CurrencyFilterDefinitionType } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import AccountingTable from './AccountingTable';
import AccountingTableToggles from './AccountingTableToggles';
import { useSession } from '../../../../src/components/SessionProvider';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { convertFiscalYearDates } from '../../../../utils/convertDates';
import { AccountingType } from '../../types/accounting';
import { SwitchOperatorType } from '../../types/table';

const Accounts = () => {
	const { events, push } = useRouter();
	const { permissions } = useSession();

	const {
		balanceFilter: queryBalanceFilter,
		hasTransaction: hasQueryTransaction,
		selectedFiscalYear: querySelectedFiscalYear,
		selectedDateRange: querySelectedDateRange,
		accountingType: queryAccountingType,
		expandedLevels: queryExpandedLevels,
	} = useMemo(() => {
		const storedFilters = globalThis.sessionStorage.getItem('accounting_accountsFilters');

		if (storedFilters) {
			const { selectedDateRange, hasTransaction, balanceFilter, selectedFiscalYear, accountingType, expandedLevels } =
				JSON.parse(storedFilters) as {
					hasTransaction?: boolean;
					balanceFilter?: CurrencyFilterDefinitionType['value'];
					selectedFiscalYear?: FiscalYearType;
					selectedDateRange?: [Date, Date];
					accountingType?: AccountingType;
					expandedLevels: string[][];
				};

			return {
				selectedDateRange: selectedDateRange?.map((item) => new Date(item)) as [Date, Date],
				hasTransaction,
				balanceFilter,
				selectedFiscalYear: selectedFiscalYear ? convertFiscalYearDates(selectedFiscalYear) : undefined,
				accountingType,
				expandedLevels,
			};
		}

		return {};
	}, []);

	const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYearType | undefined>(querySelectedFiscalYear);
	const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date] | undefined>(querySelectedDateRange);
	const [expandedLevels, setExpandedLevels] = useState<string[][]>(queryExpandedLevels || []);
	const [balanceFilter, setBalanceFilter] = useState<CurrencyFilterDefinitionType['value']>(queryBalanceFilter);
	const [accountingType, setAccountingType] = useState<AccountingType>(
		queryAccountingType ?? AccountingType.BALANCE_SHEET,
	);
	const [hasTransaction, setHasTransaction] = useState(hasQueryTransaction ?? true);

	const handleBalanceFilterOperatorChange = (operator: SwitchOperatorType) =>
		setBalanceFilter((prevBalanceFilter) => (prevBalanceFilter?.[operator] === '0.00' ? null : { [operator]: '0.00' }));

	const handleChangeAccountingType = (type: AccountingType) => setAccountingType(type);

	useEffect(() => {
		const hasUserPermissions = permissions.find(({ code }) => code === 'accounting-read')?.value;

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	useEffect(() => {
		globalThis.sessionStorage.setItem(
			'accounting_accountsFilters',
			JSON.stringify({
				balanceFilter,
				hasTransaction,
				selectedFiscalYear,
				selectedDateRange,
				accountingType,
				expandedLevels,
			}),
		);
	}, [accountingType, balanceFilter, hasTransaction, selectedDateRange, selectedFiscalYear, expandedLevels]);

	useEffect(() => {
		events.on('routeChangeStart', (newRoute: string) => {
			!newRoute.startsWith('/accounting/accounts') &&
				globalThis.sessionStorage.removeItem('accounting_accountsFilters');
		});
	}, [events]);

	return (
		<Stack height="100%" width="100%" gap={4} flexDirection="row">
			<AccountingTableToggles
				onAccountingTypeChange={handleChangeAccountingType}
				accountingType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				setSelectedFiscalYear={setSelectedFiscalYear}
				selectedDateRange={selectedDateRange}
				onDateRangeSelection={setSelectedDateRange}
				onBalanceFilterOperatorChange={handleBalanceFilterOperatorChange}
				balanceFilter={balanceFilter}
				hasTransaction={hasTransaction}
				onHasTransactionChange={setHasTransaction}
			/>

			<AccountingTable
				accountingType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				selectedDateRange={selectedDateRange}
				balanceFilter={balanceFilter}
				onBalanceFilterChange={setBalanceFilter}
				hasTransaction={hasTransaction}
				expandedLevels={expandedLevels}
				onToggleRows={setExpandedLevels}
			/>
		</Stack>
	);
};

export default Accounts;
