import { gql } from '@apollo/client';

import { CUSTOMER_FILE_FIELDS, CUSTOMER_FILE_WITH_DOMAIN_FIELDS } from './customerFile';
import { EXTERNAL_GROUP_FIELDS } from './externalGroup';
import { EXTERNAL_USER_FIELDS } from './externalUser';
import { USER_PERMISSION_FIELDS, USER_PERMISSION_MODULE_FIELDS } from './permission';
import { CustomerFileType } from '../../types/customerFile';
import { GroupType } from '../types/group';
import { ExternalUserCustomerFileRelationType } from '../types/relation';
import { ExternalUserType } from '../types/user';

// QUERIES

export type FetchExternalUserCustomerFileRelationsByUserType = {
	externalUserCustomerFileRelationsByUser: {
		count: number;
		items: ExternalUserCustomerFileRelationType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER = gql`
	query ExternalUserCustomerFileRelationsByUser($userId: ID!, $page: Page, $search: String) {
		externalUserCustomerFileRelationsByUser(userId: $userId, page: $page, search: $search) {
			count
			items {
				customerFile {
					${CUSTOMER_FILE_FIELDS}
				}
				group {
					${EXTERNAL_GROUP_FIELDS}
				}
				hasUserPermissions
				id
			}
			limit
			offset
		}
	}
`;

export type FetchExternalUserCustomerFileRelationsByCustomerFileType = {
	externalUserCustomerFileRelationsByCustomerFile: {
		count: number;
		items: ExternalUserCustomerFileRelationType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE = gql`
	query ExternalUserCustomerFileRelationsByCustomerFile($customerFileId: ID!, $page: Page, $search: String) {
		externalUserCustomerFileRelationsByCustomerFile(customerFileId: $customerFileId, page: $page, search: $search) {
			count
			items {
				group {
					${EXTERNAL_GROUP_FIELDS}
				}
				externalUser {
					${EXTERNAL_USER_FIELDS}
				}
				hasUserPermissions
				id
			}
			limit
			offset
		}
	}
`;

export type FetchExternalUserCustomerFileRelationByCustomerFileAndUserType = {
	externalUserCustomerFileRelationByCustomerFileAndUser: ExternalUserCustomerFileRelationType;
};

export const FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER = gql`
	query ExternalUserCustomerFileRelationByCustomerFileAndUser($customerFileId: ID!, $userId: ID!) {
		externalUserCustomerFileRelationByCustomerFileAndUser(customerFileId: $customerFileId, userId: $userId) {
			customerFile {
				${CUSTOMER_FILE_FIELDS}
			}
			id
			group {
				${EXTERNAL_GROUP_FIELDS}
			}
			permissionModules {
				${USER_PERMISSION_MODULE_FIELDS}
				permissions {
					${USER_PERMISSION_FIELDS}
				}
			}
		}
	}
`;

export type FetchExternalUserCustomerFileRelationType = {
	externalUserCustomerFileRelation: ExternalUserCustomerFileRelationType;
};

export const FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	query ExternalUserCustomerFileRelation($id: ID!) {
		externalUserCustomerFileRelation(id: $id) {
			customerFile {
				id
				name
			}
			externalUser {
				id
				lastName
				firstName
			}
			group {
				id
				isAdministrator
				name
			}
			id
			permissionModules {
				${USER_PERMISSION_MODULE_FIELDS}
				permissions {
					${USER_PERMISSION_FIELDS}
				}
			}
		}
	}
`;

// MUTATIONS

export type CreateExternalUserCustomerFileRelationType = {
	createExternalUserCustomerFileRelation: ExternalUserCustomerFileRelationType;
};

export const CREATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	mutation CreateExternalUserCustomerFileRelation(
		$createExternalUserCustomerFileRelationInput: CreateExternalUserCustomerFileRelationInput!
	) {
		createExternalUserCustomerFileRelation(
			createExternalUserCustomerFileRelationInput: $createExternalUserCustomerFileRelationInput
		) {
			customerFile {
				id
				name
			}
			externalUser {				
				${EXTERNAL_USER_FIELDS}
			}
			group {
				id
				isAdministrator
				name
			}
			id
			permissionModules {
				${USER_PERMISSION_MODULE_FIELDS}
				permissions {
					${USER_PERMISSION_FIELDS}
				}
			}
		}
	}
`;

export type CreateExternalUserWithCustomerFilesType = {
	createExternalUserWithCustomerFiles: {
		customerFilesWithGroup: {
			customerFileId: CustomerFileType['id'];
			groupId: GroupType['id'];
		}[];
		externalUser: ExternalUserType;
	};
};

export const CREATE_EXTERNAL_USER_WITH_CUSTOMER_FILES = gql`
	mutation CreateExternalUserWithCustomerFiles(
		$createExternalUserWithCustomerFilesInput: CreateExternalUserWithCustomerFilesInput!
	) {
		createExternalUserWithCustomerFiles(
			createExternalUserWithCustomerFilesInput: $createExternalUserWithCustomerFilesInput
		) {
			customerFilesWithGroup {
				customerFileId
				groupId
			}
			externalUser {
				${EXTERNAL_USER_FIELDS}
				customerFiles {
					${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				}
			}
		}
	}
`;

export type UpdateExternalUserCustomerFileRelationType = {
	updateExternalUserCustomerFileRelation: {
		customerFile: {
			id: ExternalUserCustomerFileRelationType['customerFile']['id'];
		};
		externalUser: {
			id: ExternalUserCustomerFileRelationType['externalUser']['id'];
		};
		group: ExternalUserCustomerFileRelationType['group'];
	};
};

export const UPDATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	mutation UpdateExternalUserCustomerFileRelation(
		$updateExternalUserCustomerFileRelationInput: UpdateExternalUserCustomerFileRelationInput!
	) {
		updateExternalUserCustomerFileRelation(
			updateExternalUserCustomerFileRelationInput: $updateExternalUserCustomerFileRelationInput
		) {
			customerFile {
				id
			}
			externalUser {
				id
			}
			group {
				${EXTERNAL_GROUP_FIELDS}
			}
		}
	}
`;

export type RemoveExternalUserCustomerFileRelationType = {
	removeExternalUserCustomerFileRelation: ExternalUserCustomerFileRelationType;
};

export const REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	mutation RemoveExternalUserCustomerFileRelation($id: ID!) {
		removeExternalUserCustomerFileRelation(id: $id) {
			id
		}
	}
`;

export type RemoveExternalUserCustomerFileRelationByCustomerFileAndUserType = {
	removeExternalUserCustomerFileRelationByCustomerFileAndUser: ExternalUserCustomerFileRelationType;
};

export const REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER = gql`
	mutation RemoveExternalUserCustomerFileRelationByCustomerFileAndUser($customerFileId: ID!, $userId: ID!) {
		removeExternalUserCustomerFileRelationByCustomerFileAndUser(customerFileId: $customerFileId, userId: $userId) {
			id
		}
	}
`;
