import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import { generateErrorInformations } from '../../../../../utils/errorHandler';
import { FetchVoucherType, FETCH_VOUCHER } from '../../../api/voucher';
import { VoucherType } from '../../../types/voucher';

const useVoucher = ({ voucherId }: { voucherId?: VoucherType['id'] }) => {
	const [error, setError] = useState<string>();

	const { data, loading } = useQuery<FetchVoucherType>(FETCH_VOUCHER, {
		onError: (queryError) => {
			setError(
				generateErrorInformations({
					error: queryError,
					resource: 'quickentry_voucher',
				}).message,
			);
		},
		variables: {
			id: voucherId,
		},
		skip: !voucherId,
	});

	const voucher = useMemo(() => {
		const { quickentry_voucher } = data ?? {};

		if (!quickentry_voucher) {
			return undefined;
		}

		const { accountingDate, documentDate, dueDate, ...rest } = quickentry_voucher;

		return {
			...rest,
			...(accountingDate && {
				accountingDate: new Date(accountingDate),
			}),
			...(documentDate && {
				documentDate: new Date(documentDate),
			}),
			...(dueDate && {
				dueDate: new Date(dueDate),
			}),
		};
	}, [data]);

	return { error, loading, voucher };
};

export default useVoucher;
