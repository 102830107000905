export enum ThirdPartyTypeEnum {
	SUPPLIER = 'SUPPLIER',
	CUSTOMER = 'CUSTOMER',
}

export type ThirdPartyType = {
	id: string;
	balance: string;
	code: string;
	name: string;
};
