import { useLazyQuery } from '@apollo/client';
import { Autocomplete } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { FETCH_FISCAL_YEARS, FetchFiscalYearsType } from '../../../src/api/fiscalYear';
import { FiscalYearType } from '../../../types/fiscalYear';
import { convertFiscalYearDates } from '../../../utils/convertDates';

type FiscalYearProps = {
	selectedFiscalYear?: FiscalYearType;
	onFiscalYearSelection: (fiscalYear?: FiscalYearType) => void;
};

const FiscalYearAutocomplete = ({ selectedFiscalYear, onFiscalYearSelection }: FiscalYearProps) => {
	const [fetchFiscalYears] = useLazyQuery<FetchFiscalYearsType>(FETCH_FISCAL_YEARS, {
		onCompleted: ({ fiscalYears: { items } }) => {
			if (!selectedFiscalYear && items.length > 0) {
				onFiscalYearSelection(convertFiscalYearDates(items[0]));
			}
		},
	});

	const fiscalYearsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: FiscalYearType[];
		}> => {
			const { data, error } = await fetchFiscalYears({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				fiscalYears: { count = 0, items = [] },
			} = data ?? {
				fiscalYears: {},
			};

			return {
				count,
				items: items.map((fiscalYear) => convertFiscalYearDates(fiscalYear)),
			};
		},
		[fetchFiscalYears],
	);

	return (
		<Autocomplete<FiscalYearType>
			dataSource={fiscalYearsDataSource}
			getOptionLabel={({ name }) => name}
			onChange={onFiscalYearSelection}
			label="Exercice"
			value={selectedFiscalYear}
			disableClearable
		/>
	);
};
export default FiscalYearAutocomplete;
