import { ReactNode, createContext, useContext } from 'react';

type SettingsDialogContextType = {
	isFirstPage: boolean;
	push: (component: ReactNode) => void;
	replace: (component: ReactNode) => void;
	back: () => void;
	close: () => void;
};

export const SettingsDialogContext = createContext<SettingsDialogContextType | undefined>(undefined);

export const useSettingsDialog = () => {
	const context = useContext(SettingsDialogContext);
	if (context === undefined) {
		throw new Error('SettingsDialogContext should be used within the SettingsDialog');
	}
	return context;
};
