import { gql } from '@apollo/client';

import { SupplierAccountType } from '../types/supplierAccounts';

// QUERIES

export type FetchSupplierAccountsType = {
	supplierAccounts: {
		count: number;
		items: SupplierAccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUPPLIER_ACCOUNTS = gql`
	query SupplierAccounts($search: String, $page: Page!, $orderBy: OrderBy) {
		supplierAccounts(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				code
				id
				name
			}
			limit
			offset
		}
	}
`;
