import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import CustomerFileDashboardInheritanceForm from './CustomerFileDashboardInheritanceForm';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import { WidgetWithKeyType } from '../../../../types/widget';
import {
	CREATE_CUSTOMER_FILE_DASHBOARD,
	CreateCustomerFileDashboardType,
	FETCH_CUSTOMER_FILE_DASHBOARD,
	FETCH_DOMAIN_DASHBOARD,
	FetchCustomerFileDashboardType,
	FetchDomainDashboardType,
	REMOVE_CUSTOMER_FILE_DASHBOARD,
	RemoveCustomerFileDashboardType,
	UPDATE_CUSTOMER_FILE_DASHBOARD,
	UpdateCustomerFileDashboardType,
} from '../../../api/dashboard';
import {
	FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE,
	FetchSettingModulesAvailabilityByCustomerFileType,
} from '../../../api/settingModuleAvailability';
import Dashboard from '../../../components/Dashboard';
import { CustomerFileDashboardType, DomainDashboardType } from '../../../types/dashboard';
import { duplicateDashboardWidgets } from '../../../utils/widgets';
import WidgetImagesProvider, { useWidgetImages } from '../provider/WidgetImagesProvider';
import SettingsDashboard from '../SettingsDashboard';
import SettingsWidgetConfiguration from '../SettingsWidgetConfiguration';
import SettingsWidgetSelector from '../SettingsWidgetSelector';

const getWidgetWithKeyFromDashboard = (
	dashboard: CustomerFileDashboardType | DomainDashboardType,
	availableModuleIds: string[],
) =>
	dashboard?.widgets.flatMap((widget) => {
		if (widget.moduleId) {
			return availableModuleIds.includes(widget.moduleId) ? [{ key: widget.id ?? uuid(), widget }] : [];
		}

		return [{ key: widget.id ?? uuid(), widget }];
	});

enum ViewStep {
	DASHBOARD,
	ADD_WIDGET,
	EDIT_WIDGET,
}

const initialRouterState = [
	{
		page: ViewStep.DASHBOARD,
	},
];

type WidgetDashboardCustomerFileProps = {
	customerFile: CustomerFileWithDomainType;
};

const WidgetDashboardCustomerFileWithImages = ({
	customerFile: {
		domain: { id: domainId },
		id: customerFileId,
		name: customerFileName,
	},
}: WidgetDashboardCustomerFileProps) => {
	const { clearImages, addImages, getBlob, saveImages } = useWidgetImages();

	const [isInherited, setIsInherited] = useState(true);
	const [internalRouter, setInternalRouter] =
		useState<{ page: ViewStep; widgetWithKey?: WidgetWithKeyType }[]>(initialRouterState);
	const [customerFileDashboardId, setCustomerFileDashboardId] = useState<string>();
	const [widgetsWithKey, setWidgetsWithKey] = useState<WidgetWithKeyType[]>([]);
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

	const currentStep = internalRouter[internalRouter.length - 1];

	const { data: moduleAvailabilitiesData } = useQuery<FetchSettingModulesAvailabilityByCustomerFileType>(
		FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE,
		{
			variables: { customerFileId },
		},
	);

	const availableModuleIds = useMemo(
		() => [
			...(moduleAvailabilitiesData?.settingModuleAvailabilityByCustomerFile
				? moduleAvailabilitiesData.settingModuleAvailabilityByCustomerFile.flatMap(({ isEnabled, moduleId }) =>
						isEnabled ? [moduleId] : [],
				  )
				: []),
		],
		[moduleAvailabilitiesData],
	);

	const { data: fetchDomainDashboardData, loading: isDomainDashboardFetchLoading } = useQuery<FetchDomainDashboardType>(
		FETCH_DOMAIN_DASHBOARD,
		{
			variables: {
				domainId,
			},
		},
	);

	const domainDashboard = fetchDomainDashboardData?.domainDashboardByDomain;

	const { loading: isCustomerFileDashboardFetchLoading, refetch: refetchCustomerFileDashboard } =
		useQuery<FetchCustomerFileDashboardType>(FETCH_CUSTOMER_FILE_DASHBOARD, {
			onCompleted: ({ customerFileDashboardByCustomerFile }) => {
				if (customerFileDashboardByCustomerFile) {
					setIsInherited(false);
					setCustomerFileDashboardId(customerFileDashboardByCustomerFile.id);
				}

				setWidgetsWithKey(
					getWidgetWithKeyFromDashboard(customerFileDashboardByCustomerFile ?? domainDashboard, availableModuleIds),
				);
			},
			skip: isDomainDashboardFetchLoading,
			variables: {
				customerFileId,
			},
		});

	const [createDashboard, { loading: isCreateLoading }] = useMutation<CreateCustomerFileDashboardType>(
		CREATE_CUSTOMER_FILE_DASHBOARD,
		{
			onCompleted: () => {
				saveImages().catch((error) => {
					throw error;
				});

				refetchCustomerFileDashboard().catch((error) => {
					throw error;
				});
			},
		},
	);

	const [updateDashboard] = useMutation<UpdateCustomerFileDashboardType>(UPDATE_CUSTOMER_FILE_DASHBOARD);

	const [removeDashboard, { loading: isRemoveLoading }] = useMutation<RemoveCustomerFileDashboardType>(
		REMOVE_CUSTOMER_FILE_DASHBOARD,
		{
			onCompleted: () => {
				clearImages();
				setCustomerFileDashboardId(undefined);
				setWidgetsWithKey(domainDashboard ? getWidgetWithKeyFromDashboard(domainDashboard, availableModuleIds) : []);
			},
		},
	);

	const isLoading =
		isDomainDashboardFetchLoading || isCustomerFileDashboardFetchLoading || isCreateLoading || isRemoveLoading;

	const handleInheritanceChange = async (newInheritanceValue: boolean) => {
		if (newInheritanceValue) {
			setIsConfirmationDialogOpen(true);
		} else if (domainDashboard) {
			const { duplicatedWidgets, imagesToAdd } = duplicateDashboardWidgets({
				...domainDashboard,
				widgets: domainDashboard.widgets.filter((widget) =>
					widget?.moduleId ? availableModuleIds.includes(widget?.moduleId) : true,
				),
			});

			addImages(
				(
					await Promise.all(
						imagesToAdd.map(async ({ newUuid, oldImage, newImage }) => {
							const oldImageBlob = await getBlob(oldImage);

							return oldImageBlob
								? [
										{
											widgetKey: newUuid,
											fileName: newImage,
											blob: oldImageBlob,
										},
								  ]
								: [];
						}),
					)
				).flat(),
			);

			await createDashboard({
				variables: {
					createCustomerFileDashboardInput: {
						customerFileId,
						widgets: duplicatedWidgets,
					},
				},
			});

			await saveImages();

			setIsInherited(newInheritanceValue);
		} else {
			await createDashboard({
				variables: {
					createCustomerFileDashboardInput: {
						customerFileId,
					},
				},
			});
		}
	};

	const switchToInheritedDashboard = async () => {
		await removeDashboard({ variables: { removeCustomerFileDashboardId: customerFileDashboardId } });

		setIsConfirmationDialogOpen(false);
		setIsInherited(true);

		await saveImages();
	};

	const navigateBack = () => setInternalRouter((prev) => prev.slice(0, -1));

	const navigateToAddWidget = () => setInternalRouter((prev) => [...prev, { page: ViewStep.ADD_WIDGET }]);

	const navigateToEditWidget = (widgetWithKey: WidgetWithKeyType) =>
		setInternalRouter((prev) => [...prev, { page: ViewStep.EDIT_WIDGET, widgetWithKey }]);

	const handleDashboardSave = async (widgets: WidgetWithKeyType[]) => {
		const saveWidgetInput = widgets.map(
			({ widget: { id, name, type, x, y, width, height, configuration, moduleCode } }) => ({
				id,
				name,
				type,
				x,
				y,
				width,
				height,
				configuration,
				moduleCode,
			}),
		);

		if (customerFileDashboardId) {
			await updateDashboard({
				variables: {
					updateCustomerFileDashboardInput: {
						id: customerFileDashboardId,
						widgets: saveWidgetInput,
					},
				},
			}).then(({ data }) => {
				const { updateCustomerFileDashboard } = data ?? {};
				const { widgets: updatedWidgets = [] } = updateCustomerFileDashboard ?? {};

				setWidgetsWithKey(
					updatedWidgets.map((widget) => ({
						key: widget.id as string,
						widget,
					})),
				);

				setInternalRouter(initialRouterState);
			});
		}
	};

	const handleWidgetEditSave = async (widget: WidgetWithKeyType) => {
		const { key } = widget;

		await handleDashboardSave([...widgetsWithKey.filter(({ key: widgetKey }) => widgetKey !== key), widget]);
	};

	return (
		<>
			{currentStep.page === ViewStep.DASHBOARD && (
				<SettingsDialogPage title={customerFileName}>
					<CustomerFileDashboardInheritanceForm
						isDasbhoardInherited={isInherited}
						onDashboardInheritanceChange={handleInheritanceChange}
					/>

					{isInherited ? (
						<Dashboard loading={isLoading} widgetsWithKey={widgetsWithKey} widgetsReadOnly />
					) : (
						<SettingsDashboard
							loading={isLoading}
							onSave={handleDashboardSave}
							widgetsWithKey={widgetsWithKey}
							onAdd={navigateToAddWidget}
							onEdit={navigateToEditWidget}
						/>
					)}

					<ConfirmationDialog
						open={isConfirmationDialogOpen}
						title="Voulez-vous revenir au tableau de bord du domaine ?"
						message="Le tableau de bord du dossier sera supprimé et remplacé par celui du domaine."
						confirmLabel="Remplacer"
						onCancel={() => setIsConfirmationDialogOpen(false)}
						onConfirm={switchToInheritedDashboard}
					/>
				</SettingsDialogPage>
			)}

			{currentStep.page === ViewStep.ADD_WIDGET && (
				<SettingsWidgetSelector
					customerFileId={customerFileId}
					onAddWidget={navigateToEditWidget}
					onBack={navigateBack}
					widgetsWithKey={widgetsWithKey}
				/>
			)}

			{currentStep.page === ViewStep.EDIT_WIDGET && currentStep.widgetWithKey && (
				<SettingsWidgetConfiguration
					widgetWithKey={currentStep.widgetWithKey}
					onSave={handleWidgetEditSave}
					onBack={navigateBack}
				/>
			)}
		</>
	);
};

const WidgetDashboardCustomerFile = (props: WidgetDashboardCustomerFileProps) => (
	<WidgetImagesProvider>
		<WidgetDashboardCustomerFileWithImages {...props} />
	</WidgetImagesProvider>
);

export default WidgetDashboardCustomerFile;
