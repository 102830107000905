import { useCallback } from 'react';

import CustomerFilesTableSection from './customerFile/WidgetCustomerFilesTable';
import WidgetDashboardCustomerFile from './customerFile/WidgetDashboardCustomerFile';
import WidgetDashboardDomain from './domain/WidgetDashboardDomain';
import DomainsTableSection from './domain/WidgetDomainsTable';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../types/customerFile';
import { DomainType } from '../../../types/domain';
import { PermissionEnum } from '../../../types/permission';

const SettingsWidgets = () => {
	const { push } = useSettingsDialog();

	const handleDomainSelection = useCallback(
		(domain: DomainType) => {
			push(<WidgetDashboardDomain domain={domain} />);
		},
		[push],
	);

	const handleCustomerFileSelection = useCallback(
		(customerFile: CustomerFileWithDomainType) => {
			push(<WidgetDashboardCustomerFile customerFile={customerFile} />);
		},
		[push],
	);

	return (
		<SettingsDialogPage title="Widgets">
			<PermissionWall permissionCodes={[PermissionEnum.WIDGET_DOMAIN_MANAGE]}>
				<DomainsTableSection onDomainSelection={handleDomainSelection} />
			</PermissionWall>

			<PermissionWall permissionCodes={[PermissionEnum.WIDGET_CUSTOMER_FILE_MANAGE]}>
				<CustomerFilesTableSection onCustomerFileSelection={handleCustomerFileSelection} />
			</PermissionWall>
		</SettingsDialogPage>
	);
};

export default SettingsWidgets;
