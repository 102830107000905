import { NetworkStatus } from '@apollo/client';
import { Icon, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { mdiAlertCircle } from '@mdi/js';
import { alpha, Box, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import CheckTable from './CheckTable';
import useSynchronizedChecksDataSource from './hooks/useSynchronizedChecksDataSource';
import { BankType } from '../../types/bank';
import { CheckType } from '../../types/check';

const ErrorCell = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	flex: 1,
	height: 30,

	backgroundColor: alpha(palette.error.main, 0.2),
	color: palette.error.main,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),

	paddingLeft: spacing(0.5),
}));

const columns: TableColumnType<CheckType>[] = [
	{
		field: 'documentDate',
		flexGrow: 0,
		key: 'documentDate',
		render: ({ documentDate }) => documentDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Date',
		width: 100,
	},
	{
		key: 'bank',
		render: ({ bank }) =>
			bank?.name ?? (
				<ErrorCell>
					<Icon path={mdiAlertCircle} />
					<Typography variant="caption">Banque manquante</Typography>
				</ErrorCell>
			),
		title: 'Banque',
		width: 125,
	},
	{
		field: 'documentNumber',
		flexGrow: 0,
		key: 'documentNumber',
		sortable: true,
		title: 'Numéro',
		width: 120,
	},
	{
		key: 'masterAccount',
		render: ({ account: { code, masterAccount } }) => {
			const { code: masterAccountCode } = masterAccount ?? {};

			return masterAccountCode ?? code;
		},
		title: 'Compte général',
		width: 150,
	},
	{
		key: 'account',
		render: ({ account: { code, masterAccount } }) => (masterAccount ? code : ''),
		title: 'Code auxiliaire',
		width: 150,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé',
		width: 200,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={+(amount ?? 0)} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 100,
	},
];

type SynchronizedCheckTableProps = {
	onCheckSelection: (check: CheckType) => void;
	selectedBank?: BankType;
	tableInstance: TableInstance;
};

const SynchronizedCheckTable = ({ onCheckSelection, selectedBank, tableInstance }: SynchronizedCheckTableProps) => {
	const [filteredBank, setFilteredBank] = useState<BankType>();

	const { dataSource: checksDataSource, networkStatus } = useSynchronizedChecksDataSource({
		filteredBankId: filteredBank?.id,
	});

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	useEffect(() => {
		selectedBank && setFilteredBank(selectedBank);
	}, [selectedBank]);

	return (
		<Box flex={2} paddingBottom={2}>
			<CheckTable
				columns={columns}
				dataSource={checksDataSource}
				filteredBank={filteredBank}
				onBankFilterChange={setFilteredBank}
				onCheckSelection={onCheckSelection}
				tableInstance={tableInstance}
				title="Chèques"
			/>
		</Box>
	);
};

export default SynchronizedCheckTable;
