import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Button, ConfirmationDialog, DialogProps, Icon } from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { Divider, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import TemplateDomainInfosForm from './TemplateDomainInfosForm';
import TemplateDomainLineSection from './TemplateDomainLineSection';
import SettingsDialogPage from '../../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../../types/domain';
import { generateErrorInformations } from '../../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_DOMAIN_TEMPLATE,
	CreateSettingDomainTemplateType,
	FETCH_SETTING_DOMAIN_TEMPLATE,
	FetchSettingDomainTemplateType,
	REMOVE_SETTING_DOMAIN_TEMPLATE,
	RemoveSettingDomainTemplateType,
	UPDATE_SETTING_DOMAIN_TEMPLATE,
	UpdateSettingDomainTemplateType,
} from '../../../../../api/settingTemplate';
import {
	DirectionEnum,
	SettingDomainTemplateFormType,
	SettingDomainTemplateLineMutationType,
	SettingDomainTemplateType,
	SettingTemplateLineType,
} from '../../../../../types/settingTemplate';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type TemplateDomainFormProps = {
	domainId?: DomainType['id'];
	selectedTemplate?: SettingDomainTemplateType;
};

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

const TemplateDomainForm = ({ domainId, selectedTemplate: initialTemplate }: TemplateDomainFormProps) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm<SettingDomainTemplateFormType>({
		mode: 'onBlur',
	});

	const formAnalyticalDimensionCode = watch('analyticalDimensionCode');
	const analyticalSectionCaption = watch('analyticalSectionCaption');
	const templateLines = watch('templateLines');
	const defaultEntryName = watch('defaultEntryName');

	const [template, setTemplate] = useState(initialTemplate);
	const [errorMessage, setErrorMessage] = useState('');
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);
	const [isCreateSettingDomainTemplateSucceeded, setIsCreateSettingDomainTemplateSucceeded] = useState(false);
	const [isUpdateSettingDomainTemplateSucceeded, setIsUpdateSettingDomainTemplateSucceeded] = useState(false);

	const { id: templateId, name: templateName } = useMemo(
		() => (template || {}) as SettingDomainTemplateType,
		[template],
	);

	const applyTemplateToForm = useCallback(
		({ id, validUntil, ...rest }: SettingDomainTemplateType) =>
			reset({
				...rest,
				...(validUntil && { validUntil: new Date(validUntil) }),
			}),
		[reset],
	);

	useQuery<FetchSettingDomainTemplateType>(FETCH_SETTING_DOMAIN_TEMPLATE, {
		onCompleted: ({ quickentry_settingDomainTemplate }) => applyTemplateToForm(quickentry_settingDomainTemplate),
		skip: !templateId,
		variables: { quickentrySettingDomainTemplateId: templateId },
	});

	const isTemplateLinesValid = useCallback(
		() =>
			templateLines?.length >= 2 &&
			templateLines?.some(({ direction }) => direction === DirectionEnum.CREDIT) &&
			templateLines?.some(({ direction }) => direction === DirectionEnum.DEBIT),
		[templateLines],
	);

	const isFormValid = useMemo(() => isTemplateLinesValid() && isValid, [isTemplateLinesValid, isValid]);

	const [createSettingDomainTemplate, { loading: isCreateLoading }] = useMutation<CreateSettingDomainTemplateType>(
		CREATE_SETTING_DOMAIN_TEMPLATE,
		{
			onCompleted: () => {
				setIsCreateSettingDomainTemplateSucceeded(true);
				setTimeout(() => setIsCreateSettingDomainTemplateSucceeded(false), 3000);
			},
		},
	);

	const [updateSettingDomainTemplate, { loading: isUpdateLoading }] = useMutation<UpdateSettingDomainTemplateType>(
		UPDATE_SETTING_DOMAIN_TEMPLATE,
		{
			onCompleted: () => {
				setIsUpdateSettingDomainTemplateSucceeded(true);
				setTimeout(() => setIsUpdateSettingDomainTemplateSucceeded(false), 3000);
			},
		},
	);

	const [removeSettingDomainTemplate, { loading: isRemoveLoading }] = useMutation<RemoveSettingDomainTemplateType>(
		REMOVE_SETTING_DOMAIN_TEMPLATE,
		{
			onCompleted: back,
			onError: (error: ApolloError) =>
				setConfirmationDialogDetails((prevValue) => ({
					...prevValue,
					dialogErrorMessage: generateErrorInformations({
						error,
						resource: 'quickentry_removeSettingDomainTemplate',
					})?.message,
				})),
		},
	);

	const isLoading = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading]);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveLoading || isCreateLoading || isUpdateLoading || !isValid || !isDirty || !isFormValid,
		[isCreateLoading, isDirty, isFormValid, isRemoveLoading, isUpdateLoading, isValid],
	);

	const mutationButtonName = useMemo(() => {
		if (templateId) {
			if (isCreateSettingDomainTemplateSucceeded) return 'Modèle ajouté';
			if (isUpdateSettingDomainTemplateSucceeded) return 'Modèle modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isCreateSettingDomainTemplateSucceeded, isUpdateSettingDomainTemplateSucceeded, templateId]);

	const mutationButtonStartIcon = useMemo(() => <Icon path={templateId ? mdiPencil : mdiPlus} />, [templateId]);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const handleTemplateLinesChange = useCallback(
		(newLines: SettingTemplateLineType[]) =>
			setValue('templateLines', newLines, {
				shouldDirty: true,
				shouldValidate: true,
			}),
		[setValue],
	);

	const onSubmit = useCallback(
		(values: SettingDomainTemplateFormType) => {
			const { analyticalDimensionCode, journalCode, name, paymentModeCode, state, validUntil } = values;

			const validUntilString = validUntil?.toISOString();

			const tmpTemplate: Partial<
				Omit<SettingDomainTemplateType, 'templateLines'> & {
					templateLines: SettingDomainTemplateLineMutationType[];
				}
			> = {
				analyticalDimensionCode,
				analyticalSectionCaption,
				defaultEntryName,
				journalCode,
				name,
				paymentModeCode,
				state,
				validUntil: validUntilString,
			};

			if (templateLines) {
				tmpTemplate.templateLines = templateLines.map(
					(
						{
							accountCode,
							analyticalSectionCode,
							defaultTransactionName,
							direction,
							isAnalyticalEnabled,
							name: templateLineName,
							subaccountCode,
							subaccountPrefix,
							type,
						},
						index,
					) => ({
						name: templateLineName,
						rank: index,
						accountCode,
						analyticalSectionCode,
						defaultTransactionName,
						direction,
						isAnalyticalEnabled,
						subaccountCode,
						subaccountPrefix,
						type,
					}),
				);
			}

			setErrorMessage('');

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createSettingDomainTemplate,
						mutationName: 'createSettingDomainTemplate',
						inputValues: {
							domainId,
							...tmpTemplate,
						},
					},
				],
				[
					'update',
					{
						mutation: updateSettingDomainTemplate,
						mutationName: 'updateSettingDomainTemplate',
						inputValues: {
							id: templateId,
							...tmpTemplate,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(templateId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id }: { id: string } = data[`quickentry_${mutationName}` as keyof typeof data];

					setTemplate({
						...values,
						validUntil: validUntilString,
						id,
						state,
					});
				})
				.catch((error: ApolloError) =>
					setErrorMessage(
						generateErrorInformations({
							error,
							resource: templateId
								? 'quickentry_updateSettingDomainTemplate'
								: 'quickentry_createSettingDomainTemplate',
						})?.message,
					),
				);
		},
		[
			analyticalSectionCaption,
			createSettingDomainTemplate,
			defaultEntryName,
			domainId,
			templateId,
			templateLines,
			updateSettingDomainTemplate,
		],
	);

	const handleRemoval = useCallback(
		() =>
			removeSettingDomainTemplate({
				variables: { quickentryRemoveSettingDomainTemplateId: templateId },
			}),
		[removeSettingDomainTemplate, templateId],
	);

	// Resetting form error if there's a change in the form
	useEffect(() => {
		isDirty && setErrorMessage('');
	}, [isDirty]);

	// Syncs the templateName field with the defaultEntryName field
	useEffect(() => {
		!dirtyFields.defaultEntryName && setValue('defaultEntryName', templateName);
	}, [setValue, dirtyFields, templateName]);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveLoading,
				persistantErrorMessage: dialogErrorMessage,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemoval, isRemoveLoading, dialogErrorMessage],
	);

	return (
		<SettingsDialogPage title={templateName ?? "Ajout d'un modèle de pièces"}>
			<TemplateDomainInfosForm control={control} />

			<Divider />

			<TemplateDomainLineSection
				analyticalDimensionCode={formAnalyticalDimensionCode}
				analyticalSectionCaption={analyticalSectionCaption}
				defaultEntryName={defaultEntryName}
				onChange={handleTemplateLinesChange}
				templateLines={templateLines ?? []}
			/>

			<ActionWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={mutationButtonStartIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}

				{templateId && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</ActionWrapper>

			{templateId && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isOpen}
					onClose={closeConfirmationDialog}
					title={`Êtes-vous sûr de vouloir supprimer le modèle ${templateName ?? ''} ?`}
				/>
			)}
		</SettingsDialogPage>
	);
};

export default TemplateDomainForm;
