import { FiscalYearType } from '../types/fiscalYear';

export const convertFiscalYearDates = (fiscalYear: FiscalYearType): FiscalYearType => {
	const { startDate, endDate } = fiscalYear;

	return {
		...fiscalYear,
		startDate: new Date(startDate),
		endDate: new Date(endDate),
	};
};
