import { ModuleCodeEnum, WidgetTemplateType } from '../../../types/widget';

import DocumentLastUploads from './DocumentLastUploadsWidget';
import DocumentUploadWidget from './WidgetDocumentUpload';

const widgets: WidgetTemplateType[] = [
	{
		component: DocumentUploadWidget,
		name: 'Dépôt de documents',
		type: 'document-upload',
		minW: 2,
		maxW: Infinity,
		minH: 1,
		maxH: 1,
		moduleCode: ModuleCodeEnum.DOCUMENT,
	},
	{
		component: DocumentLastUploads,
		name: 'Historique des documents',
		type: 'document-last-uploads',
		minW: 2,
		maxW: 2,
		minH: 2,
		maxH: Infinity,
		moduleCode: ModuleCodeEnum.DOCUMENT,
	},
];

export default widgets;
