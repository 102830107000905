import { useCallback } from 'react';

import { BaseSettingsItem, Icon, IconButton, SettingsGroup, TextField, Tooltip } from '@elipssolution/harfang';

import { styled } from '@mui/material';

import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js';

import { v4 as uuid } from 'uuid';

const ExclusionList = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(1),

	width: 250,
}));

const ExclusionWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',

	gap: theme.spacing(1),
}));

export type ExclusionType = {
	id: string;
	value?: string;
};

type ExclusionItemProps = {
	exclusion: ExclusionType;
	isLast: boolean;
	onAdd: () => void;
	onChange: (exclusion: ExclusionType) => void;
	onRemove: () => void;
};

const ExclusionItem = ({ exclusion: { id, value }, isLast, onAdd, onChange, onRemove }: ExclusionItemProps) => (
	<ExclusionWrapper>
		<TextField onChange={(newValue) => onChange({ id, value: newValue })} value={value} />
		{isLast ? (
			<Tooltip content={value ? 'Ajouter une exclusion' : ''}>
				<div>
					<IconButton disabled={!value} onClick={onAdd} size="small">
						<Icon path={mdiPlusCircle} />
					</IconButton>
				</div>
			</Tooltip>
		) : (
			<Tooltip content="Supprimer l'exclusion">
				<div>
					<IconButton onClick={onRemove} size="small">
						<Icon path={mdiMinusCircle} />
					</IconButton>
				</div>
			</Tooltip>
		)}
	</ExclusionWrapper>
);

type ExclusionsFormProps = {
	onChange: (value: ExclusionType[]) => void;
	exclusions?: ExclusionType[];
};

const ExclusionsForm = ({ onChange, exclusions = [{ id: uuid(), value: '' }] }: ExclusionsFormProps) => {
	const handleScroll = () => {
		const settingsContentDiv = document.querySelector('#settings-page');

		settingsContentDiv?.scrollTo({
			behavior: 'smooth',
			top: settingsContentDiv.scrollHeight,
		});
	};

	const handleChange = useCallback(
		(exclusion: ExclusionType) => {
			const isExclusionExisting = exclusions.some(({ id }) => id === exclusion.id);

			isExclusionExisting
				? onChange(exclusions.map((exclusionItem) => (exclusionItem.id === exclusion.id ? exclusion : exclusionItem)))
				: onChange([...exclusions, exclusion]);
		},
		[onChange, exclusions],
	);

	const addExclusion = useCallback(() => {
		(async () => {
			await Promise.resolve(onChange([...exclusions, { id: uuid(), value: '' }]));

			handleScroll();
		})().catch((e) => {
			throw e;
		});
	}, [exclusions, onChange]);

	const removeExclusion = useCallback(
		(idToRemove: ExclusionType['id']) => {
			onChange(exclusions.filter(({ id }) => id !== idToRemove));
		},
		[onChange, exclusions],
	);

	return (
		<SettingsGroup label="Exclusions">
			<BaseSettingsItem
				description="Astuce : le caractère * permet de définir des niveaux variables"
				label="Exclure les chemins suivants"
			>
				<ExclusionList>
					{exclusions.map((exclusion, index) => {
						const { id } = exclusion;

						const handleRemoveExclusion = () => removeExclusion(id);

						return (
							<ExclusionItem
								exclusion={exclusion}
								key={id}
								isLast={index === exclusions.length - 1}
								onAdd={addExclusion}
								onChange={handleChange}
								onRemove={handleRemoveExclusion}
							/>
						);
					})}
				</ExclusionList>
			</BaseSettingsItem>
		</SettingsGroup>
	);
};

export default ExclusionsForm;
