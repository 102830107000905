import { gql } from '@apollo/client';

import { SettingStorageUploadType } from '../types/settingStorage';

import { DOCUMENT_UPLOAD_TAG_FIELDS } from './tag';

export const BASE_STORAGE_UPLOAD_FIELDS = `
	configuration
	connector
	documentPath
	id
	isImmediatelyProcessed
	name
`;

// QUERIES

export type FetchAutocompleteStorageUploadsType = {
	document_settingStorageUploads: {
		count: number;
		items: Pick<SettingStorageUploadType, 'id' | 'name'>[];
		limit: number;
		offset: number;
	};
};

export const FETCH_AUTOCOMPLETE_STORAGE_UPLOADS = gql`
	query Document_settingStorageUploads($page: Page!, $search: String) {
		document_settingStorageUploads(page: $page, search: $search) {
			count
			items {
				id
				name
			}
			limit
			offset
		}
	}
`;

export type FetchStorageUploadsType = {
	document_settingStorageUploads: {
		count: number;
		items: SettingStorageUploadType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_STORAGE_UPLOADS = gql`
	query Document_settingStorageUploads($page: Page!, $search: String) {
		document_settingStorageUploads(page: $page, search: $search) {
			count
			items {
				id
				name
				tags {
					${DOCUMENT_UPLOAD_TAG_FIELDS}
				}
			}
			limit
			offset
		}
	}
`;

export type FetchStorageUploadType = {
	document_settingStorageUpload: SettingStorageUploadType;
};

export const FETCH_STORAGE_UPLOAD = gql`
	query Document_settingStorageUpload($id: ID!) {
		document_settingStorageUpload(id: $id) {
			${BASE_STORAGE_UPLOAD_FIELDS}
			tags {
				${DOCUMENT_UPLOAD_TAG_FIELDS}
			}
		}
	}
`;

// MUTATIONS

export type CreateStorageUploadType = {
	document_createSettingStorageUpload: SettingStorageUploadType;
};

export const CREATE_STORAGE_UPLOAD = gql`
	mutation Document_createSettingStorageUpload(
		$createSettingStorageUploadInput: document_CreateSettingStorageUploadInput!
	) {
		document_createSettingStorageUpload(createSettingStorageUploadInput: $createSettingStorageUploadInput) {
			id
		}
	}
`;

export type UpdateStorageUploadType = {
	document_updateSettingStorageUpload: SettingStorageUploadType;
};

export const UPDATE_STORAGE_UPLOAD = gql`
	mutation Document_updateSettingStorageUpload(
		$updateSettingStorageUploadInput: document_UpdateSettingStorageUploadInput!
	) {
		document_updateSettingStorageUpload(updateSettingStorageUploadInput: $updateSettingStorageUploadInput) {
			id
		}
	}
`;

export const REMOVE_STORAGE_UPLOAD = gql`
	mutation Document_removeSettingStorageUpload($id: ID!) {
		document_removeSettingStorageUpload(id: $id) {
			id
		}
	}
`;
