import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { SettingCustomerFileBankType } from '../types/settingBank';

// QUERIES

export type FetchSettingBanksByCustomerFileType = {
	quickentry_settingBanksByCustomerFile: {
		count: number;
		items: SettingCustomerFileBankType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_BANKS_BY_CUSTOMER_FILE = gql`
	query Quickentry_settingBanksByCustomerFile($customerFileId: ID!, $search: String, $page: Page, $orderBy: OrderBy) {
		quickentry_settingBanksByCustomerFile(
			customerFileId: $customerFileId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				id
				account {
					${ACCOUNT_FIELDS}
				}
				name
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateSettingCustomerFileBankType = {
	quickentry_createSettingCustomerFileBank: SettingCustomerFileBankType;
};

export const CREATE_SETTING_CUSTOMER_FILE_BANK = gql`
	mutation Quickentry_createSettingCustomerFileBank(
		$createSettingCustomerFileBankInput: quickentry_CreateSettingCustomerFileBankInput!
	) {
		quickentry_createSettingCustomerFileBank(createSettingCustomerFileBankInput: $createSettingCustomerFileBankInput) {
			id
		}
	}
`;

export type UpdateSettingCustomerFileBankType = {
	quickentry_updateSettingCustomerFileBank: SettingCustomerFileBankType;
};

export const UPDATE_SETTING_CUSTOMER_FILE_BANK = gql`
	mutation Quickentry_updateSettingCustomerFileBank(
		$updateSettingCustomerFileBankInput: quickentry_UpdateSettingCustomerFileBankInput!
	) {
		quickentry_updateSettingCustomerFileBank(updateSettingCustomerFileBankInput: $updateSettingCustomerFileBankInput) {
			id
		}
	}
`;

export type RemoveSettingCustomerFileBankType = {
	quickentry_removeSettingCustomerFileBank: {
		id: SettingCustomerFileBankType['id'];
	};
};

export const REMOVE_SETTING_CUSTOMER_FILE_BANK = gql`
	mutation Quickentry_removeSettingCustomerFileBank($quickentryRemoveSettingCustomerFileBankId: ID!) {
		quickentry_removeSettingCustomerFileBank(id: $quickentryRemoveSettingCustomerFileBankId) {
			id
		}
	}
`;
