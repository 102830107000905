import { gql } from '@apollo/client';

import { DocumentUploadStatusEnum } from '../../modules/document/types/documentUpload';

export type DocumentUploadType = {
	id: string;
	status: DocumentUploadStatusEnum;
	name: string;
};

// QUERIES

export type FetchDocumentUploadsType = {
	document_documentUploads: {
		items: DocumentUploadType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENT_UPLOADS = gql`
	query Document_documentUploads($page: Page!) {
		document_documentUploads(page: $page) {
			count
			items {
				id
				status
				name
			}
			limit
			offset
		}
	}
`;
