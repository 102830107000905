import { useCallback } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';

import { DomainType } from '../../../../../../types/domain';

import { FETCH_DOMAINS, FetchDomainsType } from '../../../../../../src/api/domain';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';

const columns: TableColumnType<DomainType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du domaine',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ isDefault }) => isDefault && <Chip label="Défaut" color="info" />,
		width: 110,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type DomainTableProps = {
	onDomainSelection?: (domain: DomainType) => void;
};

const DomainTable = ({ onDomainSelection }: DomainTableProps) => {
	const [fetchDomains] = useLazyQuery<FetchDomainsType>(FETCH_DOMAINS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<DomainType>,
		): Promise<{
			count: number;
			items: DomainType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchDomains({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				domains: { count = 0, items = [] },
			} = data ?? {
				domains: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchDomains],
	);

	const handleRowClick = useCallback((domain: DomainType) => onDomainSelection?.(domain), [onDomainSelection]);

	return (
		<SettingsTable<DomainType>
			addButtonLabel={null}
			dataSource={dataSource}
			minHeight={400}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Domaines"
		/>
	);
};

export default DomainTable;
