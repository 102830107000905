import { useLazyQuery } from '@apollo/client';
import { CircularProgress, Icon } from '@elipssolution/harfang';
import { mdiMenuDown, mdiMenuRight } from '@mdi/js';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import {
	FETCH_ALL_CATEGORIES_BY_PARENT,
	FETCH_CHILDREN_CATEGORIES_BY_PARENTS,
	FetchAllCategoriesByParentType,
	FetchChildrenCategoriesByParentsType,
} from '../../api/category';
import { CategoryWithChildrenType } from '../../types/category';

const StyledLoader = styled(Stack)(({ theme: { palette } }) => ({
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	color: palette.grey[400],
}));

const CategoriesList = styled(List)(({ theme: { shadows, shape, palette, spacing } }) => ({
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,
	marginTop: spacing(3.75),
	width: '250px',
	maxHeight: 'calc(100vh - 46px - 16px - 40px - 12px - 30px - 18px)',
	overflow: 'auto',
	padding: spacing(0.5, 1),
}));

const CategoryButton = styled(ListItemButton)(({ theme: { palette, shape, spacing } }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	minHeight: '36px',
	width: '100%',
	borderRadius: shape.borderRadius * 2,
	padding: spacing(0.25, 1),
	paddingLeft: 0,
	margin: spacing(0.25, 0),

	'&.selected': {
		backgroundColor: palette.secondary.main,
	},
}));

type FoldersNavigationProps = {
	selectedCategories: CategoryWithChildrenType[];
	onSelectedCategoriesChange: (categoriesSelected: CategoryWithChildrenType[]) => void;
	lastCategorySelected?: CategoryWithChildrenType;
	isSearchActive: boolean;
};

const FoldersNavigation = ({
	selectedCategories,
	onSelectedCategoriesChange,
	lastCategorySelected,
	isSearchActive,
}: FoldersNavigationProps) => {
	const [categories, setCategories] = useState<CategoryWithChildrenType[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const addRecursivelyChildrenToSelectedCategory = useCallback(
		(
			currentCategories: CategoryWithChildrenType[],
			categoryChildren: CategoryWithChildrenType[],
		): CategoryWithChildrenType[] =>
			currentCategories.map((currentCategory) => {
				if (lastCategorySelected?.id === currentCategory.id) {
					onSelectedCategoriesChange([
						...selectedCategories.filter(({ id }) => id !== currentCategory.id),
						{ ...currentCategory, children: categoryChildren },
					]);
				}

				return {
					...currentCategory,
					...(selectedCategories.some(({ id }) => id === currentCategory.id) && {
						isLevelOpen: true,
						children:
							lastCategorySelected?.id === currentCategory.id
								? categoryChildren
								: addRecursivelyChildrenToSelectedCategory(currentCategory.children || [], categoryChildren),
					}),
				};
			}),
		[lastCategorySelected?.id, onSelectedCategoriesChange, selectedCategories],
	);

	const addRecursivelyChildrenToParentCategories = useCallback(
		(
			currentCategories: CategoryWithChildrenType[],
			parents: CategoryWithChildrenType[],
		): CategoryWithChildrenType[] => {
			if (parents.length === 0) {
				return currentCategories;
			}

			const [currentParent, ...remainingParents] = parents;

			return currentCategories.map((category) =>
				category.id === currentParent.id && currentParent.children
					? {
							...category,
							isLevelOpen: true,
							children: addRecursivelyChildrenToParentCategories(currentParent.children, remainingParents),
					  }
					: category,
			);
		},
		[],
	);

	const [fetchAllCategoriesByParent] = useLazyQuery<FetchAllCategoriesByParentType>(FETCH_ALL_CATEGORIES_BY_PARENT, {
		onCompleted: ({ document_documentAllCategoriesByParent }) => {
			if (isLoading) {
				setIsLoading(false);
			}

			setCategories((prevValue) =>
				prevValue.length === 0
					? document_documentAllCategoriesByParent
					: addRecursivelyChildrenToSelectedCategory(prevValue, document_documentAllCategoriesByParent),
			);
		},
	});

	const [fetchChildrenCategoriesByParents] = useLazyQuery<FetchChildrenCategoriesByParentsType>(
		FETCH_CHILDREN_CATEGORIES_BY_PARENTS,
		{
			onCompleted: ({ document_documentChildrenCategoriesByParents }) =>
				setCategories((prevCategories) =>
					addRecursivelyChildrenToParentCategories(prevCategories, document_documentChildrenCategoriesByParents),
				),
		},
	);

	const closeChildren = useCallback(
		(categoriesToClose: CategoryWithChildrenType[]): CategoryWithChildrenType[] =>
			categoriesToClose.map((category) => ({
				...category,
				isLevelOpen: false,
				children: category.children ? closeChildren(category.children) : [],
			})),
		[],
	);

	const closeRecursivelyChildrenToSelectedCategory = useCallback(
		(
			currentCategories: CategoryWithChildrenType[],
			categoryId: CategoryWithChildrenType['id'],
		): CategoryWithChildrenType[] =>
			currentCategories.map((currentCategory) =>
				currentCategory.id === categoryId
					? {
							...currentCategory,
							isLevelOpen: false,
							children: currentCategory.children ? closeChildren(currentCategory.children) : [],
					  }
					: {
							...currentCategory,
							children: currentCategory.children
								? closeRecursivelyChildrenToSelectedCategory(currentCategory.children, categoryId)
								: currentCategory.children,
					  },
			),
		[closeChildren],
	);

	const openCategory = useCallback(
		(
			currentCategories: CategoryWithChildrenType[],
			categoryId: CategoryWithChildrenType['id'],
		): CategoryWithChildrenType[] =>
			currentCategories.map((currentCategory) =>
				currentCategory.id === categoryId
					? {
							...currentCategory,
							isLevelOpen: true,
					  }
					: {
							...currentCategory,
							children: currentCategory.children
								? openCategory(currentCategory.children, categoryId)
								: currentCategory.children,
					  },
			),
		[],
	);

	const findPath = useCallback(
		(
			currentCategories: CategoryWithChildrenType[],
			targetCategoryId: CategoryWithChildrenType['id'],
		): CategoryWithChildrenType[] => {
			let path: CategoryWithChildrenType[] = [];

			currentCategories.some((node) => {
				if (node.id === targetCategoryId) {
					path = [node];
					return true;
				}

				if (node.children) {
					const childPath = findPath(node.children, targetCategoryId);

					if (childPath.length > 0) {
						path = [node, ...childPath];
						return true;
					}
				}

				return false;
			});

			return path;
		},
		[],
	);

	const handleCategoryClick = useCallback(
		({ id: categoryId, children }: CategoryWithChildrenType, isLevelOpen?: boolean) => {
			if (children !== undefined) {
				setCategories((prevValue) =>
					isLevelOpen && lastCategorySelected?.id === categoryId
						? closeRecursivelyChildrenToSelectedCategory(prevValue, categoryId)
						: openCategory(prevValue, categoryId),
				);
			}

			if (lastCategorySelected?.id !== categoryId) {
				onSelectedCategoriesChange(findPath(categories, categoryId) || []);
			}
		},
		[
			categories,
			closeRecursivelyChildrenToSelectedCategory,
			findPath,
			lastCategorySelected?.id,
			onSelectedCategoriesChange,
			openCategory,
		],
	);

	const findCategoryLevel = useCallback(
		(
			curentCategories: CategoryWithChildrenType[],
			categoryId: CategoryWithChildrenType['id'],
			currentLevel?: number,
		): number =>
			curentCategories.reduce((acc, category) => {
				if (acc !== -1) {
					return acc;
				}
				if (category.id === categoryId) {
					return currentLevel || 0;
				}
				if (category.children) {
					return findCategoryLevel(category.children, categoryId, (currentLevel || 0) + 1);
				}
				return acc;
			}, -1),
		[],
	);

	const getButtonPaddingLeft = useCallback(
		(categoryId: CategoryWithChildrenType['id']) => {
			const level = findCategoryLevel(categories, categoryId);

			return level === -1 ? 0 : level * 2;
		},
		[categories, findCategoryLevel],
	);

	const renderCategoryButton = useCallback(
		(category: CategoryWithChildrenType): ReactElement => {
			const { id: categoryId, name: categoryName, children: categoryChildren, isLevelOpen, hasChildren } = category;
			const isLastCategorySelected = lastCategorySelected?.id === categoryId;
			const paddingLeft = getButtonPaddingLeft(categoryId);

			return (
				<>
					<ListItem sx={{ padding: 0, paddingLeft }}>
						<CategoryButton
							key={categoryId}
							onClick={() => handleCategoryClick(category, isLevelOpen)}
							className={clsx({ selected: isLastCategorySelected })}
						>
							{hasChildren && (
								<ListItemIcon sx={{ minWidth: 0 }}>
									<Icon path={isLevelOpen ? mdiMenuDown : mdiMenuRight} />
								</ListItemIcon>
							)}
							<ListItemText sx={{ ...(!hasChildren && { marginLeft: '24px' }) }}>
								<Typography variant="body2" align="left" marginLeft={1}>
									{categoryName}
								</Typography>
							</ListItemText>
						</CategoryButton>
					</ListItem>

					{!!categoryChildren &&
						isLevelOpen &&
						categoryChildren.map((categoryChild) => renderCategoryButton(categoryChild))}
				</>
			);
		},
		[getButtonPaddingLeft, handleCategoryClick, lastCategorySelected?.id],
	);

	const categoryButtonRenderer = useMemo(
		() => categories?.map((category) => renderCategoryButton(category)),
		[categories, renderCategoryButton],
	);

	useEffect(() => {
		if (
			(!lastCategorySelected?.children && selectedCategories.filter(({ children }) => !children).length === 1) ||
			(lastCategorySelected?.hasChildren &&
				(!lastCategorySelected.children || lastCategorySelected.children.length === 0)) ||
			(!lastCategorySelected && categories.length === 0)
		) {
			fetchAllCategoriesByParent({
				variables: {
					parentId: lastCategorySelected ? lastCategorySelected.id : null,
				},
			}).catch((error) => {
				throw error;
			});
		} else if (selectedCategories.some(({ children }) => !children)) {
			fetchChildrenCategoriesByParents({
				variables: {
					parentIds: selectedCategories.map(({ id }) => id),
				},
			}).catch((error) => {
				throw error;
			});
		}
	}, [
		categories.length,
		fetchAllCategoriesByParent,
		fetchChildrenCategoriesByParents,
		lastCategorySelected,
		selectedCategories,
	]);

	useEffect(() => {
		if (isSearchActive) {
			setCategories((prevValue) => closeChildren(prevValue));
		}
	}, [closeChildren, isSearchActive]);

	return (
		<CategoriesList>
			{isLoading ? (
				<StyledLoader>
					<CircularProgress color="inherit" />
				</StyledLoader>
			) : (
				categoryButtonRenderer
			)}
		</CategoriesList>
	);
};

export default FoldersNavigation;
