import { SettingsGroup } from '@elipssolution/harfang';
import { CircularProgress, styled, Typography } from '@mui/material';

import ExternalGroupPermission from './ExternalGroupPermission';
import modulesConfig from '../../../../modules/modules';
import { GroupPermissionType, PermissionModuleType } from '../../../types/permission';

const LoaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: theme.spacing(1),

	marginTop: theme.spacing(1),

	'& svg': {
		color: theme.palette.grey[400],
	},
}));

type ExternalGroupPermissionModulesProps = {
	loading?: boolean;
	permissionModules: PermissionModuleType<GroupPermissionType>[];
	disabled: boolean;
	onPermissionChanged: () => void;
};

const ExternalGroupPermissionModules = ({
	loading = false,
	permissionModules,
	disabled,
	onPermissionChanged,
}: ExternalGroupPermissionModulesProps) =>
	loading ? (
		<LoaderWrapper>
			<Typography>Chargement des droits du groupe en cours...</Typography>
			<CircularProgress size="24px" color="inherit" />
		</LoaderWrapper>
	) : (
		<>
			{permissionModules.map(({ code, id, permissions }) => {
				const moduleName = modulesConfig.find((module) => module.code === code)?.name;

				return (
					<SettingsGroup key={id} label={code === 'general' ? 'Général' : moduleName} actionsWidth={180}>
						{permissions.map((permission: GroupPermissionType) => (
							<ExternalGroupPermission
								key={permission.id}
								permission={permission}
								disabled={disabled}
								onPermissionChanged={onPermissionChanged}
							/>
						))}
					</SettingsGroup>
				);
			})}
		</>
	);

export default ExternalGroupPermissionModules;
