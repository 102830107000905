import { NetworkStatus, useLazyQuery } from '@apollo/client';
import {
	CircularProgress,
	Icon,
	Table,
	TableColumnType,
	TableOrderByType,
	Tooltip,
	IconButton,
	TableRow,
	useTable,
} from '@elipssolution/harfang';
import {
	mdiAlertCircle,
	mdiDownload,
	mdiFileExcelBox,
	mdiFilePdfBox,
	mdiFileWordBox,
	mdiFolder,
	mdiTextBox,
} from '@mdi/js';
import { alpha, Stack, styled, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import DocumentPreviewDialog from './DocumentPreviewDialog';
import { useSession } from '../../../../src/components/SessionProvider';
import { downloadFile, formatFileSize, getFileBlob } from '../../../../src/utils/file';
import {
	FETCH_DOCUMENTS_AND_CATEGORIES_BY_CATEGORY,
	FetchDocumentsAndCategoriesByCategoryType,
} from '../../api/document';
import { CategoryWithChildrenType } from '../../types/category';
import { DocumentAndCategoriesType, FileType } from '../../types/document';

const TypeIcon = styled(Icon)(({ theme: { palette } }) => ({
	color: palette.grey[900],

	'&.pdf': {
		color: '#de524e',
	},

	'&.word': {
		color: '#295497',
	},

	'&.excel': {
		color: '#1a7141',
	},
}));

const fileIconsMapper = new Map([
	[FileType.PDF, mdiFilePdfBox],
	[FileType.DOCX, mdiFileWordBox],
	[FileType.XLSX, mdiFileExcelBox],
	[FileType.OTHER, mdiTextBox],
]);

const fileTypeMapper = new Map([
	[FileType.PDF, 'pdf'],
	[FileType.DOCX, 'word'],
	[FileType.XLSX, 'excel'],
]);

type ActionButtonProps = {
	error: boolean;
	id: string;
	loading: boolean;
	name: string;
	onFileDownload: (event: MouseEvent<HTMLButtonElement>, id: string, name: string) => void;
};

const ActionItem = ({ error, id, loading, name, onFileDownload }: ActionButtonProps) => {
	const handleFileDownload = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => onFileDownload(event, id, name),
		[id, name, onFileDownload],
	);

	if (!error && !loading) {
		return (
			<Tooltip content="Télécharger" placement="left">
				<IconButton disabled={loading} onClick={handleFileDownload} size="small">
					<Icon path={mdiDownload} />
				</IconButton>
			</Tooltip>
		);
	}

	return loading ? (
		<CircularProgress />
	) : (
		<Tooltip color="error" content="Erreur lors de la récupération du document" placement="left">
			<Stack justifyContent="center" alignItems="center" width="100%">
				<Icon path={mdiAlertCircle} color="error" />
			</Stack>
		</Tooltip>
	);
};

type StorageDownloadTableProps = {
	onCategoryAdd: (category: CategoryWithChildrenType) => void;
	onSelectedCategoriesChange: (categoriesSelected: CategoryWithChildrenType[]) => void;
	search?: string;
	lastCategorySelected?: CategoryWithChildrenType;
};

const StorageDownloadTable = ({
	onCategoryAdd,
	onSelectedCategoriesChange,
	search,
	lastCategorySelected,
}: StorageDownloadTableProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();
	const tableInstance = useTable();
	const {
		palette: { tertiary },
	} = useTheme();

	const [document, setDocument] = useState<Blob>();
	const [documentSelectedId, setDocumentSelectedId] = useState<string>();
	const [itemIdsError, setItemIdsError] = useState<string[]>();
	const [itemIdsLoading, setItemIdsLoading] = useState<string[]>();
	const [fetchError, setFetchError] = useState<string>();
	const [isFetchLoading, setIsFetchLoading] = useState(false);

	const [fetchDocumentsAndCategories, { networkStatus }] = useLazyQuery<FetchDocumentsAndCategoriesByCategoryType>(
		FETCH_DOCUMENTS_AND_CATEGORIES_BY_CATEGORY,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const documentsAndCategoriesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			dataSearch?: string,
			orderBy?: TableOrderByType<DocumentAndCategoriesType>,
		): Promise<{
			count: number;
			items: DocumentAndCategoriesType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchDocumentsAndCategories({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					categoryId: lastCategorySelected?.id || null,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_documentsAndCategoriesByCategory: { count = 0, items = [] },
			} = data ?? {
				document_documentsAndCategoriesByCategory: {},
			};

			return {
				count,
				items: items.map(({ sourceUpdatedAt, ...rest }) => ({
					...rest,
					sourceUpdatedAt: sourceUpdatedAt ? new Date(sourceUpdatedAt).toLocaleDateString('fr-FR') : undefined,
				})),
			};
		},
		[fetchDocumentsAndCategories, lastCategorySelected, search],
	);

	const openDocumentPreviewDialog = useCallback(
		(documentId: string) => {
			if (fetchError || isFetchLoading || itemIdsLoading?.includes(documentId) || itemIdsError?.includes(documentId))
				return;

			setDocumentSelectedId(documentId);
			setIsFetchLoading(true);

			getFileBlob(`/documents?id=${documentId}`, access_token, sessionCustomerFile?.id)
				.then((data) => {
					setDocument(data);
					setIsFetchLoading(false);
				})
				.catch((err) => {
					setIsFetchLoading(false);
					setFetchError((err as Error).message);

					setTimeout(() => {
						tableInstance.reload();
						setFetchError(undefined);
						setDocumentSelectedId(undefined);
					}, 5000);
				});
		},
		[access_token, fetchError, isFetchLoading, itemIdsError, itemIdsLoading, sessionCustomerFile?.id, tableInstance],
	);

	const closeDocumentPreviewDialog = useCallback(() => {
		setDocumentSelectedId(undefined);
		setDocument(undefined);
	}, []);

	const handleRowClick = useCallback(
		(documentOrCategory: DocumentAndCategoriesType) => {
			const { id, name, fileType, path } = documentOrCategory;

			if (fileType) {
				if (fileType === FileType.PDF) {
					openDocumentPreviewDialog(id);
				}
			} else if (search && path) {
				onSelectedCategoriesChange(path);
			} else {
				onCategoryAdd({ id, name });
			}
		},
		[onCategoryAdd, onSelectedCategoriesChange, openDocumentPreviewDialog, search],
	);

	const handleFileDownload = useCallback(
		(event: MouseEvent<HTMLButtonElement>, id: string, name: string) => {
			event.stopPropagation();

			(async () => {
				try {
					setItemIdsLoading((prevValue = []) => [...prevValue, id]);
					setItemIdsError((prevValue) => prevValue?.filter((value) => value !== id));

					const documentBlob = await getFileBlob(`/documents?id=${id}`, access_token, sessionCustomerFile?.id);
					downloadFile(URL.createObjectURL(documentBlob), name);
				} catch {
					setItemIdsError((prevValue = []) => [...prevValue, id]);
				} finally {
					setItemIdsLoading((prevValue) => prevValue?.filter((value) => value !== id));
				}
			})().catch((e) => {
				throw e;
			});
		},
		[access_token, sessionCustomerFile?.id],
	);

	const columns: TableColumnType<DocumentAndCategoriesType>[] = useMemo(
		() => [
			{
				field: 'name',
				key: 'name',
				sortable: true,
				title: 'Nom',
				render: ({ name, path, fileType = FileType.OTHER }) => (
					<Stack flexDirection="row" gap={1} alignItems="center">
						<Stack justifyContent="center" alignItems="center">
							<TypeIcon
								className={clsx(fileTypeMapper.get(fileType))}
								path={fileIconsMapper.get(fileType) ?? mdiFolder}
							/>
						</Stack>
						<Stack>
							<Typography variant="body2">{name}</Typography>
							{path && (
								<Typography fontStyle="italic" variant="caption">
									{path.map(({ name: pathName }) => pathName).join('/')}
								</Typography>
							)}
						</Stack>
					</Stack>
				),
				width: 200,
			},
			{
				align: 'right',
				field: 'size',
				flexGrow: 0,
				key: 'size',
				sortable: true,
				render: ({ size }) => (size ? formatFileSize(+size) : '--'),
				title: 'Taille',
				width: 100,
			},
			{
				field: 'sourceUpdatedAt',
				flexGrow: 0,
				key: 'sourceUpdatedAt',
				sortable: true,
				render: ({ sourceUpdatedAt }) => sourceUpdatedAt || '',
				title: 'Modifié le',
				width: 100,
			},
			{
				align: 'center',
				key: 'actions',
				flexGrow: 0,
				render: ({ id, name, fileType }) => {
					if (documentSelectedId === id && isFetchLoading) {
						return (
							<Stack justifyContent="center" alignItems="center" width="100%">
								<CircularProgress />
							</Stack>
						);
					}

					if (documentSelectedId === id && fetchError) {
						return (
							<Tooltip color="error" content={fetchError} open={!!fetchError} placement="left">
								<Stack justifyContent="center" alignItems="center" width="100%">
									<Icon path={mdiAlertCircle} color="error" />
								</Stack>
							</Tooltip>
						);
					}

					return fileType ? (
						<ActionItem
							error={!!itemIdsError?.includes(id)}
							id={id}
							loading={!!itemIdsLoading?.includes(id)}
							name={name}
							onFileDownload={handleFileDownload}
						/>
					) : undefined;
				},
				width: 40,
			},
		],
		[documentSelectedId, fetchError, handleFileDownload, isFetchLoading, itemIdsError, itemIdsLoading],
	);

	const rowRenderer = useCallback(
		(rowData: DocumentAndCategoriesType, index: number) => (
			<TableRow<DocumentAndCategoriesType>
				item={rowData}
				index={index}
				columns={columns}
				style={{ backgroundColor: index % 2 === 0 ? alpha(tertiary.main, 0.2) : undefined }}
			/>
		),
		[columns, tertiary],
	);

	useEffect(() => {
		if (networkStatus === NetworkStatus.refetch) {
			tableInstance.reload();
		}
	}, [tableInstance, networkStatus]);

	useEffect(() => {
		setFetchError(undefined);
		setIsFetchLoading(false);
		setItemIdsError(undefined);
		setItemIdsLoading(undefined);
	}, [lastCategorySelected]);

	return (
		<Stack flex={2}>
			<Table<DocumentAndCategoriesType>
				table={tableInstance}
				columns={columns}
				dataSource={documentsAndCategoriesDataSource}
				onRowClick={handleRowClick}
				rowRenderer={rowRenderer}
				style={{
					height: '100%',
				}}
			/>

			<DocumentPreviewDialog document={document} documentId={documentSelectedId} onClose={closeDocumentPreviewDialog} />
		</Stack>
	);
};

export default StorageDownloadTable;
