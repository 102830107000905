import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { FetchCheckConfigurationsType, FETCH_CHECK_CONFIGURATIONS } from '../../../api/check';

const useCheckConfigurations = ({ skip = false }: { skip?: boolean } = {}) => {
	const [error, setError] = useState<string>();

	const { data, loading } = useQuery<FetchCheckConfigurationsType>(FETCH_CHECK_CONFIGURATIONS, {
		onCompleted: ({ quickentry_checkConfigurations: { journalCode, paymentModeCode } }) => {
			let missingConfigurations = '';

			if (!journalCode) {
				missingConfigurations += 'journal';
			}

			if (!paymentModeCode) {
				missingConfigurations += `${missingConfigurations.length > 0 ? ' et' : ''} mode de paiement`;
			}

			if (missingConfigurations) {
				setError(
					`Aucun ${missingConfigurations} configuré pour la saisie de chèques sur ce dossier. Veuillez contacter votre responsable paramétrage.`,
				);
			}
		},
		skip,
	});

	return { checkConfigurations: data?.quickentry_checkConfigurations, error, loading };
};

export default useCheckConfigurations;
