import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { NavigationCarousel, useCarousel } from '@elipssolution/harfang';
import { Typography, styled } from '@mui/material';
import { isWithinInterval } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import shave from 'shave';

import { FETCH_ALL_FISCAL_YEARS, FetchAllFiscalYearsType } from '../../../src/api/fiscalYear';
import { FETCH_BANKS, FetchBanksType } from '../api/bank';
import { BankType } from '../types/bank';

const itemHeight = 84;
const itemWidth = 150;

const CarouselItemContainer = styled('div')({
	display: 'grid',
	placeItems: 'center',
});

type CarouselItemType = {
	name?: string;
};

const CarouselItem = ({ name = '' }: CarouselItemType) => {
	const bankName = useRef(null);

	useEffect(() => {
		if (bankName.current) {
			shave(bankName.current, itemHeight);
		}
	}, [bankName]);

	return (
		<CarouselItemContainer>
			<Typography align="center" ref={bankName}>
				{name}
			</Typography>
		</CarouselItemContainer>
	);
};

const renderItem = (item?: BankType) => <CarouselItem {...item} />;

type BanksCarouselProps = {
	onBankSelection: (bank: BankType) => void;
	selectedBank?: BankType;
};

const BanksCarousel = ({ onBankSelection, selectedBank }: BanksCarouselProps) => {
	const carouselInstance = useCarousel();
	const { called: isFetchFiscalYearsCalled, data: fiscalYearsFetchData } =
		useQuery<FetchAllFiscalYearsType>(FETCH_ALL_FISCAL_YEARS);

	const fiscalYears = useMemo(
		() =>
			fiscalYearsFetchData?.fiscalYearsWithoutPagination.map(({ startDate, endDate, ...rest }) => ({
				...rest,
				startDate: new Date(startDate),
				endDate: new Date(endDate),
			})),
		[fiscalYearsFetchData],
	);

	const isFiscalYearValid = useMemo(
		() =>
			fiscalYears?.some(({ startDate, endDate }) => isWithinInterval(new Date(), { start: startDate, end: endDate })) ??
			isFetchFiscalYearsCalled,
		[fiscalYears, isFetchFiscalYearsCalled],
	);

	const [fetchBanks, { networkStatus }] = useLazyQuery<FetchBanksType>(FETCH_BANKS, {
		notifyOnNetworkStatusChange: true,
	});

	const banksDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: BankType[];
		}> => {
			const { data, error } = await fetchBanks({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_banks: { count = 0, items = [] },
			} = data ?? {
				quickentry_banks: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchBanks],
	);

	const isBankSelected = useCallback(({ id }: BankType) => id === selectedBank?.id, [selectedBank]);
	const isItemDisabled = useCallback(() => !isFiscalYearValid, [isFiscalYearValid]);

	const handleBankSelection = useCallback((bank: BankType) => onBankSelection(bank), [onBankSelection]);

	// Reload the carousel if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && carouselInstance.reload();
	}, [carouselInstance, networkStatus]);

	return (
		<NavigationCarousel<BankType>
			carousel={carouselInstance}
			itemHeight={itemHeight}
			dataSource={banksDataSource}
			isItemSelected={isBankSelected}
			itemWidth={itemWidth}
			onItemClick={handleBankSelection}
			renderItem={renderItem}
			title="Banques"
			enableSearch
			isItemDisabled={isItemDisabled}
			info={
				isFiscalYearValid
					? undefined
					: "La saisie n'est pas possible car aucune année fiscale ne correspond à la date du jour"
			}
		/>
	);
};

export default BanksCarousel;
