import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemAutocomplete } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import { FETCH_JOURNALS_BY_CUSTOMER_FILE, FetchJournalsByCustomerFileType } from '../../../../api/journal';
import {
	CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL,
	CREATE_CUSTOMER_FILE_CHECK_SETTING_JOURNAL,
	CreateCustomerFileBillExchangeSettingJournalType,
	CreateCustomerFileCheckSettingJournalType,
	FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL,
	FETCH_CUSTOMER_FILE_CHECK_SETTING_JOURNAL,
	FetchCustomerFileBillExchangeSettingJournalType,
	FetchCustomerFileCheckSettingJournalType,
} from '../../../../api/settingJournal';
import { JournalType } from '../../../../types/journal';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	billExchangeJournal?: JournalType;
	checkJournal?: JournalType;
};

type JournalCodesProps = {
	customerFileId?: CustomerFileType['id'];
};

const JournalCodes = ({ customerFileId }: JournalCodesProps) => {
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		setValue,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();
	const [isCreateCustomerFileBillExchangeJournalSucceeded, setIsCreateCustomerFileBillExchangeJournalSucceeded] =
		useState(false);

	const [fetchJournalsByCustomerFile] = useLazyQuery<FetchJournalsByCustomerFileType>(FETCH_JOURNALS_BY_CUSTOMER_FILE);

	const journalsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: JournalType[];
		}> => {
			const { data, error: queryError } = await fetchJournalsByCustomerFile({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				journalsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				journalsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchJournalsByCustomerFile, customerFileId],
	);

	useQuery<FetchCustomerFileBillExchangeSettingJournalType>(FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL, {
		onCompleted: ({ quickentry_settingCustomerFileBillExchangeJournal: { currentJournal } }) =>
			setValue('billExchangeJournal', currentJournal ?? undefined),
		onError: (queryError) =>
			setError(
				generateErrorInformations({
					error: queryError,
					resource: 'quickentry_settingCustomerFileBillExchangeJournal',
				}).message,
			),
		skip: !customerFileId,
		variables: {
			id: customerFileId,
		},
	});

	const [createCustomerFileBillExchangeJournal] = useMutation<CreateCustomerFileBillExchangeSettingJournalType>(
		CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL,
		{
			onCompleted: ({ quickentry_createSettingCustomerFileBillExchangeJournal: { currentJournal } }) => {
				setValue('billExchangeJournal', currentJournal ?? undefined);
				setIsCreateCustomerFileBillExchangeJournalSucceeded(true);
				setTimeout(() => setIsCreateCustomerFileBillExchangeJournalSucceeded(false), 3000);
			},
		},
	);

	useQuery<FetchCustomerFileCheckSettingJournalType>(FETCH_CUSTOMER_FILE_CHECK_SETTING_JOURNAL, {
		onCompleted: ({ quickentry_settingCustomerFileCheckJournal: { currentJournal } }) =>
			setValue('checkJournal', currentJournal ?? undefined),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingCustomerFileCheckJournal' })
					.message,
			),
		skip: !customerFileId,
		variables: {
			id: customerFileId,
		},
	});

	const [createCustomerFileCheckJournal] = useMutation<CreateCustomerFileCheckSettingJournalType>(
		CREATE_CUSTOMER_FILE_CHECK_SETTING_JOURNAL,
		{
			onCompleted: ({ quickentry_createSettingCustomerFileCheckJournal: { currentJournal } }) =>
				setValue('checkJournal', currentJournal),
		},
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { billExchangeJournal, checkJournal } = values;

			const { billExchangeJournal: isBillExchangeJournalDirty, checkJournal: isCheckJournalDirty } = dirtyFields;

			const submitActions = [];

			if (isBillExchangeJournalDirty) {
				const { id: billExchangeJournalId } = billExchangeJournal ?? {};

				submitActions.push(
					createCustomerFileBillExchangeJournal({
						variables: {
							createSettingCustomerFileBillExchangeJournalInput: {
								customerFileId,
								journalId: billExchangeJournalId ?? undefined,
							},
						},
					}),
				);
			}

			if (isCheckJournalDirty) {
				const { id: checkJournalId } = checkJournal ?? {};

				submitActions.push(
					createCustomerFileCheckJournal({
						variables: {
							createSettingCustomerFileCheckJournalInput: {
								customerFileId,
								journalId: checkJournalId ?? undefined,
							},
						},
					}),
				);
			}

			return Promise.all(submitActions);
		},
		[createCustomerFileBillExchangeJournal, createCustomerFileCheckJournal, dirtyFields, customerFileId],
	);

	return (
		<SettingsDialogPage title="Journaux">
			<SettingsGroup>
				<Controller
					control={control}
					name="billExchangeJournal"
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (changedValue?: JournalType) => onChange(changedValue ?? null);

						return (
							<SettingsItemAutocomplete<JournalType>
								{...field}
								dataSource={journalsDataSource}
								getOptionLabel={({ code, name: journalName }) => `${code} - ${journalName}`}
								label="Journal LCR"
								onChange={handleChange}
								value={value ?? undefined}
								disableClearable
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name="checkJournal"
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (changedValue?: JournalType) => onChange(changedValue ?? null);

						return (
							<SettingsItemAutocomplete<JournalType>
								{...field}
								dataSource={journalsDataSource}
								getOptionLabel={({ code, name: journalName }) => `${code} - ${journalName}`}
								label="Journal chèques"
								onChange={handleChange}
								value={value ?? undefined}
								disableClearable
							/>
						);
					}}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateCustomerFileBillExchangeJournalSucceeded ? 'LCR validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default JournalCodes;
