import { gql } from '@apollo/client';

import { DocumentUserType } from '../types/documentUser';

// QUERIES

export type FetchDocumentUserType = {
	document_documentUploadUsers: {
		items: DocumentUserType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENT_USERS = gql`
	query Document_documentUploadUsers($search: String, $page: Page!) {
		document_documentUploadUsers(search: $search, page: $page) {
			count
			items {
				email
				lastName
				firstName
			}
			limit
			offset
		}
	}
`;
