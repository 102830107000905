import { gql } from '@apollo/client';

import { AccountType } from '../types/account';

export const ACCOUNT_FIELDS = `
	id
	code
	name
`;

// QUERIES

export type FetchAccountsByTypeAndCustomerFileType = {
	accountsByTypeAndCustomerFile: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_ACCOUNTS_BY_TYPE_AND_CUSTOMER_FILE = gql`
	query AccountsByTypeAndCustomerFile($type: AccountType!, $customerFileId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		accountsByTypeAndCustomerFile(type: $type, customerFileId: $customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchSubaccountsByAccountType = {
	subaccountsByAccount: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUBACCOUNTS_BY_ACCOUNT = gql`
	query SubaccountsByAccount($accountId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		subaccountsByAccount(accountId: $accountId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchAccountsByCheckAccountType = {
	quickentry_accountsByCheckAccountType: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_ACCOUNTS_BY_CHECK_ACCOUNT_TYPE = gql`
	query Quickentry_accountsByCheckAccountType($checkAccountTypeId: ID!, $orderBy: OrderBy, $page: Page, $search: String) {
		quickentry_accountsByCheckAccountType(checkAccountTypeId: $checkAccountTypeId, orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchSubaccountsType = {
	subaccountsByAccount: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUBACCOUNTS = gql`
	query SubaccountsByAccount($accountId: ID!, $orderBy: OrderBy, $page: Page, $search: String) {
		subaccountsByAccount(accountId: $accountId, orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCheckAccountsByCustomerFileType = {
	quickentry_checkAccountsByCustomerFile: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CHECK_ACCOUNTS_BY_CUSTOMER_FILE = gql`
	query Quickentry_checkAccountsByCustomerFile($customerFileId: ID!, $orderBy: OrderBy, $page: Page, $search: String) {
		quickentry_checkAccountsByCustomerFile(customerFileId: $customerFileId, orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCounterpartAccountsByCustomerFileType = {
	quickentry_counterpartAccounts: {
		count: number;
		items: AccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_COUNTERPART_ACCOUNTS_BY_CUSTOMER_FILE = gql`
	query quickentry_counterpartAccounts($customerFileId:ID!, $search: String, $page: Page, $orderBy: OrderBy) {
		quickentry_counterpartAccounts( customerFileId:$customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;
