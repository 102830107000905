import { useLazyQuery } from '@apollo/client';
import { FilterMenuOutputType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import { FetchSynchronizedBillExchangesType, FETCH_SYNCHRONIZED_BILL_EXCHANGES } from '../../../api/billExchange';
import { BankType } from '../../../types/bank';
import { BillExchangeFilterType, BillExchangeType } from '../../../types/billExchange';

const useSynchronizedBillExchangesDataSource = ({ filteredBankId }: { filteredBankId?: BankType['id'] }) => {
	const [fetchSynchronizedBillExchanges, { networkStatus }] = useLazyQuery<FetchSynchronizedBillExchangesType>(
		FETCH_SYNCHRONIZED_BILL_EXCHANGES,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<BillExchangeType>,
			filters?: FilterMenuOutputType<BillExchangeFilterType>,
		): Promise<{
			count: number;
			items: BillExchangeType[];
		}> => {
			const { field, order } = orderBy ?? {};

			const { bank: filteredBank, ...restFilters } = filters ?? {};

			const { data, error } = await fetchSynchronizedBillExchanges({
				variables: {
					filter: {
						...restFilters,
						...((filteredBank || filteredBankId) && {
							bankId: {
								eq: filteredBankId ?? filteredBank?.eq?.id,
							},
						}),
					},
					...(orderBy && {
						orderBy: {
							field,
							order,
						},
					}),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_synchronizedBillExchanges: { count = 0, items = [] },
			} = data ?? {
				quickentry_synchronizedBillExchanges: {},
			};

			return {
				count,
				items: items.map(({ documentDate, ...rest }) => ({
					...rest,
					documentDate: new Date(documentDate),
				})),
			};
		},
		[fetchSynchronizedBillExchanges, filteredBankId],
	);

	return { dataSource, networkStatus };
};

export default useSynchronizedBillExchangesDataSource;
