import { useQuery } from '@apollo/client';
import { Table, TableColumnType } from '@elipssolution/harfang';
import { Checkbox, styled } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { DomainConfigurationEnum } from '../../../../types/domain';
import { FETCH_SETTING_MODULES, FetchSettingModulesType } from '../../../api/settingModule';

const Wrapper = styled('section')({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

type TableConfigurationType = {
	id: keyof typeof DomainConfigurationEnum | 'ALL';
	label: DomainConfigurationEnum | 'TOUS';
};

type DomainDuplicationTableProps = {
	onValueChange: (configurationsToDuplicate: (keyof typeof DomainConfigurationEnum)[]) => void;
	value?: (keyof typeof DomainConfigurationEnum)[];
};

const DomainDuplicationTable = ({ onValueChange, value = [] }: DomainDuplicationTableProps) => {
	const countEnabledModuleConfigurations = value.length;

	const { data: settingModulesData } = useQuery<FetchSettingModulesType>(FETCH_SETTING_MODULES);

	const domainConfigurations = useMemo(() => {
		const { settingModules = [] } = settingModulesData ?? {};

		return Object.entries(DomainConfigurationEnum).flatMap(([key, objValue]) => {
			const domainConfig = {
				id: key as keyof typeof DomainConfigurationEnum,
				label: objValue,
			};

			switch (objValue) {
				case DomainConfigurationEnum.WIDGETS:
					return settingModules.some(({ code }) => code === 'general') ? [domainConfig] : [];

				case DomainConfigurationEnum.TAGS:
					return settingModules.some(({ code }) => code === 'document') ? [domainConfig] : [];

				case DomainConfigurationEnum.BANKS:
				case DomainConfigurationEnum.CHECK_ACCOUNTS:
				case DomainConfigurationEnum.JOURNALS:
				case DomainConfigurationEnum.PAYMENT_MODES:
				case DomainConfigurationEnum.TEMPLATES:
					return settingModules.some(({ code }) => code === 'quickentry') ? [domainConfig] : [];

				default:
					return [];
			}
		});
	}, [settingModulesData]);

	const handleChange = useCallback(
		({ currentTarget: { id: currentTargetId } }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			const configurationId = currentTargetId as TableConfigurationType['id'];

			if (configurationId === 'ALL') {
				onValueChange(
					countEnabledModuleConfigurations === domainConfigurations.length
						? []
						: domainConfigurations.map(({ id }) => id),
				);
			} else {
				onValueChange(checked ? value.concat(configurationId) : value.filter((id) => id !== configurationId));
			}
		},
		[onValueChange, countEnabledModuleConfigurations, domainConfigurations, value],
	);

	const columns: TableColumnType<TableConfigurationType>[] = useMemo(
		() => [
			{
				field: 'label',
				key: 'label',
				title: 'Configuration',
				render: ({ label }) => label,
				width: 250,
			},
			{
				align: 'center',
				key: 'duplicate',
				title: 'Dupliquer',
				render: ({ id }) => (
					<Checkbox
						id={id}
						checked={
							id === 'ALL' ? countEnabledModuleConfigurations === domainConfigurations.length : value.includes(id)
						}
						indeterminate={
							id === 'ALL'
								? countEnabledModuleConfigurations > 0 &&
								  countEnabledModuleConfigurations !== domainConfigurations.length
								: false
						}
						onChange={handleChange}
					/>
				),
				width: 100,
			},
		],
		[countEnabledModuleConfigurations, domainConfigurations.length, value, handleChange],
	);

	const dataSource = useCallback(
		() =>
			Promise.resolve({
				items: domainConfigurations,
				count: domainConfigurations.length,
			}),
		[domainConfigurations],
	);

	return (
		<Wrapper>
			<Table<TableConfigurationType>
				columns={columns}
				dataSource={dataSource}
				stickyRowItem={{
					id: 'ALL',
					label: 'TOUS',
				}}
				style={{ height: '100%', minHeight: 200 }}
				title="Configurations à dupliquer"
			/>
		</Wrapper>
	);
};

export default DomainDuplicationTable;
