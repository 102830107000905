import { gql } from '@apollo/client';

// MUTATIONS

export type SendSuggestionsMailType = {
	sendSuggestionsMail: {
		suggestions: string;
	};
};

export const SEND_SUGGESTIONS_MAIL = gql`
	mutation SendSuggestionsMail($sendSuggestionsMailInput: SendSuggestionsMailInput!) {
		sendSuggestionsMail(sendSuggestionsMailInput: $sendSuggestionsMailInput) {
			suggestions
		}
	}
`;
