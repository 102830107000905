import { Icon } from '@elipssolution/harfang';

import { mdiFileDocument } from '@mdi/js';

import { ModuleCodeEnum } from '../../types/widget';

import documentPages from './pages/pages';
import DocumentSettings from './settings/DocumentSettings';
import widgets from './widgets/widgets';

const documentModule = {
	code: ModuleCodeEnum.DOCUMENT,
	icon: <Icon path={mdiFileDocument} />,
	name: 'Document',
	pages: documentPages,
	settings: <DocumentSettings />,
	widgets,
};

export default documentModule;
