import { CurrencyFilterDefinitionType } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import ThirdPartiesTable from './ThirdPartiesTable';
import ThirdPartiesTableToggles from './ThirdPartiesTableToggles';
import { useSession } from '../../../../src/components/SessionProvider';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { convertFiscalYearDates } from '../../../../utils/convertDates';
import { SwitchOperatorType } from '../../types/table';
import { ThirdPartyTypeEnum } from '../../types/thirdParty';

const ThirdParties = () => {
	const { events, push } = useRouter();
	const { permissions } = useSession();

	const {
		hasTransaction: hasQueryTransaction,
		balanceFilter: queryBalanceFilter,
		selectedFiscalYear: querySelectedFiscalYear,
		selectedDateRange: querySelectedDateRange,
		selectedType: querySelectedType,
	} = useMemo(() => {
		const storedFilters = globalThis.sessionStorage.getItem('accounting_thirdPartiesFilters');

		if (storedFilters) {
			const { balanceFilter, hasTransaction, selectedDateRange, selectedFiscalYear, selectedType } = JSON.parse(
				storedFilters,
			) as {
				balanceFilter?: CurrencyFilterDefinitionType['value'];
				hasTransaction?: boolean;
				selectedDateRange?: [Date, Date];
				selectedFiscalYear?: FiscalYearType;
				selectedType?: ThirdPartyTypeEnum;
			};

			return {
				selectedDateRange: selectedDateRange?.map((item) => new Date(item)) as [Date, Date],
				selectedFiscalYear: selectedFiscalYear ? convertFiscalYearDates(selectedFiscalYear) : undefined,
				balanceFilter,
				hasTransaction,
				selectedType,
			};
		}

		return {};
	}, []);

	const [selectedType, setSelectedType] = useState(querySelectedType ?? ThirdPartyTypeEnum.SUPPLIER);
	const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYearType | undefined>(querySelectedFiscalYear);
	const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date] | undefined>(querySelectedDateRange);
	const [balanceFilter, setBalanceFilter] = useState<CurrencyFilterDefinitionType['value']>(queryBalanceFilter);
	const [hasTransaction, setHasTransaction] = useState(hasQueryTransaction ?? true);

	const handleBalanceFilterOperatorChange = (operator: SwitchOperatorType) =>
		setBalanceFilter((prevBalanceFilter) => (prevBalanceFilter?.[operator] === '0.00' ? null : { [operator]: '0.00' }));

	useEffect(() => {
		const hasUserPermissions = permissions.find(({ code }) => code === 'accounting-read')?.value;

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	useEffect(() => {
		globalThis.sessionStorage.setItem(
			'accounting_thirdPartiesFilters',
			JSON.stringify({
				balanceFilter,
				hasTransaction,
				selectedDateRange,
				selectedFiscalYear,
				selectedType,
			}),
		);
	}, [hasTransaction, balanceFilter, selectedFiscalYear, selectedType, selectedDateRange]);

	useEffect(() => {
		events.on('routeChangeStart', (newRoute: string) => {
			!newRoute.startsWith('/accounting/thirdParties') &&
				globalThis.sessionStorage.removeItem('accounting_thirdPartiesFilters');
		});
	}, [events]);

	return (
		<Stack height="100%" width="100%" gap={4} flexDirection="row">
			<ThirdPartiesTableToggles
				selectedFiscalYear={selectedFiscalYear}
				onFiscalYearSelection={setSelectedFiscalYear}
				selectedDateRange={selectedDateRange}
				onDateRangeSelection={setSelectedDateRange}
				selectedType={selectedType}
				onTypeSelection={setSelectedType}
				balanceFilter={balanceFilter}
				onBalanceFilterOperatorChange={handleBalanceFilterOperatorChange}
				hasTransaction={hasTransaction}
				onHasTransactionChange={setHasTransaction}
			/>

			<ThirdPartiesTable
				hasTransaction={hasTransaction}
				selectedFiscalYear={selectedFiscalYear}
				selectedDateRange={selectedDateRange}
				selectedType={selectedType}
				balanceFilter={balanceFilter}
				onBalanceFilterChange={setBalanceFilter}
			/>
		</Stack>
	);
};

export default ThirdParties;
