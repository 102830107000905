import { FilterMenuOutputType, Table, TableColumnType, TableInstance, TableOrderByType } from '@elipssolution/harfang';
import { useMemo } from 'react';

import useBankDataSource from '../../hooks/useBankDataSource';
import { BankType } from '../../types/bank';
import { BillExchangeFilterType, BillExchangeType } from '../../types/billExchange';

type BillExchangeTableProps = {
	columns: TableColumnType<BillExchangeType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<BillExchangeType>,
		filters?: FilterMenuOutputType<BillExchangeFilterType>,
	) => Promise<{ items: BillExchangeType[]; count: number }>;
	filteredBank?: BankType;
	onBankFilterChange?: (filteredBank?: BankType) => void;
	onBillExchangeSelection?: (check: BillExchangeType) => void;
	tableInstance: TableInstance;
	title: string;
};

const BillExchangeTable = ({
	filteredBank,
	columns,
	dataSource,
	onBankFilterChange,
	onBillExchangeSelection,
	tableInstance,
	title,
}: BillExchangeTableProps) => {
	const { dataSource: banksDataSource } = useBankDataSource();

	const filters: BillExchangeFilterType = useMemo(
		() => ({
			bank: {
				label: 'Banque',
				type: 'autocomplete',
				dataSource: banksDataSource,
				getOptionLabel: ({ name }) => name,
				...(filteredBank && {
					value: { eq: filteredBank },
				}),
			},
			documentDate: {
				label: 'Saisie entre',
				type: 'period',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
		}),
		[banksDataSource, filteredBank],
	);

	const handleFilterSubmit = (submittedFilters?: FilterMenuOutputType<BillExchangeFilterType>) => {
		const { bank } = submittedFilters ?? {};
		onBankFilterChange?.(bank?.eq);
	};

	return (
		<Table<BillExchangeType, BillExchangeFilterType>
			columns={columns}
			dataSource={dataSource}
			filters={filters}
			onFilterSubmit={handleFilterSubmit}
			onRowClick={onBillExchangeSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onBankFilterChange}
		/>
	);
};

export default BillExchangeTable;
