import { useMutation, useQuery } from '@apollo/client';
import { SettingsGroup, SettingsItemDate, SettingsItemNavigation } from '@elipssolution/harfang';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Banks from './banks/Banks';
import BillExchangeCounterpartAccount from './billExchangeCounterpartAccount/BillExchangeCounterpartAccount';
import CheckAccountTypes from './checkAccountTypes/CheckAccountTypes';
import JournalCodes from './journalCodes/JournalCodes';
import PaymentModes from './paymentModes/PaymentModes';
import Templates from './templates/Templates';
import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';
import {
	FetchCustomerFileType,
	FETCH_CUSTOMER_FILE,
	UpdateCustomerFileType,
	UPDATE_CUSTOMER_FILE,
} from '../../../../../src/api/customerFile';
import { CustomerFileType } from '../../../../../types/customerFile';

type FormFieldsType = {
	minimumEntryDate?: Date;
};

type CustomerFileSectionProps = {
	customerFileId: CustomerFileType['id'];
};

const CustomerFileSection = ({ customerFileId }: CustomerFileSectionProps) => {
	const { push } = useSettingsDialog();

	const {
		control,
		formState: { isValid },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = useForm<FormFieldsType>();

	const formMinimumEntryDate = watch('minimumEntryDate');

	const { data: customerFileFetchData } = useQuery<FetchCustomerFileType>(FETCH_CUSTOMER_FILE, {
		onCompleted: ({ customerFile: { minimumEntryDate } }) =>
			!!minimumEntryDate && setValue('minimumEntryDate', new Date(minimumEntryDate)),
		variables: {
			id: customerFileId,
		},
	});

	const { customerFile } = customerFileFetchData ?? {};
	const { connector, name: customerFileName } = customerFile ?? {};

	const [updateCustomerFile] = useMutation<UpdateCustomerFileType>(UPDATE_CUSTOMER_FILE);

	const navigateToTemplatesSection = () => push(<Templates connector={connector} customerFileId={customerFileId} />);
	const navigateToAccountTypesSection = () => push(<CheckAccountTypes customerFileId={customerFileId} />);
	const navigateToBillExchangeCounterparAccountSection = () =>
		push(<BillExchangeCounterpartAccount customerFileId={customerFileId} />);
	const navigateToBanksSection = () => push(<Banks customerFileId={customerFileId} />);
	const navigateToJournalCodesSection = () => push(<JournalCodes customerFileId={customerFileId} />);
	const navigateToPaymentModesSection = () => push(<PaymentModes customerFileId={customerFileId} />);

	const onSubmit = useCallback(
		({ minimumEntryDate }: FormFieldsType) => {
			updateCustomerFile({
				variables: {
					updateCustomerFileInput: {
						id: customerFileId,
						minimumEntryDate: minimumEntryDate ?? null,
					},
				},
			}).catch((error: Error) => setError('minimumEntryDate', error));
		},
		[setError, updateCustomerFile, customerFileId],
	);

	useEffect(() => {
		if (isValid) {
			handleSubmit(onSubmit)().catch((error) => {
				throw error;
			});
		}
	}, [handleSubmit, onSubmit, isValid, formMinimumEntryDate]);

	return (
		<SettingsDialogPage title={customerFileName}>
			<Controller
				control={control}
				name="minimumEntryDate"
				render={({ field, fieldState: { error: fieldError } }) => (
					<SettingsItemDate
						{...field}
						label="Date minimale de saisie"
						invalid={!!fieldError}
						helperText={fieldError?.message}
					/>
				)}
			/>
			<SettingsGroup label="Paramétrage Modèles de pièce ">
				<SettingsItemNavigation label="Modèles de pièces" onClick={navigateToTemplatesSection} />
			</SettingsGroup>

			<SettingsGroup label="Paramétrage Saisie Chèques et LCR ">
				<SettingsItemNavigation label="Journaux" onClick={navigateToJournalCodesSection} />
				<SettingsItemNavigation label="Modes de règlement" onClick={navigateToPaymentModesSection} />

				<SettingsItemNavigation label="Type de dépense chèque" onClick={navigateToAccountTypesSection} />
				<SettingsItemNavigation
					label="Compte de contrepartie LCR"
					onClick={navigateToBillExchangeCounterparAccountSection}
				/>
				<SettingsItemNavigation label="Banques" onClick={navigateToBanksSection} />
			</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default CustomerFileSection;
