import { IconButton, NumericField } from '@elipssolution/harfang';
import { mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Divider, InputAdornment, Stack, Typography, styled } from '@mui/material';
import dynamic from 'next/dynamic';
import numeral from 'numeral';
import { useCallback, useState } from 'react';

import { FileType } from '../types/document';

const PdfViewer = dynamic(() => import('../../../components/PdfViewer'), {
	ssr: false,
});

const Toolbar = styled('div')(({ theme: { palette, spacing } }) => ({
	height: '48px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
	padding: spacing(1),
	backgroundColor: palette.background.paper,
}));

const DocumentViewerContainer = styled('div')(({ theme: { palette } }) => ({
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'auto',
	borderTop: `1px solid ${palette.divider}`,
	borderRight: `1px solid ${palette.divider}`,

	'& > div': {
		height: '100%',
		overflow: 'auto',
		width: '100%',

		'& > div': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
}));

type DocumentViewerProps = {
	fileType?: string;
	fileName?: string;
	document?: Blob;
};

let inputTimer: ReturnType<typeof setTimeout>;

const DocumentWrapper = ({ fileType = '', fileName = '', document }: DocumentViewerProps) => {
	const [numPages, setNumPages] = useState<number>();
	const [scale, setScale] = useState(1);

	const decreaseScale = useCallback(() => setScale((prev) => numeral(prev).subtract(0.2).value() || 1), []);

	const increaseScale = useCallback(() => setScale((prev) => numeral(prev).add(0.2).value() || 1), []);

	const handleZoomInput = useCallback((value) => {
		clearTimeout(inputTimer);

		inputTimer = setTimeout(() => {
			if (value <= 25) {
				setScale(0.25);
			} else if (value >= 25 && value <= 500) {
				setScale(numeral(100).divide(100).value() || 1);
			} else {
				setScale(5);
			}
		}, 300);
	}, []);

	const onDocumentLoadSuccess = useCallback(
		({ numPages: nextNumPages }: { numPages: number }) => setNumPages(nextNumPages),
		[],
	);

	return (
		<Stack height="100%" width="100%">
			<Toolbar>
				{fileType !== FileType.PDF ? (
					<Typography>{fileName}</Typography>
				) : (
					<>
						<IconButton disabled={scale <= 0.25} onClick={decreaseScale}>
							<Icon path={mdiMagnifyMinusOutline} size="24px" />
						</IconButton>
						<NumericField
							onChange={handleZoomInput}
							sx={{ width: 120 }}
							InputProps={{
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							value={numeral(scale).multiply(100).value() || 1}
						/>
						<IconButton disabled={scale >= 5} onClick={increaseScale}>
							<Icon path={mdiMagnifyPlusOutline} size="24px" />
						</IconButton>

						<Divider orientation="vertical" />

						<Typography>{`${numPages || ''} page${numPages !== 1 ? 's' : ''}`}</Typography>
					</>
				)}
			</Toolbar>

			<DocumentViewerContainer>
				{fileType !== FileType.PDF ? (
					<Typography>Seuls les documents au format PDF peuvent être prévisualisés.</Typography>
				) : (
					<PdfViewer
						document={document}
						scale={scale}
						numPages={numPages}
						onDocumentLoadSuccess={onDocumentLoadSuccess}
					/>
				)}
			</DocumentViewerContainer>
		</Stack>
	);
};

export default DocumentWrapper;
