import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FetchBanksType, FETCH_BANKS } from '../api/bank';
import { BankType } from '../types/bank';

const useBankDataSource = () => {
	const [fetchBanks] = useLazyQuery<FetchBanksType>(FETCH_BANKS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: BankType[];
		}> => {
			const { data, error } = await fetchBanks({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_banks: { count = 0, items = [] },
			} = data ?? {
				quickentry_banks: {},
			};

			return { count, items };
		},
		[fetchBanks],
	);

	return { dataSource };
};

export default useBankDataSource;
