import { gql } from '@apollo/client';

import { RevenueType } from '../types/revenue';

// QUERIES

export type FetchRevenueType = {
	revenue: {
		balance: string;
		data: RevenueType[];
		previousBalance: string;
	};
};

export const FETCH_REVENUE = gql`
	query Revenue($revenueInput: RevenueInput!) {
		revenue(revenueInput: $revenueInput) {
			balance
			data {
				name
				previousValue
				value
			}
			previousBalance
		}
	}
`;
