import { useLazyQuery } from '@apollo/client';
import { Autocomplete, DatePicker, TableOrderByType, TextField } from '@elipssolution/harfang';
import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { Control, Controller, FieldErrors, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';

import {
	FETCH_ANALYTICAL_DIMENSIONS_BY_CUSTOMER_FILE,
	FetchAnalyticalDimensionsByCustomerFileType,
} from '../../../../../api/analytical';
import { FETCH_JOURNALS_BY_CUSTOMER_FILE, FetchJournalsByCustomerFileType } from '../../../../../api/journal';
import {
	FETCH_PAYMENT_MODES_BY_CUSTOMER_FILE,
	FetchPaymentModesByCustomerFileType,
} from '../../../../../api/paymentMode';
import { AnalyticalType } from '../../../../../types/analytical';
import { JournalType } from '../../../../../types/journal';
import { PaymentModeType } from '../../../../../types/paymentMode';
import { SettingCustomerFileTemplateFormType, StateEnum } from '../../../../../types/settingTemplate';

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(2),

	'& > label': {
		margin: 0,
	},

	'& > div': {
		flex: 1,
	},
}));

type TemplateCustomerFileInfosFormProps = {
	control: Control<SettingCustomerFileTemplateFormType>;
	customerFileId?: string;
	isDomainRelated?: boolean;
	setValue: UseFormSetValue<SettingCustomerFileTemplateFormType>;
	clearErrors?: UseFormClearErrors<SettingCustomerFileTemplateFormType>;
	formErrors?: FieldErrors<SettingCustomerFileTemplateFormType>;
};

const TemplateCustomerFileInfosForm = ({
	control,
	customerFileId,
	isDomainRelated = false,
	setValue,
	formErrors,
	clearErrors,
}: TemplateCustomerFileInfosFormProps) => {
	const [fetchJournalsByCustomerFile] = useLazyQuery<FetchJournalsByCustomerFileType>(FETCH_JOURNALS_BY_CUSTOMER_FILE, {
		onCompleted: ({ journalsByCustomerFile: { items } }) => items.length === 1 && setValue('journal', items[0]),
	});

	const journalsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<JournalType>,
		): Promise<{
			count: number;
			items: JournalType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: tagsError } = await fetchJournalsByCustomerFile({
				variables: {
					customerFileId,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (tagsError) {
				throw tagsError;
			}

			const {
				journalsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				journalsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[customerFileId, fetchJournalsByCustomerFile],
	);

	const [fetchPaymentModesByCustomerFile] = useLazyQuery<FetchPaymentModesByCustomerFileType>(
		FETCH_PAYMENT_MODES_BY_CUSTOMER_FILE,
		{
			onCompleted: ({ paymentModesByCustomerFile: { items } }) =>
				items.length === 1 && setValue('paymentMode', items[0]),
		},
	);

	const paymentModesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<PaymentModeType>,
		): Promise<{
			count: number;
			items: PaymentModeType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: tagsError } = await fetchPaymentModesByCustomerFile({
				variables: {
					customerFileId,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (tagsError) {
				throw tagsError;
			}

			const {
				paymentModesByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				paymentModesByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[customerFileId, fetchPaymentModesByCustomerFile],
	);

	const [fetchAnalyticalDimensions] = useLazyQuery<FetchAnalyticalDimensionsByCustomerFileType>(
		FETCH_ANALYTICAL_DIMENSIONS_BY_CUSTOMER_FILE,
	);

	const analyticalDimensionsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<AnalyticalType>,
		): Promise<{
			count: number;
			items: AnalyticalType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: tagsError } = await fetchAnalyticalDimensions({
				variables: {
					customerFileId,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (tagsError) {
				throw tagsError;
			}

			const {
				analyticalDimensionsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				analyticalDimensionsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[customerFileId, fetchAnalyticalDimensions],
	);

	return (
		<>
			<FormRow>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <TextField {...field} disabled={isDomainRelated} label="Nom du modèle" required />}
					rules={{ required: 'Champ requis', pattern: /(.|\s)*\S(.|\s)*/ }}
				/>

				<Controller
					name="defaultEntryName"
					control={control}
					render={({ field }) => <TextField {...field} label="Libellé de l'écriture" required />}
					rules={{ required: 'Champ requis', pattern: /(.|\s)*\S(.|\s)*/ }}
				/>

				<Controller
					name="validUntil"
					control={control}
					render={({ field }) => <DatePicker {...field} label="Valide jusqu'au" placeholder="dd/mm/yyyy" />}
				/>
			</FormRow>

			<FormRow>
				<Controller
					name="journal"
					control={control}
					rules={{ required: 'Champ requis' }}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handleJournalChange = (journal?: JournalType) => {
							onChange(journal);
							clearErrors?.('journal');
						};
						return (
							<Autocomplete<JournalType>
								{...field}
								dataSource={journalsDataSource}
								onChange={handleJournalChange}
								invalid={!!error}
								helperText={error?.message}
								getOptionLabel={({ code, name }) => `${code} - ${name}`}
								label="Journal"
								optionWidth={300}
								placeholder="Journal"
								disableClearable
								required
							/>
						);
					}}
				/>
				<Controller
					name="paymentMode"
					control={control}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handlePaymentModeChange = (paymentMode?: PaymentModeType) => {
							onChange(paymentMode);
							clearErrors?.('paymentMode');
						};
						return (
							<Autocomplete<PaymentModeType>
								{...field}
								invalid={!!error}
								helperText={error?.message}
								onChange={handlePaymentModeChange}
								dataSource={paymentModesDataSource}
								getOptionLabel={({ code, name }) => `${code} - ${name}`}
								label="Mode de règlement"
								optionWidth={200}
								placeholder="Mode de règlement"
								disableClearable
							/>
						);
					}}
				/>
				<Controller
					name="analyticalSectionCaption"
					control={control}
					render={({ field }) => <TextField {...field} label="En-tête de la section analytique" />}
				/>
				<Controller
					name="analyticalDimension"
					control={control}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handleAnalyticalDimensionChange = (analyticalDimension?: AnalyticalType) => {
							onChange(analyticalDimension);
							clearErrors?.('analyticalDimension');
						};
						return (
							<Autocomplete<AnalyticalType>
								{...field}
								dataSource={analyticalDimensionsDataSource}
								getOptionLabel={({ code, name }) => `${code} - ${name}`}
								label="Axe analytique"
								optionWidth={300}
								invalid={!!error}
								onChange={handleAnalyticalDimensionChange}
								placeholder="Axe analytique"
								helperText={error?.message}
								required={!!formErrors?.analyticalDimension}
							/>
						);
					}}
					rules={{
						required: !!formErrors?.analyticalDimension,
					}}
				/>
			</FormRow>

			<FormRow>
				<Controller
					control={control}
					defaultValue={StateEnum.ENABLED}
					name="state"
					render={({ field: { value, onChange, ...field } }) => {
						const isChecked = value === StateEnum.ENABLED;

						const handleCheckboxCheck = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
							onChange(checked ? StateEnum.ENABLED : StateEnum.DISABLED);

						return (
							<FormControlLabel
								control={<Checkbox {...field} checked={isChecked} onChange={handleCheckboxCheck} />}
								label="Actif"
							/>
						);
					}}
				/>
			</FormRow>
		</>
	);
};

export default TemplateCustomerFileInfosForm;
