import { gql } from '@apollo/client';

// QUERIES

export type FetchSettingModulesType = {
	settingModules: {
		id: string;
		code: string;
	}[];
};

export const FETCH_SETTING_MODULES = gql`
	query SettingModules {
		settingModules {
			id
			code
		}
	}
`;
