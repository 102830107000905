import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchAccountsByCheckAccountType, FETCH_ACCOUNTS_BY_CHECK_ACCOUNT_TYPE } from '../../../api/account';
import { AccountType } from '../../../types/account';
import { CheckAccountTypeType } from '../../../types/checkAccountType';

const useAccountsByCheckAccountDataSource = ({
	checkAccountType,
}: {
	checkAccountType?: CheckAccountTypeType | null;
}) => {
	const { id: checkAccountTypeId } = checkAccountType ?? {};

	const [fetchAccounts] = useLazyQuery<FetchAccountsByCheckAccountType>(FETCH_ACCOUNTS_BY_CHECK_ACCOUNT_TYPE);

	const dataSource = useCallback(
		async (limit: number, offset: number, search?: string): Promise<DataSourceResultFormatType<AccountType>> => {
			if (!checkAccountTypeId) return { count: 0, items: [] };

			const { data, error: queryError } = await fetchAccounts({
				variables: {
					page: {
						limit,
						offset,
					},
					checkAccountTypeId,
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				quickentry_accountsByCheckAccountType: { count = 0, items = [] },
			} = data ?? {
				quickentry_accountsByCheckAccountType: {},
			};

			return { count, items };
		},
		[fetchAccounts, checkAccountTypeId],
	);

	return { dataSource };
};

export default useAccountsByCheckAccountDataSource;
