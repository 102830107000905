import {
	AutocompleteFilterDefinitionType,
	CurrencyFilterDefinitionType,
	PeriodFilterDefinitionType,
} from '@elipssolution/harfang';

import { AccountType, AccountTypeEnum } from './account';
import { AnalyticalSectionType } from './analyticalSection';
import { PaymentModeType } from './paymentMode';
import { DirectionEnum } from './settingTemplate';
import { CustomerFileTemplateType, TemplateType } from './template';

export enum VoucherNatureEnum {
	INVOICE = 'invoice',
	CREDIT_NOTE = 'creditNote',
}

export enum VoucherNatureLabelEnum {
	invoice = 'Facture',
	creditNote = 'Avoir',
}

export type VoucherUserType = {
	email: string;
};

export type VoucherLineType = {
	id: string;
	account: AccountType;
	analyticalSection?: AnalyticalSectionType;
	creditAmount?: string;
	debitAmount?: string;
	name: string;
	subaccount?: AccountType;
	subaccountPrefix?: string;
};

export type VoucherType = {
	id: string;
	accountingDate: Date;
	amount: string;
	creditNote: boolean;
	documentDate: Date;
	documentNumber: string;
	dueDate: Date;
	name: string;
	paymentMode: PaymentModeType;
	template: TemplateType;
	voucherLines: VoucherLineType[];
};

export type VoucherDialogLineType = {
	id?: VoucherLineType['id'];
	account: Partial<AccountType>;
	amount?: string;
	analyticalSection?: Partial<AnalyticalSectionType>;
	description: string;
	direction: DirectionEnum;
	isAnalyticalEnabled: boolean;
	name: string;
	subaccount?: AccountType;
	subaccountPrefix?: string;
	type: AccountTypeEnum;
};

export type VoucherFormType = {
	accountingDate: Date;
	documentDate: Date;
	documentNumber: string;
	dueDate?: Date;
	name: string;
	nature: VoucherNatureEnum;
	paymentMode?: PaymentModeType;
	lines: VoucherDialogLineType[];
};

export type VoucherFilterType = {
	template: AutocompleteFilterDefinitionType<CustomerFileTemplateType>;
	accountingDate: PeriodFilterDefinitionType;
	amount: CurrencyFilterDefinitionType;
	createdBy: AutocompleteFilterDefinitionType<VoucherUserType>;
};
