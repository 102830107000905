import { Tooltip } from '@elipssolution/harfang';
import { ButtonBase, Stack, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

export const logoSize = 86;

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
	flex: '1 1',
	borderRadius: theme.shape.borderRadius,
	overflow: 'hidden',
	padding: theme.spacing(1),
}));

const StyledImage = styled(Stack)(() => ({
	width: logoSize,
	height: logoSize,
	flexShrink: 0,
	flexGrow: 0,
	borderRadius: 16,
	overflow: 'hidden',

	'&.disabled': {
		filter: 'grayscale(1)',
	},
}));

const DisabledWrapper = ({
	readOnly,
	disabled,
	disabledMessage,
	children,
}: {
	readOnly?: boolean;
	disabled?: boolean;
	disabledMessage?: string;
	children: JSX.Element;
}): JSX.Element => {
	if (!readOnly && disabled && disabledMessage) {
		return (
			<Tooltip content={disabledMessage}>
				<Stack>{children}</Stack>
			</Tooltip>
		);
	}

	return <Stack>{children}</Stack>;
};

type ApplicationCardProps = {
	name: string;
	image: ReactNode;
	disabled?: boolean;
	disabledMessage?: string;
	readOnly?: boolean;
	onClick: () => void;
};

const ApplicationCard = ({ name, image, disabled, disabledMessage, readOnly, onClick }: ApplicationCardProps) => (
	<DisabledWrapper readOnly={readOnly} disabled={disabled} disabledMessage={disabledMessage}>
		<StyledButtonBase disabled={disabled || readOnly} onClick={onClick}>
			<Stack gap={1} width="100%" height="100%" alignItems="center">
				<StyledImage className={clsx({ disabled })}>{image}</StyledImage>

				<Typography align="center" width="100%" noWrap>
					{name}
				</Typography>
			</Stack>
		</StyledButtonBase>
	</DisabledWrapper>
);

export default ApplicationCard;
