import { gql } from '@apollo/client';

import { SettingCustomerFileCheckAccountType, SettingDomainCheckAccountType } from '../types/settingCheckAccountType';

// QUERIES

export type FetchSettingCheckAccountTypeByCustomerFileType = {
	quickentry_settingCheckAccountTypesByCustomerFile: {
		count: number;
		items: SettingCustomerFileCheckAccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_CUSTOMER_FILE = gql`
	query Quickentry_settingCheckAccountTypesByCustomerFile(
		$customerFileId: ID!
		$search: String
		$page: Page!
		$orderBy: OrderBy
	) {
		quickentry_settingCheckAccountTypesByCustomerFile(
			customerFileId: $customerFileId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				accountPrefix
				id
				isEnabled
				isInherited
				isOverload
				name
			}
			limit
			offset
		}
	}
`;

export type FetchSettingCheckAccountTypeByDomainType = {
	quickentry_settingCheckAccountTypesByDomain: {
		count: number;
		items: SettingDomainCheckAccountType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_DOMAIN = gql`
	query Quickentry_settingCheckAccountTypesByDomain($domainId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		quickentry_settingCheckAccountTypesByDomain(domainId: $domainId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				accountPrefix
				id
				isEnabled
				name
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateSettingCustomerFileCheckAccountTypeType = {
	quickentry_createSettingCustomerFileCheckAccountType: SettingCustomerFileCheckAccountType;
};

export const CREATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_createSettingCustomerFileCheckAccountType(
		$quickentry_createSettingCustomerFileCheckAccountTypeInput: quickentry_CreateSettingCustomerFileCheckAccountTypeInput!
	) {
		quickentry_createSettingCustomerFileCheckAccountType(
			createSettingCustomerFileCheckAccountTypeInput: $quickentry_createSettingCustomerFileCheckAccountTypeInput
		) {
			id
		}
	}
`;

export type UpdateSettingCustomerFileCheckAccountTypeType = {
	quickentry_updateSettingCustomerFileCheckAccountType: SettingCustomerFileCheckAccountType;
};

export const UPDATE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_updateSettingCustomerFileCheckAccountType(
		$quickentry_updateSettingCustomerFileCheckAccountTypeInput: quickentry_UpdateSettingCustomerFileCheckAccountTypeInput!
	) {
		quickentry_updateSettingCustomerFileCheckAccountType(
			updateSettingCustomerFileCheckAccountTypeInput: $quickentry_updateSettingCustomerFileCheckAccountTypeInput
		) {
			id
		}
	}
`;

export type RemoveSettingCustomerFileCheckAccountTypeType = {
	quickentry_removeSettingCustomerFileCheckAccountType: {
		id: SettingCustomerFileCheckAccountType['id'];
	};
};

export const REMOVE_SETTING_CUSTOMER_FILE_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_removeSettingCustomerFileCheckAccountType(
		$quickentryRemoveSettingCustomerFileCheckAccountTypeId: ID!
	) {
		quickentry_removeSettingCustomerFileCheckAccountType(id: $quickentryRemoveSettingCustomerFileCheckAccountTypeId) {
			id
		}
	}
`;

export type CreateSettingDomainCheckAccountTypeType = {
	quickentry_createSettingDomainCheckAccountType: SettingDomainCheckAccountType;
};

export const CREATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_createSettingDomainCheckAccountType(
		$quickentry_createSettingDomainCheckAccountTypeInput: quickentry_CreateSettingDomainCheckAccountTypeInput!
	) {
		quickentry_createSettingDomainCheckAccountType(
			createSettingDomainCheckAccountTypeInput: $quickentry_createSettingDomainCheckAccountTypeInput
		) {
			id
		}
	}
`;

export type UpdateSettingDomainCheckAccountTypeType = {
	quickentry_updateSettingDomainCheckAccountType: SettingDomainCheckAccountType;
};

export const UPDATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_updateSettingDomainCheckAccountType(
		$quickentry_updateSettingDomainCheckAccountTypeInput: quickentry_UpdateSettingDomainCheckAccountTypeInput!
	) {
		quickentry_updateSettingDomainCheckAccountType(
			updateSettingDomainCheckAccountTypeInput: $quickentry_updateSettingDomainCheckAccountTypeInput
		) {
			id
		}
	}
`;

export type RemoveSettingDomainCheckAccountTypeType = {
	quickentry_removeSettingDomainCheckAccountType: {
		id: SettingDomainCheckAccountType['id'];
	};
};

export const REMOVE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE = gql`
	mutation Quickentry_removeSettingDomainCheckAccountType($quickentryRemoveSettingDomainCheckAccountTypeId: ID!) {
		quickentry_removeSettingDomainCheckAccountType(id: $quickentryRemoveSettingDomainCheckAccountTypeId) {
			id
		}
	}
`;
