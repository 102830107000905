import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { ANALYTICAL_FIELDS } from './analytical';
import { ERROR_FIELDS } from './error';
import { JOURNAL_FIELDS } from './journal';
import { PAYMENT_MODE_FIELDS } from './paymentMode';
import {
	SettingCustomerFileTemplateType,
	SettingCustomerFileTemplateWithErrorFieldsType,
	SettingDomainTemplateType,
} from '../types/settingTemplate';

// QUERIES

export type FetchSettingTemplatesByCustomerFileType = {
	quickentry_settingTemplatesByCustomerFile: {
		count: number;
		items: SettingCustomerFileTemplateType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_TEMPLATES_BY_CUSTOMER_FILE = gql`
	query Quickentry_settingTemplatesByCustomerFile(
		$customerFileId: ID!
		$search: String
		$page: Page!
		$orderBy: OrderBy
	) {
		quickentry_settingTemplatesByCustomerFile(
			customerFileId: $customerFileId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				id
				isInherited
				isOverload
				name
				state
				validUntil
			}
			limit
			offset
		}
	}
`;

export type FetchSettingTemplatesByDomainType = {
	quickentry_settingTemplatesByDomain: {
		count: number;
		items: SettingDomainTemplateType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_TEMPLATES_BY_DOMAIN = gql`
	query Quickentry_settingTemplatesByDomain($domainId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		quickentry_settingTemplatesByDomain(domainId: $domainId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				id
				name
				state
				validUntil
			}
			limit
			offset
		}
	}
`;

export type FetchSettingDomainTemplateType = {
	quickentry_settingDomainTemplate: SettingDomainTemplateType;
};

export const FETCH_SETTING_DOMAIN_TEMPLATE = gql`
	query Quickentry_settingDomainTemplate($quickentrySettingDomainTemplateId: ID!) {
		quickentry_settingDomainTemplate(id: $quickentrySettingDomainTemplateId) {
			analyticalDimensionCode
			analyticalSectionCaption
			defaultEntryName
			id
			journalCode
			name
			paymentModeCode
			state
			templateLines {
				accountCode
				analyticalSectionCode
				defaultTransactionName
				direction
				id
				isAnalyticalEnabled
				name
				rank
				subaccountCode
				subaccountPrefix
				type
			}
		}
	}
`;

export type FetchSettingCustomerFileTemplateType = {
	quickentry_settingCustomerFileTemplate: SettingCustomerFileTemplateWithErrorFieldsType;
};

export const FETCH_SETTING_CUSTOMER_FILE_TEMPLATE = gql`
	query Quickentry_settingCustomerFileTemplate($quickentrySettingCustomerFileTemplateId: ID!) {
		quickentry_settingCustomerFileTemplate(id: $quickentrySettingCustomerFileTemplateId) {
			id
			name
			defaultEntryName
			validUntil
			journal {
				data {
					${JOURNAL_FIELDS}
					}
				${ERROR_FIELDS}	
			}
			paymentMode {
				data {
					${PAYMENT_MODE_FIELDS}
					}
				${ERROR_FIELDS}
			}

			analyticalDimension {
				data {
					${ANALYTICAL_FIELDS}
					}
				${ERROR_FIELDS}		
			}
			analyticalSectionCaption
			isInherited
			isOverload
			state
			templateLines {
				id
				name
				defaultTransactionName
				type
				rank
				account {
					data {
						${ACCOUNT_FIELDS}
						}
					${ERROR_FIELDS}
				}
				subaccountPrefix
				subaccount {
					data {
						${ACCOUNT_FIELDS}
						}
					${ERROR_FIELDS}	
				}
				direction
				analyticalSection {
					data {
					${ANALYTICAL_FIELDS}
						}
					${ERROR_FIELDS}	
				}
				isAnalyticalEnabled
			}
		}
	}
`;

// MUTATIONS

export type CreateSettingDomainTemplateType = {
	quickentry_createSettingDomainTemplate: {
		id: SettingDomainTemplateType['id'];
	};
};

export const CREATE_SETTING_DOMAIN_TEMPLATE = gql`
	mutation Quickentry_createSettingDomainTemplate(
		$createSettingDomainTemplateInput: quickentry_CreateSettingDomainTemplateInput!
	) {
		quickentry_createSettingDomainTemplate(createSettingDomainTemplateInput: $createSettingDomainTemplateInput) {
			id
		}
	}
`;

export type CreateSettingCustomerFileTemplateType = {
	quickentry_createSettingCustomerFileTemplate: {
		id: SettingCustomerFileTemplateType['id'];
	};
};

export const CREATE_SETTING_CUSTOMER_FILE_TEMPLATE = gql`
	mutation Quickentry_createSettingCustomerFileTemplate(
		$createSettingCustomerFileTemplateInput: quickentry_CreateSettingCustomerFileTemplateInput!
	) {
		quickentry_createSettingCustomerFileTemplate(
			createSettingCustomerFileTemplateInput: $createSettingCustomerFileTemplateInput
		) {
			id
		}
	}
`;

export type UpdateSettingDomainTemplateType = {
	quickentry_updateSettingDomainTemplate: {
		id: SettingDomainTemplateType['id'];
	};
};

export const UPDATE_SETTING_DOMAIN_TEMPLATE = gql`
	mutation Quickentry_updateSettingDomainTemplate(
		$updateSettingDomainTemplateInput: quickentry_UpdateSettingDomainTemplateInput!
	) {
		quickentry_updateSettingDomainTemplate(updateSettingDomainTemplateInput: $updateSettingDomainTemplateInput) {
			id
		}
	}
`;

export type UpdateSettingCustomerFileTemplateType = {
	quickentry_updateSettingCustomerFileTemplate: {
		id: SettingCustomerFileTemplateType['id'];
	};
};

export const UPDATE_SETTING_CUSTOMER_FILE_TEMPLATE = gql`
	mutation Quickentry_updateSettingCustomerFileTemplate(
		$updateSettingCustomerFileTemplateInput: quickentry_UpdateSettingCustomerFileTemplateInput!
	) {
		quickentry_updateSettingCustomerFileTemplate(
			updateSettingCustomerFileTemplateInput: $updateSettingCustomerFileTemplateInput
		) {
			id
		}
	}
`;

export type RemoveSettingDomainTemplateType = {
	quickentry_removeSettingDomainTemplate: {
		id: SettingDomainTemplateType['id'];
	};
};

export const REMOVE_SETTING_DOMAIN_TEMPLATE = gql`
	mutation Quickentry_removeSettingDomainTemplate($quickentryRemoveSettingDomainTemplateId: ID!) {
		quickentry_removeSettingDomainTemplate(id: $quickentryRemoveSettingDomainTemplateId) {
			id
		}
	}
`;

export type RemoveSettingCustomerFileTemplateType = {
	quickentry_removeSettingCustomerFileTemplate: {
		id: SettingCustomerFileTemplateType['id'];
	};
};

export const REMOVE_SETTING_CUSTOMER_FILE_TEMPLATE = gql`
	mutation Quickentry_removeSettingCustomerFileTemplate($quickentryRemoveSettingCustomerFileTemplateId: ID!) {
		quickentry_removeSettingCustomerFileTemplate(id: $quickentryRemoveSettingCustomerFileTemplateId) {
			id
		}
	}
`;
