import { Button, Icon, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useState } from 'react';

import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { WidgetWithKeyType } from '../../../types/widget';
import { getWidgetTemplateByType } from '../../widgets/widgets';

const DefaultConfigurationComponent = ({
	name: initialName,
	onSave,
	onBack,
}: {
	name?: string;
	onSave: (name: string) => Promise<void>;
	onBack: () => void;
}) => {
	const [name, setName] = useState(initialName);

	const handleNameChange = (changedName?: string) => {
		setName(changedName);
	};

	const handleSave = () => onSave(name ?? '');

	return (
		<SettingsDialogPage title={name} onBack={onBack}>
			<SettingsItemTextField label="Nom du widget" value={name} onChange={handleNameChange} />
			<Stack direction="row" justifyContent="flex-end">
				<Button startIcon={<Icon path={mdiPencil} size="small" />} variant="contained" onClick={handleSave}>
					Appliquer
				</Button>
			</Stack>
		</SettingsDialogPage>
	);
};

type SettingsWidgetConfigurationProps = {
	widgetWithKey: WidgetWithKeyType;
	onSave: (widgetWithKey: WidgetWithKeyType) => Promise<void>;
	onBack: () => void;
};

const SettingsWidgetConfiguration = ({ widgetWithKey, onSave, onBack }: SettingsWidgetConfigurationProps) => {
	const { key, widget } = widgetWithKey;
	const { name, configuration } = widget;
	const jsonConfiguration = configuration ? (JSON.parse(configuration) as object) : undefined;

	const handleSave = async (savedName: string, savedConfiguration?: object) => {
		const stringConfiguration = savedConfiguration ? JSON.stringify(savedConfiguration) : undefined;
		return onSave({ key, widget: { ...widget, name: savedName, configuration: stringConfiguration } });
	};

	const { configurationComponent: ConfigurationComponent } = getWidgetTemplateByType(widgetWithKey.widget.type);

	return ConfigurationComponent ? (
		<ConfigurationComponent
			name={name}
			widgetWithKey={widgetWithKey}
			configuration={jsonConfiguration}
			onSave={handleSave}
			onBack={onBack}
		/>
	) : (
		<DefaultConfigurationComponent name={name} onSave={handleSave} onBack={onBack} />
	);
};

export default SettingsWidgetConfiguration;
