import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

import { CustomerFileWithGroup } from '../../types/customerFile';
import {
	AddExternalUserRelationType,
	AddExternalUserRelationWithCustomerFilesType,
	AddInternalUserRelationWithCustomerFilesType,
	AddInternalUserType,
} from '../types/user';

type UserCustomerFileRelationType =
	| AddExternalUserRelationType
	| CustomerFileWithGroup
	| AddExternalUserRelationWithCustomerFilesType
	| AddInternalUserType
	| AddInternalUserRelationWithCustomerFilesType;

type UserCustomerFileRelationContextType = {
	userCustomerFileRelations: UserCustomerFileRelationType[];
	setUserCustomerFileRelations: Dispatch<SetStateAction<UserCustomerFileRelationType[]>>;
};

export const UserCustomerFileRelationContext = createContext<UserCustomerFileRelationContextType | undefined>(
	undefined,
);

export const useUserCustomerFileRelationContext = () => {
	const context = useContext(UserCustomerFileRelationContext);

	if (context === undefined) {
		throw new Error('UserCustomerFileRelationContext should be used within a Provider');
	}

	return context;
};

const UserCustomerFileRelationProvider = ({ children }: { children: ReactNode }) => {
	const [userCustomerFileRelations, setUserCustomerFileRelations] = useState<UserCustomerFileRelationType[]>([]);

	const contextValue = useMemo(
		() => ({
			userCustomerFileRelations,
			setUserCustomerFileRelations,
		}),
		[userCustomerFileRelations],
	);

	return (
		<UserCustomerFileRelationContext.Provider value={contextValue}>{children}</UserCustomerFileRelationContext.Provider>
	);
};

export default UserCustomerFileRelationProvider;
