type DocumentCategory = {
	name: string;
};

type FiscalYear = {
	id: string;
	code: string;
	name: string;
};

export enum FileType {
	PDF = 'PDF',
	DOCX = 'DOCX',
	XLSX = 'XLSX',
	OTHER = 'OTHER',
}

export type DocumentType = {
	id: string;
	name: string;
	category1: DocumentCategory;
	category2: DocumentCategory;
	category3: DocumentCategory;
	fiscalYear: FiscalYear;
	fileType: FileType;
	sourceUpdatedAt?: string;
};

export type DocumentAndCategoriesType = {
	id: string;
	name: string;
	size?: number;
	fileType?: FileType;
	sourceUpdatedAt?: string;
	path?: {
		id: string;
		name: string;
	}[];
};
