import { SettingsItemAutocomplete } from '@elipssolution/harfang';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { WidgetExternalApplicationType, WidgetWithKeyType } from '../../../../types/widget';
import { CecurityForm } from '../applications/CecurityApplication';
import { FinthesisForm } from '../applications/FinthesisApplication';
import { GenericForm } from '../applications/GenericApplication';
import { MegForm } from '../applications/MegApplication';
import { MySilaeForm } from '../applications/MySilaeApplication';
import { N2fForm } from '../applications/N2fApplication';
import { SilaeForm } from '../applications/SilaeApplication';
import { YoozForm } from '../applications/YoozApplication';

type ApplicationFormType = {
	type?: string;
	name: string;
	component: (props: {
		widgetWithKey: WidgetWithKeyType;
		application: WidgetExternalApplicationType;
		isNew: boolean;
		onSubmit: (application: WidgetExternalApplicationType) => void;
		onRemove: (application: WidgetExternalApplicationType) => void;
	}) => JSX.Element;
};

const defaultApplicationForm: ApplicationFormType = {
	type: undefined,
	name: 'Personnalisé',
	component: GenericForm,
};

const applicationForms: ApplicationFormType[] = [
	defaultApplicationForm,
	{
		type: 'cecurity',
		name: 'Cecurity',
		component: CecurityForm,
	},
	{
		type: 'silae',
		name: 'Silae',
		component: SilaeForm,
	},
	{
		type: 'mySilae',
		name: 'MySilae',
		component: MySilaeForm,
	},
	{
		type: 'yooz',
		name: 'Yooz',
		component: YoozForm,
	},
	{
		type: 'finthesis',
		name: 'Analyses financières',
		component: FinthesisForm,
	},
	{
		type: 'n2f',
		name: 'N2F',
		component: N2fForm,
	},
	{
		type: 'meg',
		name: 'MEG facturation',
		component: MegForm,
	},
];

type ExternalApplicationFormProps = {
	widgetWithKey: WidgetWithKeyType;
	application?: WidgetExternalApplicationType;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
	onBack: () => void;
};

const ExternalApplicationForm = ({
	widgetWithKey,
	application: initialApplication,
	onSubmit,
	onRemove,
	onBack,
}: ExternalApplicationFormProps) => {
	const [application, setApplication] = useState<WidgetExternalApplicationType>(
		initialApplication ?? {
			key: uuid(),
			name: defaultApplicationForm.name,
			type: defaultApplicationForm.type,
		},
	);

	const selectedApplicationForm =
		applicationForms.find((applicationForm) => applicationForm.type === application.type) ?? defaultApplicationForm;

	const handleChangeType = (type?: { type?: string; name: string }) =>
		setApplication({
			key: uuid(),
			name: type?.name ?? defaultApplicationForm.name,
			type: type?.type ?? defaultApplicationForm.type,
		});

	const dataSource = useCallback(
		async (): Promise<{
			items: { key?: string; name: string }[];
			count: number;
		}> =>
			Promise.resolve({
				items: applicationForms,
				count: applicationForms.length,
			}),
		[],
	);

	return (
		<SettingsDialogPage title={application.name} onBack={onBack}>
			<SettingsItemAutocomplete<{ type?: string; name: string }>
				dataSource={dataSource}
				dataKey="type"
				description="Liste des applications disponibles."
				getOptionLabel={({ name }) => name}
				label="Type d'application"
				onChange={handleChangeType}
				value={selectedApplicationForm}
				disableClearable
			/>

			<selectedApplicationForm.component
				widgetWithKey={widgetWithKey}
				application={application}
				isNew={!initialApplication}
				onSubmit={onSubmit}
				onRemove={onRemove}
			/>
		</SettingsDialogPage>
	);
};

export default ExternalApplicationForm;
