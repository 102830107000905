import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, BaseSettingsItem, Icon, IconButton, SettingsGroup, Tooltip } from '@elipssolution/harfang';
import { mdiSync } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { CustomerFileType, CustomerFileWithDomainType } from '../../../types/customerFile';
import { FiscalYearType } from '../../../types/fiscalYear';
import { PermissionEnum } from '../../../types/permission';
import { convertFiscalYearDates } from '../../../utils/convertDates';
import { FETCH_FISCAL_YEARS_BY_CUSTOMER_FILE, FetchFiscalYearsByCustomerFileType } from '../../api/fiscalYear';
import { SYNCHRONIZE_CUSTOMER_FILE } from '../../api/synchonization';

type AllFiscalYearsOptionType = {
	id?: string;
	name: string;
};

type SettingsSynchronizableItemProps = {
	title: string;
	onSynchronization: () => Promise<unknown>;
	onFiscalYearChange: (fiscalYear?: FiscalYearType | AllFiscalYearsOptionType) => void;
	fiscalYear?: FiscalYearType | AllFiscalYearsOptionType;
	customerFileId: CustomerFileType['id'];
};

const SettingsSynchronizableItem = ({
	title,
	onSynchronization,
	fiscalYear,
	onFiscalYearChange,
	customerFileId,
}: SettingsSynchronizableItemProps) => {
	const [fetchFiscalYearsByCustomerFile] = useLazyQuery<FetchFiscalYearsByCustomerFileType>(
		FETCH_FISCAL_YEARS_BY_CUSTOMER_FILE,
		{
			onCompleted: ({ fiscalYearsByCustomerFile: { items } }) => {
				if (!fiscalYear && items.length > 0) {
					onFiscalYearChange(convertFiscalYearDates(items[0]));
				}
			},
		},
	);

	const fiscalYearsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: (FiscalYearType | AllFiscalYearsOptionType)[];
		}> => {
			const { data, error } = await fetchFiscalYearsByCustomerFile({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				fiscalYearsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				fiscalYearsByCustomerFile: {},
			};

			return {
				count: count + 1,
				items: [
					{ id: undefined, name: 'Toutes les années fiscales' },
					...items.map((fiscalYearItem) => convertFiscalYearDates(fiscalYearItem)),
				],
			};
		},
		[customerFileId, fetchFiscalYearsByCustomerFile],
	);

	return (
		<BaseSettingsItem label={title}>
			<Stack alignItems="flex-end" flexDirection="row" gap={1}>
				<Autocomplete<FiscalYearType | AllFiscalYearsOptionType>
					dataSource={fiscalYearsDataSource}
					getOptionLabel={({ name }) => name}
					onChange={onFiscalYearChange}
					label="Exercice"
					value={fiscalYear}
					disableClearable
					sx={{ width: 200 }}
				/>
				<Tooltip content="Synchroniser" placement="left">
					<IconButton onClick={onSynchronization}>
						<Icon path={mdiSync} />
					</IconButton>
				</Tooltip>
			</Stack>
		</BaseSettingsItem>
	);
};

type CustomerFileSynchronizeProps = {
	customerFile: CustomerFileWithDomainType;
};

const CustomerFileSynchronize = ({
	customerFile: { id: customerFileId, name: customerFileName },
}: CustomerFileSynchronizeProps) => {
	const [fiscalYear, setFiscalYear] = useState<FiscalYearType | AllFiscalYearsOptionType>();
	const [synchronizeCustomerFile] = useMutation(SYNCHRONIZE_CUSTOMER_FILE, {
		variables: { customerFileId, fiscalYearId: fiscalYear?.id },
	});

	const synchonizableModules = useMemo(() => {
		const modulesSynchonization = [
			{
				permissionsCode: [PermissionEnum.ACCOUNTING_SYNCHRONIZE],
				synchronizations: [
					{
						label: 'Synchroniser les référentiels et les données comptables',
						onSync: synchronizeCustomerFile,
					},
				],
			},
		];

		return modulesSynchonization.map(({ permissionsCode, synchronizations }) => (
			<PermissionWall key={uuid()} permissionCodes={permissionsCode}>
				<>
					{synchronizations.map(({ label, onSync }) => (
						<SettingsSynchronizableItem
							key={uuid()}
							title={label}
							onSynchronization={onSync}
							fiscalYear={fiscalYear}
							onFiscalYearChange={setFiscalYear}
							customerFileId={customerFileId}
						/>
					))}
				</>
			</PermissionWall>
		));
	}, [customerFileId, fiscalYear, synchronizeCustomerFile]);

	return (
		<SettingsDialogPage title={customerFileName}>
			<SettingsGroup label="Synchronisations">{synchonizableModules}</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default CustomerFileSynchronize;
