import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { UPDATE_EXTERNAL_USER_PERMISSION, UpdateExternalUserPermissionType } from '../../../api/permission';
import { UserPermissionType } from '../../../types/permission';
import SettingsItemPermission from '../../SettingsItemPermission';

type RelationPermissionProps = {
	disabled: boolean;
	permission: UserPermissionType;
	onPermissionChanged: (value: boolean) => void;
};

const RelationPermission = ({
	disabled,
	permission: { currentValue: initialCurrentValue, inheritedValue, id, name, value: initialValue },
	onPermissionChanged,
}: RelationPermissionProps) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [currentValue, setCurrentValue] = useState(initialCurrentValue);
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [value, setValue] = useState(initialValue);

	const [updatePermission, { loading }] = useMutation<UpdateExternalUserPermissionType>(
		UPDATE_EXTERNAL_USER_PERMISSION,
		{
			onCompleted: (data) => {
				const { updateExternalUserPermission } = data ?? {};
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const { currentValue: updatedCurrentValue, value: updatedValue } = updateExternalUserPermission ?? {};

				onPermissionChanged(true);
				setCurrentValue(updatedCurrentValue);
				setValue(updatedValue);
			},
		},
	);

	const handlePermissionUpdate = useCallback(
		(newValue: boolean | null) => {
			updatePermission({
				variables: {
					updateExternalUserPermissionInput: { id, value: newValue },
				},
			}).catch((e) => {
				throw e;
			});
		},
		[updatePermission, id],
	);

	useEffect(() => setCurrentValue(initialCurrentValue), [initialCurrentValue]);

	useEffect(() => setValue(initialValue), [initialValue]);

	return (
		<SettingsItemPermission
			currentValue={currentValue}
			disabled={disabled}
			inheritedValue={inheritedValue}
			label={name}
			loading={loading}
			onChange={handlePermissionUpdate}
			value={value}
			hasInheritance
		/>
	);
};

export default RelationPermission;
