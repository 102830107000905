import { gql } from '@apollo/client';

import { DocumentAndCategoriesType, DocumentType } from '../types/document';

const CATEGORIES_FIELDS = `
	category1 {
		name
	}
	category2 {
		name
	}
	category3 {
		name
	}
`;

// QUERIES

export type FetchDocumentsType = {
	document_documents: {
		items: DocumentType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENTS = gql`
	query Document_documents($search: String, $filter: document_DocumentFilterInput, $page: Page!, $orderBy: OrderBy) {
		document_documents(search: $search, filter: $filter, page: $page, orderBy: $orderBy) {
			count
			items {
				${CATEGORIES_FIELDS}
				fileType
				fiscalYear {
					name
				}
				id
				name
				sourceUpdatedAt
			}
			limit
			offset
		}
	}
`;

export type FetchDocumentsAndCategoriesByCategoryType = {
	document_documentsAndCategoriesByCategory: {
		items: DocumentAndCategoriesType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENTS_AND_CATEGORIES_BY_CATEGORY = gql`
	query Document_documentsAndCategoriesByCategory($categoryId: ID, $page: Page!, $orderBy: OrderBy, $search: String) {
		document_documentsAndCategoriesByCategory(
			categoryId: $categoryId
			page: $page
			orderBy: $orderBy
			search: $search
		) {
			items {
				id
				name
				fileType
				size
				sourceUpdatedAt
				path {
					id
					name
				}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchDocumentType = {
	document_document: DocumentType;
};

export const FETCH_DOCUMENT = gql`
	query Document_document($documentId: ID!) {
		document_document(id: $documentId) {
			${CATEGORIES_FIELDS}
			fileType
			fiscalYear {
				code
				id
				name
			}
			id
			name
		}
	}
`;
