import { Carousel } from '@elipssolution/harfang';
import { SxProps, Theme } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';

import { logoSize } from './ApplicationCard';
import CecurityApplication from './applications/CecurityApplication';
import FinthesisApplication from './applications/FinthesisApplication';
import GenericApplication from './applications/GenericApplication';
import MegApplication from './applications/MegApplication';
import MySilaeApplication from './applications/MySilaeApplication';
import N2fApplication from './applications/N2fApplication';
import SilaeApplication from './applications/SilaeApplication';
import YoozApplication from './applications/YoozApplication';
import {
	WidgetComponentProps,
	WidgetExternalApplicationType,
	WidgetExternalApplicationsConfigurationType,
} from '../../../types/widget';
import { useSession } from '../../components/SessionProvider';
import { UserTypeEnum } from '../../types/user';

const arrowStyles: SxProps<Theme> = {
	'.alice-carousel__prev-btn, .alice-carousel__next-btn': {
		position: 'absolute',
		width: 'auto',
		top: logoSize / 2 + 8,
	},
	'.alice-carousel__prev-btn': {
		left: 0,
	},
	'.alice-carousel__next-btn': {
		right: 0,
	},
};

type WidgetExternalApplicationsProps = Omit<WidgetExternalApplicationType, 'isExternal' | 'isExternal'>;

const getWidgetComponent = ({ key, name, type, specific }: WidgetExternalApplicationsProps, readOnly: boolean) => {
	const applicationTypeComponentMapper = new Map([
		['cecurity', CecurityApplication],
		['finthesis', FinthesisApplication],
		['meg', MegApplication],
		['mySilae', MySilaeApplication],
		['n2f', N2fApplication],
		['silae', SilaeApplication],
		['yooz', YoozApplication],
	]);

	if (type) {
		const Component = applicationTypeComponentMapper.get(type);
		if (Component) return <Component key={key} name={name} specific={specific} readOnly={readOnly} />;
	}

	return <GenericApplication key={key} name={name} specific={specific} readOnly={readOnly} />;
};

const WidgetExternalApplications = ({
	readOnly,
	configuration,
}: WidgetComponentProps<WidgetExternalApplicationsConfigurationType>) => {
	const {
		user: { type: userType },
	} = useSession();

	const carouselItems = useMemo(
		() =>
			(configuration?.applications ?? [])
				.sort((a, b) => a.name.localeCompare(b.name))
				.reduce(
					(acc, application) =>
						(userType === UserTypeEnum.INTERNAL && !application.isInternal) ||
						(userType === UserTypeEnum.EXTERNAL && !application.isExternal)
							? acc
							: [...acc, getWidgetComponent(application, readOnly)],
					[] as ReactNode[],
				),
		[configuration, readOnly, userType],
	);

	const dataSource = useCallback(
		() =>
			Promise.resolve({
				items: carouselItems,
				count: carouselItems.length,
			}),
		[carouselItems],
	);

	const renderItem = useCallback((node?: ReactNode) => node ?? <div />, []);

	return (
		<Carousel<ReactNode>
			sx={arrowStyles}
			dataSource={dataSource}
			itemWidth={132}
			itemHeight={132}
			renderItem={renderItem}
		/>
	);
};

export default WidgetExternalApplications;
