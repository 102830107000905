import { CircularProgress, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useRef } from 'react';
import GridLayout from 'react-grid-layout';
import ReactResizeDetector from 'react-resize-detector';

import DashboardItem from './DashboardItem';
import { WidgetWithKeyType } from '../../types/widget';
import { DashboardLayoutType } from '../types/dashboard';
import { getWidgetTemplateByType } from '../widgets/widgets';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const COLS = 6;
const ROW_HEIGHT = 196;

const StyledContainer = styled('div')(({ theme: { palette, shape } }) => ({
	flex: 1,
	borderRadius: shape.borderRadius * 2,
	overflow: 'auto',
	border: `1px dashed ${palette.divider}`,

	'&.withPadding': {
		padding: '46px 16px 16px 32px',
	},

	'&.layoutReadOnly': {
		border: 'none',
	},

	'.react-draggable': {
		cursor: 'grab',
	},

	'.react-draggable-dragging': {
		cursor: 'grabbing',
	},
}));

const InfoWrapper = styled('div')(({ theme: { spacing } }) => ({
	height: '100%',
	width: '100%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(2),
}));

const StyledGridLayout = styled(GridLayout)(({ theme }) => ({
	'.react-grid-item.react-grid-placeholder': {
		background: `${theme.palette.tertiary.main} !important`,
		zIndex: 0,
	},

	'.react-resizable-handle': {
		color: theme.palette.divider,
	},
}));

type DashboardProps = {
	widgetsWithKey?: WidgetWithKeyType[];
	loading?: boolean;
	widgetsReadOnly?: boolean;
	onLayoutChange?: (widgets: WidgetWithKeyType[]) => void;
	onEditWidget?: (widget: WidgetWithKeyType) => void;
	onRemoveWidget?: (widget: WidgetWithKeyType) => Promise<void>;
};

const Dashboard = ({
	widgetsWithKey = [],
	loading = false,
	widgetsReadOnly = false,
	onLayoutChange,
	onEditWidget,
	onRemoveWidget,
}: DashboardProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const isLayoutReadOnly = !onLayoutChange;

	const layout = useMemo(
		() =>
			widgetsWithKey.map(({ key, widget }) => {
				const { type, x, y, width, height } = widget;

				const { minW, maxW, minH, maxH } = getWidgetTemplateByType(type);

				return {
					i: key,
					x,
					y,
					w: width,
					h: height,
					minW,
					minH,
					maxW,
					maxH,
				};
			}),
		[widgetsWithKey],
	);

	const handleLayoutChange = useCallback(
		(newLayout: DashboardLayoutType) => {
			let hasChanges = false;

			const newWidgets = widgetsWithKey.map(({ key, widget }) => {
				const item = newLayout.find(({ i }) => i === key);

				if (!item) {
					throw new Error('Widget not found in layout');
				}

				const { x, y, w, h } = item;

				hasChanges = hasChanges || x !== widget.x || y !== widget.y || w !== widget.width || h !== widget.height;

				return {
					key,
					widget: {
						...widget,
						x,
						y,
						width: w,
						height: h,
					},
				};
			});

			if (!hasChanges) {
				return;
			}

			onLayoutChange?.(newWidgets);
		},
		[widgetsWithKey, onLayoutChange],
	);

	return (
		<ReactResizeDetector handleWidth targetRef={ref}>
			{({ width }) => (
				<StyledContainer
					ref={ref}
					className={clsx({ layoutReadOnly: isLayoutReadOnly, withPadding: !widgetsReadOnly })}
				>
					{loading && (
						<InfoWrapper>
							<CircularProgress size={36} color="inherit" />
							<Typography>Chargement du tableau de bord...</Typography>
						</InfoWrapper>
					)}

					{!loading && !widgetsWithKey.length && (
						<InfoWrapper>
							<Typography variant="h6">Aucun widget</Typography>
							<Typography variant="body2">Ajoutez des widgets pour les afficher ici</Typography>
						</InfoWrapper>
					)}

					{!loading && widgetsWithKey.length > 0 && width && (
						<StyledGridLayout
							className="layout"
							layout={layout}
							cols={COLS}
							rowHeight={ROW_HEIGHT}
							width={width}
							isDraggable={!isLayoutReadOnly}
							isResizable={!isLayoutReadOnly}
							onLayoutChange={handleLayoutChange}
							// Disable animation
							useCSSTransforms={false}
						>
							{widgetsWithKey?.map((widgetWithKey) => (
								<div key={widgetWithKey.key}>
									<DashboardItem
										widgetWithKey={widgetWithKey}
										readOnly={widgetsReadOnly}
										onEdit={onEditWidget}
										onRemove={onRemoveWidget}
									/>
								</div>
							))}
						</StyledGridLayout>
					)}
				</StyledContainer>
			)}
		</ReactResizeDetector>
	);
};

export default Dashboard;
