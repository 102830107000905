import { useLazyQuery } from '@apollo/client';
import { Icon, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { mdiCheck, mdiClose } from '@mdi/js';
import { useCallback } from 'react';
import InheritChip from '../../../../../../components/InheritChip';
import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { CustomerFileType } from '../../../../../../types/customerFile';
import {
	FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_CUSTOMER_FILE,
	FetchSettingCheckAccountTypeByCustomerFileType,
} from '../../../../api/settingCheckAccountType';
import { SettingCustomerFileCheckAccountType } from '../../../../types/settingCheckAccountType';

const columns: TableColumnType<SettingCustomerFileCheckAccountType>[] = [
	{
		key: 'level',
		render: ({ isInherited, isOverload }) => (
			<InheritChip parentLabel="Domaine" childLabel="Dossier" inherited={isInherited} override={isOverload} />
		),
		title: 'Niveau',
		width: 144,
		flexGrow: 0,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Description',
		width: 200,
	},
	{
		field: 'accountPrefix',
		key: 'accountPrefix',
		sortable: true,
		title: 'Préfixe de compte',
		width: 150,
	},
	{
		align: 'center',
		field: 'isEnabled',
		flexGrow: 0,
		key: 'isEnabled',
		render: ({ isEnabled }) => <Icon color={isEnabled ? 'success' : 'error'} path={isEnabled ? mdiCheck : mdiClose} />,
		sortable: true,
		title: 'Actif',
		width: 50,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type CheckAccountTypesCustomerFileTableProps = {
	customerFileId?: CustomerFileType['id'];
	onCheckAccountTypeSelection: (accountType?: SettingCustomerFileCheckAccountType) => void;
};

const CheckAccountTypesCustomerFileTable = ({
	customerFileId,
	onCheckAccountTypeSelection,
}: CheckAccountTypesCustomerFileTableProps) => {
	const [fetchSettingCheckAccountTypesByCustomerFile] = useLazyQuery<FetchSettingCheckAccountTypeByCustomerFileType>(
		FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_CUSTOMER_FILE,
	);

	const settingCheckAccountTypesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingCustomerFileCheckAccountType>,
		): Promise<{
			count: number;
			items: SettingCustomerFileCheckAccountType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingCheckAccountTypesByCustomerFile({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					customerFileId,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_settingCheckAccountTypesByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				quickentry_settingCheckAccountTypesByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingCheckAccountTypesByCustomerFile, customerFileId],
	);

	const handleAddButtonClick = useCallback(() => onCheckAccountTypeSelection(), [onCheckAccountTypeSelection]);

	const handleRowClick = useCallback(
		(checkAccountType: SettingCustomerFileCheckAccountType) => onCheckAccountTypeSelection(checkAccountType),
		[onCheckAccountTypeSelection],
	);

	return (
		<SettingsTable<SettingCustomerFileCheckAccountType>
			dataSource={settingCheckAccountTypesDataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Types de compte"
		/>
	);
};

export default CheckAccountTypesCustomerFileTable;
