import { SettingsGroup } from '@elipssolution/harfang';
import { Control, Controller } from 'react-hook-form';

import ExclusionsForm from './ExclusionsForm';
import ConnectorFieldsForm from '../../components/ConnectorFieldsForm';
import { ConnectorType } from '../../types/connector';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

type StorageDownloadConfigFormProps = {
	control: Control<StorageDownloadFormType>;
	connector: ConnectorType;
	validateRequiredFolders: (value?: Record<string, string | undefined> | null) => boolean;
};

const StorageDownloadConfigForm = ({ control, connector, validateRequiredFolders }: StorageDownloadConfigFormProps) => (
	<>
		<SettingsGroup label="Arborescences" actionsWidth={550}>
			<Controller
				control={control}
				render={({ field }) => <ConnectorFieldsForm fields={connector?.folders} {...field} />}
				name="folders"
				rules={{
					validate: validateRequiredFolders,
				}}
			/>
		</SettingsGroup>

		<Controller
			control={control}
			render={({ field: { onChange, value } }) => <ExclusionsForm onChange={onChange} exclusions={value} />}
			name="exclusions"
		/>
	</>
);
export default StorageDownloadConfigForm;
