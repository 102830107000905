import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	ApolloProvider as OriginalApolloProvider,
	from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useRouter } from 'next/router';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CustomerFileType } from '../../types/customerFile';

const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
		query: {
			fetchPolicy: 'no-cache',
		},
	},
	ssrMode: true,
});

type ApolloContextType = {
	apolloClient: ApolloClient<NormalizedCacheObject>;
	customerFileId?: string;
	userId?: string;
	refetchActiveQueries: () => Promise<void>;
	setCustomerFileId: (customerFileId?: string) => void;
	setUserId: (userId?: string) => void;
};

const ApolloContext = createContext<ApolloContextType | undefined>(undefined);

export const useApollo = () => {
	const context = useContext(ApolloContext);

	if (context === undefined) {
		throw new Error('ApolloContext should be used within a Provider');
	}

	return context;
};

export const ApolloProvider = ({ children }: { children: JSX.Element }) => {
	const [customerFileId, setCustomerFileId] = useState<CustomerFileType['id']>();
	const [userId, setUserId] = useState<string>();

	const { push } = useRouter();
	const { data } = useNextAuthSession();
	const { access_token } = data ?? {};

	const errorLink = onError(({ graphQLErrors }) => {
		const [promise] = (graphQLErrors ?? []).map(({ extensions: { code }, message }) => {
			switch (code) {
				case 'UNAUTHENTICATED':
					return push('/error/401');

				case 'FORBIDDEN':
					return message === 'Forbidden user.'
						? push(
								{
									pathname: '/error/403',
									query: {
										error: 'forbidden-user',
									},
								},
								'/error/403',
						  )
						: push('/error/403');

				case '404':
					if (
						/^Customer File with id .* not found.$/.test(message) ||
						/^CustomerFile with code .* not found.$/.test(message)
					) {
						setCustomerFileId(undefined);
						return push(
							{
								pathname: '/error/404',
								query: {
									error: 'customerFile-not-found',
								},
							},
							'/error/404',
						);
					}

					return push('/error/404');

				default:
					return undefined;
			}
		});

		if (!promise) {
			return;
		}

		promise.catch((e) => {
			throw e;
		});
	});

	const httpLink = new HttpLink({
		uri: `/graphql`,
		headers: {
			...(access_token && { authorization: `Bearer ${access_token}` }),
			...(customerFileId && { 'customer-file-id': customerFileId }),
			...(userId && { 'user-id': userId }),
		},
	});

	apolloClient.setLink(from([errorLink, httpLink]));

	const refetchActiveQueries = useCallback(async () => {
		await apolloClient.refetchQueries({ include: 'active' });
	}, []);

	const contextValue = useMemo(
		() => ({
			apolloClient,
			customerFileId,
			refetchActiveQueries,
			setCustomerFileId,
			setUserId,
			userId,
		}),
		[refetchActiveQueries, customerFileId, userId],
	);

	useEffect(() => {
		if (!customerFileId) return;

		refetchActiveQueries().catch((error) => {
			throw error;
		});
	}, [refetchActiveQueries, customerFileId]);

	return (
		<ApolloContext.Provider value={contextValue}>
			<OriginalApolloProvider client={apolloClient}>{children}</OriginalApolloProvider>
		</ApolloContext.Provider>
	);
};
