import { FieldValues } from 'react-hook-form';

export const getFormResettedValues = ({ values }: { values: FieldValues }) => {
	const resettedValues: { [key: string]: unknown } = {};

	Object.entries(values).forEach(([key, value]) => {
		if (value) {
			switch (typeof values[key]) {
				case 'object':
					Array.isArray(value) ? (resettedValues[key] = []) : (resettedValues[key] = undefined);
					break;

				case 'boolean':
					resettedValues[key] = false;
					break;

				default:
					resettedValues[key] = undefined;
					break;
			}
		} else {
			resettedValues[key] = value;
		}
	});

	return resettedValues;
};
