import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiPencil, mdiPlus, mdiSync } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CustomerFileCodeField from './CustomerFileCodeField';
import PermissionWall from '../../../../components/PermissionWall';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import { DomainType } from '../../../../types/domain';
import { PermissionEnum } from '../../../../types/permission';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CREATE_CUSTOMER_FILE,
	CreateCustomerFileType,
	REMOVE_CUSTOMER_FILE,
	RemoveCustomerFileType,
	UPDATE_CUSTOMER_FILE,
	UpdateCustomerFileType,
} from '../../../api/customerFile';
import { FETCH_DOMAINS, FetchDomainsType } from '../../../api/domain';
import { SYNCHRONIZE_CUSTOMER_FILE } from '../../../api/synchonization';
import { useSession } from '../../../components/SessionProvider';

const ErrorWrapper = styled('div')(({ theme }) => ({
	overflow: 'auto',

	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	padding: theme.spacing(1),
	gap: theme.spacing(1),
	margin: '16px auto 0 auto',

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

const ButtonWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

type CustomerFileFormProps = {
	customerFile?: CustomerFileWithDomainType;
	onCustomerFileChange: (customerFile: CustomerFileWithDomainType) => void;
};

const CustomerFileForm = ({ customerFile, onCustomerFileChange }: CustomerFileFormProps) => {
	const { back } = useSettingsDialog();
	const { refetch: refetchSession, customerFile: sessionCustomerFile } = useSession();
	const { id, code, domain, name } = customerFile ?? {};

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setValue,
		watch,
		reset,
		setError,
	} = useForm<Omit<CustomerFileWithDomainType, 'id'>>();

	const [errorMessage, setErrorMessage] = useState('');
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);
	const [isCreateCustomerFileSucceeded, setIsCreateCustomerFileSucceeded] = useState(false);
	const [isUpdateCustomerFileSucceeded, setIsUpdateCustomerFileSucceeded] = useState(false);

	const [fetchDomains] = useLazyQuery<FetchDomainsType>(FETCH_DOMAINS);

	const domainsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: DomainType[];
			count: number;
		}> => {
			const { data, error } = await fetchDomains({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				domains: { count = 0, items = [] },
			} = data ?? {
				domains: {},
			};

			if (!watch('domain')) {
				setValue('domain', items.find(({ isDefault }) => isDefault) as DomainType);
			}
			return {
				count,
				items,
			};
		},
		[fetchDomains, setValue, watch],
	);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createCustomerFile, { loading: isCreateCustomerFileLoading }] = useMutation<CreateCustomerFileType>(
		CREATE_CUSTOMER_FILE,
		{
			onCompleted: ({ createCustomerFile: customerFileResult }) => {
				onCustomerFileChange(customerFileResult);
				setIsCreateCustomerFileSucceeded(true);
				setTimeout(() => setIsCreateCustomerFileSucceeded(false), 3000);
			},
		},
	);

	const [updateCustomerFile, { loading: isUpdateCustomerFileLoading }] = useMutation<UpdateCustomerFileType>(
		UPDATE_CUSTOMER_FILE,
		{
			onCompleted: ({ updateCustomerFile: customerFileResult }) => {
				onCustomerFileChange(customerFileResult);
				setIsUpdateCustomerFileSucceeded(true);
				setTimeout(() => setIsUpdateCustomerFileSucceeded(false), 3000);
			},
		},
	);

	const [removeCustomerFile, { loading: isRemoveCustomerFileLoading }] = useMutation<RemoveCustomerFileType>(
		REMOVE_CUSTOMER_FILE,
		{
			onCompleted: ({ removeCustomerFile: customerFileResult }) => {
				if (sessionCustomerFile?.id === customerFileResult.id) {
					refetchSession().catch((error) => {
						throw error;
					});
				}
				back();
			},
			onError: (error: ApolloError) =>
				setConfirmationDialogDetails((prevValue) => ({
					...prevValue,
					dialogErrorMessage: generateErrorInformations({ error, resource: 'removeCustomerFile' })?.message,
				})),
		},
	);

	const [synchronizeCustomerFile] = useMutation(SYNCHRONIZE_CUSTOMER_FILE, {
		variables: { customerFileId: id },
	});

	const isLoading = useMemo(
		() => isCreateCustomerFileLoading || isUpdateCustomerFileLoading,
		[isCreateCustomerFileLoading, isUpdateCustomerFileLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveCustomerFileLoading || !isValid || !isDirty,
		[isDirty, isRemoveCustomerFileLoading, isValid],
	);

	const startIcon = useMemo(() => <Icon path={customerFile ? mdiPencil : mdiPlus} />, [customerFile]);

	const onSubmit = useCallback(
		(values: Omit<CustomerFileWithDomainType, 'id'>) =>
			(customerFile
				? updateCustomerFile({
						variables: {
							updateCustomerFileInput: {
								id: customerFile.id,
								domainId: values.domain.id,
								name: values.name,
							},
						},
				  })
				: createCustomerFile({
						variables: {
							createCustomerFileInput: {
								code: values.code,
								domainId: values.domain.id,
								name: values.name,
							},
						},
				  })
			).catch((error: ApolloError) => {
				const { code: errorCode, message } = generateErrorInformations({
					error,
					resource: customerFile ? 'updateCustomerFile' : 'createCustomerFile',
				});

				setErrorMessage(
					errorCode === 409
						? 'Le code du dossier client que vous avez saisi existe déjà. Si ce dernier ne vous est pas attribué, veuillez contacter votre responsable.'
						: message,
				);
			}),
		[customerFile, updateCustomerFile, createCustomerFile],
	);

	const deleteCustomerFile = useCallback(
		() =>
			removeCustomerFile({
				variables: {
					removeCustomerFileId: customerFile?.id,
				},
			}),
		[removeCustomerFile, customerFile],
	);

	const synchronize = useCallback(() => synchronizeCustomerFile(), [synchronizeCustomerFile]);

	const handleCustomerFileCodeError = useCallback(
		(error?: string) => (error ? setError('code', { message: error }) : clearErrors('code')),
		[clearErrors, setError],
	);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveCustomerFileLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				onClick: deleteCustomerFile,
				loading: isRemoveCustomerFileLoading,
				persistantErrorMessage: dialogErrorMessage,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, deleteCustomerFile, dialogErrorMessage, isRemoveCustomerFileLoading],
	);

	const submitButtonLabel = useMemo(() => {
		if (customerFile) {
			if (isCreateCustomerFileSucceeded) return 'Dossier ajouté';
			if (isUpdateCustomerFileSucceeded) return 'Dossier modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isCreateCustomerFileSucceeded, isUpdateCustomerFileSucceeded, customerFile]);

	useEffect(() => {
		reset({ code, domain, name });
	}, [code, domain, name, reset]);

	return (
		<>
			<SettingsGroup>
				<CustomerFileCodeField control={control} disabled={!!customerFile} onError={handleCustomerFileCodeError} />

				<Controller
					name="name"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<SettingsItemTextField
							{...field}
							description="Nom du dossier client."
							invalid={!!error}
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="domain"
					control={control}
					render={({ field: { ...field } }) => (
						<SettingsItemAutocomplete<DomainType>
							{...field}
							disableClearable
							dataSource={domainsDataSource}
							description="Domaine attaché au dossier client."
							getOptionLabel={(option) => option?.name || ''}
							label="Domaine"
						/>
					)}
					rules={{ required: true }}
				/>
			</SettingsGroup>

			<ButtonWrapper>
				<Stack flexDirection="row" gap={1}>
					{customerFile && (
						<PermissionWall permissionCodes={[PermissionEnum.ACCOUNTING_SYNCHRONIZE]}>
							<Button onClick={synchronize} startIcon={<Icon path={mdiSync} />}>
								Synchroniser
							</Button>
						</PermissionWall>
					)}

					<Button
						disabled={isMutationButtonDisabled}
						onClick={handleSubmit(onSubmit)}
						startIcon={startIcon}
						variant="contained"
					>
						{submitButtonLabel}
					</Button>
				</Stack>

				{customerFile && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</ButtonWrapper>

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title={`Êtes-vous sûr de vouloir supprimer le dossier ${name ?? ''} ?`}
			/>
		</>
	);
};

export default CustomerFileForm;
