import { useCallback, useMemo } from 'react';

import { useTheme } from '@mui/material';

import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';

import { useLazyQuery } from '@apollo/client';

import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import { DomainType } from '../../../../../../types/domain';
import { SettingTagByDomainType } from '../../../../types/settingTag';

import { FETCH_SETTING_TAGS_BY_DOMAIN, FetchSettingTagsByDomainType } from '../../../../api/settingDomainTag';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';

type DomainTagsTableProps = {
	domain?: DomainType;
	onTagSelection: (tag?: SettingTagByDomainType) => void;
};

const DomainTagsTable = ({ domain, onTagSelection }: DomainTagsTableProps) => {
	const { palette } = useTheme();

	const { id: domainId } = domain ?? {};

	const columns: TableColumnType<SettingTagByDomainType>[] = useMemo(
		() => [
			{
				key: 'name',
				field: 'name',
				title: 'Nom',
				width: 150,
			},
			{
				key: 'color',
				render: ({ color }) => <Chip color={color} label={color} style={{ width: 80 }} />,
				title: 'Color',
				width: 80,
				flexGrow: 0,
			},
			{
				key: 'storageUploadName',
				field: 'storageUpload',
				render: ({ storageUpload }) => storageUpload && <Chip color="default" label={storageUpload?.name} />,
				title: 'Emplacement',
				width: 150,
			},
			{
				align: 'center',
				key: 'isEnabled',
				title: 'Actif',
				render: ({ isEnabled }) => (
					<Icon
						color={isEnabled ? palette.success.main : palette.error.main}
						path={isEnabled ? mdiCheck : mdiClose}
						size="24px"
					/>
				),
				width: 38,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[palette],
	);

	const [fetchSettingTagsByDomain] = useLazyQuery<FetchSettingTagsByDomainType>(FETCH_SETTING_TAGS_BY_DOMAIN, {
		variables: { domainId },
	});

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingTagByDomainType>,
		): Promise<{
			count: number;
			items: SettingTagByDomainType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingTagsByDomain({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_settingTagsByDomain: { count = 0, items = [] },
			} = data ?? {
				document_settingTagsByDomain: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingTagsByDomain],
	);

	const handleRowClick = useCallback((tag: SettingTagByDomainType) => onTagSelection(tag), [onTagSelection]);

	const handleAddButtonClick = useCallback(() => onTagSelection(), [onTagSelection]);

	return (
		<SettingsTable<SettingTagByDomainType>
			addButtonLabel="Ajouter un tag"
			dataSource={dataSource}
			minHeight={400}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Tags"
		/>
	);
};

export default DomainTagsTable;
