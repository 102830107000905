import { Icon } from '@elipssolution/harfang';

import { mdiCheckbook, mdiMail, mdiReceiptText } from '@mdi/js';

import BillExchange from './billExchange';
import Checks from './checks';
import Voucher from './voucher';

const pages = [
	{
		component: Voucher,
		icon: <Icon path={mdiReceiptText} />,
		name: 'Pièces',
		route: 'voucher',
	},
	{
		component: Checks,
		icon: <Icon path={mdiCheckbook} />,
		name: 'Chèques',
		route: 'checks',
	},
	{
		component: BillExchange,
		icon: <Icon path={mdiMail} />,
		name: 'LCR',
		route: 'billExchange',
	},
];

export default pages;
