import { useMutation } from '@apollo/client';
import { Chip, Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiSync } from '@mdi/js';

import useArchivedCustomerFileDataSource from './hooks/useArchivedCustomerFileDataSource';
import SettingsTable from '../../../components/SettingsTable';
import { CustomerFileType, CustomerFileWithDomainType } from '../../../types/customerFile';
import { SYNCHRONIZE_CUSTOMER_FILE } from '../../api/synchonization';

const ArchivedCustomerFileTableSection = () => {
	const { dataSource: archivedCustomerFileDataSource } = useArchivedCustomerFileDataSource();

	const [synchronizeCustomerFile] = useMutation(SYNCHRONIZE_CUSTOMER_FILE);

	const handleSynchronizeButtonClick = (customerFileId: CustomerFileType['id']) =>
		synchronizeCustomerFile({
			variables: {
				customerFileId,
			},
		});

	const columns: TableColumnType<CustomerFileWithDomainType>[] = [
		{
			field: 'code',
			flexGrow: 0,
			key: 'code',
			sortable: true,
			title: 'Code',
			width: 100,
		},
		{
			field: 'name',
			key: 'name',
			sortable: true,
			title: 'Nom du dossier',
			width: 200,
		},
		{
			key: 'chip',
			flexGrow: 0,
			render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
			width: 110,
		},
		{
			key: 'actions',
			flexGrow: 0,
			render: ({ id }) => (
				<Tooltip content="Synchroniser le dossier">
					<IconButton onClick={() => handleSynchronizeButtonClick(id)}>
						<Icon path={mdiSync} />
					</IconButton>
				</Tooltip>
			),
			width: 40,
		},
	];

	return (
		<SettingsTable<CustomerFileWithDomainType>
			dataSource={archivedCustomerFileDataSource}
			tableColumns={columns}
			title="Dossiers archivés"
		/>
	);
};

export default ArchivedCustomerFileTableSection;
