import { gql } from '@apollo/client';

// QUERIES

export type CheckUserEmailAvailabilityType = {
	checkUserEmailAvailability: boolean;
};

export const CHECK_USER_EMAIL_AVAILABILITY = gql`
	query CheckUserEmailAvailability($email: String!) {
		checkUserEmailAvailability(email: $email)
	}
`;
