import { FilterMenuOutputType, Table, TableColumnType, TableInstance, TableOrderByType } from '@elipssolution/harfang';
import { useMemo } from 'react';

import useBankDataSource from '../../hooks/useBankDataSource';
import { BankType } from '../../types/bank';
import { CheckFilterType, CheckType } from '../../types/check';

type CheckTableProps = {
	filteredBank?: BankType;
	columns: TableColumnType<CheckType>[];
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<CheckType>,
		filters?: FilterMenuOutputType<CheckFilterType>,
	) => Promise<{ items: CheckType[]; count: number }>;
	onBankFilterChange?: (filteredBank?: BankType) => void;
	onCheckSelection: (check: CheckType) => void;
	tableInstance: TableInstance;
	title: string;
};

const CheckTable = ({
	filteredBank,
	columns,
	dataSource,
	onBankFilterChange,
	onCheckSelection,
	tableInstance,
	title,
}: CheckTableProps) => {
	const { dataSource: banksDataSource } = useBankDataSource();

	const filters: CheckFilterType = useMemo(
		() => ({
			bank: {
				label: 'Banque',
				type: 'autocomplete',
				dataSource: banksDataSource,
				getOptionLabel: ({ name }) => name,
				...(filteredBank && {
					value: { eq: filteredBank },
				}),
			},
			documentDate: {
				label: 'Saisie entre',
				type: 'period',
			},
			amount: {
				label: 'Montant',
				type: 'currency',
			},
		}),
		[banksDataSource, filteredBank],
	);

	const handleFilterSubmit = (submittedFilters?: FilterMenuOutputType<CheckFilterType>) => {
		const { bank } = submittedFilters ?? {};
		onBankFilterChange?.(bank?.eq);
	};

	return (
		<Table<CheckType, CheckFilterType>
			columns={columns}
			dataSource={dataSource}
			filters={filters}
			onFilterSubmit={handleFilterSubmit}
			onRowClick={onCheckSelection}
			style={{
				height: '100%',
			}}
			table={tableInstance}
			title={title}
			enableSearch={!!onBankFilterChange}
		/>
	);
};

export default CheckTable;
