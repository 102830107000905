import CheckAccountTypesDomainForm from './CheckAccountTypesDomainForm';
import CheckAccountTypesDomainTable from './CheckAccountTypesDomainTable';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../types/domain';
import { SettingDomainCheckAccountType } from '../../../../types/settingCheckAccountType';

type CheckAccountTypesProps = {
	domainId?: DomainType['id'];
};

const CheckAccountTypes = ({ domainId }: CheckAccountTypesProps) => {
	const { push } = useSettingsDialog();

	const handleCheckAccountTypeSelection = (selectedCheckAccountType?: SettingDomainCheckAccountType) => {
		push(<CheckAccountTypesDomainForm domainId={domainId} selectedCheckAccountType={selectedCheckAccountType} />);
	};

	return (
		<SettingsDialogPage title="Type de dépense chèque">
			<CheckAccountTypesDomainTable domainId={domainId} onCheckAccountTypeSelection={handleCheckAccountTypeSelection} />
		</SettingsDialogPage>
	);
};

export default CheckAccountTypes;
