import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { UPDATE_INTERNAL_USER_PERMISSION, UpdateInternalUserPermissionType } from '../../../api/permission';
import { UserPermissionType } from '../../../types/permission';
import SettingsItemPermission from '../../SettingsItemPermission';

type InternalUserPermissionProps = {
	disabled: boolean;
	permission: UserPermissionType;
	onPermissionChanged: () => void;
};

const InternalUserPermission = ({
	disabled,
	permission: { currentValue: initialCurrentValue, id, inheritedValue, name, value: initialValue },
	onPermissionChanged,
}: InternalUserPermissionProps) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [currentValue, setCurrentValue] = useState(initialCurrentValue);
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [value, setValue] = useState(initialValue);

	const [updatePermission, { loading }] = useMutation<UpdateInternalUserPermissionType>(
		UPDATE_INTERNAL_USER_PERMISSION,
		{
			onCompleted: (data) => {
				const { updateInternalUserPermission } = data ?? {};
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const { currentValue: updatedCurrentValue, value: updatedValue } = updateInternalUserPermission ?? {};

				setCurrentValue(updatedCurrentValue);
				setValue(updatedValue);
				onPermissionChanged();
			},
		},
	);

	const handlePermissionUpdate = useCallback(
		(newValue: boolean | null) => {
			updatePermission({
				variables: {
					updateInternalUserPermissionInput: { id, value: newValue },
				},
			}).catch((e) => {
				throw e;
			});
		},
		[updatePermission, id],
	);

	useEffect(() => setCurrentValue(initialCurrentValue), [initialCurrentValue]);

	useEffect(() => setValue(initialValue), [initialValue]);

	return (
		<SettingsItemPermission
			currentValue={currentValue}
			disabled={disabled}
			inheritedValue={inheritedValue}
			label={name}
			loading={loading}
			onChange={handlePermissionUpdate}
			value={value}
			hasInheritance
		/>
	);
};

export default InternalUserPermission;
