import { gql } from '@apollo/client';

export type CreateThirdPartyType = { createThirdParty: string };

export const CREATE_THIRD_PARTY = gql`
	mutation CreateThirdParty($createThirdPartyInput: CreateThirdPartyInput!) {
		createThirdParty(createThirdPartyInput: $createThirdPartyInput) {
			id
		}
	}
`;
