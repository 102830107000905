import { gql } from '@apollo/client';

import { CheckAccountTypeType } from '../types/checkAccountType';

export const CHECK_ACCOUNT_TYPE_FIELDS = `
	id
	name
`;

// QUERIES

export type FetchCheckAccountTypesType = {
	quickentry_checkAccountTypes: {
		count: number;
		items: CheckAccountTypeType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CHECK_ACCOUNT_TYPES = gql`
	query Quickentry_checkAccountTypes($page: Page, $search: String) {
		quickentry_checkAccountTypes(page: $page, search: $search) {
			count
			items {
				${CHECK_ACCOUNT_TYPE_FIELDS}
				accountPrefix
			}
			limit
			offset
		}
	}
`;
