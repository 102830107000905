import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import { FETCH_CHECK_ACCOUNTS_BY_CUSTOMER_FILE, FetchCheckAccountsByCustomerFileType } from '../../../../api/account';
import {
	CREATE_SETTING_CUSTOMER_FILE_BANK,
	CreateSettingCustomerFileBankType,
	REMOVE_SETTING_CUSTOMER_FILE_BANK,
	RemoveSettingCustomerFileBankType,
	UPDATE_SETTING_CUSTOMER_FILE_BANK,
	UpdateSettingCustomerFileBankType,
} from '../../../../api/settingBank';
import { AccountType } from '../../../../types/account';
import { SettingCustomerFileBankType } from '../../../../types/settingBank';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = Pick<SettingCustomerFileBankType, 'account' | 'name'>;

type CustomerFileBankFormProps = {
	customerFileId?: CustomerFileType['id'];
	settingBank?: SettingCustomerFileBankType;
};

const CustomerFileBankForm = ({ customerFileId, settingBank: initialSettingBank }: CustomerFileBankFormProps) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormFieldType>();

	const [settingBank, setSettingBank] = useState(initialSettingBank);
	const [error, setError] = useState<string>();
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();
	const [isCreateSettingCustomerFileBankSucceeded, setIsCreateSettingCustomerFileBankSucceeded] = useState(false);
	const [isUpdateSettingCustomerFileBankSucceeded, setIsUpdateSettingCustomerFileBankSucceeded] = useState(false);

	const { id: settingBankId, name: settingBankName } = useMemo(
		() => (settingBank || {}) as SettingCustomerFileBankType,
		[settingBank],
	);

	const openConfirmationDialog = useCallback(() => setIsConfirmationDialogOpen(true), []);
	const closeConfirmationDialog = useCallback(() => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	}, []);

	const [fetchCheckAccountsByCustomerFileType] = useLazyQuery<FetchCheckAccountsByCustomerFileType>(
		FETCH_CHECK_ACCOUNTS_BY_CUSTOMER_FILE,
	);

	const accountsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			const { data, error: queryError } = await fetchCheckAccountsByCustomerFileType({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				quickentry_checkAccountsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				quickentry_checkAccountsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCheckAccountsByCustomerFileType, customerFileId],
	);

	const [createSettingCustomerFileBank, { loading: isCreateLoading }] = useMutation<CreateSettingCustomerFileBankType>(
		CREATE_SETTING_CUSTOMER_FILE_BANK,
		{
			onCompleted: () => {
				setIsCreateSettingCustomerFileBankSucceeded(true);
				setTimeout(() => setIsCreateSettingCustomerFileBankSucceeded(false), 3000);
			},
		},
	);

	const [updateSettingCustomerFileBank, { loading: isUpdateLoading }] = useMutation<UpdateSettingCustomerFileBankType>(
		UPDATE_SETTING_CUSTOMER_FILE_BANK,
		{
			onCompleted: () => {
				setIsUpdateSettingCustomerFileBankSucceeded(true);
				setTimeout(() => setIsUpdateSettingCustomerFileBankSucceeded(false), 3000);
			},
		},
	);

	const [removeSettingCustomerFileBank, { loading: isRemoveLoading }] = useMutation<RemoveSettingCustomerFileBankType>(
		REMOVE_SETTING_CUSTOMER_FILE_BANK,
		{
			onCompleted: back,
			onError: (mutationError) =>
				setError(
					generateErrorInformations({ error: mutationError, resource: 'quickentry_removeSettingCustomerFileBank' })
						.message,
				),
		},
	);

	const handleRemoval = useCallback(
		() => removeSettingCustomerFileBank({ variables: { quickentryRemoveSettingCustomerFileBankId: settingBankId } }),
		[removeSettingCustomerFileBank, settingBankId],
	);

	const isMutationButtonDisabled = useMemo(() => !isDirty || !isValid, [isDirty, isValid]);

	const startIcon = useMemo(() => <Icon path={settingBankId ? mdiPencil : mdiPlus} />, [settingBankId]);

	const mutationButtonLabel = useMemo(() => {
		if (settingBankId) {
			if (isCreateSettingCustomerFileBankSucceeded) return 'Banque ajoutée';
			if (isUpdateSettingCustomerFileBankSucceeded) return 'Banque modifiée';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isCreateSettingCustomerFileBankSucceeded, isUpdateSettingCustomerFileBankSucceeded, settingBankId]);

	const isRemoveButtonDisabled = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading]);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { account, name } = values;
			const { id: accountId } = account ?? {};

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createSettingCustomerFileBank,
						mutationName: 'createSettingCustomerFileBank',
						inputValues: {
							accountId,
							customerFileId,
							name,
						},
					},
				],
				[
					'update',
					{
						mutation: updateSettingCustomerFileBank,
						mutationName: 'updateSettingCustomerFileBank',
						inputValues: {
							id: settingBankId,
							accountId,
							name,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(settingBankId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id }: { id: string } = data[`quickentry_${mutationName}` as keyof typeof data];

					setSettingBank({
						...values,
						id,
					});
				})
				.catch((mutationError: ApolloError) =>
					setError(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[createSettingCustomerFileBank, settingBankId, customerFileId, updateSettingCustomerFileBank],
	);

	useEffect(() => {
		const { account, name } = settingBank ?? {};

		reset({
			account: account ?? undefined,
			name,
		});
	}, [reset, settingBank]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveLoading,
				error: !!error,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, error, handleRemoval, isRemoveLoading],
	);

	return (
		<SettingsDialogPage title={settingBankName ?? "Ajout d'une banque"}>
			<SettingsGroup actionsWidth={350}>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de la banque." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					name="account"
					render={({ field }) => (
						<SettingsItemAutocomplete<AccountType>
							{...field}
							dataSource={accountsDataSource}
							description="Compte de chèque associé."
							getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
							label="Compte de chèque"
							required
						/>
					)}
					rules={{ required: true }}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={startIcon}
					variant="contained"
				>
					{mutationButtonLabel}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}

				{settingBankId && (
					<Button color="error" disabled={isRemoveButtonDisabled} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</ActionWrapper>

			{settingBankId && settingBankName && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					error={confirmationDialogError}
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					title={`Êtes-vous sûr de vouloir supprimer la banque ${settingBankName} ?`}
				/>
			)}
		</SettingsDialogPage>
	);
};

export default CustomerFileBankForm;
