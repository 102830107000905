import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { generateErrorInformations } from '../../../../../utils/errorHandler';
import { FetchTemplateType, FETCH_TEMPLATE } from '../../../api/template';
import { TemplateType } from '../../../types/template';

const useTemplate = ({ templateId }: { templateId?: TemplateType['id'] }) => {
	const [error, setError] = useState<string>();

	const { data } = useQuery<FetchTemplateType>(FETCH_TEMPLATE, {
		onCompleted: ({ quickentry_template }) =>
			quickentry_template?.templateLines?.some(({ account }) => !account) &&
			setError(
				'Le modèle de pièce configuré possède des comptes inexistants pour ce dossier. Veuillez contacter votre responsable paramétrage.',
			),
		onError: (queryError) => {
			if (/^AccountCode .+ not exist for customerFileId/.test(queryError.message)) {
				setError(
					'Le modèle de pièce configuré possède des comptes inexistants pour ce dossier. Veuillez contacter votre responsable paramétrage.',
				);
			} else {
				setError(
					generateErrorInformations({
						error: queryError,
						resource: 'quickentry_template',
					}).message,
				);
			}
		},
		skip: !templateId,
		variables: {
			id: templateId,
		},
	});

	return { error, template: data?.quickentry_template };
};

export default useTemplate;
