import { Typography, styled } from '@mui/material';
import Image from 'next/image';

type SplashScreenProps = {
	description?: string;
};

const StyledContainer = styled('div')(({ theme }) => ({
	position: 'fixed',
	inset: 0,

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	gap: theme.spacing(1),
}));

const SplashScreen = ({ description }: SplashScreenProps) => (
	<StyledContainer>
		<Image
			src="/logoCollapsed"
			loader={({ src }) => src}
			alt="logo"
			width={96}
			height={96}
			objectFit="contain"
			unoptimized
		/>
		<Typography variant="subtitle1">{description}</Typography>
	</StyledContainer>
);

export default SplashScreen;
