import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { PAYMENT_MODE_FIELDS } from './paymentMode';
import { CustomerFileTemplateType, TemplateType } from '../types/template';

// QUERIES

export type FetchCustomerFileTemplatesType = {
	quickentry_customerFileTemplates: {
		count: number;
		items: CustomerFileTemplateType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILE_TEMPLATES = gql`
	query Quickentry_customerFileTemplates($orderBy: OrderBy, $page: Page!, $search: String) {
		quickentry_customerFileTemplates(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				id
				isFavorite
				name
			}
			limit
			offset
		}
	}
`;

export type FetchTemplateType = {
	quickentry_template: TemplateType;
};

export const FETCH_TEMPLATE = gql`
	query Quickentry_template($id: ID!) {
		quickentry_template(id: $id) {
			id
			analyticalDimension {
				id
			}
			analyticalSectionCaption
			defaultEntryName
			name
			paymentMode {
				${PAYMENT_MODE_FIELDS}
			}
			templateLines {
				id
				analyticalSection {
					id
					code
					name
				}
				account {
					id
					code
					isMaster
				}
				defaultTransactionName
				direction
				isAnalyticalEnabled
				name
				subaccount {
					${ACCOUNT_FIELDS}
				}
				subaccountPrefix
				type
			}
		}
	}
`;

// MUTATIONS

export type UpdateCustomerFileTemplateType = {
	quickentry_updateCustomerFileTemplate: CustomerFileTemplateType;
};

export const UPDATE_CUSTOMER_FILE_TEMPLATE = gql`
	mutation Quickentry_updateCustomerFileTemplate(
		$updateCustomerFileTemplateInput: quickentry_UpdateCustomerFileTemplateInput!
	) {
		quickentry_updateCustomerFileTemplate(updateCustomerFileTemplateInput: $updateCustomerFileTemplateInput) {
			id
		}
	}
`;
