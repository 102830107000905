import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';

import BillExchangeCounterpartAccount from './billExchangeCounterpartAccount/BillExchangeCounterpartAccount';
import CheckAccountTypes from './checkAccountTypes/CheckAccountTypes';
import JournalCodes from './journalCodes/JournalCodes';
import PaymentModes from './paymentModes/PaymentModes';
import Templates from './templates/Templates';
import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../types/domain';

type DomainSectionProps = {
	domain?: DomainType;
};

const DomainSection = ({ domain }: DomainSectionProps) => {
	const { push } = useSettingsDialog();

	const { id: domainId, name: domainName } = domain || {};

	const navigateToTemplatesSection = () => push(<Templates domainId={domainId} />);
	const navigateToAccountTypesSection = () => push(<CheckAccountTypes domainId={domainId} />);
	const navigateToBillExchangeCounterparAccountSection = () =>
		push(<BillExchangeCounterpartAccount domainId={domainId} />);
	const navigateToJournalCodesSection = () => push(<JournalCodes domainId={domainId} />);
	const navigateToPaymentModesSection = () => push(<PaymentModes domainId={domainId} />);

	return (
		<SettingsDialogPage title={domainName}>
			<SettingsGroup label="Paramétrage Modèles de pièce">
				<SettingsItemNavigation label="Modèles de pièces" onClick={navigateToTemplatesSection} />
			</SettingsGroup>

			<SettingsGroup label="Paramétrage Saisie Chèques et LCR ">
				<SettingsItemNavigation label="Journaux" onClick={navigateToJournalCodesSection} />
				<SettingsItemNavigation label="Modes de règlement" onClick={navigateToPaymentModesSection} />
				<SettingsItemNavigation label="Type de dépense chèque" onClick={navigateToAccountTypesSection} />
				<SettingsItemNavigation
					label="Compte de contrepartie LCR"
					onClick={navigateToBillExchangeCounterparAccountSection}
				/>
			</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default DomainSection;
