import { Icon } from '@elipssolution/harfang';

import { mdiFileEye } from '@mdi/js';

import { ModuleCodeEnum } from '../../types/widget';

import accountingPages from './pages/pages';
import widgets from './widgets/widgets';

const accountingModule = {
	code: ModuleCodeEnum.ACCOUNTING,
	icon: <Icon path={mdiFileEye} />,
	name: 'Comptabilité',
	pages: accountingPages,
	widgets,
};

export default accountingModule;
