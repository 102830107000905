import { gql } from '@apollo/client';

import { DomainType } from '../../types/domain';

export const DOMAIN_FIELDS = `
	id
	name
	isDefault
`;

// QUERIES

export type FetchDomainsType = {
	domains: {
		items: DomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOMAINS = gql`
	query Domains($search: String, $page: Page, $orderBy: OrderBy) {
		domains(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${DOMAIN_FIELDS}
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateDomainType = {
	createDomain: DomainType;
};

export const CREATE_DOMAIN = gql`
	mutation CreateDomain($createDomainInput: CreateDomainInput!) {
		createDomain(createDomainInput: $createDomainInput) {
			${DOMAIN_FIELDS}
		}
	}
`;

export type UpdateDomainType = {
	updateDomain: DomainType;
};

export const UPDATE_DOMAIN = gql`
	mutation UpdateDomain($updateDomainInput: UpdateDomainInput!) {
		updateDomain(updateDomainInput: $updateDomainInput) {
			${DOMAIN_FIELDS}
		}
	}
`;

export const REMOVE_DOMAIN = gql`
	mutation RemoveDomain($removeDomainId: ID!) {
		removeDomain(id: $removeDomainId) {
			id
		}
	}
`;

export type FetchUsedDomainsType = {
	usedDomains: {
		items: DomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_USED_DOMAINS = gql`
	query UsedDomains($search: String, $page: Page!, $orderBy: OrderBy) {
		usedDomains(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${DOMAIN_FIELDS}
			}
			limit
			offset
		}
	}
`;

export const DUPLICATE_DOMAIN = gql`
	mutation DuplicateDomain(
		$duplicatedDomainId: ID!
		$createDomainInput: CreateDomainInput!
		$duplicatedModuleConfigurations: [DuplicatedModuleConfiguration!]!
	) {
		duplicateDomain(
			duplicatedDomainId: $duplicatedDomainId
			createDomainInput: $createDomainInput
			duplicatedModuleConfigurations: $duplicatedModuleConfigurations
		) {
			id
		}
	}
`;

export type CheckDomainNameAvailabilityType = {
	checkDomainNameAvailability: boolean;
};

export const CHECK_DOMAIN_NAME_AVAILABILITY = gql`
	query CheckDomainNameAvailability($name: String!) {
		checkDomainNameAvailability(name: $name)
	}
`;
