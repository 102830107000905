import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { FetchAllFiscalYearsType, FETCH_ALL_FISCAL_YEARS } from '../../../src/api/fiscalYear';

const useUnpaginatedFiscalYears = ({ skip = false }: { skip?: boolean } = {}) => {
	const { called, data, loading, error } = useQuery<FetchAllFiscalYearsType>(FETCH_ALL_FISCAL_YEARS, {
		skip,
	});

	const fiscalYears = useMemo(
		() =>
			data?.fiscalYearsWithoutPagination.map(({ startDate, endDate, ...rest }) => ({
				...rest,
				startDate: new Date(startDate),
				endDate: new Date(endDate),
			})),
		[data],
	);

	return { called, error, fiscalYears, loading };
};

export default useUnpaginatedFiscalYears;
