import { useCallback } from 'react';

import { CustomerFileWithDomainType } from '../../../../../types/customerFile';
import { DomainType } from '../../../../../types/domain';

import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';

import CustomerFileTable from './customerFile/CustomerFileTable';
import CustomerFileTags from './customerFile/CustomerFileTags';
import DomainTable from './domain/DomainTable';
import DomainTags from './domain/DomainTags';

const Tags = () => {
	const { push } = useSettingsDialog();

	const handleDomainSelection = useCallback(
		(domain: DomainType) => {
			push(<DomainTags domain={domain} />);
		},
		[push],
	);

	const handleCustomerFileSelection = useCallback(
		(customerFile: CustomerFileWithDomainType) => {
			push(<CustomerFileTags customerFile={customerFile} />);
		},
		[push],
	);

	return (
		<SettingsDialogPage title="Tags">
			<DomainTable onDomainSelection={handleDomainSelection} />
			<CustomerFileTable onCustomerFileSelection={handleCustomerFileSelection} />
		</SettingsDialogPage>
	);
};

export default Tags;
