import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import ExternalGroup from './externalGroup/ExternalGroup';
import LinkIcon from '../../../components/LinkIcon';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { FETCH_EXTERNAL_GROUPS, FetchExternalGroupsType } from '../../api/externalGroup';
import { GroupType } from '../../types/group';

const columns: TableColumnType<GroupType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du groupe',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ isAdministrator, isDefault }: GroupType) => {
			if (isAdministrator) {
				return <Chip label="Administrateur" color="error" />;
			}

			if (isDefault) {
				return <Chip label="Défaut" color="info" />;
			}

			return null;
		},
		width: 120,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const ExternalGroupsTableSection = () => {
	const { push } = useSettingsDialog();

	const [fetchGroups] = useLazyQuery<FetchExternalGroupsType>(FETCH_EXTERNAL_GROUPS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<GroupType>,
		): Promise<{
			count: number;
			items: GroupType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchGroups({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				externalGroups: { count = 0, items = [] },
			} = data ?? {
				externalGroups: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchGroups],
	);

	const openGroupForm = (group?: GroupType) => {
		push(<ExternalGroup group={group} />);
	};

	return (
		<SettingsTable<GroupType>
			addButtonLabel="Ajouter un groupe"
			dataSource={dataSource}
			onAddButtonClick={openGroupForm}
			onRowClick={openGroupForm}
			tableColumns={columns}
			title="Groupes"
		/>
	);
};

export default ExternalGroupsTableSection;
