import { gql } from '@apollo/client';
import { DocumentKpiType } from '../types/documentKpi';

// QUERIES

export type FetchDocumentKpiType = {
	document_documentKpi: DocumentKpiType;
};

export const FETCH_DOCUMENT_KPI = gql`
	query Document_documentKpi($filter: document_DocumentUploadFilterInput) {
		document_documentKpi(filter: $filter) {
			documentPending
			documentProcessed
			documentUploaded
		}
	}
`;
