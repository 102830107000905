export type RevenueType = {
	name: string;
	previousValue: string;
	value: string;
};

export type PeriodicityType = {
	id: PeriodicityEnum;
	label: string;
};

export type ChartType = {
	id: ChartTypeEnum;
	label: string;
};

export enum PeriodicityEnum {
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
}

export enum ChartTypeEnum {
	BAR_CHART = 'bar-chart',
	LINE_CHART = 'line-chart',
}
