import { gql } from '@apollo/client';

import { CUSTOMER_FILE_WITH_DOMAIN_FIELDS } from './customerFile';
import { INTERNAL_USER_FIELDS } from './internalUser';
import { InternalUserCustomerFileRelationType } from '../types/relation';

// QUERIES

export type FetchInternalUserCustomerFileRelationsByUserType = {
	internalUserCustomerFileRelationsByUser: {
		items: InternalUserCustomerFileRelationType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER = gql`
	query InternalUserCustomerFileRelationsByUser($userId: ID!, $search: String, $page: Page!) {
		internalUserCustomerFileRelationsByUser(userId: $userId, search: $search, page: $page) {
			count
			items {
				id
				customerFile {
					${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				}
				user {
					${INTERNAL_USER_FIELDS}
				}
			}
			limit
			offset
		}
	}
`;

export type FetchInternalUserCustomerFileRelationsByCustomerFileType = {
	internalUserCustomerFileRelationsByCustomerFile: {
		items: InternalUserCustomerFileRelationType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE = gql`
	query InternalUserCustomerFileRelationsByCustomerFile($customerFileId: ID!, $page: Page!, $search: String) {
		internalUserCustomerFileRelationsByCustomerFile(customerFileId: $customerFileId, page: $page, search: $search) {
			items {
				id
				customerFile {
					${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				}
				user {
					${INTERNAL_USER_FIELDS}
				}
			}
			count
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateInternalUserCustomerFileRelationType = {
	createInternalUserCustomerFileRelation: {
		id: string;
	};
};

export const CREATE_INTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	mutation CreateInternalUserCustomerFileRelation(
		$createInternalUserCustomerFileRelationInput: CreateInternalUserCustomerFileRelationInput!
	) {
		createInternalUserCustomerFileRelation(
			createInternalUserCustomerFileRelationInput: $createInternalUserCustomerFileRelationInput
		) {
			id
		}
	}
`;

export const REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION = gql`
	mutation RemoveInternalUserCustomerFileRelation($removeInternalUserCustomerFileRelationId: ID!) {
		removeInternalUserCustomerFileRelation(id: $removeInternalUserCustomerFileRelationId) {
			id
		}
	}
`;

export const REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER = gql`
	mutation RemoveInternalUserCustomerFileRelationByCustomerFileAndUser($customerFileId: ID!, $userId: ID!) {
		removeInternalUserCustomerFileRelationByCustomerFileAndUser(customerFileId: $customerFileId, userId: $userId) {
			id
		}
	}
`;
