import { ModuleCodeEnum, WidgetTemplateType } from '../../../types/widget';

import WidgetRevenue from './WidgetRevenue/WidgetRevenue';
import WidgetRevenueConfiguration from './WidgetRevenue/configuration/WidgetRevenueConfiguration';

const widgets: WidgetTemplateType[] = [
	{
		component: WidgetRevenue,
		configurationComponent: WidgetRevenueConfiguration,
		name: 'Graphique',
		type: 'chart',
		minW: 2,
		maxW: Infinity,
		minH: 2,
		maxH: Infinity,
		moduleCode: ModuleCodeEnum.ACCOUNTING,
	},
];

export default widgets;
