import { useQuery } from '@apollo/client';

import { FETCH_ALERT, FetchAlertType } from '../api/alert';

const useAlert = () => {
	const { data } = useQuery<FetchAlertType>(FETCH_ALERT);

	return { alert: data?.alert };
};

export default useAlert;
