/* eslint-disable react/no-danger */
import { Icon } from '@elipssolution/harfang';
import { mdiAlert } from '@mdi/js';
import { styled } from '@mui/material';
import sanitizeHtml from 'sanitize-html';

import Banner from './Banner';
import { AlertType } from '../types/alert';

const AlertBannerContainer = styled(Banner)(({ theme: { palette } }) => ({
	backgroundColor: palette.error.main,
	color: palette.getContrastText(palette.error.main),

	'& > svg': {
		minWidth: 24,
	},
}));

const sanitizeOptions = {
	allowedTags: ['b', 'strong', 'i', 'em', 'mark', 'small', 'del', 'ins', 'sub', 'sup', 'p', 'span', 'a'],
	allowedAttributes: {
		a: ['href', 'name', 'target'],
		'*': ['style'],
	},
};

type AlertBannerProps = {
	content: AlertType['content'];
};

const AlertBanner = ({ content }: AlertBannerProps) => (
	<AlertBannerContainer>
		<Icon path={mdiAlert} size="medium" />
		<div
			dangerouslySetInnerHTML={{
				__html: sanitizeHtml(content, sanitizeOptions),
			}}
		/>
	</AlertBannerContainer>
);

export default AlertBanner;
