import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemCheckbox,
	SettingsItemColor,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiCheck, mdiPencil, mdiPlus } from '@mdi/js';
import { Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingDomainTagNameField from './SettingDomainTagNameField';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../types/domain';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_DOMAIN_TAG,
	CreateSettingDomainTagType,
	FETCH_SETTING_DOMAIN_TAG,
	FetchSettingDomainTagType,
	REMOVE_SETTING_DOMAIN_TAG,
	UPDATE_SETTING_DOMAIN_TAG,
	UpdateSettingDomainTagType,
} from '../../../../api/settingDomainTag';
import {
	FETCH_AUTOCOMPLETE_STORAGE_UPLOADS,
	FetchAutocompleteStorageUploadsType,
} from '../../../../api/settingStorageUpload';
import { SettingStorageUploadType } from '../../../../types/settingStorage';
import { SettingTagByDomainType } from '../../../../types/settingTag';

const ErrorWrapper = styled('div')(({ theme }) => ({
	overflow: 'auto',

	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	padding: theme.spacing(1),
	gap: theme.spacing(1),
	margin: '16px auto 0 auto',

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

const ButtonWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

type DomainTagsFormProps = {
	domain: DomainType;
	tag?: SettingTagByDomainType;
};

type FormFieldType = Pick<SettingTagByDomainType, 'color' | 'isEnabled' | 'name' | 'storageUpload' | 'info'>;

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

const DomainTagsForm = ({ domain, tag }: DomainTagsFormProps) => {
	const { id: domainId } = domain;
	const { id: initialTagId, name: initialTagName } = (tag || {}) as SettingTagByDomainType;

	const { back } = useSettingsDialog();

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setError,
		reset,
	} = useForm<FormFieldType>();

	const [tagName, setTagName] = useState(initialTagName);
	const [tagId, setTagId] = useState(initialTagId);
	const [isTagArchived, setIsTagArchived] = useState(false);
	const [isCreateSettingDomainTagSucceeded, setIsCreateSettingDomainTagSucceeded] = useState(false);
	const [isUpdateSettingDomainTagSucceeded, setIsUpdateSettingDomainTagSucceeded] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);

	const [fetchSettingTag, { loading: isSettingDomainTagLoading }] = useLazyQuery<FetchSettingDomainTagType>(
		FETCH_SETTING_DOMAIN_TAG,
		{
			onCompleted: ({ document_settingDomainTag: { name } }) => setTagName(name),
			onError: (fetchError) =>
				setErrorMessage(
					generateErrorInformations({ error: fetchError, resource: 'document_settingDomainTag' }).message,
				),
		},
	);

	const [fetchSettingStorageUploads] = useLazyQuery<FetchAutocompleteStorageUploadsType>(
		FETCH_AUTOCOMPLETE_STORAGE_UPLOADS,
	);

	const settingStorageUploadsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: Pick<SettingStorageUploadType, 'id' | 'name'>[];
			count: number;
		}> => {
			const { data, error } = await fetchSettingStorageUploads({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_settingStorageUploads: { count = 0, items = [] },
			} = data ?? {
				document_settingStorageUploads: {},
			};

			return {
				items,
				count,
			};
		},
		[fetchSettingStorageUploads],
	);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createTag, { loading: isCreateTagLoading }] = useMutation<CreateSettingDomainTagType>(
		CREATE_SETTING_DOMAIN_TAG,
		{
			onCompleted: ({ document_createSettingDomainTag: { id } }) => {
				setTagId(id);
				setIsCreateSettingDomainTagSucceeded(true);
				setTimeout(() => setIsCreateSettingDomainTagSucceeded(false), 3000);
			},
		},
	);

	const [updateTag, { loading: isUpdateTagLoading }] = useMutation<UpdateSettingDomainTagType>(
		UPDATE_SETTING_DOMAIN_TAG,
		{
			onCompleted: () => {
				setIsUpdateSettingDomainTagSucceeded(true);
				setTimeout(() => setIsUpdateSettingDomainTagSucceeded(false), 3000);
			},
		},
	);

	const [removeTag, { loading: isRemoveTagLoading }] = useMutation(REMOVE_SETTING_DOMAIN_TAG, {
		onCompleted: back,
		onError: (error: ApolloError) =>
			setConfirmationDialogDetails((prevValue) => ({
				...prevValue,
				dialogErrorMessage: generateErrorInformations({ error, resource: 'document_removeSettingDomainTag' })?.message,
			})),
	});

	const applyArchivedTag = useCallback(
		(tagArchivedId: string) => {
			setIsTagArchived(true);

			fetchSettingTag({
				variables: {
					settingDomainTagId: tagArchivedId,
				},
			}).catch((error) => {
				throw error;
			});
		},
		[fetchSettingTag],
	);

	const isLoading = useMemo(
		() => isSettingDomainTagLoading || isCreateTagLoading || isUpdateTagLoading,
		[isSettingDomainTagLoading, isCreateTagLoading, isUpdateTagLoading],
	);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveTagLoading || !isValid || (!isDirty && !isTagArchived),
		[isDirty, isRemoveTagLoading, isTagArchived, isValid],
	);

	const mutationButtonName = useMemo(() => {
		if (isTagArchived) return 'Confirmer';
		if (tagId) {
			if (isCreateSettingDomainTagSucceeded) return 'Tag ajouté';
			if (isUpdateSettingDomainTagSucceeded) return 'Tag modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isCreateSettingDomainTagSucceeded, isTagArchived, isUpdateSettingDomainTagSucceeded, tagId]);

	const startIcon = useMemo(
		() => (isTagArchived ? <Icon path={mdiCheck} /> : <Icon path={tagId ? mdiPencil : mdiPlus} />),
		[isTagArchived, tagId],
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			setErrorMessage('');

			const { color, isEnabled, name, storageUpload, info } = values;
			const { id: storageUploadId } = storageUpload || {};

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createTag,
						mutationName: 'createSettingDomainTag',
						inputValues: {
							domainId: domain?.id,
							color,
							isEnabled,
							name,
							storageUploadId,
							info,
						},
					},
				],
				[
					'update',
					{
						mutation: updateTag,
						mutationName: 'updateSettingDomainTag',
						inputValues: {
							id: tagId,
							color,
							isEnabled,
							name,
							storageUploadId,
							...(isTagArchived && { isArchived: false }),
							info: info || null,
						},
					},
				],
			]);

			const { mutation, mutationName = '', inputValues } = modeMutationInfoMap.get(tagId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					setIsTagArchived(false);

					reset(values);

					setTagName(name);
				})
				.catch((mutationError: ApolloError) =>
					setErrorMessage(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[createTag, reset, updateTag, domain, isTagArchived, tagId],
	);

	const deleteTag = useCallback(
		() =>
			removeTag({
				variables: {
					removeSettingDomainTagId: tagId,
				},
			}),
		[removeTag, tagId],
	);
	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveTagLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveTagLoading,
				persistantErrorMessage: dialogErrorMessage,
				onClick: deleteTag,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, deleteTag, dialogErrorMessage, isRemoveTagLoading],
	);
	useEffect(() => reset(tag), [reset, tag]);

	return (
		<SettingsDialogPage title={tagName ?? "Ajout d'un tag"}>
			<SettingsGroup>
				<SettingDomainTagNameField
					clearErrors={clearErrors}
					control={control}
					domainId={domainId}
					initialValue={tagName}
					onApplyArchivedTag={applyArchivedTag}
					setError={setError}
					tagId={tagId}
				/>
				<Controller
					control={control}
					name="info"
					render={({ field: { onChange, ...field }, fieldState: { error } }) => (
						<SettingsItemTextField
							{...field}
							description="Informations du tag du domaine."
							helperText={error?.message ?? ' '}
							invalid={!!error}
							label="Information"
							onChange={onChange}
						/>
					)}
				/>

				<Controller
					name="color"
					control={control}
					render={({ field: { value, ...field } }) => (
						<SettingsItemColor
							{...field}
							label="Couleur"
							description="Couleur du tag."
							selectedColorCode={value}
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="storageUpload"
					control={control}
					render={({ field: { ...field } }) => (
						<SettingsItemAutocomplete<Pick<SettingStorageUploadType, 'id' | 'name'>>
							{...field}
							dataSource={settingStorageUploadsDataSource}
							description="Emplacement attaché au domaine."
							getOptionLabel={(option) => option?.name || ''}
							label="Emplacement"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isEnabled"
					control={control}
					defaultValue
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le tag domaine est actif."
							disabled={isLoading}
							label="Actif"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<ButtonWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={startIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{tagId && !isTagArchived && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</ButtonWrapper>

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title={`Êtes-vous sûr de vouloir supprimer le tag ${tagName || ''} ?`}
			/>
		</SettingsDialogPage>
	);
};

export default DomainTagsForm;
