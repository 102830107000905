import { Dialog, DialogProps } from '@elipssolution/harfang';
import { CircularProgress, Divider, styled, Typography } from '@mui/material';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import VoucherInformationsSection from './VoucherInformationsSection';
import VoucherLinesSection from './VoucherLinesSection';
import { AccountTypeEnum } from '../../../types/account';
import { DirectionEnum } from '../../../types/settingTemplate';
import { VoucherFormType, VoucherNatureEnum, VoucherType } from '../../../types/voucher';
import useVoucher from '../hooks/useVoucher';

const LoadingWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
}));

type ConsultVoucherDialogProps = {
	open: boolean;
	onClose: () => void;
	voucherId: VoucherType['id'];
};

const ConsultVoucherDialog = ({ open, onClose, voucherId }: ConsultVoucherDialogProps) => {
	const [error, setError] = useState<string>();

	const { control, reset } = useForm<VoucherFormType>();

	const {
		fields: lines,
		append: appendToLines,
		remove: removeLines,
	} = useFieldArray({
		name: 'lines',
		control,
		rules: {
			minLength: 2,
		},
	});

	const { loading: isVoucherFetchLoading, voucher } = useVoucher({ voucherId });

	const { analyticalDimensionId, analyticalSectionCaption, isSubaccountInLines } = useMemo(() => {
		const { dueDate, template, voucherLines } = voucher ?? {};

		const {
			analyticalDimension: templateAnalyticalDimension,
			analyticalSectionCaption: templateAnalyticalSectionCaption,
		} = template ?? {};

		const { id: templateAnalyticalDimensionId } = templateAnalyticalDimension ?? {};

		return {
			analyticalDimensionId: templateAnalyticalDimensionId,
			analyticalSectionCaption: templateAnalyticalSectionCaption ?? 'Section',
			isSubaccountInLines:
				(!!dueDate || voucherLines?.some(({ subaccount, subaccountPrefix }) => !!subaccount || !!subaccountPrefix)) ??
				false,
		};
	}, [voucher]);

	const handleClose = useCallback(() => {
		setError(undefined);

		reset({
			accountingDate: undefined,
			documentDate: undefined,
			documentNumber: undefined,
			dueDate: undefined,
			name: undefined,
			paymentMode: undefined,
		});
		removeLines();

		onClose?.();
	}, [removeLines, reset, onClose]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				label: 'Fermer',
				onClick: handleClose,
			},
		],
		[handleClose],
	);

	// Applies the voucher to the form
	useEffect(() => {
		if (!voucher) return;

		const { accountingDate, creditNote, documentDate, documentNumber, dueDate, name, paymentMode, voucherLines } =
			voucher;

		const defaultValues: VoucherFormType = {
			accountingDate: new Date(accountingDate),
			documentDate: new Date(documentDate),
			nature: creditNote ? VoucherNatureEnum.CREDIT_NOTE : VoucherNatureEnum.INVOICE,
			dueDate: dueDate ? new Date(dueDate) : undefined,
			lines: [],
			documentNumber,
			name,
			paymentMode,
		};

		reset(defaultValues);

		voucherLines.forEach(
			({ account, analyticalSection, creditAmount, debitAmount, name: lineName, subaccount, subaccountPrefix }) => {
				appendToLines({
					amount: numeral(creditAmount ?? debitAmount).format('0,00'),
					analyticalSection: analyticalSection ?? undefined,
					description: '',
					direction: creditAmount ? DirectionEnum.CREDIT : DirectionEnum.DEBIT,
					isAnalyticalEnabled: !!analyticalSection,
					name: lineName,
					subaccount: subaccount ?? undefined,
					type: subaccount ? AccountTypeEnum.SUBACCOUNT : AccountTypeEnum.GENERAL,
					account,
					subaccountPrefix,
				});
			},
		);
	}, [appendToLines, reset, voucher]);

	return (
		<Dialog
			actionsDialog={actionsDialog}
			error={error}
			maxWidth="lg"
			open={open}
			onClose={handleClose}
			title={voucher?.name ?? 'Chargement de la pièce...'}
			fullWidth
		>
			{isVoucherFetchLoading ? (
				<LoadingWrapper>
					<CircularProgress size={36} color="inherit" />
					<Typography>Chargement en cours...</Typography>
				</LoadingWrapper>
			) : (
				<>
					<VoucherInformationsSection control={control} isDueDateVisible={isSubaccountInLines} readOnly />
					<Divider />
					<VoucherLinesSection
						analyticalDimensionId={analyticalDimensionId}
						analyticalSectionCaption={analyticalSectionCaption}
						isSubaccountInLines={isSubaccountInLines}
						control={control}
						lines={lines}
						readOnly
					/>
				</>
			)}
		</Dialog>
	);
};

export default ConsultVoucherDialog;
