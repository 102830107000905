import { Button, CircularProgress, CurrencyFilterDefinitionType, Icon, Menu } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiMenuDown, mdiMenuUp } from '@mdi/js';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useSession } from '../../../../src/components/SessionProvider';
import { downloadFile } from '../../../../src/utils/file';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { AnalyticalType } from '../../../quickentry/types/analytical';
import { AccountingDate, AccountingType, DownloadTypeEnum } from '../../types/accounting';

type DownloadFilterType = {
	balance?: CurrencyFilterDefinitionType['value'];
};

const ACCOUNT_TYPES: Map<AccountingType, string> = new Map([
	[AccountingType.BALANCE_SHEET, 'Bilan'],
	[AccountingType.INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT, 'Résultat'],
]);
type CustomMenuItemProps = {
	downloadFilter: DownloadFilterType;
	selectedFiscalYear?: FiscalYearType;
	selectedType: AccountingType;
	type: DownloadTypeEnum;
	typeFileLoading: DownloadTypeEnum[];
	isGroupedByAccount: boolean;
	analyticalDimension?: AnalyticalType;
	accountingDate: AccountingDate;
	onTypeFileLoading: Dispatch<SetStateAction<DownloadTypeEnum[]>>;
};

const CustomMenuItem = ({
	downloadFilter,
	selectedFiscalYear,
	selectedType,
	type,
	typeFileLoading,
	isGroupedByAccount,
	analyticalDimension,
	accountingDate,
	onTypeFileLoading,
}: CustomMenuItemProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};

	const { id: analyticalDimensionId, name: analyticalDimensionName } = analyticalDimension || {};

	const { customerFile: sessionCustomerFile } = useSession();
	const { id: sessionCustomerFileId, code: sessionCustomerFileCode } = sessionCustomerFile || {};

	const [typeFileError, setTypeFileError] = useState<DownloadTypeEnum[]>([]);

	const isMenuItemInAnyInfoArray = typeFileLoading.includes(type) || typeFileError.includes(type);

	const fileName = useMemo(
		() =>
			`${sessionCustomerFileCode || ''}_${ACCOUNT_TYPES.get(selectedType)?.toString() || ''}_${
				selectedFiscalYear?.name || ''
			}${analyticalDimensionName ? `_${analyticalDimensionName}` : ''}`,
		[analyticalDimensionName, selectedFiscalYear?.name, selectedType, sessionCustomerFileCode],
	);

	const exportAnalyticalSections = useCallback(async () => {
		const headers = new Headers();

		if (access_token) {
			headers.append('Authorization', `Bearer ${access_token}`);
		}

		if (sessionCustomerFileId) {
			headers.append('customer-file-id', sessionCustomerFileId);
		}
		const commonParams = `fileName=${fileName}&fiscalYearId=${selectedFiscalYear?.id || ''}&type=${selectedType}${
			Object.keys(downloadFilter).length ? `&filter=${JSON.stringify(downloadFilter)}` : ''
		}${accountingDate !== null ? `&accountingDate=${JSON.stringify(accountingDate)}` : ''}`;

		const endpoint = isGroupedByAccount
			? `/accounting/analyticalSectionsExport/${type}?${commonParams}`
			: `/accounting/accountsByAnalyticalSectionLevelsExport/${type}?analyticalDimensionId=${
					analyticalDimensionId || ''
			  }&${commonParams}`;

		const result = await fetch(endpoint, {
			headers,
			method: 'GET',
		});

		if (!result.ok) {
			throw new Error('Erreur lors du téléchargement du fichier.');
		}

		const documentBlob = await result.blob();

		downloadFile(URL.createObjectURL(documentBlob), `${fileName}.${type === 'excel' ? 'xlsx' : 'csv'}`);
	}, [
		access_token,
		accountingDate,
		analyticalDimensionId,
		downloadFilter,
		fileName,
		isGroupedByAccount,
		selectedFiscalYear?.id,
		selectedType,
		sessionCustomerFileId,
		type,
	]);

	const handleExportAnalyticalSections = useCallback(() => {
		onTypeFileLoading((prevValue) => [...prevValue, type]);

		exportAnalyticalSections()
			.catch((e) => {
				setTypeFileError((prevValue) => [...prevValue, type]);
				throw e;
			})
			.finally(() => onTypeFileLoading((prevValue) => prevValue.filter((item) => item !== type)));
	}, [exportAnalyticalSections, onTypeFileLoading, type]);

	return (
		<MenuItem disabled={isMenuItemInAnyInfoArray} onClick={handleExportAnalyticalSections}>
			<Typography color={typeFileError.includes(type) ? 'error' : 'inherit'}>
				{type === 'csv' ? 'CSV' : type.charAt(0).toUpperCase() + type.slice(1)}
			</Typography>

			{isMenuItemInAnyInfoArray && (
				<Stack flex={1} alignItems="flex-end">
					{typeFileLoading.includes(type) ? (
						<CircularProgress color="inherit" size="small" />
					) : (
						<Icon color="error" path={mdiAlertCircle} />
					)}
				</Stack>
			)}
		</MenuItem>
	);
};

type DownloadAnalyticSectionsButtonProps = {
	disabled: boolean;
	downloadFilter: DownloadFilterType;
	selectedFiscalYear?: FiscalYearType;
	selectedType: AccountingType;
	isGroupedByAccount: boolean;
	analyticalDimension?: AnalyticalType;
	accountingDate: AccountingDate;
};

const DownloadAnalyticSectionsButton = ({
	disabled,
	downloadFilter,
	analyticalDimension,
	selectedFiscalYear,
	selectedType,
	isGroupedByAccount,
	accountingDate,
}: DownloadAnalyticSectionsButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [typeFileLoading, setTypeFileLoading] = useState<DownloadTypeEnum[]>([]);

	const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget);

	const handleClose = () => setAnchorEl(null);

	return (
		<div>
			<Button
				disabled={disabled}
				onClick={handleClick}
				variant="contained"
				endIcon={<Icon path={anchorEl ? mdiMenuUp : mdiMenuDown} />}
			>
				Télécharger
			</Button>

			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={!!anchorEl}
				onClose={handleClose}
			>
				<Stack width="140px">
					{Object.values(DownloadTypeEnum).map((downloadType) => (
						<CustomMenuItem
							key={downloadType}
							type={downloadType}
							downloadFilter={downloadFilter}
							selectedFiscalYear={selectedFiscalYear}
							selectedType={selectedType}
							typeFileLoading={typeFileLoading}
							onTypeFileLoading={setTypeFileLoading}
							isGroupedByAccount={isGroupedByAccount}
							analyticalDimension={analyticalDimension}
							accountingDate={accountingDate}
						/>
					))}
				</Stack>
			</Menu>
		</div>
	);
};

export default DownloadAnalyticSectionsButton;
