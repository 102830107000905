import { gql } from '@apollo/client';

import { AlertType } from '../types/alert';

// QUERIES

export type FetchAlertType = {
	alert: Pick<AlertType, 'content' | 'isActive'>;
};

export const FETCH_ALERT = gql`
	query Alert {
		alert {
			content
			isActive
		}
	}
`;

export type FetchSettingsAlertType = {
	alert: AlertType;
};

export const FETCH_SETTINGS_ALERT = gql`
	query SettingsAlert {
		alert {
			id
			content
			isActive
		}
	}
`;

// MUTATIONS

export type EditAlertType = {
	editAlert: AlertType;
};

export const EDIT_ALERT = gql`
	mutation EditAlert($editAlertInput: EditAlertInput!) {
		editAlert(editAlertInput: $editAlertInput) {
			id
		}
	}
`;
