import ArchivedCustomerFileTableSection from './ArchivedCustomerFilesTableSection';
import CustomerFileTableSection from './CustomerFileTableSection';
import DomainTableSection from './DomainTableSection';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { PermissionEnum } from '../../../types/permission';
import { useSession } from '../../components/SessionProvider';

const CustomerFiles = () => {
	const {
		user: { isInternalAdministrator },
	} = useSession();

	return (
		<SettingsDialogPage title="Dossiers clients">
			<PermissionWall permissionCodes={[PermissionEnum.DOMAIN_MANAGE]}>
				<DomainTableSection />
			</PermissionWall>
			<PermissionWall permissionCodes={[PermissionEnum.CUSTOMER_FILE_MANAGE]}>
				<CustomerFileTableSection />
			</PermissionWall>
			{isInternalAdministrator && <ArchivedCustomerFileTableSection />}
		</SettingsDialogPage>
	);
};

export default CustomerFiles;
