import { SettingsGroup } from '@elipssolution/harfang';
import { CircularProgress, Typography, styled } from '@mui/material';

import RelationPermission from './RelationPermission';
import modulesConfig from '../../../../modules/modules';
import { PermissionModuleType, UserPermissionType } from '../../../types/permission';

const LoaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: theme.spacing(1),

	marginTop: theme.spacing(1),

	'& svg': {
		color: theme.palette.grey[400],
	},
}));

type RelationPermissionModulesProps = {
	disabled: boolean;
	loading?: boolean;
	permissionModules: PermissionModuleType<UserPermissionType>[];
	onPermissionChanged: (value: boolean) => void;
};

const RelationPermissionModules = ({
	disabled,
	loading,
	permissionModules,
	onPermissionChanged,
}: RelationPermissionModulesProps) =>
	loading ? (
		<LoaderWrapper>
			<Typography>Chargement des droits de l&apos;utilisateur en cours...</Typography>
			<CircularProgress size="24px" color="inherit" />
		</LoaderWrapper>
	) : (
		<>
			{permissionModules.map(({ code, id, permissions }) => {
				const moduleName = modulesConfig.find((module) => module.code === code)?.name;

				return (
					<SettingsGroup key={id} label={code === 'general' ? 'Général' : moduleName} actionsWidth={180}>
						{permissions.map((permission: UserPermissionType) => (
							<RelationPermission
								key={permission.id}
								disabled={disabled}
								permission={permission}
								onPermissionChanged={onPermissionChanged}
							/>
						))}
					</SettingsGroup>
				);
			})}
		</>
	);

export default RelationPermissionModules;
