import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';

import Contacts from './Contacts';
import CustomerFileForm from './CustomerFileForm';
import Modules from './Modules';
import PermissionWall from '../../../../components/PermissionWall';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../../types/customerFile';
import { PermissionEnum } from '../../../../types/permission';

type CustomerFileProps = {
	customerFile?: CustomerFileWithDomainType;
};

const CustomerFile = ({ customerFile }: CustomerFileProps) => {
	const { replace, push } = useSettingsDialog();

	const { id, name } = customerFile ?? {};

	const navigateToModulesPage = () => id && push(<Modules customerFileId={id} />);

	const navigateToContactsPage = () => customerFile && push(<Contacts customerFile={customerFile} />);

	const handleCustomerFileChange = (newCustomerFile: CustomerFileWithDomainType) =>
		replace(<CustomerFile customerFile={newCustomerFile} />);

	return (
		<SettingsDialogPage title={name ?? "Ajout d'un dossier client"}>
			<CustomerFileForm customerFile={customerFile} onCustomerFileChange={handleCustomerFileChange} />

			{id && (
				<SettingsGroup>
					<PermissionWall
						permissionCodes={[
							PermissionEnum.DOCUMENT_SETTING_STORAGE,
							PermissionEnum.DOCUMENT_SETTING_TAG,
							PermissionEnum.QUICKENTRY_SETTING,
							PermissionEnum.ACCOUNTING_SETTING,
						]}
					>
						<SettingsItemNavigation
							description="Configuration des modules du dossier"
							label="Modules"
							onClick={navigateToModulesPage}
						/>
					</PermissionWall>
					<SettingsItemNavigation
						description="Configuration des contacts du dossier"
						label="Contacts"
						onClick={navigateToContactsPage}
					/>
				</SettingsGroup>
			)}
		</SettingsDialogPage>
	);
};

export default CustomerFile;
