export enum AccountingType {
	BALANCE_SHEET = 'BALANCE_SHEET',
	INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT = 'INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT',
}
export type AccountingDate = {
	min: Date;
	max: Date;
} | null;

export enum DownloadTypeEnum {
	CSV = 'csv',
	EXCEL = 'excel',
}
