import {
	CartesianGrid,
	Line,
	LineChart as RechartsLineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip as GraphTooltip,
	XAxis,
	YAxis,
} from 'recharts';

import { tickFormatter, currencyFormatter, N_MINUS_1_COLOR, N_COLOR } from '../utils/chart';

type dataType = {
	name: string;
	previousValue: number;
	value: number;
};

const LineChart = ({ data, labelFormatter }: { data: dataType[]; labelFormatter: (label: string) => string }) => (
	<ResponsiveContainer width="100%" height="100%">
		<RechartsLineChart
			data={data}
			margin={{
				top: 32,
				left: -16,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="name" height={20} />
			<YAxis width={80} tickFormatter={tickFormatter} />
			<ReferenceLine y={0} stroke="#000" />
			<GraphTooltip formatter={currencyFormatter} labelFormatter={labelFormatter} />
			<Line dataKey="previousValue" name="N-1" fill={N_MINUS_1_COLOR} />
			<Line type="monotone" dataKey="value" name="N" fill={N_COLOR} />
		</RechartsLineChart>
	</ResponsiveContainer>
);
export default LineChart;
