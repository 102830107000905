import { useTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import BillExchangeDialog from './BillExchangeDialog';
import SubmittedOrValidatedBillExchangeTable from './SubmittedOrValidatedBillExchangeTable';
import SynchronizedBillExchangeTable from './SynchronizedBillExchangeTable';
import PermissionWall from '../../../../components/PermissionWall';
import { useSession } from '../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../types/permission';
import BanksCarousel from '../../components/BanksCarousel';
import { BankType } from '../../types/bank';
import { BillExchangeType } from '../../types/billExchange';

const BillExchange = () => {
	const { push } = useRouter();
	const { permissions } = useSession();

	const submittedOrValidatedBillExchangeTableInstance = useTable();
	const synchronizedBillExchangeTableInstance = useTable();

	const [selectedBank, setSelectedBank] = useState<BankType>();
	const [selectedBillExchange, setSelectedBillExchange] = useState<BillExchangeType>();
	const [isBillExchangeDialogOpen, setIsBillExchangeDialogOpen] = useState(false);

	const handleBillExchangeDialogClose = () => {
		setSelectedBank(undefined);
		setSelectedBillExchange(undefined);
		setIsBillExchangeDialogOpen(false);
	};

	const handleBankSelection = (bank: BankType) => {
		setIsBillExchangeDialogOpen(true);
		setSelectedBank(bank);
	};

	const handleCheckSelection = (check: BillExchangeType) => {
		setIsBillExchangeDialogOpen(true);
		setSelectedBillExchange(check);
	};

	const handleSubmittedOrValidatedCheckSelection = (check: BillExchangeType) => {
		handleCheckSelection(check);
	};

	const handleBillExchangeEdition = () => {
		handleBillExchangeDialogClose();

		submittedOrValidatedBillExchangeTableInstance.reload();
	};

	const handleBillExchangeValidation = () => {
		submittedOrValidatedBillExchangeTableInstance.reload();
		synchronizedBillExchangeTableInstance.reload();
	};

	useEffect(() => {
		const hasUserPermissions = [PermissionEnum.QUICKENTRY_WRITE, PermissionEnum.QUICKENTRY_READ].some(
			(permission) => permissions.find(({ code }) => permission === code)?.value,
		);

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	return (
		<>
			<Stack flex={1} gap={2}>
				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_WRITE]}>
					<BanksCarousel onBankSelection={handleBankSelection} selectedBank={selectedBank} />
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_VALIDATE, PermissionEnum.QUICKENTRY_WRITE]}>
					<SubmittedOrValidatedBillExchangeTable
						onBillExchangeSelection={handleSubmittedOrValidatedCheckSelection}
						onBillExchangeValidation={handleBillExchangeValidation}
						tableInstance={submittedOrValidatedBillExchangeTableInstance}
					/>
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_READ]}>
					<SynchronizedBillExchangeTable
						selectedBank={selectedBank}
						tableInstance={synchronizedBillExchangeTableInstance}
					/>
				</PermissionWall>
			</Stack>

			<BillExchangeDialog
				bankId={selectedBank?.id ?? selectedBillExchange?.bank.id}
				billExchange={selectedBillExchange}
				onBillExchangeEdition={handleBillExchangeEdition}
				onClose={handleBillExchangeDialogClose}
				open={isBillExchangeDialogOpen}
			/>
		</>
	);
};

export default BillExchange;
