import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { DomainType } from '../../../../../../types/domain';
import { FETCH_SETTING_TEMPLATES_BY_DOMAIN, FetchSettingTemplatesByDomainType } from '../../../../api/settingTemplate';
import { SettingDomainTemplateType, StateEnum } from '../../../../types/settingTemplate';

const columns: TableColumnType<SettingDomainTemplateType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom',
		width: 200,
	},
	{
		field: 'validUntil',
		key: 'validUntil',
		render: ({ validUntil = '' }) => validUntil,
		sortable: true,
		title: "Valide jusqu'à",
		width: 150,
	},
	{
		field: 'state',
		flexGrow: 0,
		key: 'state',
		render: ({ state }) => {
			const isDisabled = state === StateEnum.DISABLED;
			const isEnabled = state === StateEnum.ENABLED;

			const chipColor = () => {
				if (isDisabled) return 'default';

				return isEnabled ? 'success' : 'info';
			};

			const chipLabel = () => {
				if (isDisabled) return 'Inactif';

				return isEnabled ? 'Actif' : 'Archivé';
			};

			return <Chip color={chipColor()} label={chipLabel()} />;
		},
		sortable: true,
		title: 'État',
		width: 110,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type SettingTemplateDomainTableProps = {
	domainId?: DomainType['id'];
	onTemplateSelection: (template?: SettingDomainTemplateType) => void;
};

const SettingTemplateDomainTable = ({ domainId, onTemplateSelection }: SettingTemplateDomainTableProps) => {
	const [fetchSettingCheckAccountTypesByDomain] = useLazyQuery<FetchSettingTemplatesByDomainType>(
		FETCH_SETTING_TEMPLATES_BY_DOMAIN,
	);

	const settingCheckAccountTypesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingDomainTemplateType>,
		): Promise<{
			count: number;
			items: SettingDomainTemplateType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingCheckAccountTypesByDomain({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					domainId,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_settingTemplatesByDomain: { count = 0, items = [] },
			} = data ?? {
				quickentry_settingTemplatesByDomain: {},
			};

			return {
				count,
				items: items.map(({ validUntil, ...rest }) => ({
					...rest,
					...(validUntil && {
						validUntil: new Date(validUntil).toLocaleDateString('fr-FR'),
					}),
				})),
			};
		},
		[fetchSettingCheckAccountTypesByDomain, domainId],
	);

	const handleAddButtonClick = useCallback(() => onTemplateSelection(), [onTemplateSelection]);

	const handleRowClick = useCallback(
		(template: SettingDomainTemplateType) => onTemplateSelection(template),
		[onTemplateSelection],
	);

	return (
		<SettingsTable<SettingDomainTemplateType>
			dataSource={settingCheckAccountTypesDataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Modèles"
		/>
	);
};

export default SettingTemplateDomainTable;
