import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import InternalGroupForm from './InternalGroupForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { FETCH_INTERNAL_GROUPS, FetchInternalGroupsType } from '../../../api/internalGroup';
import { GroupType } from '../../../types/group';

const columns: TableColumnType<GroupType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du groupe',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ isAdministrator, isDefault }: GroupType) => {
			if (isAdministrator) {
				return <Chip label="Administrateur" color="error" />;
			}

			if (isDefault) {
				return <Chip label="Défaut" color="info" />;
			}

			return null;
		},
		width: 120,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const InternalGroupTable = () => {
	const { push } = useSettingsDialog();

	const [fetchGroups] = useLazyQuery<FetchInternalGroupsType>(FETCH_INTERNAL_GROUPS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<GroupType>,
		): Promise<{ count: number; items: GroupType[] }> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchGroups({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			if (!data) {
				throw Error('Aucune donnée reçue');
			}

			return data.internalGroups;
		},
		[fetchGroups],
	);

	const handleGroupSelection = (group?: GroupType) => push(<InternalGroupForm group={group} />);

	const handleAddButtonClick = () => push(<InternalGroupForm />);

	return (
		<SettingsTable<GroupType>
			addButtonLabel="Ajouter un groupe"
			dataSource={dataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleGroupSelection}
			tableColumns={columns}
			title="Groupes"
		/>
	);
};

export default InternalGroupTable;
