import { ApolloError, useMutation } from '@apollo/client';
import { Button, Icon, SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiContentDuplicate } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import DomainDuplicationTable from './DomainDuplicationTable';
import DomainNameField from './DomainNameField';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { DomainConfigurationEnum, DomainType } from '../../../../types/domain';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { DUPLICATE_DOMAIN } from '../../../api/domain';

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	overflow: 'auto',

	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	padding: spacing(1),
	gap: spacing(1),
	margin: spacing(2, 'auto'),

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormType = {
	configurationsToDuplicate?: (keyof typeof DomainConfigurationEnum)[];
	isDefault: DomainType['isDefault'];
	name: DomainType['name'];
};

type DomainProps = {
	domain: DomainType;
};

const DomainDuplication = ({ domain }: DomainProps) => {
	const { back } = useSettingsDialog();
	const { id, name } = domain;

	const {
		clearErrors,
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setError,
		setValue,
		watch,
	} = useForm<FormType>();

	const configurationsToDuplicateValue = watch('configurationsToDuplicate');

	const [errorMessage, setErrorMessage] = useState<string>();

	const [duplicateDomain] = useMutation(DUPLICATE_DOMAIN, {
		onCompleted: back,
	});

	const isMutationButtonDisabled = useMemo(
		() => !isValid || !isDirty || !configurationsToDuplicateValue || configurationsToDuplicateValue.length === 0,
		[configurationsToDuplicateValue, isDirty, isValid],
	);

	const handleConfigurationsToDuplicateValueChange = (
		configurationsToDuplicate: (keyof typeof DomainConfigurationEnum)[],
	) => setValue('configurationsToDuplicate', configurationsToDuplicate);

	const onSubmit = useCallback(
		({ configurationsToDuplicate, isDefault, name: domainName }: FormType) =>
			duplicateDomain({
				variables: {
					createDomainInput: {
						name: domainName,
						isDefault,
					},
					duplicatedDomainId: id,
					duplicatedModuleConfigurations: configurationsToDuplicate,
				},
			}).catch((error: ApolloError) =>
				setErrorMessage(generateErrorInformations({ error, resource: 'duplicateDomain' })?.message),
			),
		[duplicateDomain, id],
	);

	const handleDomainNameError = useCallback(
		(error?: string) => (error ? setError('name', { message: error }) : clearErrors('name')),
		[clearErrors, setError],
	);

	return (
		<SettingsDialogPage title="Dupliquer un domaine">
			<SettingsGroup>
				<SettingsItemTextField description="Le domaine d'origine." label="Origine" value={name} disabled />

				<DomainNameField control={control} onError={handleDomainNameError} />

				<Controller
					name="isDefault"
					defaultValue={false}
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le domaine est celui par défaut."
							label="Défaut"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<DomainDuplicationTable
				onValueChange={handleConfigurationsToDuplicateValueChange}
				value={configurationsToDuplicateValue}
			/>

			{errorMessage && (
				<ErrorWrapper>
					<Icon path={mdiAlertCircle} />
					<Typography>{errorMessage}</Typography>
				</ErrorWrapper>
			)}

			<Stack flexDirection="row" justifyContent="flex-end">
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={mdiContentDuplicate} />}
					variant="contained"
				>
					Dupliquer
				</Button>
			</Stack>
		</SettingsDialogPage>
	);
};
export default DomainDuplication;
