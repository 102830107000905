import { Button, ConfirmationDialog, DialogProps, SettingsItemImage, Tooltip } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { useSession } from '../../components/SessionProvider';
import { ContactType } from '../../types/contact';
import { uploadFileWithFormData } from '../../utils/file';

type ContactFormProps = {
	contact: Pick<ContactType, 'id' | 'name' | 'hasPicture'>;
};

type FormType = {
	picture: File;
};

const ContactForm = ({ contact: { id: contactId, name: contactName, hasPicture } }: ContactFormProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();
	const { id: customerFileId } = sessionCustomerFile || {};
	const { back } = useSettingsDialog();

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { isDirty },
	} = useForm<FormType>();

	const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [isRemoveImageLoading, setRemoveImageLoading] = useState(false);
	const [confirmationDialogError, setConfirmationDialogError] = useState<string>();

	const openConfirmationDialog = () => setConfirmationDialogOpen(true);

	const closeConfirmationDialog = () => {
		setConfirmationDialogOpen(false);
		setConfirmationDialogError(undefined);
	};

	const deleteImage = useCallback(async () => {
		if (customerFileId && access_token) {
			setRemoveImageLoading(true);

			const headers = new Headers();
			headers.append('Authorization', `Bearer ${access_token}`);
			headers.append('customer-file-id', customerFileId);

			const result = await fetch(`/contact/images?contactId=${contactId}`, {
				method: 'DELETE',
				headers,
			});

			if (!result.ok) {
				setConfirmationDialogError("Erreur lors de la suppression de l'image.");
				throw new Error("Erreur lors de la suppression de l'image.");
			}

			setRemoveImageLoading(false);
			closeConfirmationDialog();
			back();
		}
	}, [access_token, back, contactId, customerFileId]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveImageLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveImageLoading,
				onClick: deleteImage,
				color: 'error',
				persistantErrorMessage: confirmationDialogError,
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[confirmationDialogError, deleteImage, isRemoveImageLoading],
	);

	const onSubmit = useCallback(
		async ({ picture }: FormType) => {
			if (customerFileId && access_token) {
				await uploadFileWithFormData(access_token, picture, customerFileId, `/contact/images?contactId=${contactId}`);
				back();
			}
		},
		[customerFileId, access_token, contactId, back],
	);

	// Fetch contact picture on page load
	useEffect(() => {
		if (hasPicture) {
			(async () => {
				const response = await fetch(`/contact/images?contactId=${contactId}`);

				response.ok && setValue('picture', (await response.blob()) as File);
			})().catch((e) => {
				throw e;
			});
		}

		return () => {
			reset();
		};
	}, [setValue, reset, contactId, hasPicture]);

	return (
		<SettingsDialogPage title={contactName}>
			<Controller
				control={control}
				render={({ field: { value, ...field } }) => (
					<SettingsItemImage
						{...field}
						description="Photo du contact. La résolution optimale est 100x100."
						label="Photo"
						image={value as File | undefined}
						onDelete={hasPicture ? openConfirmationDialog : undefined}
						previewHeight={150}
						previewWidth={150}
					/>
				)}
				name="picture"
			/>

			<Stack flexDirection="row-reverse">
				<Tooltip
					content={!isDirty ? 'Vous devez sélectionner une nouvelle image pour appliquer la modification' : undefined}
				>
					<div>
						<Button disabled={!isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
							Appliquer
						</Button>
					</div>
				</Tooltip>
			</Stack>

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isConfirmationDialogOpen}
				title="Êtes-vous sûr de vouloir supprimer l'image du contact ?"
			/>
		</SettingsDialogPage>
	);
};

export default ContactForm;
