import { Icon, IconButton, Menu } from '@elipssolution/harfang';
import { mdiCog } from '@mdi/js';
import { MenuItem, Paper, Stack, Typography, styled } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { WidgetWithKeyType } from '../../types/widget';
import { getWidgetTemplateByType } from '../widgets/widgets';

const StyledDashboardItem = styled(Paper)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: 8,
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
}));

type WidgetContextMenuProps = {
	onEdit?: () => void;
	onRemove?: () => void;
};

const WidgetContextMenu = ({ onEdit, onRemove }: WidgetContextMenuProps) => {
	const [localWidgetMenuAnchorEl, setWidgetMenuAnchorEl] = useState<HTMLButtonElement>();

	const openMenu = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => setWidgetMenuAnchorEl(currentTarget);

	const closeMenu = useCallback(() => setWidgetMenuAnchorEl(undefined), []);

	const handleEdit = () => {
		closeMenu();
		onEdit?.();
	};

	const handleRemove = () => {
		closeMenu();
		onRemove?.();
	};

	return (
		<>
			<Stack alignItems="center" padding={1}>
				<IconButton onClick={openMenu} size="small">
					<Icon path={mdiCog} size="small" />
				</IconButton>
			</Stack>

			<Menu
				anchorEl={localWidgetMenuAnchorEl}
				open={!!localWidgetMenuAnchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={closeMenu}
			>
				{onEdit && <MenuItem onClick={handleEdit}>Modifier</MenuItem>}
				{onRemove && <MenuItem onClick={handleRemove}>Supprimer</MenuItem>}
			</Menu>
		</>
	);
};

type DashboardItemProps = {
	widgetWithKey: WidgetWithKeyType;
	readOnly: boolean;
	onEdit?: (widget: WidgetWithKeyType) => void;
	onRemove?: (widget: WidgetWithKeyType) => void;
};

const DashboardItem = ({ widgetWithKey, readOnly, onEdit, onRemove }: DashboardItemProps) => {
	const { widget } = widgetWithKey;
	const { name, type, configuration } = widget;
	const { component: WidgetComponent } = getWidgetTemplateByType(type);

	const parsedConfiguration = useMemo(
		() => (configuration ? (JSON.parse(configuration) as object) : undefined),
		[configuration],
	);

	return (
		<StyledDashboardItem>
			<Stack direction="row">
				<Typography flex={1} padding={1} variant="h6" noWrap>
					{name}
				</Typography>
				{(onEdit || onRemove) && (
					<WidgetContextMenu onEdit={() => onEdit?.(widgetWithKey)} onRemove={() => onRemove?.(widgetWithKey)} />
				)}
			</Stack>
			<Stack display="flex" flex={1} padding={2} paddingTop={0}>
				<WidgetComponent widgetWithKey={widgetWithKey} configuration={parsedConfiguration} readOnly={readOnly} />
			</Stack>
		</StyledDashboardItem>
	);
};

export default DashboardItem;
