import { SettingsGroup } from '@elipssolution/harfang';

import InternalGroupPermission from './InternalGroupPermission';
import modulesConfig from '../../../../modules/modules';
import { GroupPermissionType, PermissionModuleType } from '../../../types/permission';

type InternalGroupPermissionModulesProps = {
	permissionModules: PermissionModuleType<GroupPermissionType>[];
	disabled: boolean;
	onPermissionChanged: (value: boolean) => void;
};

const InternalGroupPermissionModules = ({
	permissionModules,
	disabled,
	onPermissionChanged,
}: InternalGroupPermissionModulesProps) => (
	<>
		{permissionModules.map(({ code, id, permissions }) => {
			const moduleName = modulesConfig.find((module) => module.code === code)?.name;

			return (
				<SettingsGroup key={id} label={code === 'general' ? 'Général' : moduleName} actionsWidth={180}>
					{permissions.map((permission: GroupPermissionType) => (
						<InternalGroupPermission
							key={permission.id}
							permission={permission}
							disabled={disabled}
							onPermissionChanged={onPermissionChanged}
						/>
					))}
				</SettingsGroup>
			);
		})}
	</>
);

export default InternalGroupPermissionModules;
