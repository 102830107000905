import { BaseSettingsItem, BaseSettingsItemProps, Chip } from '@elipssolution/harfang';
import { InputBase, styled } from '@mui/material';
import {
	ChangeEvent,
	MouseEvent,
	ReactElement,
	cloneElement,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

const TEXTFIELD_PADDING = 0.375;

const ActionWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(1),

	minHeight: 82,
}));

const DroppableTextField = styled('div')(({ theme: { shape, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',

	minHeight: 40,

	border: '1px solid rgba(0, 0, 0, 0.23)',
	borderRadius: shape.borderRadius * 2,

	boxSizing: 'border-box',

	padding: spacing(TEXTFIELD_PADDING),

	cursor: 'pointer',
}));

const PlaceholderInputBase = styled(InputBase)(({ theme: { spacing } }) => ({
	minWidth: '100%',

	'& > input': {
		padding: spacing(0, 1.75 - TEXTFIELD_PADDING),
	},
}));

const TextInputBase = styled(InputBase)(({ theme: { palette } }) => ({
	'& > input': {
		textAlign: 'center',
	},

	'&:hover': {
		backgroundColor: palette.action.hover,
	},
}));

const ChipWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: spacing(1),
}));

type ChipType = {
	color?: string;
	id: string;
	label: string;
};

const isChipType = (item: ChipType | InputType): item is ChipType => item && 'label' in item;

type InputType = {
	id: string;
	value: string;
};

const isInputType = (item: ChipType | InputType): item is InputType => item && 'value' in item;

type ItemType = ChipType | InputType;

type DraggableItemProps = {
	children: ReactElement;
	id: string;
	index: number;
};

const DraggableItem = ({ children, id, index }: DraggableItemProps) => (
	<Draggable key={id} draggableId={id} index={index}>
		{(draggableProvided) => (
			<div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
				{cloneElement(children, {
					...draggableProvided.dragHandleProps,
				})}
			</div>
		)}
	</Draggable>
);

const fuseInputs = (items: InputType[]) => {
	const [{ id }] = items;
	const lastItemValue = items[items.length - 1].value;

	return {
		value: `${items.reduce((acc, { value }) => acc + value, '')}${!lastItemValue.endsWith('/') ? '/' : ''}`.replace(
			/\/+/g,
			'/',
		),
		id,
	};
};

const parseItemsToString = (items: ItemType[]) =>
	items.reduce((acc, item) => {
		if (isInputType(item)) return `${acc}${item.value}`;
		if (isChipType(item)) return `${acc}{{${item.id}}}`;

		return acc;
	}, '');

const parseStringToItems = (value: string, chips: ChipType[]): ItemType[] => {
	const splittedValue = value.match(/({{[^{}]+}})|(\/[^{}]*)/g) ?? [];

	return (
		splittedValue?.map((item) => {
			if (item.startsWith('{{') && item.endsWith('}}')) {
				const id = item.slice(2, -2);

				const chip = chips.find(({ id: chipId }) => chipId === id);

				if (chip) return chip;
			}

			return {
				id: uuid(),
				value: item,
			} as InputType;
		}) ?? []
	);
};

type DraggableChipProps = {
	chip: ChipType;
	index: number;
	removeChip: (index: number) => void;
};

const DraggableChip = ({ chip: { color, id, label }, index, removeChip }: DraggableChipProps) => {
	const handleOnDeleteClick = useCallback(() => removeChip(index), [index, removeChip]);

	return (
		<DraggableItem key={id} id={id} index={index}>
			<Chip color={color} key={id} label={label} onDelete={handleOnDeleteClick} />
		</DraggableItem>
	);
};

type TextFieldInputProps = {
	focused?: boolean;
	id: InputType['id'];
	index: number;
	onBlur: (id: InputType['id'], value: InputType['value']) => void;
	onChange: (id: InputType['id'], index: number, value: InputType['value']) => void;
	value: InputType['value'];
};

const TextFieldInput = ({ focused = false, id, index, onBlur, onChange, value }: TextFieldInputProps) => {
	const handleValueChange = useCallback(
		({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => onChange(id, index, newValue),
		[onChange, id, index],
	);

	const handleFieldBlur = useCallback(() => onBlur(id, value), [id, onBlur, value]);

	return (
		<DraggableItem key={id} id={id} index={index}>
			<TextInputBase
				onChange={handleValueChange}
				onBlur={handleFieldBlur}
				inputProps={{
					size: value?.length ?? 1,
				}}
				inputRef={(input: HTMLInputElement) => focused && input && input.focus()}
				value={value}
			/>
		</DraggableItem>
	);
};

// Checks for anomalies in the items when the items change (input as first item, two inputs in a row, two chips in a row)
const getCleanedItems = (items: ItemType[]) => {
	type ActionType = {
		type: 'fuse' | 'insert';
		index?: number;
		items: InputType[];
	};

	const actions: ActionType[] = items.reduce((acc, item, index) => {
		// If there is two inputs in a row, fuse them
		if (isInputType(item) && isInputType(items[index - 1])) {
			acc.push({
				type: 'fuse',
				items: [items[index - 1] as InputType, item],
			});
		}

		// If there is two chips in a row, insert a slash between them
		else if (isChipType(item) && isChipType(items[index - 1])) {
			acc.push({
				type: 'insert',
				index,
				items: [
					{
						id: uuid(),
						value: '/',
					},
				],
			});
		}

		return acc;
	}, [] as ActionType[]);

	const newItems = [...items];

	actions.forEach(({ type, index, items: actionItems }) => {
		const [firstItem] = actionItems;

		switch (type) {
			case 'fuse':
				newItems.splice(newItems.indexOf(firstItem), 2, fuseInputs(actionItems));

				break;
			case 'insert':
				newItems.splice(index as number, 0, firstItem);

				break;

			default:
				break;
		}
	});

	return JSON.stringify(items) === JSON.stringify(newItems) ? items : newItems;
};

type SettingsItemDragTextFieldProps = BaseSettingsItemProps & {
	chips: ChipType[];
	onChange: (value: string) => void;
	value?: string;
};

const SettingsItemDragTextField = forwardRef<HTMLDivElement, SettingsItemDragTextFieldProps>(
	(
		{
			chips,
			description = 'Astuce : vous pouvez déplacer les étiquettes au sein du chemin',
			label,
			onChange,
			required,
			value,
		},
		ref,
	) => {
		const [items, setItems] = useState<ItemType[]>([]);
		const [isLastInputFocused, setIsLastInputFocused] = useState(false);

		const chipLeftOver = useMemo(() => {
			const itemIds = items.map((item) => (isChipType(item) ? item.id : null));

			return chips.filter((chip) => !itemIds.includes(chip.id));
		}, [chips, items]);

		const applyChanges = useCallback(
			(itemsToApply: ItemType[]) => {
				const parsedItems = parseItemsToString(itemsToApply);

				onChange(parsedItems);
			},
			[onChange],
		);

		const addChip = useCallback(
			(chip: ChipType) => {
				setItems((prevItems) => {
					const itemsToInsert: ItemType[] = [chip];

					isChipType(items[items.length - 1]) && itemsToInsert.unshift({ id: uuid(), value: '/' });

					const newItems = [...prevItems, ...itemsToInsert];

					const cleanedItems = getCleanedItems(newItems);

					applyChanges(cleanedItems);

					return cleanedItems;
				});
			},
			[applyChanges, items],
		);

		const removeChip = useCallback(
			(index: number) => {
				setItems((prevItems) => {
					const { length } = prevItems;

					if (length === 1) {
						applyChanges([]);
						return [];
					}

					const newItems = [...prevItems];
					newItems.splice(index, 1);

					const cleanedItems = getCleanedItems(newItems);

					applyChanges(cleanedItems);

					return cleanedItems;
				});
			},
			[applyChanges, setItems],
		);

		const handleDragEnd = useCallback(
			({ destination, source: { index: sourceIndex } }: DropResult) => {
				if (!destination) return;

				const { index: destinationIndex } = destination;

				setItems((prevItems) => {
					const newItems = [...prevItems];

					const [removedChip] = newItems.splice(sourceIndex, 1);
					newItems.splice(destinationIndex, 0, removedChip);

					const cleanedItems = getCleanedItems(newItems);

					applyChanges(cleanedItems);

					return cleanedItems;
				});
			},
			[applyChanges],
		);

		const handleFieldClick = useCallback(
			({ target }: MouseEvent<HTMLDivElement>) => {
				if (items.length > 0) {
					const lastItem = items[items.length - 1];

					if ((target as HTMLElement).tagName !== 'DIV') return;

					if (lastItem && isInputType(lastItem)) {
						setIsLastInputFocused(true);

						return;
					}
				}

				setItems((prevItems) => {
					const newInputId = uuid();

					const cleanedItems = getCleanedItems([
						...prevItems,
						{
							id: newInputId,
							value: '/',
						},
					]);

					applyChanges(cleanedItems);

					setIsLastInputFocused(true);

					return cleanedItems;
				});
			},
			[applyChanges, items],
		);

		const handleInputValueChange = useCallback(
			(id: InputType['id'], index: number, inputValue: InputType['value']) =>
				setItems((prevItems) => {
					if (index === 0 && inputValue.length === 0) {
						return prevItems.filter((prevItem) => prevItem.id !== id);
					}

					if (!inputValue.startsWith('/')) return prevItems;

					if ((index === 0 || index === prevItems.length - 1) && inputValue.length === 0)
						return prevItems.filter((prevItem) => prevItem.id !== id);

					const newValue = inputValue.length > 0 ? inputValue : '/';

					return prevItems.map((prevItem) =>
						prevItem.id === id
							? {
									...prevItem,
									value: newValue,
							  }
							: prevItem,
					);
				}),
			[setItems],
		);

		const handleInputBlur = useCallback(
			(id: InputType['id'], inputValue: InputType['value']) => {
				setIsLastInputFocused(false);

				setItems((prevItems) => {
					const newItems = prevItems.map((prevItem) =>
						prevItem.id === id
							? {
									...prevItem,
									value: `${inputValue}${!inputValue.endsWith('/') ? '/' : ''}`.replace(/\/+/g, '/'),
							  }
							: prevItem,
					);

					const cleanedItems = getCleanedItems(newItems);

					applyChanges(cleanedItems);

					return cleanedItems;
				});
			},
			[applyChanges, setItems],
		);

		// Parse the value string and translate it to items
		useEffect(() => {
			const newItems = parseStringToItems(value ?? '', chips);

			setItems((prevItems) =>
				prevItems.length !== 0 || JSON.stringify(newItems) === JSON.stringify(prevItems) ? prevItems : newItems,
			);
		}, [chips, value]);

		return (
			<BaseSettingsItem ref={ref} description={description} label={`${label}${required ? ' *' : ''}`}>
				<ActionWrapper>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId={uuid()} direction="horizontal">
							{(provided) => (
								<DroppableTextField {...provided.droppableProps} ref={provided.innerRef} onClick={handleFieldClick}>
									{items.length === 0 && (
										<PlaceholderInputBase
											as="div"
											placeholder="Cliquez sur une étiquette pour l'ajouter au chemin"
											readOnly
										/>
									)}

									{items.map((item, index) => {
										if (isChipType(item)) {
											const { id } = item;
											return <DraggableChip chip={item} index={index} key={id} removeChip={removeChip} />;
										}

										const { id, value: inputValue } = item;

										return (
											<TextFieldInput
												focused={index === items.length - 1 && isLastInputFocused}
												id={id}
												index={index}
												key={id}
												onBlur={handleInputBlur}
												onChange={handleInputValueChange}
												value={inputValue}
											/>
										);
									})}
									{provided.placeholder}
								</DroppableTextField>
							)}
						</Droppable>
					</DragDropContext>

					<ChipWrapper>
						{chipLeftOver.map((chip) => {
							const { color, id, label: chipLabel } = chip;

							return <Chip color={color} key={id} label={chipLabel} onClick={() => addChip(chip)} />;
						})}
					</ChipWrapper>
				</ActionWrapper>
			</BaseSettingsItem>
		);
	},
);
SettingsItemDragTextField.displayName = 'SettingsItemDragTextField';

export default SettingsItemDragTextField;
