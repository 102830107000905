import { Icon, IconButton } from '@elipssolution/harfang';
import { mdiChevronLeft, mdiClose } from '@mdi/js';
import { Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useCallback, useRef, useState } from 'react';

import { useSettingsDialog } from '../hooks/useSettingsDialog';

const StyledHeader = styled('header')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',

	padding: spacing(2),

	'& > div': {
		display: 'flex',
		alignItems: 'center',
		gap: spacing(1),
	},

	'&.hasNavigateBackIconButton': {
		paddingLeft: spacing(1),
	},
}));

const StyledContent = styled('div', { shouldForwardProp: (prop) => prop !== 'hasScrolled' })<{
	hasScrolled: boolean;
}>(({ hasScrolled, theme: { palette, spacing } }) => ({
	flex: 1,

	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),

	padding: spacing(1, 2, 2, 2),

	overflowY: 'auto',

	...(hasScrolled && { borderTop: `1px solid ${palette.divider}` }),
}));

type SettingsContentProps = {
	title?: string;
	titleActionButton?: ReactNode;
	onBack?: () => void;
	children: ReactNode;
};

const SettingsDialogPage = ({ title, onBack, children, titleActionButton }: SettingsContentProps) => {
	const { isFirstPage, close, back } = useSettingsDialog();
	const contentRef = useRef<HTMLDivElement>(null);

	const [hasScrolled, setHasScrolled] = useState(false);

	const handleContentScroll = useCallback(({ target: { scrollTop } }) => setHasScrolled(scrollTop > 0), []);

	return (
		<>
			<StyledHeader className={clsx({ hasNavigateBackIconButton: !isFirstPage })}>
				<div>
					{!isFirstPage && (
						<IconButton onClick={onBack ?? back}>
							<Icon path={mdiChevronLeft} />
						</IconButton>
					)}
					<Typography variant="h4" marginRight={2}>
						{title}
					</Typography>
					<div>{titleActionButton}</div>
				</div>

				<IconButton onClick={close}>
					<Icon path={mdiClose} />
				</IconButton>
			</StyledHeader>

			<StyledContent id="settings-page" hasScrolled={hasScrolled} ref={contentRef} onScroll={handleContentScroll}>
				{children}
			</StyledContent>
		</>
	);
};

export default SettingsDialogPage;
