import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

export const N_MINUS_1_COLOR = '#002362';
export const N_COLOR = '#335ba3';

export const currencyFormatter = (value: ValueType) =>
	new Intl.NumberFormat('fr-FR', {
		style: 'currency',
		currency: 'EUR',
	}).format(+value);

export const tickFormatter = (value: number) => {
	if (!Number.isNaN(value)) {
		if (Math.abs(value) >= 1e9) {
			return `${(value / 1e9).toFixed(1)} Md`;
		}
		if (Math.abs(value) >= 1e6) {
			return `${(value / 1e6).toFixed(1)} M`;
		}
		if (Math.abs(value) >= 1e3) {
			return `${(value / 1e3).toFixed(1)} k`;
		}
	}

	return `${value}`;
};
