import { useLazyQuery } from '@apollo/client';
import { Autocomplete, Table, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { Checkbox } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { CustomerFileType, CustomerFileWithGroup } from '../../../../types/customerFile';
import { FETCH_CUSTOMER_FILES, FetchCustomerFilesType } from '../../../api/customerFile';
import { FETCH_EXTERNAL_GROUPS, FetchExternalGroupsType } from '../../../api/externalGroup';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import { UserTypeEnum } from '../../../types/user';

type AddCustomerFilesTableProps = {
	selectedCustomerFiles: CustomerFileWithGroup[];
	onSelectedCustomerFilesChange: (newSelectedCustomerFiles: CustomerFileWithGroup[]) => void;
	tableInstance: TableInstance;
};

const AddCustomerFilesTable = ({
	selectedCustomerFiles,
	onSelectedCustomerFilesChange,
	tableInstance,
}: AddCustomerFilesTableProps) => {
	const {
		user: { type },
	} = useSession();

	const [fetchExternalGroup, { data: fetchExternalGroupData }] =
		useLazyQuery<FetchExternalGroupsType>(FETCH_EXTERNAL_GROUPS);

	const [fetchCustomerFiles] = useLazyQuery<FetchCustomerFilesType>(FETCH_CUSTOMER_FILES);

	const defaultExternalGroup = useMemo(() => {
		const { externalGroups } = fetchExternalGroupData || {};
		const { items = [] } = externalGroups || {};

		return items.find(({ isDefault }) => isDefault);
	}, [fetchExternalGroupData]);

	const customerFileDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data: { customerFiles } = {}, error } = await fetchCustomerFiles({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const { count = 0, items = [] } = customerFiles ?? {};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFiles],
	);

	const externalGroupsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: GroupType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchExternalGroup({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			if (!data)
				return {
					count: 0,
					items: [],
				};

			const {
				externalGroups: { count, items },
			} = data;

			return {
				count,
				items,
			};
		},
		[fetchExternalGroup],
	);

	const handleCustomerFileSelection = useCallback(
		({ customerFile, checked }: { customerFile: CustomerFileType; checked: boolean }) => {
			// If the entry already exists in the temporary table state
			if (!checked) {
				onSelectedCustomerFilesChange(
					selectedCustomerFiles.filter(({ customerFile: { id } }) => id !== customerFile.id),
				);
			}

			// If the entry does not exist in the temporary table state and the associated group hasn't been changed
			if (checked && !selectedCustomerFiles.some(({ customerFile: { id } }) => id === customerFile.id)) {
				onSelectedCustomerFilesChange([...selectedCustomerFiles, { customerFile, group: defaultExternalGroup }]);
			}

			// If the entry does not exist in the temporary table state but the associated group has already been changed
			if (checked && selectedCustomerFiles.some(({ customerFile: { id } }) => id === customerFile.id)) {
				onSelectedCustomerFilesChange([
					...selectedCustomerFiles.filter(({ customerFile: { id } }) => id !== customerFile.id),
					{
						customerFile,
						group: selectedCustomerFiles.find(({ customerFile: { id } }) => id === customerFile.id)?.group,
					},
				]);
			}
		},
		[defaultExternalGroup, onSelectedCustomerFilesChange, selectedCustomerFiles],
	);

	const handleGroupChange = useCallback(
		({ customerFile, group }: { customerFile: CustomerFileType; group?: GroupType }) =>
			onSelectedCustomerFilesChange(
				selectedCustomerFiles.some(({ customerFile: { id } }) => id === customerFile.id)
					? [
							...selectedCustomerFiles.filter(({ customerFile: { id } }) => id !== customerFile.id),
							{ customerFile, group },
					  ]
					: [...selectedCustomerFiles, { customerFile, group }],
			),
		[onSelectedCustomerFilesChange, selectedCustomerFiles],
	);

	const getIsCheckboxEnabled = useCallback(
		({ customerFileId }: { customerFileId: CustomerFileWithGroup['customerFile']['id'] }) =>
			selectedCustomerFiles.some(({ customerFile: { id } }) => id === customerFileId),
		[selectedCustomerFiles],
	);

	const columns: TableColumnType<CustomerFileType>[] = useMemo(
		() => [
			{
				align: 'left',
				flexGrow: 0,
				key: 'check',
				render: (customerFile) => (
					<Checkbox
						checked={getIsCheckboxEnabled({ customerFileId: customerFile.id })}
						onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
							handleCustomerFileSelection({ customerFile, checked })
						}
					/>
				),
				width: 80,
			},
			{
				field: 'code',
				flexGrow: 0,
				key: 'code',
				title: 'Code',
				render: ({ code }) => code || 'TOUS',
				width: 150,
			},
			{
				field: 'name',
				key: 'name',
				title: 'Nom',
				render: ({ name }) => name || 'TOUS',
				width: 200,
			},
			...(type === UserTypeEnum.INTERNAL
				? [
						{
							flexGrow: 0,
							key: 'group',
							title: 'Groupe',
							render: (customerFile: CustomerFileType) => (
								<Autocomplete<GroupType>
									dataSource={externalGroupsDataSource}
									getOptionLabel={({ name }) => name}
									onChange={(group) => handleGroupChange({ customerFile, group })}
									value={
										selectedCustomerFiles.some(
											({ customerFile: { id: selectedCustomerFileId } }) => customerFile.id === selectedCustomerFileId,
										)
											? selectedCustomerFiles.find(
													({ customerFile: { id: selectedCustomerFileId } }) =>
														customerFile.id === selectedCustomerFileId,
											  )?.group
											: defaultExternalGroup
									}
									placeholder="Groupe"
									sx={{
										width: '100%',
										'.MuiInputBase-root': {
											fontSize: '0.875rem',
											padding: `3.5px 32px 3.5px 8px !important`,
										},
									}}
									disableClearable
								/>
							),
							width: 200,
						},
				  ]
				: []),
		],
		[
			defaultExternalGroup,
			externalGroupsDataSource,
			getIsCheckboxEnabled,
			handleCustomerFileSelection,
			handleGroupChange,
			selectedCustomerFiles,
			type,
		],
	);

	return (
		<Table<CustomerFileType>
			table={tableInstance}
			columns={columns}
			dataSource={customerFileDataSource}
			style={{ minHeight: 250 }}
			title="Dossiers clients"
			enableSearch
		/>
	);
};

export default AddCustomerFilesTable;
