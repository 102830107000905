import { useCallback } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';

import { CustomerFileWithDomainType } from '../../../../../../types/customerFile';

import {
	FETCH_CUSTOMER_FILES_WITH_DOMAIN,
	FetchCustomerFilesWithDomainType,
} from '../../../../../../src/api/customerFile';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';

const columns: TableColumnType<CustomerFileWithDomainType>[] = [
	{
		field: 'code',
		flexGrow: 0,
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du dossier',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
		width: 110,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type CustomerFileTableProps = {
	onCustomerFileSelection?: (customerFile: CustomerFileWithDomainType) => void;
};

const CustomerFileTable = ({ onCustomerFileSelection }: CustomerFileTableProps) => {
	const [fetchCustomerFilesWithDomain] = useLazyQuery<FetchCustomerFilesWithDomainType>(
		FETCH_CUSTOMER_FILES_WITH_DOMAIN,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CustomerFileWithDomainType>,
		): Promise<{
			count: number;
			items: CustomerFileWithDomainType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchCustomerFilesWithDomain({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				customerFiles: { count = 0, items = [] },
			} = data ?? {
				customerFiles: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFilesWithDomain],
	);

	const handleRowClick = useCallback(
		(customerFile: CustomerFileWithDomainType) => onCustomerFileSelection?.(customerFile),
		[onCustomerFileSelection],
	);

	return (
		<SettingsTable<CustomerFileWithDomainType>
			addButtonLabel={null}
			dataSource={dataSource}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Dossiers clients"
		/>
	);
};

export default CustomerFileTable;
