import { gql } from '@apollo/client';
import { accounting_SearchAccountLevelResult } from '../types/account';
import { AnalyticalSectionByAccountLevelType } from '../types/analyticalSection';

export const ACCOUNTS_LEVEL_FIELDS = `
	analyticalSectionId
	accountId
	name
	code
	level
	creditAmount
	debitAmount
	balance
`;

export type FetchAccountingAnalyticalSectionByAccountLevel = {
	accounting_analyticalSectionByAccountLevels: {
		items: AnalyticalSectionByAccountLevelType[];
		totalBalance: string;
		totalCredit: string;
		totalDebit: string;
	};
};

export const ACCOUNTING_ANALYTICAL_SECTION_BY_ACCOUNT_LEVELS = gql`
	query Accounting_analyticalSectionByAccountLevels(
		$fiscalYearId: ID!
		$type: accounting_AccountingType!
		$level: Int!
		$accountingDate: BetweenDate
		$accountCode: String
		$parentCode: String
		$filter: accounting_AnalyticalSectionByAccountLevelFilterInput
	)
	{
		accounting_analyticalSectionByAccountLevels(
			accountingDate: $accountingDate
			fiscalYearId: $fiscalYearId
			type: $type
			level: $level
			accountCode: $accountCode
			parentCode: $parentCode
			filter: $filter
		) {
			items {
				${ACCOUNTS_LEVEL_FIELDS}
			}
			totalCredit
			totalDebit
			totalBalance
		}
	}
`;

export type FetchAccountingAccountsByAnalyticalSectionLevel = {
	accounting_accountsByAnalyticalSectionLevel: {
		items: AnalyticalSectionByAccountLevelType[];
		totalBalance: string;
		totalCredit: string;
		totalDebit: string;
	};
};

export const ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL = gql`
	query Accounting_accountsByAnalyticalSectionLevel(
		$fiscalYearId: ID!,
		$type: accounting_AccountingType!,
		$level: Float!,
		$analyticalDimensionId: ID!,
		$accountingDate: BetweenDate,
		$parentCode: String,
		$filter: accounting_AccountByAnalyticalSectionLevelFilterInput
	)
	{
		accounting_accountsByAnalyticalSectionLevel(
			fiscalYearId: $fiscalYearId
			type: $type
			level: $level
			analyticalDimensionId: $analyticalDimensionId
			accountingDate: $accountingDate
			parentCode: $parentCode
			filter: $filter
		) {
			items {
				${ACCOUNTS_LEVEL_FIELDS}
			}
			totalCredit
			totalDebit
			totalBalance
		}
	}
`;

export type FetchAccountingSearchAccountsByAnalyticalSectionLevelType = {
	accounting_searchAccountByAnalyticalSectionLevels: accounting_SearchAccountLevelResult[];
};

export const SEARCH_ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL = gql`
	query accounting_searchAccountByAnalyticalSectionLevels(
		$fiscalYearId: ID!
		$analyticalDimensionId: ID!
		$type: accounting_AccountingType!
		$search: String!
		$filter: accounting_AccountByAnalyticalSectionLevelFilterInput
		$accountingDate: BetweenDate
	) {
		accounting_searchAccountByAnalyticalSectionLevels(
			fiscalYearId: $fiscalYearId
			analyticalDimensionId: $analyticalDimensionId
			type: $type
			search: $search
			filter: $filter
			accountingDate: $accountingDate
		) {
			level0Code
			level1Code
		}
	}
`;

export type FetchAccountingSearchAnalyticalSectionByAccountLevelType = {
	accounting_searchAnalyticalSectionByAccountLevels: accounting_SearchAccountLevelResult[];
};

export const SEARCH_ANALYTICAL_SECTION_ACCOUNTS_BY_ACCOUNTS_LEVEL = gql`
	query accounting_searchAnalyticalSectionByAccountLevels(
		$fiscalYearId: ID!
		$type: accounting_AccountingType!
		$search: String!
		$filter: accounting_AnalyticalSectionByAccountLevelFilterInput
		$accountingDate: BetweenDate
	) {
		accounting_searchAnalyticalSectionByAccountLevels(
			fiscalYearId: $fiscalYearId
			type: $type
			search: $search
			filter: $filter
			accountingDate: $accountingDate
		) {
			level0Code
			level1Code
			level2Code
		}
	}
`;
