import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { CustomerFileType } from '../../../../../types/customerFile';
import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchSupplierMasterAccountsType, FETCH_SUPPLIER_MASTER_ACCOUNTS } from '../../../api/masterAccount';
import { AccountType } from '../../../types/account';

const useSupplierMasterAccountsDataSource = ({ customerFileId }: { customerFileId?: CustomerFileType['id'] }) => {
	const [fetchSupplierMasterAccounts, { data }] =
		useLazyQuery<FetchSupplierMasterAccountsType>(FETCH_SUPPLIER_MASTER_ACCOUNTS);

	const { supplierMasterAccounts } = data ?? {};
	const { count, items } = supplierMasterAccounts ?? {};

	const defaultValue = count === 1 ? items?.[0] : undefined;

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<DataSourceResultFormatType<Omit<AccountType, 'isMaster'>>> => {
			const { data: fetchedData, error: err } = await fetchSupplierMasterAccounts({
				variables: {
					page: {
						limit,
						offset,
					},
					customerFileId,
					search,
				},
			});

			if (err) {
				throw err;
			}

			const {
				supplierMasterAccounts: { count: supplierMasterAccountsCount = 0, items: supplierMasterAccountsItems = [] },
			} = fetchedData ?? {
				supplierMasterAccounts: {},
			};

			return {
				count: supplierMasterAccountsCount,
				items: supplierMasterAccountsItems,
			};
		},
		[fetchSupplierMasterAccounts, customerFileId],
	);

	return { count, dataSource, defaultValue };
};

export default useSupplierMasterAccountsDataSource;
