import { gql } from '@apollo/client';

import { JournalType } from '../types/journal';

export const JOURNAL_FIELDS = `
	id
	code
	name
`;

// QUERIES

export type FetchJournalsByCustomerFileType = {
	journalsByCustomerFile: {
		count: number;
		items: JournalType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_JOURNALS_BY_CUSTOMER_FILE = gql`
	query JournalsByCustomerFile($customerFileId: ID!, $search: String, $page: Page, $orderBy: OrderBy) {
		journalsByCustomerFile(customerFileId: $customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${JOURNAL_FIELDS}
			}
			limit
			offset
		}
	}
`;
