import {
	BaseSettingsItem,
	BaseSettingsItemProps,
	Button,
	Chip,
	CircularProgress,
	Icon,
	Select,
} from '@elipssolution/harfang';
import { mdiCheck, mdiClose } from '@mdi/js';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

const ActionWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),

	'& > button': {
		minWidth: 100,
	},
}));

const PermissionChip = styled(Chip)({
	width: '100%',

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
});

const IconWrapper = styled('div')(({ theme: { palette } }) => ({
	'&.authorized': {
		color: palette.success.main,
	},

	'&.rejected': {
		color: palette.error.main,
	},
}));

type PermissionOptionType = {
	inheritedValue?: boolean;
	label: string;
	value: string;
};

const basePermissionOptions = [
	{ value: 'authorized', label: 'Autorisé' },
	{ value: 'rejected', label: 'Refusé' },
];

const PermissionOptionChip = ({
	inheritedValue,
	label,
	value,
	withIcon,
}: PermissionOptionType & {
	withIcon?: boolean;
}) => {
	const chipColor = useMemo(() => {
		if (value === 'inherited') return 'default';

		return value === 'authorized' ? 'success' : 'error';
	}, [value]);

	const chipIcon = useMemo(() => {
		if (value === 'inherited') return <Icon path={inheritedValue ? mdiCheck : mdiClose} />;

		return <Icon path={value === 'authorized' ? mdiCheck : mdiClose} />;
	}, [inheritedValue, value]);

	return withIcon ? (
		<PermissionChip color={chipColor} icon={chipIcon} label={label} />
	) : (
		<PermissionChip color={chipColor} label={label} />
	);
};

export type SettingsItemPermissionProps = BaseSettingsItemProps & {
	/** The value of the `Select` when the right is inherited. */
	currentValue?: boolean;
	/** If `true`, the `Select` is disabled. */
	disabled?: boolean;
	/** If `true`, an option "Hérité" will be added to the select. */
	hasInheritance?: boolean;
	/** The value herited from the group. */
	inheritedValue?: boolean;
	/** If `true`, the component shows a loading indicator. */
	loading?: boolean;
	/** Callback triggered when a new value is selected. */
	onChange: (value: boolean | null) => void;
	/** The value of the `Select`. */
	value: boolean | null;
};

const SettingsItemPermission = ({
	currentValue,
	disabled,
	description,
	hasInheritance = false,
	icon,
	inheritedValue,
	label,
	loading,
	onChange,
	value,
	...rest
}: SettingsItemPermissionProps) => {
	const isInherited = useMemo(() => value === null, [value]);

	const permissionOptions: PermissionOptionType[] = useMemo(
		() => [
			...(hasInheritance ? [{ label: 'Hérité', value: 'inherited', inheritedValue }] : []),
			...basePermissionOptions,
		],
		[hasInheritance, inheritedValue],
	);

	const selectValue = useMemo(
		() =>
			value !== null
				? permissionOptions.find((option) => {
						if (isInherited) return option.value === 'inherited';

						return option.value === (value ? 'authorized' : 'rejected');
				  })
				: permissionOptions[0],
		[isInherited, permissionOptions, value],
	);

	const hasValue = useMemo(() => !!selectValue, [selectValue]);

	const handleChange = useCallback(
		(valueObject?: PermissionOptionType) =>
			valueObject?.value === 'inherited' ? onChange(null) : onChange(valueObject?.value === 'authorized'),
		[onChange],
	);

	const handleResetClick = useCallback(() => {
		currentValue !== undefined && onChange(currentValue === value ? null : currentValue);
	}, [currentValue, onChange, value]);

	const permissionIcon = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const permissionValue = isInherited ? inheritedValue : value;

		return (
			<IconWrapper className={clsx({ authorized: permissionValue, rejected: !permissionValue })}>
				<Icon path={permissionValue ? mdiCheck : mdiClose} />
			</IconWrapper>
		);
	}, [inheritedValue, isInherited, value]);

	return (
		<BaseSettingsItem
			description={description}
			icon={icon}
			label={label}
			actionWidth={hasInheritance && !disabled && !isInherited ? '288px !important' : ''}
		>
			<ActionWrapper>
				{hasInheritance && !disabled && !isInherited && (
					<Button onClick={handleResetClick} color="inherit">
						Réinitialiser
					</Button>
				)}
				{loading ? (
					<div>
						<CircularProgress />
					</div>
				) : (
					permissionIcon
				)}
				<Select<PermissionOptionType>
					{...rest}
					disabled={disabled}
					onChange={handleChange}
					options={permissionOptions}
					placeholder={hasValue ? undefined : 'Non défini'}
					renderOption={(option: PermissionOptionType) => <PermissionOptionChip {...option} withIcon />}
					renderValue={(option) => <PermissionOptionChip {...option} />}
					value={selectValue}
					disableClearable
				/>
			</ActionWrapper>
		</BaseSettingsItem>
	);
};

export default SettingsItemPermission;
