import { useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { DomainType } from '../../../../../../types/domain';
import {
	CreateDomainBillExchangeSettingCounterpartAccountType,
	CREATE_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
	FetchDomainBillExchangeSettingCounterpartAccountType,
	FETCH_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
} from '../../../../api/settingBillExchangeCounterpartAccount';
import { AccountType } from '../../../../types/account';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	accountCode: AccountType['code'];
};

type BillExchangeCounterpartProps = {
	domainId?: DomainType['id'];
};

const BillExchangeCounterpartAccount = ({ domainId }: BillExchangeCounterpartProps) => {
	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setValue,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();
	const [isCreateBillExchangeCounterpartAccountSucceeded, setIsCreateBillExchangeCounterpartAccountSucceeded] =
		useState(false);

	useQuery<FetchDomainBillExchangeSettingCounterpartAccountType>(
		FETCH_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
		{
			variables: {
				id: domainId,
			},
			onCompleted: ({ quickentry_settingDomainBillExchangeCounterpartAccount: { accountCode } }) => {
				if (accountCode) {
					setValue('accountCode', accountCode, { shouldDirty: true });
				}
			},
		},
	);

	const [createBillExchangeCounterpartAccount] = useMutation<CreateDomainBillExchangeSettingCounterpartAccountType>(
		CREATE_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT,
		{
			onCompleted: () => {
				setIsCreateBillExchangeCounterpartAccountSucceeded(true);
				setTimeout(() => setIsCreateBillExchangeCounterpartAccountSucceeded(false), 3000);
			},
			onError: (mutationError) => {
				setError(mutationError.message);
			},
		},
	);

	const onSubmit = ({ accountCode }: FormFieldType) =>
		createBillExchangeCounterpartAccount({
			variables: {
				createSettingDomainBillExchangeCounterpartAccountInput: {
					accountCode,
					domainId,
				},
			},
		});

	return (
		<SettingsDialogPage title="Contrepartie LCR">
			<SettingsGroup>
				<Controller
					name="accountCode"
					control={control}
					rules={{
						pattern: {
							value: /^5/,
							message: 'Le code du compte est incorrect',
						},
						required: true,
					}}
					render={({ field }) => <SettingsItemTextField {...field} label="Compte de contrepartie" required />}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateBillExchangeCounterpartAccountSucceeded ? 'LCR validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default BillExchangeCounterpartAccount;
