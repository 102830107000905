import { useLazyQuery } from '@apollo/client';
import { Chip, IconButton, Menu, TableColumnType, TableOrderByType, Tooltip } from '@elipssolution/harfang';
import { mdiDotsVertical } from '@mdi/js';
import Icon from '@mdi/react';
import { MenuItem, Stack, styled, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import ExternalUser from './externalUser/ExternalUser';
import LinkIcon from '../../../components/LinkIcon';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../types/customerFile';
import { PermissionEnum } from '../../../types/permission';
import {
	FETCH_EXTERNAL_USERS_BY_USER_CUSTOMER_FILES,
	FetchExternalUsersByUserCustomerFilesType,
} from '../../api/externalUser';
import { useSession } from '../../components/SessionProvider';
import { ExternalUserType } from '../../types/user';

const TooltipCustomerFilesWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: spacing(0.5),

	fontWeight: 400,

	'& > div': {
		minHeight: 24,
		height: 'fit-content',
		paddingTop: spacing(0.5),
		paddingBottom: spacing(0.5),

		'& > span': {
			whiteSpace: 'wrap',
		},
	},
}));

const MeasureChip = styled(Chip)({
	position: 'absolute',
	visibility: 'hidden',
	zIndex: -1,
});

const CustomerFilesWrapper = ({
	columnWidth,
	customerFiles,
}: {
	columnWidth: number;
	customerFiles: CustomerFileType[];
}) => {
	const { spacing } = useTheme();

	const [visibleCustomerFiles, setVisibleCustomerFiles] = useState<CustomerFileType[]>();

	const measureChipRef = useRef<HTMLDivElement>(null);
	const measureChipElement = measureChipRef.current;
	const measureChipSpan = measureChipElement?.children[0];
	const moreCustomerFilesQuantity = visibleCustomerFiles ? customerFiles.length - visibleCustomerFiles.length : 0;
	const sortedCustomerFiles = customerFiles.sort((a, b) => (a.name.length >= b.name.length ? 1 : -1));

	useEffect(() => {
		if (!measureChipElement || !measureChipSpan) return;

		let maxCustomerFiles = 0;
		let currentWidth = 31;

		sortedCustomerFiles.every(({ name }) => {
			measureChipSpan.textContent = name;
			const chipWidth = measureChipElement.offsetWidth + +spacing(1).slice(0, -2);

			if (currentWidth + chipWidth < columnWidth) {
				currentWidth += chipWidth;
				maxCustomerFiles += 1;

				return true;
			}

			return false;
		});

		setVisibleCustomerFiles(sortedCustomerFiles.slice(0, maxCustomerFiles));
	}, [spacing, measureChipElement, measureChipSpan, columnWidth, sortedCustomerFiles]);

	return (
		<>
			{visibleCustomerFiles?.map(({ id, name }) => (
				<Chip key={id} label={name} />
			))}

			{moreCustomerFilesQuantity > 0 && (
				<Tooltip
					content={
						<TooltipCustomerFilesWrapper>
							{sortedCustomerFiles.slice(-moreCustomerFilesQuantity).map(({ id, name }) => (
								<Chip key={id} label={name} size="small" />
							))}
						</TooltipCustomerFilesWrapper>
					}
				>
					<Chip label={`+${moreCustomerFilesQuantity}`} size="small" />
				</Tooltip>
			)}
			<MeasureChip ref={measureChipRef} />
		</>
	);
};

const Options = ({ user }: { user: ExternalUserType }) => {
	const { impersonate } = useSession();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

	return (
		<>
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					setAnchorEl(event.currentTarget);
				}}
			>
				<Icon path={mdiDotsVertical} size="24px" />
			</IconButton>

			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={(event: MouseEvent) => {
					event.stopPropagation();
					setAnchorEl(undefined);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<MenuItem
					onClick={() => {
						impersonate({ id: user.id, email: user.email, lastName: user.lastName, firstName: user.firstName }).catch(
							(error) => {
								throw error;
							},
						);
					}}
				>
					Voir en tant que
				</MenuItem>
			</Menu>
		</>
	);
};

const ExternalUsersTableSection = () => {
	const { push } = useSettingsDialog();
	const { checkPermission, impersonatedUser } = useSession();

	const columns: TableColumnType<ExternalUserType>[] = [
		{
			field: 'lastName',
			key: 'name',
			render: ({ firstName, lastName }) => `${lastName} ${firstName ?? ''}`.trim(),
			sortable: true,
			title: "Nom de l'utilisateur",
			width: 200,
		},
		{
			field: 'email',
			key: 'email',
			sortable: true,
			title: 'Email',
			width: 200,
		},
		{
			key: 'customerFiles',
			render: ({ customerFiles }) =>
				customerFiles &&
				customerFiles?.length > 0 && (
					<ReactResizeDetector handleWidth>
						{({ width }) => (
							<Stack alignItems="center" direction="row" gap={1} overflow="hidden" width="100%">
								<CustomerFilesWrapper columnWidth={width ?? 0} customerFiles={customerFiles} />
							</Stack>
						)}
					</ReactResizeDetector>
				),
			title: 'Dossiers',
			width: 200,
		},
		...(!impersonatedUser && checkPermission(PermissionEnum.IMPERSONATE)
			? [
					{
						key: 'options',
						flexGrow: 0,
						width: 40,
						render: (user: ExternalUserType) => <Options user={user} />,
					},
			  ]
			: []),

		{
			key: 'actions',
			flexGrow: 0,
			render: () => <LinkIcon />,
			width: 40,
		},
	];
	const [fetchUsersByUserCustomerFiles] = useLazyQuery<FetchExternalUsersByUserCustomerFilesType>(
		FETCH_EXTERNAL_USERS_BY_USER_CUSTOMER_FILES,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<ExternalUserType>,
		): Promise<{
			count: number;
			items: ExternalUserType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchUsersByUserCustomerFiles({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				externalUsersByUserCustomerFiles: { count = 0, items = [] },
			} = data ?? {
				externalUsersByUserCustomerFiles: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchUsersByUserCustomerFiles],
	);

	const openUserPage = (user?: ExternalUserType) => {
		push(<ExternalUser user={user} />);
	};

	return (
		<SettingsTable<ExternalUserType>
			addButtonLabel="Ajout d'utilisateurs"
			dataSource={dataSource}
			onAddButtonClick={openUserPage}
			onRowClick={openUserPage}
			tableColumns={columns}
			title="Utilisateurs"
		/>
	);
};

export default ExternalUsersTableSection;
