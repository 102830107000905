import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FetchCustomerFileTemplatesType, FETCH_CUSTOMER_FILE_TEMPLATES } from '../../../api/template';
import { CustomerFileTemplateType } from '../../../types/template';

const useTemplateDataSource = () => {
	const [fetchTemplates] = useLazyQuery<FetchCustomerFileTemplatesType>(FETCH_CUSTOMER_FILE_TEMPLATES);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: CustomerFileTemplateType[];
		}> => {
			const { data, error } = await fetchTemplates({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_customerFileTemplates: { count = 0, items = [] },
			} = data ?? {
				quickentry_customerFileTemplates: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchTemplates],
	);

	return { dataSource };
};

export default useTemplateDataSource;
