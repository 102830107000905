import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import { useMemo, useState } from 'react';

import ExternalGroupForm from './ExternalGroupForm';
import ExternalGroupPermissionModules from './ExternalGroupPermissionModules';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_EXTERNAL_GROUP, FetchExternalGroupType } from '../../../api/externalGroup';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import { GroupPermissionType, PermissionModuleType } from '../../../types/permission';

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

type ExternalGroupProps = {
	group?: GroupType;
};

const ExternalGroup = ({ group: initialGroup }: ExternalGroupProps) => {
	const { back } = useSettingsDialog();
	const { refetch: refetchSession } = useSession();

	const [group, setGroup] = useState(initialGroup);
	const [error, setError] = useState<string>();

	const { id: groupId, name, isAdministrator = false } = group ?? {};

	const { data, loading } = useQuery<FetchExternalGroupType>(FETCH_EXTERNAL_GROUP, {
		skip: !groupId,
		variables: {
			id: groupId,
		},
		onError: (fetchError) =>
			setError(generateErrorInformations({ error: fetchError, resource: 'externalGroup' }).message),
	});

	const { permissionModules = [] } = useMemo(() => {
		const { externalGroup } = data ?? {};

		return (
			externalGroup ?? {
				isAdministrator: false,
				isDefault: false,
				name: undefined,
				permissionModules: [] as PermissionModuleType<GroupPermissionType>[],
			}
		);
	}, [data]);

	return (
		<SettingsDialogPage title={name ?? "Ajout d'un groupe"}>
			<ExternalGroupForm
				group={group}
				isFetchLoading={loading}
				onError={setError}
				onReplaceNeeded={setGroup}
				onRemoval={back}
			/>
			<ExternalGroupPermissionModules
				loading={loading}
				permissionModules={permissionModules}
				disabled={isAdministrator}
				onPermissionChanged={() => {
					refetchSession().catch((e) => {
						throw e;
					});
				}}
			/>

			{error && <ErrorWrapper>{error}</ErrorWrapper>}
		</SettingsDialogPage>
	);
};

export default ExternalGroup;
