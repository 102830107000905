import { Chip, Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo, MouseEvent } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { useSession } from '../../../components/SessionProvider';
import { AddExternalUserRelationType, ExternalUserType, UserTypeEnum } from '../../../types/user';
import UserCustomerFileRelation from '../userCustomerFileRelation/UserCustomerFileRelation';

type AddedCustomerFileUsersTableSectionProps = {
	customerFile: CustomerFileType;
	users: AddExternalUserRelationType[];
	onRemoveUser: (userId: AddExternalUserRelationType['user']['id']) => Promise<void>;
};

const AddedCustomerFileUsersTableSection = ({
	customerFile,
	users,
	onRemoveUser,
}: AddedCustomerFileUsersTableSectionProps) => {
	const { push } = useSettingsDialog();
	const {
		user: { type },
	} = useSession();

	const removeUser = useCallback(
		async (
			event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
			userId: AddExternalUserRelationType['user']['id'],
		) => {
			event.stopPropagation();
			await onRemoveUser(userId);
		},
		[onRemoveUser],
	);

	const columns: TableColumnType<AddExternalUserRelationType>[] = useMemo(
		() => [
			{
				key: 'name',
				render: ({ user: { firstName, lastName } }) => `${lastName} ${firstName ?? ''}`.trim(),
				title: 'Nom',
				width: 200,
			},
			{
				key: 'email',
				render: ({ user: { email } }) => email,
				title: 'Email',
				width: 200,
			},
			...(type === UserTypeEnum.INTERNAL
				? [
						{
							flexGrow: 0,
							key: 'group',
							render: ({ group }: AddExternalUserRelationType) => {
								const { isAdministrator, isDefault, name } = group || {};

								return isAdministrator ? (
									<Chip label={name} color="error" />
								) : (
									<Chip label={name} color={isDefault ? 'info' : 'default'} />
								);
							},
							title: 'Groupe',
							width: 150,
						},
				  ]
				: []),
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ user: { id } }) => (
					<Tooltip content="Retire la liaison entre l'utilisateur et le dossier">
						<IconButton onClick={(event) => removeUser(event, id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
			{
				key: 'navigate',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[removeUser, type],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: AddExternalUserRelationType[];
		}> =>
			Promise.resolve({
				items: users,
				count: users.length,
			}),
		[users],
	);

	const handleRowClick = useCallback(
		({ user }: AddExternalUserRelationType) =>
			push(<UserCustomerFileRelation user={user as ExternalUserType} initialCustomerFile={customerFile} />),
		[customerFile, push],
	);

	return (
		<SettingsTable
			dataSource={dataSource}
			enableSearch={false}
			tableColumns={columns}
			title="Utilisateurs ajoutés"
			onRowClick={handleRowClick}
		/>
	);
};

export default AddedCustomerFileUsersTableSection;
