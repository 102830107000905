import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { BankType } from '../types/bank';

// QUERIES

export type FetchBanksType = {
	quickentry_banks: {
		count: number;
		items: BankType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_BANKS = gql`
	query Quickentry_banks($search: String, $page: Page!, $orderBy: OrderBy) {
		quickentry_banks(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				id
				name
			}
			limit
			offset
		}
	}
`;

export type FetchBankType = {
	quickentry_bank: Required<BankType>;
};

export const FETCH_BANK = gql`
	query Quickentry_bank($id: ID!) {
		quickentry_bank(id: $id) {
			id
			name
			account {
				${ACCOUNT_FIELDS}
			}
		}
	}
`;
