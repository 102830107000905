import { CurrencyFilterDefinitionType, useTreeTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import AnalyticalSectionsTable from './AnalyticalSectionsTable';
import AnalyticalSectionsToggles from './AnalyticalSectionsToggles';
import { useSession } from '../../../../src/components/SessionProvider';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { convertFiscalYearDates } from '../../../../utils/convertDates';
import { AnalyticalType } from '../../../quickentry/types/analytical';
import { AccountingType } from '../../types/accounting';
import { SwitchOperatorType } from '../../types/table';

const AnalyticalSections = () => {
	const { events, push } = useRouter();
	const { permissions } = useSession();
	const tableInstance = useTreeTable();

	const {
		balanceFilter: queryBalanceFilter,
		selectedFiscalYear: querySelectedFiscalYear,
		selectedAnalyticalDimensionId: querySelectedAnalyticalDimensionId,
		selectedDateRange: querySelectedDateRange,
		accountingType: queryAccountingType,
		isGroupedByAccount: isQueryGroupedByAccount,
		expandedLevels: queryExpandedLevels,
	} = useMemo(() => {
		const storedFilters = globalThis.sessionStorage.getItem('analyticalSectionsFilters');

		if (storedFilters) {
			const {
				accountingType,
				balanceFilter,
				expandedLevels,
				isGroupedByAccount,
				selectedAnalyticalDimensionId,
				selectedDateRange,
				selectedFiscalYear,
			} = JSON.parse(storedFilters) as {
				accountingType?: AccountingType;
				balanceFilter?: CurrencyFilterDefinitionType['value'];
				expandedLevels: string[][];
				isGroupedByAccount: boolean;
				selectedAnalyticalDimensionId?: AnalyticalType;
				selectedDateRange?: [Date, Date];
				selectedFiscalYear?: FiscalYearType;
			};

			return {
				selectedFiscalYear: selectedFiscalYear ? convertFiscalYearDates(selectedFiscalYear) : undefined,
				selectedDateRange: selectedDateRange?.map((item) => new Date(item)) as [Date, Date],
				accountingType,
				balanceFilter,
				expandedLevels,
				isGroupedByAccount,
				selectedAnalyticalDimensionId,
			};
		}

		return {};
	}, []);

	const [expandedLevels, setExpandedLevels] = useState<string[][]>(queryExpandedLevels || []);
	const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYearType | undefined>(querySelectedFiscalYear);
	const [selectedAnalyticalDimension, setSelectedAnalyticalDimension] = useState<AnalyticalType | undefined>(
		querySelectedAnalyticalDimensionId,
	);
	const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date] | undefined>(querySelectedDateRange);
	const [balanceFilter, setBalanceFilter] = useState<CurrencyFilterDefinitionType['value']>(queryBalanceFilter);
	const [accountingType, setAccountingType] = useState<AccountingType>(
		queryAccountingType ?? AccountingType.INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT,
	);
	const [isGroupedByAccount, setIsGroupedByAccount] = useState(isQueryGroupedByAccount ?? true);

	const handleBalanceFilterOperatorChange = (operator: SwitchOperatorType) =>
		setBalanceFilter((prevBalanceFilter) => (prevBalanceFilter?.[operator] === '0.00' ? null : { [operator]: '0.00' }));

	const handleChangeAccountingType = (type: AccountingType) => setAccountingType(type);

	const handleGroupedByAccountChange = (groupedByAccount: boolean) => {
		setIsGroupedByAccount(groupedByAccount);
		setExpandedLevels([]);
	};

	useEffect(() => {
		const hasUserPermissions = permissions.find(({ code }) => code === 'accounting-read')?.value;

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	useEffect(() => {
		globalThis.sessionStorage.setItem(
			'analyticalSectionsFilters',
			JSON.stringify({
				accountingType,
				balanceFilter,
				expandedLevels,
				isGroupedByAccount,
				selectedDateRange,
				selectedFiscalYear,
			}),
		);
	}, [accountingType, balanceFilter, selectedDateRange, selectedFiscalYear, isGroupedByAccount, expandedLevels]);

	useEffect(() => {
		events.on('routeChangeStart', (newRoute: string) => {
			!newRoute.startsWith('/accounting/analyticalSections') &&
				globalThis.sessionStorage.removeItem('analyticalSectionsFilters');
		});
	}, [events]);

	return (
		<Stack height="100%" width="100%" gap={4} flexDirection="row">
			<AnalyticalSectionsToggles
				accountingType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				selectedAnalyticalDimension={selectedAnalyticalDimension}
				selectedDateRange={selectedDateRange}
				balanceFilter={balanceFilter}
				isGroupedByAccount={isGroupedByAccount}
				onAccountingTypeChange={handleChangeAccountingType}
				onFiscalYearChange={setSelectedFiscalYear}
				onAnalyticalDimensionChange={setSelectedAnalyticalDimension}
				onDateRangeSelection={setSelectedDateRange}
				onBalanceFilterOperatorChange={handleBalanceFilterOperatorChange}
				onIsGroupedByAccountChange={handleGroupedByAccountChange}
				tableInstance={tableInstance}
			/>

			<AnalyticalSectionsTable
				isGroupedByAccount={isGroupedByAccount}
				accountType={accountingType}
				selectedFiscalYear={selectedFiscalYear}
				selectedAnalyticalDimension={selectedAnalyticalDimension}
				selectedDateRange={selectedDateRange}
				balanceFilter={balanceFilter}
				onBalanceFilterChange={setBalanceFilter}
				expandedLevels={expandedLevels}
				onToggleRows={setExpandedLevels}
				tableInstance={tableInstance}
			/>
		</Stack>
	);
};

export default AnalyticalSections;
