import { ButtonSwitch } from '@elipssolution/harfang';
import { AccountingType } from '../types/accounting';

const ACCOUNTING_TYPES = [
	{
		id: AccountingType.BALANCE_SHEET,
		label: 'Bilan',
	},
	{
		id: AccountingType.INCOME_STATEMENT_PROFIT_AND_LOSS_ACCOUNT,
		label: 'Résultat',
	},
];

const isAccountingType = (string: string): string is AccountingType => string in AccountingType;

type AccountingTypeSelectorProps = {
	accountingType: AccountingType;
	onAccountingTypeChange: (filter: AccountingType) => void;
};

const AccountingTypeSelector = ({ onAccountingTypeChange, accountingType }: AccountingTypeSelectorProps) => {
	const handleButtonClick = (value: string) => {
		isAccountingType(value) && onAccountingTypeChange(value);
	};

	return <ButtonSwitch items={ACCOUNTING_TYPES} onClick={handleButtonClick} selectedItem={accountingType as string} />;
};
export default AccountingTypeSelector;
