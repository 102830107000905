import { useLazyQuery } from '@apollo/client';
import { Button, SettingsItemTextField } from '@elipssolution/harfang';
import { Typography, styled } from '@mui/material';
import { FocusEvent, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { Control, Controller, UseFormClearErrors, UseFormSetError } from 'react-hook-form';

import {
	CHECK_SETTING_DOMAIN_TAG_ARCHIVED,
	CHECK_SETTING_DOMAIN_TAG_NAME_AVAILABILITY,
	CheckSettingDomainTagArchivedType,
	CheckSettingDomainTagNameAvailabilityType,
} from '../../../../api/settingDomainTag';
import { SettingTagByDomainType } from '../../../../types/settingTag';
import { TagType } from '../../../../types/tag';

const ArchivedTagTooltipDiv = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: spacing(1),

	'& > div': {
		display: 'flex',
		justifyContent: 'flex-end',

		gap: spacing(1),
		marginTop: spacing(2),
	},
}));

type ArchivedTagTooltipProps = {
	archivedTagId: TagType['id'];
	onTooltipContentSet: (tooltipContent: SetStateAction<ReactNode>) => void;
	onTagIdSet: (tagArchivedId: TagType['id']) => void;
};

const ArchivedTagTooltip = ({ archivedTagId, onTooltipContentSet, onTagIdSet }: ArchivedTagTooltipProps) => {
	const closeTooltip = () => onTooltipContentSet(undefined);
	const applyTooltipActions = () => {
		onTagIdSet(archivedTagId);
		closeTooltip();
	};

	return (
		<ArchivedTagTooltipDiv>
			<Typography>Un tag portant le même nom a été trouvé dans les tags archivés.</Typography>
			<Typography>Voulez-vous le réactiver ?</Typography>
			<div>
				<Button onClick={closeTooltip}>Annuler</Button>
				<Button onClick={applyTooltipActions} variant="contained">
					Réactiver
				</Button>
			</div>
		</ArchivedTagTooltipDiv>
	);
};

let availabilityTimer: ReturnType<typeof setTimeout>;

type FormFieldType = Pick<SettingTagByDomainType, 'color' | 'isEnabled' | 'name' | 'storageUpload'>;

type SettingDomainTagNameFieldProps = {
	clearErrors: UseFormClearErrors<FormFieldType>;
	control: Control<FormFieldType>;
	domainId: string;
	initialValue?: string;
	onApplyArchivedTag: (tagArchivedId: string) => void;
	setError: UseFormSetError<FormFieldType>;
	tagId?: string | null;
};

const SettingDomainTagNameField = ({
	clearErrors,
	control,
	domainId,
	initialValue,
	onApplyArchivedTag,
	setError,
	tagId,
}: SettingDomainTagNameFieldProps) => {
	const [nameFieldTooltipContent, setNameFieldTooltipContent] = useState<ReactNode>();

	const [checkSettingDomainTagNameAvailability] = useLazyQuery<CheckSettingDomainTagNameAvailabilityType>(
		CHECK_SETTING_DOMAIN_TAG_NAME_AVAILABILITY,
	);

	const [checkIfSettingDomainTagArchived, { loading: isCheckSettingDomainTagArchivedLoading }] =
		useLazyQuery<CheckSettingDomainTagArchivedType>(CHECK_SETTING_DOMAIN_TAG_ARCHIVED, {
			onCompleted: ({ document_checkSettingDomainTagArchived: { tagId: archivedTagId } }) =>
				archivedTagId &&
				setNameFieldTooltipContent(
					<ArchivedTagTooltip
						archivedTagId={archivedTagId}
						onTagIdSet={onApplyArchivedTag}
						onTooltipContentSet={setNameFieldTooltipContent}
					/>,
				),
		});

	const checkDomainTagNameAvailability = useCallback(
		(domainTagName: string) => {
			clearTimeout(availabilityTimer);

			if (initialValue === domainTagName) {
				clearErrors('name');
				return true;
			}

			availabilityTimer = setTimeout(() => {
				checkSettingDomainTagNameAvailability({ variables: { domainId, name: domainTagName } })
					.then(({ data }) => {
						const { document_checkDomainTagNameAvailability: isDomainTagNameAvailable } = data || {};

						!isDomainTagNameAvailable && setError('name', { message: 'Nom du tag domaine déjà utilisé' });

						return isDomainTagNameAvailable;
					})
					.catch((e) => {
						throw e;
					});
			}, 300);

			return undefined;
		},
		[initialValue, clearErrors, checkSettingDomainTagNameAvailability, domainId, setError],
	);

	const checkSettingDomainTagArchived = useCallback(
		({ target: { value: name } }: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
			!tagId &&
				name &&
				checkIfSettingDomainTagArchived({
					variables: {
						name,
					},
				}).catch((e) => {
					throw e;
				});
		},
		[checkIfSettingDomainTagArchived, tagId],
	);

	return (
		<Controller
			control={control}
			name="name"
			render={({ field: { onBlur, onChange, ...field }, fieldState: { error } }) => {
				const handleChange = (value?: string) => {
					clearErrors('name');

					value && checkDomainTagNameAvailability(value);

					onChange(value);
				};

				return (
					<SettingsItemTextField
						{...field}
						description="Nom du tag domaine."
						disabled={isCheckSettingDomainTagArchivedLoading}
						helperText={error?.message ?? ' '}
						invalid={!!error}
						label="Nom"
						onBlur={checkSettingDomainTagArchived}
						onChange={handleChange}
						tooltipContent={nameFieldTooltipContent}
						required
					/>
				);
			}}
			rules={{
				pattern: {
					message: "Le nom du tag doit être constitué d'un ou plusieurs mots séparés par des points et/ou des espaces.",
					value: /^(\w+\.?\s?)*\w+$/,
				},
				required: 'Champ requis',
				validate: checkDomainTagNameAvailability,
			}}
		/>
	);
};

export default SettingDomainTagNameField;
