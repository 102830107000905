import { AccountType, AccountTypeEnum } from './account';
import { AnalyticalType } from './analytical';
import { ErrorType } from './error';
import { JournalType } from './journal';
import { PaymentModeType } from './paymentMode';

export enum DirectionEnum {
	CREDIT = 'CREDIT',
	DEBIT = 'DEBIT',
}

export enum StateEnum {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
}

// TEMPLATE LINES

export type SettingTemplateLineType = {
	id: string;
	defaultTransactionName?: string;
	direction: DirectionEnum;
	isAnalyticalEnabled: boolean;
	name: string;
	rank: number;
	subaccountPrefix?: string;
	type: AccountTypeEnum;
};

export type SettingCustomerFileTemplateLineType = SettingTemplateLineType & {
	account?: AccountType;
	subaccount?: AccountType;
	analyticalSection?: AnalyticalType;
};

export type SettingCustomerFileTemplateLineWithErrorFieldsType = SettingTemplateLineType & {
	account?: { data: AccountType; error?: ErrorType };
	subaccount?: { data: AccountType; error?: ErrorType };
	analyticalSection?: { data: AccountType; error?: ErrorType };
};

export type SettingCustomerFileTemplateLineMutationType = Omit<SettingTemplateLineType, 'id'> & {
	accountId?: AccountType['id'];
	subaccountId?: AccountType['id'];
	analyticalSectionId: AnalyticalType['id'];
};

export type SettingDomainTemplateLineType = SettingTemplateLineType & {
	accountCode?: string;
	subaccountCode?: string;
	analyticalSectionCode?: string;
};

export type SettingDomainTemplateLineMutationType = Omit<SettingDomainTemplateLineType, 'id'>;

export type SettingTemplateType = {
	id?: string;
	name: string;
	defaultEntryName: string;
	validUntil?: string;
	analyticalSectionCaption?: string;
	state: StateEnum;
};

export type SettingDomainTemplateType = SettingTemplateType & {
	journalCode: string;
	paymentModeCode?: string;
	analyticalDimensionCode?: string;
	templateLines: SettingDomainTemplateLineType[];
};

export type SettingDomainTemplateFormType = Omit<SettingDomainTemplateType, 'id' | 'validUntil'> & {
	validUntil?: Date;
};

export type SettingCustomerFileTemplateType = SettingTemplateType & {
	journal: JournalType;
	paymentMode: PaymentModeType;
	analyticalDimension?: AnalyticalType;
	isInherited: boolean;
	isOverload: boolean;
	templateLines: SettingCustomerFileTemplateLineType[];
};
export type TemplateLineErrorMessageType = {
	account?: ErrorType['errorMessage'];
	subaccount?: ErrorType['errorMessage'];
	analyticalSection?: ErrorType['errorMessage'];
};
export type SettingCustomerFileTemplateWithErrorFieldsType = Pick<
	SettingCustomerFileTemplateType,
	'isInherited' | 'isOverload'
> &
	SettingTemplateType & {
		journal: { data: JournalType; error?: ErrorType };
		paymentMode: { data: PaymentModeType; error?: ErrorType };
		analyticalDimension?: { data: AnalyticalType; error?: ErrorType };
		templateLines: SettingCustomerFileTemplateLineWithErrorFieldsType[];
	};

export type SettingCustomerFileTemplateFormType = Omit<SettingCustomerFileTemplateType, 'id' | 'validUntil'> & {
	validUntil?: Date;
};

export type SettingCustomerFileTemplateMutationType = Omit<
	SettingCustomerFileTemplateType,
	'journal' | 'paymentMode' | 'analyticalDimension' | 'templateLines'
> & {
	analyticalDimensionId?: AnalyticalType['id'];
	journalId: JournalType['id'];
	paymentModeId?: PaymentModeType['id'];
	templateLines: SettingCustomerFileTemplateLineMutationType[];
};
