import { gql } from '@apollo/client';

import { SettingTagByDomainType } from '../types/settingTag';

import { BASE_STORAGE_UPLOAD_FIELDS } from './settingStorageUpload';
import { DOCUMENT_UPLOAD_TAG_FIELDS } from './tag';

const BASE_SETTING_TAG_BY_DOMAIN_FIELDS = `
	color
	id
	isEnabled
	name
	info
`;

const STORAGE_UPLOAD_FIELDS = `
	${BASE_STORAGE_UPLOAD_FIELDS}
	tags {
		${DOCUMENT_UPLOAD_TAG_FIELDS}
	}
`;

// QUERIES

export type FetchSettingTagsByDomainType = {
	document_settingTagsByDomain: {
		items: SettingTagByDomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_TAGS_BY_DOMAIN = gql`
	query Document_settingTagsByDomain($domainId: ID!, $page: Page!, $search: String) {
		document_settingTagsByDomain(domainId: $domainId, page: $page, search: $search) {
			count
			items {
				${BASE_SETTING_TAG_BY_DOMAIN_FIELDS}
				storageUpload {
					id
					name
				}
			}
			limit
			offset
		}
	}
`;

export type FetchSettingDomainTagType = {
	document_settingDomainTag: SettingTagByDomainType;
};

export const FETCH_SETTING_DOMAIN_TAG = gql`
	query Document_settingDomainTag($settingDomainTagId: ID!) {
		document_settingDomainTag(id: $settingDomainTagId) {
			${BASE_SETTING_TAG_BY_DOMAIN_FIELDS}
			storageUpload {
				${STORAGE_UPLOAD_FIELDS}
			}
		}
	}
`;

export type CheckSettingDomainTagArchivedType = {
	document_checkSettingDomainTagArchived: {
		isArchived: boolean;
		tagId: string;
	};
};

export const CHECK_SETTING_DOMAIN_TAG_ARCHIVED = gql`
	query Document_checkSettingDomainTagArchived($name: String!) {
		document_checkSettingDomainTagArchived(name: $name) {
			isArchived
			tagId
		}
	}
`;

export type CheckSettingDomainTagNameAvailabilityType = {
	document_checkDomainTagNameAvailability: boolean;
};

export const CHECK_SETTING_DOMAIN_TAG_NAME_AVAILABILITY = gql`
	query Document_checkDomainTagNameAvailability($name: String!, $domainId: String!) {
		document_checkDomainTagNameAvailability(name: $name, domainId: $domainId)
	}
`;

// MUTATIONS

export type CreateSettingDomainTagType = {
	document_createSettingDomainTag: {
		id: string;
	};
};

export const CREATE_SETTING_DOMAIN_TAG = gql`
	mutation Document_createSettingDomainTag($createSettingDomainTagInput: document_CreateSettingDomainTagInput!) {
		document_createSettingDomainTag(createSettingDomainTagInput: $createSettingDomainTagInput) {
			id
		}
	}
`;

export type UpdateSettingDomainTagType = {
	document_updateSettingDomainTag: Pick<SettingTagByDomainType, 'id'>;
};

export const UPDATE_SETTING_DOMAIN_TAG = gql`
	mutation Document_updateSettingDomainTag($updateSettingDomainTagInput: document_UpdateSettingDomainTagInput!) {
		document_updateSettingDomainTag(updateSettingDomainTagInput: $updateSettingDomainTagInput) {
			id
		}
	}
`;

export const REMOVE_SETTING_DOMAIN_TAG = gql`
	mutation Document_removeSettingDomainTag($removeSettingDomainTagId: ID!) {
		document_removeSettingDomainTag(id: $removeSettingDomainTagId) {
			id
		}
	}
`;
