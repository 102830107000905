import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useApollo } from './ApolloProvider';
import SplashScreen from './SplashScreen';
import { CustomerFileType } from '../../types/customerFile';
import { PermissionEnum } from '../../types/permission';
import { FETCH_CUSTOMER_FILE_BY_CODE, FetchCustomerFileByCodeType } from '../api/customerFile';
import { FETCH_MODULES, FetchModulesType } from '../api/module';
import { FETCH_SESSION, FetchSessionType } from '../api/session';
import { SessionPermissionType, SessionUserType } from '../types/session';
import { UserType } from '../types/user';
import useLocalStorage from '../utils/useLocalStorage';

export type ImpersonatedUserType = Pick<UserType, 'id' | 'email' | 'lastName' | 'firstName'>;

export type SessionContextType = {
	user: SessionUserType;
	customerFile?: CustomerFileType;
	permissions: SessionPermissionType[];
	impersonatedUser?: ImpersonatedUserType;
	checkPermission: (code: PermissionEnum) => boolean;
	changeCustomerFile: (customerFile: CustomerFileType) => void;
	refetch: () => Promise<void>;
	impersonate: (user?: ImpersonatedUserType) => Promise<void>;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider = ({ children }: { children: JSX.Element }) => {
	const {
		customerFileId: apolloCustomerFileId,
		setCustomerFileId: setApolloCustomerFileId,
		setUserId: setApolloUserId,
	} = useApollo();

	const router = useRouter();
	const { replace, push, pathname, query } = router;
	const { dossier: queryCustomerFileCode } = query || {};

	const routerRef = useRef(router);
	routerRef.current = router;

	const [localStorageCustomerFileCode, setLocalStorageCustomerFileCode] = useLocalStorage<
		CustomerFileType['code'] | undefined
	>('sessionCustomerFileCode', undefined);

	const [isImpersonateLoading, setImpersonateLoading] = useState(false);
	const [impersonatedUser, setImpersonatedUser] = useState<ImpersonatedUserType>();
	const [sessionCustomerFile, setSessionCustomerFile] = useState<CustomerFileType>();

	const isOnErrorPage = pathname.startsWith('/error');

	const { loading } = useQuery<FetchModulesType>(FETCH_MODULES, {
		onCompleted: ({ modules: fetchedModules }) => {
			if (pathname !== '/' && !isOnErrorPage) {
				const isModuleAvailable = fetchedModules.some(({ code }) => pathname.includes(code));

				if (!isModuleAvailable) {
					push('/error/403').catch((e) => {
						throw e;
					});
				}
			}
		},
		skip: !apolloCustomerFileId || isOnErrorPage,
	});

	const updateCustomerFileStorages = useCallback(
		(customerFile?: CustomerFileType) => {
			if (!customerFile) {
				setSessionCustomerFile(undefined);
				setLocalStorageCustomerFileCode(undefined);

				const updatedQuery = { ...query };
				delete updatedQuery.dossier;
				replace({
					query: updatedQuery,
				}).catch((error) => {
					throw error;
				});
			}

			const { id: customerFileId, code: customerFileCode } = customerFile ?? {};

			if (localStorageCustomerFileCode !== customerFileCode) {
				setLocalStorageCustomerFileCode(customerFileCode);
			}

			if (queryCustomerFileCode !== customerFileCode) {
				replace({
					query: {
						...query,
						dossier: customerFileCode,
					},
				}).catch((error) => {
					throw error;
				});
			}

			if (sessionCustomerFile?.code !== customerFileCode) {
				setSessionCustomerFile(customerFile);
			}

			setApolloCustomerFileId(customerFileId);
		},
		[
			localStorageCustomerFileCode,
			queryCustomerFileCode,
			sessionCustomerFile?.code,
			setApolloCustomerFileId,
			setLocalStorageCustomerFileCode,
			replace,
			query,
		],
	);

	const { data: sessionData, refetch } = useQuery<FetchSessionType>(FETCH_SESSION, {
		onCompleted: ({ session: { customerFile } }) => {
			if (customerFile === null) {
				push('/error/emptyCustomerFiles').catch((e) => {
					throw e;
				});
			} else if (!queryCustomerFileCode && !localStorageCustomerFileCode) {
				updateCustomerFileStorages(customerFile);
			}
		},
		skip: isOnErrorPage,
	});
	const { session } = sessionData ?? {};

	useQuery<FetchCustomerFileByCodeType>(FETCH_CUSTOMER_FILE_BY_CODE, {
		onCompleted: ({ customerFileByCode }) => updateCustomerFileStorages(customerFileByCode),
		onError: (error) => {
			updateCustomerFileStorages(undefined);

			if (error.message.includes("can't be accessed for current user.")) {
				push('/error/403').catch((e) => {
					throw e;
				});
			}
		},
		variables: { code: queryCustomerFileCode },
		skip: !!sessionCustomerFile || !queryCustomerFileCode || isOnErrorPage,
	});

	useQuery<FetchCustomerFileByCodeType>(FETCH_CUSTOMER_FILE_BY_CODE, {
		onCompleted: ({ customerFileByCode }) => updateCustomerFileStorages(customerFileByCode),
		onError: () => updateCustomerFileStorages(undefined),
		variables: { code: localStorageCustomerFileCode },
		skip: !!sessionCustomerFile || !!queryCustomerFileCode || !localStorageCustomerFileCode || isOnErrorPage,
	});

	const contextValue = useMemo(
		() =>
			session && {
				user: session.user,
				customerFile: sessionCustomerFile,
				permissions: session.permissions,
				impersonatedUser,
				checkPermission: (code: PermissionEnum) =>
					session.permissions.some((permission) => code === permission.code && permission.value),
				changeCustomerFile: (newCustomerFile: CustomerFileType) => updateCustomerFileStorages(newCustomerFile),
				refetch: async () => {
					await refetch();
				},
				impersonate: async (user?: ImpersonatedUserType) => {
					try {
						setImpersonateLoading(true);
						updateCustomerFileStorages(undefined);
						await routerRef.current.push('/');
						const { id } = user ?? {};
						setImpersonatedUser(user);
						setApolloUserId(id);
						await refetch({ notifyOnNetworkStatusChange: true });
					} finally {
						setImpersonateLoading(false);
					}
				},
			},
		[refetch, setApolloUserId, updateCustomerFileStorages, impersonatedUser, session, sessionCustomerFile],
	);

	if (isOnErrorPage) {
		return children;
	}

	if (isImpersonateLoading) {
		return <SplashScreen description="Chargement de l'utilisateur..." />;
	}

	if (!contextValue || loading) {
		return <SplashScreen description="Chargement de la session..." />;
	}

	return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export const useSession = (): SessionContextType => {
	const context = useContext(SessionContext);
	if (context === undefined) {
		throw new Error('SessionContext should be used within a Provider');
	}

	return context;
};
