import { gql } from '@apollo/client';

export type ThemeType = {
	primaryColor: string;
	secondaryColor: string;
	tertiaryColor: string;
};

const THEME_FIELDS = `
	primaryColor
	secondaryColor
	tertiaryColor
`;

// QUERIES

export type FetchThemeType = {
	theme: ThemeType;
};

export const FETCH_THEME = gql`
	query Theme {
		theme {
			${THEME_FIELDS}
		}
	}
`;

// MUTATIONS

export type UpdateThemeType = {
	updateTheme: ThemeType;
};

export const UPDATE_THEME = gql`
	mutation UpdateTheme($updateThemeInput: UpdateThemeInput!) {
		updateTheme(updateThemeInput: $updateThemeInput) {
			${THEME_FIELDS}
		}
	}
`;
