import { gql } from '@apollo/client';

import { ContactType } from '../types/contact';

export const CONTACT_FIELDS = `
	id
	job
	name
	hasPicture
`;

type ContactResultListType = {
	count: number;
	items: ContactType[];
	limit: number;
	offset: number;
};

// QUERIES

export type FetchContactsType = {
	contacts: ContactResultListType;
};

export const FETCH_CONTACTS = gql`
	query Contacts($page: Page, $search: String) {
		contacts(page: $page, search:$search) {
			count
			items {
				${CONTACT_FIELDS}
				email,
				phoneNumber,
			}
			limit
			offset
		}
	}
`;

export type FetchContactsByCustomerFileType = {
	contactsByCustomerFile: ContactResultListType;
};

export const FETCH_CONTACTS_BY_CUSTOMER_FILE = gql`
	query ContactsByCustomerFile($customerFileId: ID!, $page: Page, $search: String) {
		contactsByCustomerFile(customerFileId: $customerFileId, page: $page, search:$search) {
			count
			items {
				${CONTACT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchSettingContactsType = {
	settingContacts: ContactResultListType;
};

export const FETCH_SETTING_CONTACTS = gql`
	query SettingContacts($page: Page, $orderBy: OrderBy, $search: String) {
		settingContacts(page: $page, orderBy: $orderBy, search: $search) {
			items {
				id
				email
				name
				job
				phoneNumber
				hasPicture
			}
			count
			limit
			offset
		}
	}
`;
