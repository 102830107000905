import { gql } from '@apollo/client';

import { CustomerFileSettingJournalType, DomainSettingJournalType } from '../types/settingJournal';
import { JOURNAL_FIELDS } from './journal';

const DOMAIN_SETTING_JOURNAL_FIELDS = `
	currentCodeValue
`;

const CUSTOMER_FILE_SETTING_JOURNAL_FIELDS = `
	currentJournal {
		${JOURNAL_FIELDS}
	}
	isInherited
`;

// QUERIES

export type FetchDomainBillExchangeSettingJournalType = {
	quickentry_settingDomainBillExchangeJournal: DomainSettingJournalType;
};

export const FETCH_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL = gql`
	query Quickentry_settingDomainBillExchangeJournal($id: ID!) {
		quickentry_settingDomainBillExchangeJournal(id: $id) {
			${DOMAIN_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type FetchCustomerFileBillExchangeSettingJournalType = {
	quickentry_settingCustomerFileBillExchangeJournal: CustomerFileSettingJournalType;
};

export const FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL = gql`
	query Quickentry_settingCustomerFileBillExchangeJournal($id: ID!) {
		quickentry_settingCustomerFileBillExchangeJournal(id: $id) {
			${CUSTOMER_FILE_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type FetchDomainCheckSettingJournalType = {
	quickentry_settingDomainCheckJournal: DomainSettingJournalType;
};

export const FETCH_DOMAIN_CHECK_SETTING_JOURNAL = gql`
	query Quickentry_settingDomainCheckJournal($id: ID!) {
		quickentry_settingDomainCheckJournal(id: $id) {
			${DOMAIN_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type FetchCustomerFileCheckSettingJournalType = {
	quickentry_settingCustomerFileCheckJournal: CustomerFileSettingJournalType;
};

export const FETCH_CUSTOMER_FILE_CHECK_SETTING_JOURNAL = gql`
	query Quickentry_settingCustomerFileCheckJournal($id: ID!) {
		quickentry_settingCustomerFileCheckJournal(id: $id) {
			${CUSTOMER_FILE_SETTING_JOURNAL_FIELDS}
		}
	}
`;

// MUTATIONS

export type CreateDomainBillExchangeSettingJournalType = {
	quickentry_createSettingDomainBillExchangeJournal: DomainSettingJournalType;
};

export const CREATE_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL = gql`
	mutation Quickentry_createSettingDomainBillExchangeJournal($createSettingDomainBillExchangeJournalInput: quickentry_CreateSettingDomainBillExchangeJournalInput!) {
		quickentry_createSettingDomainBillExchangeJournal(createSettingDomainBillExchangeJournalInput: $createSettingDomainBillExchangeJournalInput) {
			${DOMAIN_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type CreateCustomerFileBillExchangeSettingJournalType = {
	quickentry_createSettingCustomerFileBillExchangeJournal: CustomerFileSettingJournalType;
};

export const CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_JOURNAL = gql`
	mutation Quickentry_createSettingCustomerFileBillExchangeJournal($createSettingCustomerFileBillExchangeJournalInput: quickentry_CreateSettingCustomerFileBillExchangeJournalInput!) {
		quickentry_createSettingCustomerFileBillExchangeJournal(createSettingCustomerFileBillExchangeJournalInput: $createSettingCustomerFileBillExchangeJournalInput) {
			${CUSTOMER_FILE_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type CreateDomainCheckSettingJournalType = {
	quickentry_createSettingDomainCheckJournal: DomainSettingJournalType;
};

export const CREATE_DOMAIN_CHECK_SETTING_JOURNAL = gql`
	mutation Quickentry_createSettingDomainCheckJournal($createSettingDomainCheckJournalInput: quickentry_CreateSettingDomainCheckJournalInput!) {
		quickentry_createSettingDomainCheckJournal(createSettingDomainCheckJournalInput: $createSettingDomainCheckJournalInput) {
			${DOMAIN_SETTING_JOURNAL_FIELDS}
		}
	}
`;

export type CreateCustomerFileCheckSettingJournalType = {
	quickentry_createSettingCustomerFileCheckJournal: CustomerFileSettingJournalType;
};

export const CREATE_CUSTOMER_FILE_CHECK_SETTING_JOURNAL = gql`
	mutation Quickentry_createSettingCustomerFileCheckJournal($createSettingCustomerFileCheckJournalInput: quickentry_CreateSettingCustomerFileCheckJournalInput!) {
		quickentry_createSettingCustomerFileCheckJournal(createSettingCustomerFileCheckJournalInput: $createSettingCustomerFileCheckJournalInput) {
			${CUSTOMER_FILE_SETTING_JOURNAL_FIELDS}
		}
	}
`;
