export enum AccountTypeEnum {
	GENERAL = 'GENERAL',
	SUBACCOUNT = 'SUBACCOUNT',
	VAT = 'VAT',
}

export enum AccountTypeLabelEnum {
	GENERAL = 'Général',
	SUBACCOUNT = 'Auxiliaire',
	VAT = 'TVA',
}

export type AccountType = {
	id: string;
	code: string;
	isMaster: boolean;
	name: string;
};

export type AccountTypeWithMasterAccount = AccountType & {
	masterAccount: AccountType;
};
