import { CurrencyFilterDefinitionType, DateRangePicker } from '@elipssolution/harfang';
import { Divider, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';

import { FiscalYearType } from '../../../../types/fiscalYear';
import AccountingTypeSelector from '../../components/AccountingTypeSelector';
import FiscalYearAutocomplete from '../../components/FiscalYearAutocomplete';
import { AccountingType } from '../../types/accounting';
import { SwitchOperatorType } from '../../types/table';

type FilterType = {
	id: SwitchOperatorType;
	label: string;
};

type AccountingTableTogglesType = {
	onAccountingTypeChange: (accountingType: AccountingType) => void;
	accountingType: AccountingType;
	selectedFiscalYear?: FiscalYearType;
	setSelectedFiscalYear: (fiscalYear?: FiscalYearType) => void;
	selectedDateRange?: [Date, Date];
	onDateRangeSelection: (dateRange?: [Date, Date]) => void;
	balanceFilter?: CurrencyFilterDefinitionType['value'];
	onBalanceFilterOperatorChange: (operator: FilterType['id']) => void;
	hasTransaction: boolean;
	onHasTransactionChange: (value: boolean) => void;
};

const filters: FilterType[] = [
	{
		id: 'ne',
		label: 'Solde différent de 0',
	},
	{
		id: 'lt',
		label: 'Solde négatif',
	},
	{
		id: 'gt',
		label: 'Solde positif',
	},
];

const AccountingTableToggles = ({
	accountingType,
	balanceFilter,
	hasTransaction,
	onAccountingTypeChange,
	onBalanceFilterOperatorChange,
	onHasTransactionChange,
	selectedFiscalYear,
	setSelectedFiscalYear,
	selectedDateRange,
	onDateRangeSelection,
}: AccountingTableTogglesType) => {
	const { startDate, endDate } = selectedFiscalYear || {};

	const handleBalanceFilterSwitchChange = ({ target: { id: targetId } }: ChangeEvent<HTMLInputElement>) =>
		onBalanceFilterOperatorChange(targetId as FilterType['id']);

	const handleSwitchHasTransactionChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
		onHasTransactionChange(checked);
	};
	const handleFiscalYearChange = useCallback(
		(fiscalYear?: FiscalYearType) => {
			setSelectedFiscalYear(fiscalYear);
			onDateRangeSelection(undefined);
		},
		[onDateRangeSelection, setSelectedFiscalYear],
	);

	return (
		<Stack gap={2} flex={1}>
			<AccountingTypeSelector onAccountingTypeChange={onAccountingTypeChange} accountingType={accountingType} />
			<Divider />

			<FiscalYearAutocomplete selectedFiscalYear={selectedFiscalYear} onFiscalYearSelection={handleFiscalYearChange} />

			<DateRangePicker
				label="Plage de dates"
				disabled={!selectedFiscalYear}
				min={startDate}
				max={endDate}
				value={selectedDateRange}
				onChange={onDateRangeSelection}
			/>
			<Divider />
			<FormGroup>
				{filters.map(({ id, label }) => (
					<FormControlLabel
						key={id}
						control={
							<Switch id={id} checked={balanceFilter?.[id] === '0.00'} onChange={handleBalanceFilterSwitchChange} />
						}
						label={label}
					/>
				))}
				<FormControlLabel
					control={<Switch checked={hasTransaction} onChange={handleSwitchHasTransactionChange} />}
					label="Comptes mouvementés"
				/>
			</FormGroup>
		</Stack>
	);
};

export default AccountingTableToggles;
