import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FetchSupplierAccountsType, FETCH_SUPPLIER_ACCOUNTS } from '../../../api/supplierAccounts';
import { SupplierAccountType } from '../../../types/supplierAccounts';

const useSupplierAccountsDataSource = () => {
	const [fetchSupplierAccounts] = useLazyQuery<FetchSupplierAccountsType>(FETCH_SUPPLIER_ACCOUNTS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SupplierAccountType[];
		}> => {
			const { data, error: supplierAccountsError } = await fetchSupplierAccounts({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (supplierAccountsError) {
				throw supplierAccountsError;
			}

			const {
				supplierAccounts: { count = 0, items = [] },
			} = data ?? {
				supplierAccounts: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSupplierAccounts],
	);

	return { dataSource };
};

export default useSupplierAccountsDataSource;
