import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { FETCH_SETTING_BANKS_BY_CUSTOMER_FILE, FetchSettingBanksByCustomerFileType } from '../../../../api/settingBank';
import { SettingCustomerFileBankType } from '../../../../types/settingBank';

type CustomerFileBankTableProps = {
	customerFileId?: CustomerFileType['id'];
	onSettingBankSelection: (settingBank?: SettingCustomerFileBankType) => void;
};

const CustomerFileBankTable = ({ customerFileId, onSettingBankSelection }: CustomerFileBankTableProps) => {
	const [fetchSettingBankByCustomerFile] = useLazyQuery<FetchSettingBanksByCustomerFileType>(
		FETCH_SETTING_BANKS_BY_CUSTOMER_FILE,
	);

	const columns: TableColumnType<SettingCustomerFileBankType>[] = [
		{
			field: 'name',
			key: 'name',
			sortable: true,
			title: 'Nom',
			width: 200,
		},
		{
			key: 'accountCode',
			render: ({ account }) => (account ? `${account.code} - ${account.name}` : ''),
			title: 'Compte général associé',
			width: 400,
		},
		{
			key: 'actions',
			flexGrow: 0,
			render: () => <LinkIcon />,
			width: 40,
		},
	];

	const settingBanksDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingCustomerFileBankType>,
		): Promise<{
			count: number;
			items: SettingCustomerFileBankType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingBankByCustomerFile({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					customerFileId,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_settingBanksByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				quickentry_settingBanksByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingBankByCustomerFile, customerFileId],
	);

	const handleAddButtonClick = useCallback(() => onSettingBankSelection(), [onSettingBankSelection]);

	return (
		<SettingsTable<SettingCustomerFileBankType>
			addButtonLabel="Ajouter une banque"
			dataSource={settingBanksDataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={onSettingBankSelection}
			tableColumns={columns}
			title="Banques"
		/>
	);
};

export default CustomerFileBankTable;
