export enum PermissionEnum {
	INTERNAL_CUSTOMER_FILE_USER_MANAGE = 'internal-customer-file-user-manage',
	INTERNAL_GROUP_MANAGE = 'internal-group-manage',

	EXTERNAL_CUSTOMER_FILE_USER_MANAGE = 'external-customer-file-user-manage',
	EXTERNAL_GROUP_MANAGE = 'external-group-manage',

	IMPERSONATE = 'impersonate',
	THEME_MANAGE = 'theme-manage',

	CUSTOMER_FILE_MANAGE = 'customer-file-manage',
	DOMAIN_MANAGE = 'domain-manage',
	WIDGET_CUSTOMER_FILE_MANAGE = 'widget-customer-file-manage',
	WIDGET_DOMAIN_MANAGE = 'widget-domain-manage',

	ACCOUNTING_READ = 'accounting-read',
	ACCOUNTING_SETTING = 'accounting-setting',
	ACCOUNTING_SYNCHRONIZE = 'accounting-synchronize',

	QUICKENTRY_READ = 'quickentry-read',
	QUICKENTRY_WRITE = 'quickentry-write',
	QUICKENTRY_VALIDATE = 'quickentry-validate',
	QUICKENTRY_SETTING = 'quickentry-setting',

	DOCUMENT_SETTING_TAG = 'document-setting-tag',
	DOCUMENT_DOWNLOAD = 'document-download',
	DOCUMENT_UPLOAD = 'document-upload',
	DOCUMENT_SETTING_STORAGE = 'document-setting-storage',
}
