import { useLazyQuery } from '@apollo/client';
import { Autocomplete, Button, Icon, Select, TextField } from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { Checkbox, FormControl, FormControlLabel, styled } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CustomerFileType } from '../../../../../../../types/customerFile';
import {
	FETCH_ACCOUNTS_BY_TYPE_AND_CUSTOMER_FILE,
	FETCH_SUBACCOUNTS_BY_ACCOUNT,
	FetchAccountsByTypeAndCustomerFileType,
	FetchSubaccountsByAccountType,
} from '../../../../../api/account';
import {
	FETCH_ANALYTICAL_SECTIONS_BY_ANALYTICAL_DIMENSION,
	FetchAnalyticalSectionsByAnalyticalDimensionType,
} from '../../../../../api/analytical';
import { AccountType, AccountTypeEnum, AccountTypeLabelEnum } from '../../../../../types/account';
import { AnalyticalType } from '../../../../../types/analytical';
import { AnalyticalDimensionType } from '../../../../../types/analyticalDimension';
import { AnalyticalSectionType } from '../../../../../types/analyticalSection';
import {
	DirectionEnum,
	SettingCustomerFileTemplateLineType,
	TemplateLineErrorMessageType,
} from '../../../../../types/settingTemplate';
import { TemplateType } from '../../../../../types/template';
import { getConnectorMaxNameLength } from '../../../../../utils/connector';
import { getFormResettedValues } from '../../../../../utils/formHandler';

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),

	marginTop: spacing(2),

	'& > div': {
		flex: 1,
	},
}));

type SelectsType = {
	value: DirectionEnum;
	label: string;
};

type TemplateCustomerFileLineFormSectionProps = {
	analyticalDimensionId?: AnalyticalDimensionType['id'];
	connector?: CustomerFileType['connector'];
	customerFileId?: CustomerFileType['id'];
	defaultEntryName?: TemplateType['defaultEntryName'];
	directions: SelectsType[];
	onSubmit: (values: SettingCustomerFileTemplateLineType) => void;
	resetUpdateTemplateLine: () => void;
	templateLine?: SettingCustomerFileTemplateLineType;
	templateLinesErrorMessage?: TemplateLineErrorMessageType[];
	onTemplateLinesErrorMessagesChange?: Dispatch<SetStateAction<TemplateLineErrorMessageType[]>>;
	templateLineIndex: number;
	isUpdatingSucceeded: boolean;
	isCreationSucceeded: boolean;
};

const accountTypeOptions = Object.values(AccountTypeEnum);

const TemplateCustomerFileLineFormSection = ({
	analyticalDimensionId,
	connector,
	customerFileId,
	defaultEntryName,
	directions,
	onSubmit,
	resetUpdateTemplateLine,
	templateLine,
	templateLinesErrorMessage,
	onTemplateLinesErrorMessagesChange,
	templateLineIndex,
	isUpdatingSucceeded,
	isCreationSucceeded,
}: TemplateCustomerFileLineFormSectionProps) => {
	const {
		clearErrors,
		control,
		formState: { dirtyFields, isDirty, isValid },
		getValues,
		handleSubmit,
		reset,
		setError,
		setValue,
		watch,
	} = useForm<SettingCustomerFileTemplateLineType>({
		mode: 'onBlur',
	});

	const formAccount = watch('account');
	const lineType = watch('type');
	const [temporaryTemplateLinesErrorMessage, setTemporaryTemplateLinesErrorMessage] = useState<
		TemplateLineErrorMessageType[]
	>([]);

	const nameMaxLength = useMemo(() => (connector ? getConnectorMaxNameLength(connector) ?? 999 : 999), [connector]);

	const resetForm = useCallback(() => {
		const values: { [key: string]: unknown } = getValues();

		const resettedValues = getFormResettedValues({ values });

		reset({
			...resettedValues,
		});
	}, [getValues, reset]);

	const [fetchAnalyticalSectionsByAnalyticalDimension] = useLazyQuery<FetchAnalyticalSectionsByAnalyticalDimensionType>(
		FETCH_ANALYTICAL_SECTIONS_BY_ANALYTICAL_DIMENSION,
	);

	const analyticalSectionsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AnalyticalType[];
		}> => {
			const { data, error: queryError } = await fetchAnalyticalSectionsByAnalyticalDimension({
				variables: {
					analyticalDimensionId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				analyticalSectionsByAnalyticalDimension: { count = 0, items = [] },
			} = data ?? {
				analyticalSectionsByAnalyticalDimension: {},
			};

			return {
				count,
				items,
			};
		},
		[analyticalDimensionId, fetchAnalyticalSectionsByAnalyticalDimension],
	);

	const [fetchAccountsByTypeAndCustomerFile] = useLazyQuery<FetchAccountsByTypeAndCustomerFileType>(
		FETCH_ACCOUNTS_BY_TYPE_AND_CUSTOMER_FILE,
	);

	const accountsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			const { data, error: queryError } = await fetchAccountsByTypeAndCustomerFile({
				variables: {
					type: lineType.toUpperCase(),
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				accountsByTypeAndCustomerFile: { count = 0, items = [] },
			} = data ?? {
				accountsByTypeAndCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchAccountsByTypeAndCustomerFile, lineType, customerFileId],
	);

	const [fetchSubaccountsByAccount] = useLazyQuery<FetchSubaccountsByAccountType>(FETCH_SUBACCOUNTS_BY_ACCOUNT);

	const subaccountsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: AccountType[];
		}> => {
			const { data, error: queryError } = await fetchSubaccountsByAccount({
				variables: {
					accountId: formAccount?.id,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				subaccountsByAccount: { count = 0, items = [] },
			} = data ?? {
				subaccountsByAccount: {},
			};

			return {
				count,
				items,
			};
		},
		[formAccount, fetchSubaccountsByAccount],
	);

	const handleFormSubmit = useCallback(
		(values: SettingCustomerFileTemplateLineType) => {
			onSubmit(values);
			onTemplateLinesErrorMessagesChange?.(temporaryTemplateLinesErrorMessage);
			resetForm();
		},
		[onSubmit, onTemplateLinesErrorMessagesChange, resetForm, temporaryTemplateLinesErrorMessage],
	);

	const resetUpdating = useCallback(() => {
		resetForm();
		resetUpdateTemplateLine();
	}, [resetForm, resetUpdateTemplateLine]);

	// Apply the prop template line to the form
	useEffect(() => {
		const { id, account, analyticalSection, rank, subaccount, ...rest } =
			(templateLine as SettingCustomerFileTemplateLineType & {
				id: string;
			}) ?? {};

		id
			? reset({
					...rest,
					account: account ?? undefined,
					analyticalSection: analyticalSection ?? undefined,
					subaccount: subaccount ?? undefined,
			  })
			: resetForm();
	}, [resetForm, reset, templateLine, setError]);

	const updateTemplateLinesErrors = useCallback(
		(index: number, value: { [K in keyof TemplateLineErrorMessageType]?: string }) => {
			setTemporaryTemplateLinesErrorMessage?.((previousTemplateLinesErrors: TemplateLineErrorMessageType[]) => {
				const newTemplateLinesErrorMessage = [...previousTemplateLinesErrors];

				newTemplateLinesErrorMessage[index] = { ...newTemplateLinesErrorMessage?.[index], ...value };
				return newTemplateLinesErrorMessage;
			});
		},
		[],
	);

	const submitButtonLabel = useMemo(() => {
		if (templateLine) return 'Modifier la ligne de modèle';
		if (isUpdatingSucceeded) return 'Ligne modifiée';
		if (isCreationSucceeded) return 'Ligne ajoutée';
		return 'Ajouter une ligne de modèle';
	}, [isCreationSucceeded, isUpdatingSucceeded, templateLine]);

	useEffect(() => {
		const { id } = templateLine ?? {};
		if (id) {
			if (templateLinesErrorMessage?.[templateLineIndex]?.account) {
				setError('account', { message: templateLinesErrorMessage?.[templateLineIndex]?.account });
			}
			if (templateLinesErrorMessage?.[templateLineIndex]?.subaccount) {
				setError('subaccount', { message: templateLinesErrorMessage?.[templateLineIndex]?.subaccount });
			}
			if (templateLinesErrorMessage?.[templateLineIndex]?.analyticalSection) {
				setError('analyticalSection', { message: templateLinesErrorMessage?.[templateLineIndex]?.analyticalSection });
			}
			setTemporaryTemplateLinesErrorMessage(templateLinesErrorMessage ?? []);
		}
	}, [setError, templateLine, templateLineIndex, templateLinesErrorMessage]);

	// Sets the default value of defaultTransactionName  when the form is dirty
	useEffect(() => {
		if (isDirty && !Object.keys(dirtyFields).includes('defaultTransactionName')) {
			setValue('defaultTransactionName', defaultEntryName);
		}
	}, [defaultEntryName, dirtyFields, isDirty, setValue]);

	// Reset the analyticalSection field if there is no analytical dimension or the line type is set to subaccount
	useEffect(() => {
		(!analyticalDimensionId || lineType === AccountTypeEnum.SUBACCOUNT) && setValue('analyticalSection', undefined);
	}, [setValue, analyticalDimensionId, lineType]);

	return (
		<>
			<FormRow>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <TextField {...field} label="Description" required />}
					rules={{ required: true, pattern: /(.|\s)*\S(.|\s)*/ }}
				/>
				<Controller
					name="direction"
					control={control}
					rules={{ required: true }}
					render={({ field: { value, onChange, ...field } }) => {
						const handleChange = (newValue?: SelectsType) => {
							onChange(newValue?.value);
						};

						return (
							<Select<SelectsType>
								{...field}
								options={directions}
								label="Sens *"
								onChange={handleChange}
								renderOption={({ label }: SelectsType) => label}
								renderValue={({ label }: SelectsType) => label}
								value={directions.find((direction) => direction.value === value)}
								disableClearable
								required
							/>
						);
					}}
				/>
				<Controller
					name="defaultTransactionName"
					control={control}
					rules={{
						required: true,
						pattern: /(.|\s)*\S(.|\s)*/,
						maxLength: {
							message: `Maximum ${nameMaxLength} caractères.`,
							value: nameMaxLength,
						},
					}}
					render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
						const handleChange = (value?: string) => {
							if (value && value.length > nameMaxLength) {
								setError('defaultTransactionName', { message: `Maximum ${nameMaxLength} caractères.` });
							} else {
								clearErrors('defaultTransactionName');
								onChange(value ?? '');
							}
						};
						return (
							<TextField
								{...field}
								label="Libellé par défaut"
								invalid={!!fieldError}
								helperText={fieldError?.message}
								onChange={handleChange}
								required
							/>
						);
					}}
				/>
				<Controller
					name="type"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, ...field } }) => {
						const handleChange = (newValue?: AccountTypeEnum) => {
							onChange(newValue);
							setValue('account', undefined);
							setValue('subaccount', undefined);
						};

						return (
							<Select<AccountTypeEnum>
								{...field}
								onChange={handleChange}
								options={accountTypeOptions}
								label="Type *"
								renderOption={(option) => AccountTypeLabelEnum[option]}
								renderValue={(option) => AccountTypeLabelEnum[option]}
								disableClearable
								required
							/>
						);
					}}
				/>
				<Controller
					name="account"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handleChange = (newValue?: AccountType) => {
							setValue('subaccount', undefined);
							onChange(newValue);
							clearErrors('account');
							updateTemplateLinesErrors(templateLineIndex, { account: undefined });
						};

						return (
							<Autocomplete<AccountType>
								{...field}
								optionWidth={300}
								dataSource={accountsDataSource}
								disabled={!lineType}
								invalid={!!error}
								helperText={error?.message}
								getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
								label="Compte"
								onChange={handleChange}
								disableClearable
								required
							/>
						);
					}}
				/>
			</FormRow>
			<FormRow>
				<Controller
					name="analyticalSection"
					control={control}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handleChange = (value?: AnalyticalSectionType) => {
							onChange(value);
							setValue('isAnalyticalEnabled', !!value);
							clearErrors('analyticalSection');
							updateTemplateLinesErrors(templateLineIndex, { analyticalSection: undefined });
						};

						return (
							<Autocomplete<AnalyticalType>
								{...field}
								disabled={!analyticalDimensionId || lineType === AccountTypeEnum.SUBACCOUNT}
								dataSource={analyticalSectionsDataSource}
								getOptionLabel={({ code, name: accountName }) => `${code} - ${accountName}`}
								onChange={handleChange}
								invalid={!!error}
								helperText={error?.message}
								optionWidth={300}
								label="Section analytique"
								required={!!templateLinesErrorMessage?.[templateLineIndex]?.analyticalSection}
							/>
						);
					}}
					rules={{
						required: !!templateLinesErrorMessage?.[templateLineIndex]?.analyticalSection,
					}}
				/>
				<Controller
					name="subaccount"
					control={control}
					render={({ field: { onChange, ...field }, fieldState: { error } }) => {
						const handleSubaccountChange = (value?: AccountType) => {
							onChange(value);
							clearErrors('subaccount');
							updateTemplateLinesErrors(templateLineIndex, { subaccount: undefined });
						};

						return (
							<Autocomplete<AccountType>
								{...field}
								optionWidth={300}
								onChange={handleSubaccountChange}
								invalid={!!error}
								helperText={error?.message}
								disabled={lineType !== AccountTypeEnum.SUBACCOUNT || !formAccount}
								getOptionLabel={(option) => `${option.code} - ${option.name}`}
								label="Compte auxiliaire"
								dataSource={subaccountsDataSource}
								required={!!templateLinesErrorMessage?.[templateLineIndex]?.subaccount}
							/>
						);
					}}
					rules={{
						required: !!templateLinesErrorMessage?.[templateLineIndex]?.subaccount,
					}}
				/>
				<Controller
					name="subaccountPrefix"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							disabled={lineType !== AccountTypeEnum.SUBACCOUNT}
							label="Préfixe auxiliaire"
							helperText={error?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					defaultValue={false}
					name="isAnalyticalEnabled"
					render={({ field: { value, onChange, ...field } }) => {
						const handleCheckboxCheck = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => onChange(checked);
						return (
							<FormControl>
								<FormControlLabel
									control={<Checkbox {...field} checked={value} onChange={handleCheckboxCheck} />}
									label="Utilise l'analytique"
									disabled={!analyticalDimensionId}
								/>
							</FormControl>
						);
					}}
				/>
			</FormRow>
			<FormRow>
				<Button
					disabled={!isDirty || !isValid}
					onClick={handleSubmit(handleFormSubmit)}
					startIcon={<Icon path={templateLine ? mdiPencil : mdiPlus} />}
					variant="contained"
				>
					{submitButtonLabel}
				</Button>
				{templateLine && (
					<Button onClick={resetUpdating} variant="text">
						Annuler la modification
					</Button>
				)}
			</FormRow>
		</>
	);
};

export default TemplateCustomerFileLineFormSection;
