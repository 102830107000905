import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface LogoContextType {
	favicon?: Blob;
	logo?: Blob;
	logoCollapsed?: Blob;
	setFavicon: (blob?: Blob) => void;
	setLogo: (blob?: Blob) => void;
	setLogoCollapsed: (blob?: Blob) => void;
}
const LogoContext = createContext<LogoContextType | undefined>(undefined);

export const useLogo = () => {
	const context = useContext(LogoContext);
	if (context === undefined) {
		throw new Error('LogoContext should be used within a Provider');
	}
	return context;
};

const fetchBlob = async (uri: string): Promise<Blob | undefined> => {
	const response = await fetch(uri);

	return response.ok ? response.blob() : undefined;
};

export const LogoProvider = ({ children }: { children: ReactNode }) => {
	const [favicon, setFavicon] = useState<Blob>();
	const [logoCollapsed, setLogoCollapsed] = useState<Blob>();
	const [logo, setLogo] = useState<Blob>();

	useEffect(() => {
		if (favicon && logo && logoCollapsed) return;

		(async () => {
			const [faviconResult, logoResult, logoCollapsedResult] = await Promise.all([
				fetchBlob('/favicon'),
				fetchBlob('/logo'),
				fetchBlob('/logoCollapsed'),
			]);

			setFavicon(faviconResult);
			setLogo(logoResult);
			setLogoCollapsed(logoCollapsedResult);
		})().catch((e) => {
			throw e;
		});
	}, [favicon, logo, logoCollapsed, setFavicon, setLogo, setLogoCollapsed]);

	const contextValue = useMemo(
		() => ({ favicon, logo, logoCollapsed, setFavicon, setLogo, setLogoCollapsed }),
		[setFavicon, setLogo, setLogoCollapsed, favicon, logoCollapsed, logo],
	);

	return <LogoContext.Provider value={contextValue}>{children}</LogoContext.Provider>;
};
