import { gql } from '@apollo/client';
import { accounting_AccountLevel, accounting_SearchAccountLevelResult } from '../types/account';

export const ACCOUNTING_ACCOUNTS_LEVEL_FIELDS = `
	name
	code
	level
	creditAmount
	accountId
	debitAmount
	balance
`;

// QUERIES

export type FetchAccountingAccountsLevelType = {
	accounting_accountLevels: {
		items: accounting_AccountLevel[];
		totalBalance: string;
		totalCredit: string;
		totalDebit: string;
	};
};

export const FETCH_ACCOUNTING_ACCOUNTS_LEVELS = gql`
	query Accounting_accountLevels(
		$accountingDate: BetweenDate
		$fiscalYearId: ID!
		$type: accounting_AccountingType!
		$level: Int!
		$hasTransaction: Boolean!
		$parentCode: String
		$filter: accounting_AccountLevelFilterInput
	) {
		accounting_accountLevels(
			accountingDate: $accountingDate
			fiscalYearId: $fiscalYearId
			type: $type
			level: $level
			hasTransaction: $hasTransaction
			parentCode: $parentCode
			filter: $filter
		) {
			items {
				${ACCOUNTING_ACCOUNTS_LEVEL_FIELDS}
			}
			totalCredit
			totalDebit
			totalBalance
		}
	}
`;

export type FetchAccountingSearchAccountsLevelType = {
	accounting_searchAccountLevels: accounting_SearchAccountLevelResult[];
};

export const SEARCH_ACCOUNTING_ACCOUNT_FIELDS = gql`
	query Accounting_searchAccountLevels(
		$accountingDate: BetweenDate
		$fiscalYearId: ID!
		$type: accounting_AccountingType!
		$search: String!
		$hasTransaction: Boolean!
		$filter: accounting_AccountLevelFilterInput
	) {
		accounting_searchAccountLevels(
			accountingDate: $accountingDate
			fiscalYearId: $fiscalYearId
			type: $type
			search: $search
			hasTransaction: $hasTransaction
			filter: $filter
		) {
			level0Code
			level1Code
			level2Code
			level3Code
		}
	}
`;

export const ACCOUNTING_SETTING_SYNCHRONIZE_BY_CUSTOMER_FILE = gql`
	query Accounting_settingSynchronizeByCustomerFile($customerFileId: ID!) {
		accounting_settingSynchronizeByCustomerFile(customerFileId: $customerFileId)
	}
`;
