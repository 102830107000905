import { ReactNode, useCallback } from 'react';

import { Menu } from '@elipssolution/harfang';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export type TableRowMenuAction = {
	id: string;
	icon: ReactNode;
	label: string;
	onClick: () => void;
};

type TableRowActionMenuProps = {
	anchorEl: HTMLButtonElement | null;
	onClose: () => void;
	actions: TableRowMenuAction[];
};

const TableRowActionMenu = ({ anchorEl, onClose, actions = [] }: TableRowActionMenuProps) => {
	const handleMenuItemClick = useCallback(
		(id: string) => {
			const { onClick } = actions.find((action) => action.id === id) ?? {};

			onClick?.();
			onClose();
		},
		[actions, onClose],
	);

	return (
		<Menu
			open={!!anchorEl}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			onClose={onClose}
		>
			{actions.map(({ id, icon, label }) => {
				const handleClick = () => handleMenuItemClick(id);

				return (
					<MenuItem key={id} onClick={handleClick}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText>{label}</ListItemText>
					</MenuItem>
				);
			})}
		</Menu>
	);
};

export default TableRowActionMenu;
