import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { SettingCustomerFileBankType } from '../../../../types/settingBank';
import CustomerFileBankForm from './CustomerFileBankForm';
import CustomerFileBankTable from './CustomerFileBankTable';

type CustomerFileProps = {
	customerFileId?: CustomerFileType['id'];
};

const CustomerFile = ({ customerFileId }: CustomerFileProps) => {
	const { push } = useSettingsDialog();

	const handleSettingBankSelection = (selectedSettingBank?: SettingCustomerFileBankType) => {
		push(<CustomerFileBankForm customerFileId={customerFileId} settingBank={selectedSettingBank} />);
	};

	return (
		<SettingsDialogPage title="Banques">
			<CustomerFileBankTable customerFileId={customerFileId} onSettingBankSelection={handleSettingBankSelection} />
		</SettingsDialogPage>
	);
};

export default CustomerFile;
