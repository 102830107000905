import { Chip, Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo, MouseEvent } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileWithGroup } from '../../../../types/customerFile';
import { useSession } from '../../../components/SessionProvider';
import { UserType, UserTypeEnum } from '../../../types/user';
import UserCustomerFileRelation from '../userCustomerFileRelation/UserCustomerFileRelation';

type AddedCustomerFileUsersTableSectionProps = {
	customerFiles: CustomerFileWithGroup[];
	onRemoveCustomerFile: (customerFileId: CustomerFileWithGroup['customerFile']['id']) => Promise<void>;
	user: UserType;
};

const AddedCustomerFileUsersTableSection = ({
	customerFiles,
	onRemoveCustomerFile,
	user,
}: AddedCustomerFileUsersTableSectionProps) => {
	const { push } = useSettingsDialog();
	const {
		user: { type },
	} = useSession();

	const removeCustomerFile = useCallback(
		async (
			event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
			customerFileId: CustomerFileWithGroup['customerFile']['id'],
		) => {
			event.stopPropagation();
			await onRemoveCustomerFile(customerFileId);
		},
		[onRemoveCustomerFile],
	);

	const columns: TableColumnType<CustomerFileWithGroup>[] = useMemo(
		() => [
			{
				key: 'code',
				render: ({ customerFile: { code } }) => code,
				title: 'Code',
				width: 200,
			},
			{
				key: 'name',
				render: ({ customerFile: { name } }) => name,
				title: 'Nom',
				width: 200,
			},
			...(type === UserTypeEnum.INTERNAL
				? [
						{
							flexGrow: 0,
							key: 'group',
							render: ({ group }: CustomerFileWithGroup) => {
								const { isAdministrator, isDefault, name } = group || {};

								return isAdministrator ? (
									<Chip label={name} color="error" />
								) : (
									<Chip label={name} color={isDefault ? 'info' : 'default'} />
								);
							},
							title: 'Groupe',
							width: 150,
						},
				  ]
				: []),
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ customerFile: { id } }) => (
					<Tooltip content="Retire la liaison entre l'utilisateur et le dossier">
						<IconButton onClick={(event) => removeCustomerFile(event, id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
			{
				key: 'navigate',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[removeCustomerFile, type],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: CustomerFileWithGroup[];
		}> =>
			Promise.resolve({
				items: customerFiles,
				count: customerFiles.length,
			}),
		[customerFiles],
	);

	const handleRowClick = useCallback(
		({ customerFile }: CustomerFileWithGroup) =>
			push(<UserCustomerFileRelation user={user} initialCustomerFile={customerFile} />),
		[push, user],
	);

	return (
		<SettingsTable
			dataSource={dataSource}
			enableSearch={false}
			tableColumns={columns}
			title="Dossiers ajoutés"
			onRowClick={handleRowClick}
		/>
	);
};

export default AddedCustomerFileUsersTableSection;
