import { useTable } from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import CheckDialog from './CheckDialog';
import SubmittedOrValidatedCheckTable from './SubmittedOrValidatedCheckTable';
import SynchronizedCheckTable from './SynchronizedCheckTable';
import PermissionWall from '../../../../components/PermissionWall';
import { useSession } from '../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../types/permission';
import BanksCarousel from '../../components/BanksCarousel';
import { BankType } from '../../types/bank';
import { CheckType } from '../../types/check';

const Checks = () => {
	const { push } = useRouter();
	const { permissions } = useSession();

	const submittedOrValidatedCheckTableInstance = useTable();
	const synchronizedCheckTableInstance = useTable();

	const [selectedBank, setSelectedBank] = useState<BankType>();
	const [selectedCheck, setSelectedCheck] = useState<CheckType>();
	const [isCheckDialogOpen, setIsCheckDialogOpen] = useState(false);
	const [isCheckDialogReadOnly, setIsCheckDialogReadOnly] = useState(false);

	const handleCheckDialogClose = () => {
		setSelectedBank(undefined);
		setSelectedCheck(undefined);
		setIsCheckDialogOpen(false);
		setIsCheckDialogReadOnly(false);
	};

	const handleBankSelection = (bank: BankType) => {
		setIsCheckDialogOpen(true);
		setIsCheckDialogReadOnly(false);
		setSelectedBank(bank);
	};

	const handleCheckSelection = (check: CheckType) => {
		setIsCheckDialogOpen(true);
		setSelectedCheck(check);
	};

	const handleSubmittedOrValidatedCheckSelection = (check: CheckType) => {
		setIsCheckDialogReadOnly(false);
		handleCheckSelection(check);
	};

	const handleSynchronizedCheckSelection = (check: CheckType) => {
		setIsCheckDialogReadOnly(true);
		handleCheckSelection(check);
	};

	const handleCheckEdition = () => {
		handleCheckDialogClose();

		submittedOrValidatedCheckTableInstance.reload();
	};

	const handleCheckValidation = () => {
		submittedOrValidatedCheckTableInstance.reload();
		synchronizedCheckTableInstance.reload();
	};

	// Redirect the user to the Forbidden Access page if it has not the required permission
	useEffect(() => {
		const hasUserPermissions = [PermissionEnum.QUICKENTRY_WRITE, PermissionEnum.QUICKENTRY_READ].some(
			(permission) => permissions.find(({ code }) => permission === code)?.value,
		);

		if (!hasUserPermissions) {
			push('/error/403').catch((e) => {
				throw e;
			});
		}
	}, [permissions, push]);

	return (
		<>
			<Stack flex={1} gap={2}>
				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_WRITE]}>
					<BanksCarousel selectedBank={selectedBank} onBankSelection={handleBankSelection} />
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_VALIDATE, PermissionEnum.QUICKENTRY_WRITE]}>
					<SubmittedOrValidatedCheckTable
						onCheckSelection={handleSubmittedOrValidatedCheckSelection}
						onCheckValidation={handleCheckValidation}
						tableInstance={submittedOrValidatedCheckTableInstance}
					/>
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_READ]}>
					<SynchronizedCheckTable
						onCheckSelection={handleSynchronizedCheckSelection}
						selectedBank={selectedBank}
						tableInstance={synchronizedCheckTableInstance}
					/>
				</PermissionWall>
			</Stack>

			<CheckDialog
				bankId={selectedBank?.id}
				check={selectedCheck}
				onCheckEdition={handleCheckEdition}
				onClose={handleCheckDialogClose}
				open={isCheckDialogOpen}
				readOnly={isCheckDialogReadOnly}
			/>
		</>
	);
};

export default Checks;
