import {
	Bar,
	CartesianGrid,
	BarChart as RechartsBarChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip as GraphTooltip,
	XAxis,
	YAxis,
} from 'recharts';

import { N_COLOR, N_MINUS_1_COLOR, currencyFormatter, tickFormatter } from '../utils/chart';

type dataType = {
	name: string;
	previousValue: number;
	value: number;
};

const BarChart = ({ data, labelFormatter }: { data: dataType[]; labelFormatter: (label: string) => string }) => (
	<ResponsiveContainer width="100%" height="100%">
		<RechartsBarChart
			data={data}
			margin={{
				top: 32,
				left: -16,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="name" height={20} />
			<YAxis width={80} tickFormatter={tickFormatter} />
			<ReferenceLine y={0} stroke="#000" />
			<GraphTooltip formatter={currencyFormatter} labelFormatter={labelFormatter} />
			<Bar dataKey="previousValue" name="N-1" fill={N_MINUS_1_COLOR} />
			<Bar type="monotone" dataKey="value" name="N" fill={N_COLOR} />
		</RechartsBarChart>
	</ResponsiveContainer>
);
export default BarChart;
