import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { ANALYTICAL_FIELDS } from './analytical';
import { PAYMENT_MODE_FIELDS } from './paymentMode';
import { VoucherType, VoucherUserType } from '../types/voucher';

// QUERIES

export type FetchSynchronizedVouchersType = {
	quickentry_synchronizedVouchers: {
		count: number;
		items: VoucherType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SYNCHRONIZED_VOUCHERS = gql`
	query Quickentry_synchronizedVouchers(
		$page: Page
		$orderBy: OrderBy
		$filter: quickentry_VoucherFilterInput
		$search: String
	) {
		quickentry_synchronizedVouchers(page: $page, orderBy: $orderBy, filter: $filter, search: $search) {
			count
			items {
				id
				accountingDate
				amount
				documentNumber
				name
				template {
					name
				}
			}
			limit
			offset
		}
	}
`;

export type FetchSubmittedOrValidatedVouchersType = {
	quickentry_submittedOrValidatedVouchers: {
		count: number;
		items: VoucherType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUBMITTED_OR_VALIDATED_VOUCHERS = gql`
	query Quickentry_submittedOrValidatedVouchers($page: Page, $orderBy: OrderBy) {
		quickentry_submittedOrValidatedVouchers(page: $page, orderBy: $orderBy) {
			count
			items {
				id
				accountingDate
				amount
				documentNumber
				name
				template {
					name
				}
			}
			limit
			offset
		}
	}
`;

export type FetchVoucherType = {
	quickentry_voucher: VoucherType;
};

export const FETCH_VOUCHER = gql`
	query Quickentry_voucher($id: ID!) {
		quickentry_voucher(id: $id) {
			id
			name
			documentDate
			documentNumber
			accountingDate
			creditNote
			dueDate
			paymentMode {
				${PAYMENT_MODE_FIELDS}
			}
			template {
				id
				analyticalDimension {
					id
				}
				analyticalSectionCaption
			}
			voucherLines {
				id
				account {
					${ACCOUNT_FIELDS}
				}
				analyticalSection {
					${ANALYTICAL_FIELDS}
				}
				creditAmount
				debitAmount
				name
				subaccount {
					${ACCOUNT_FIELDS}
				}
			}
		}
	}
`;

export type FetchVoucherUsersType = {
	quickentry_voucherUsers: {
		count: number;
		items: VoucherUserType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_VOUCHER_USERS = gql`
	query Quickentry_voucherUsers($search: String, $page: Page!) {
		quickentry_voucherUsers(search: $search, page: $page) {
			count
			items {
				email
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateVoucherType = {
	quickentry_createVoucher: VoucherType;
};

export const CREATE_VOUCHER = gql`
	mutation Quickentry_createVoucher($createVoucherInput: quickentry_CreateVoucherInput!) {
		quickentry_createVoucher(createVoucherInput: $createVoucherInput) {
			id
		}
	}
`;

export type UpdateVoucherType = {
	quickentry_updateVoucher: VoucherType;
};

export const UPDATE_VOUCHER = gql`
	mutation Quickentry_updateVoucher($updateVoucherInput: quickentry_UpdateVoucherInput!) {
		quickentry_updateVoucher(updateVoucherInput: $updateVoucherInput) {
			id
		}
	}
`;

export type ValidateVoucherType = {
	quickentry_validateVoucher: VoucherType;
};

export const VALIDATE_VOUCHER = gql`
	mutation Quickentry_validateVoucher($id: ID!) {
		quickentry_validateVoucher(id: $id) {
			id
		}
	}
`;

export type RemoveVoucherType = {
	quickentry_removeVoucher: {
		id: VoucherType['id'];
	};
};

export const REMOVE_VOUCHER = gql`
	mutation Quickentry_removeVoucher($id: ID!) {
		quickentry_removeVoucher(id: $id) {
			id
		}
	}
`;
