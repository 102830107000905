import CheckAccountTypesCustomerFileForm from './CheckAccountTypesCustomerFileForm';
import CheckAccountTypesCustomerFileTable from './CheckAccountTypesCustomerFileTable';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { SettingCustomerFileCheckAccountType } from '../../../../types/settingCheckAccountType';

type CustomerFileProps = {
	customerFileId?: CustomerFileType['id'];
};

const CustomerFile = ({ customerFileId }: CustomerFileProps) => {
	const { push } = useSettingsDialog();

	const handleCheckAccountTypeSelection = (selectedCheckAccountType?: SettingCustomerFileCheckAccountType) => {
		push(
			<CheckAccountTypesCustomerFileForm
				selectedCheckAccountType={selectedCheckAccountType}
				customerFileId={customerFileId}
			/>,
		);
	};

	return (
		<SettingsDialogPage title="Type de dépense chèque">
			<CheckAccountTypesCustomerFileTable
				customerFileId={customerFileId}
				onCheckAccountTypeSelection={handleCheckAccountTypeSelection}
			/>
		</SettingsDialogPage>
	);
};

export default CustomerFile;
