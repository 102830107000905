import { styled } from '@mui/material';
import { ReactNode } from 'react';

const Container = styled('div')(({ theme: { spacing } }) => ({
	inset: 0,

	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',

	padding: spacing(1, 6),

	gap: spacing(2),
}));

type BannerProps = {
	children: ReactNode;
	className?: string;
};

const Banner = ({ children, className }: BannerProps) => <Container className={className}>{children}</Container>;

export default Banner;
