import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import RelationCustomerFileForm from './RelationCustomerFileForm';
import RelationPermissionModules from './RelationPermissionModules';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import {
	FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
	FetchExternalUserCustomerFileRelationByCustomerFileAndUserType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { ExternalUserType } from '../../../types/user';

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',
	display: 'grid',
	placeItems: 'center',
	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

type UserCustomerFileRelationProps = {
	initialCustomerFile: CustomerFileType;
	user: ExternalUserType;
};

const UserCustomerFileRelation = ({ initialCustomerFile, user }: UserCustomerFileRelationProps) => {
	const { back } = useSettingsDialog();
	const {
		refetch: refetchSession,
		user: { id: sessionUserId },
	} = useSession();

	const [error, setError] = useState<string>();
	const [selectedCustomerFile, setSelectedCustomerFile] = useState(initialCustomerFile);

	const isUserSelectedUserConnected = useMemo(() => user.id === sessionUserId, [user, sessionUserId]);

	const {
		data: externalUserCustomerFileRelationByCustomerFileAndUserData,
		loading,
		refetch: refetchPermissionModulesByCustomerFileAndUser,
	} = useQuery<FetchExternalUserCustomerFileRelationByCustomerFileAndUserType>(
		FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
		{
			variables: {
				customerFileId: selectedCustomerFile.id,
				userId: user.id,
			},
		},
	);

	const externalUserCustomerFileRelation = useMemo(() => {
		const { externalUserCustomerFileRelationByCustomerFileAndUser } =
			externalUserCustomerFileRelationByCustomerFileAndUserData || {};
		const {
			customerFile,
			id,
			group,
			permissionModules = [],
		} = externalUserCustomerFileRelationByCustomerFileAndUser || {};

		return {
			customerFile,
			id,
			isAdministrator: group?.isAdministrator ?? false,
			group,
			permissionModules,
		};
	}, [externalUserCustomerFileRelationByCustomerFileAndUserData]);

	const { isAdministrator, permissionModules } = useMemo(
		() => externalUserCustomerFileRelation,
		[externalUserCustomerFileRelation],
	);

	const handlePermissionChange = useCallback(() => {
		refetchSession().catch((e) => {
			throw e;
		});

		refetchPermissionModulesByCustomerFileAndUser().catch((e) => {
			throw e;
		});
	}, [refetchPermissionModulesByCustomerFileAndUser, refetchSession]);

	const handleSelectedCustomerFile = (newSelectedCustomerFile: CustomerFileType) =>
		setSelectedCustomerFile(newSelectedCustomerFile);

	return (
		<SettingsDialogPage title={`${`${user.firstName ?? ''} ${user.lastName}`.trim()} - Permissions`}>
			<RelationCustomerFileForm
				disabled={isUserSelectedUserConnected}
				user={user}
				customerFileUserRelation={externalUserCustomerFileRelation}
				onError={setError}
				onRelationRefetch={refetchPermissionModulesByCustomerFileAndUser}
				onRemove={back}
				onSelectedCustomerFileChange={handleSelectedCustomerFile}
			/>

			<RelationPermissionModules
				disabled={isAdministrator || isUserSelectedUserConnected}
				loading={loading}
				permissionModules={permissionModules}
				onPermissionChanged={handlePermissionChange}
			/>

			{error && <ErrorWrapper>{error}</ErrorWrapper>}
		</SettingsDialogPage>
	);
};

export default UserCustomerFileRelation;
