import { useLazyQuery } from '@apollo/client';
import { SettingsGroup, SettingsItemAutocomplete } from '@elipssolution/harfang';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ConnectorFieldsForm from './ConnectorFieldsForm';
import { FETCH_CONNECTORS, FetchConnectorsType } from '../api/connector';
import { ConnectorType } from '../types/connector';

export type ConnectorFormType = {
	connector: ConnectorType['code'];
	configuration?: Record<string, string | number> | null;
};

type ConnectorFormProps = {
	values?: ConnectorFormType;
	initialValues?: ConnectorFormType;
	connector?: ConnectorType;
	type: string;
	hasStorageData: boolean;
	onConnectorChange: (value?: ConnectorType) => void;
	onValuesChange: (value?: ConnectorFormType) => void;
	onConfigurationValidityChange: (value: boolean) => void;
};

const ConnectorForm = ({
	values,
	connector,
	type,
	hasStorageData,
	initialValues,
	onConnectorChange,
	onValuesChange,
	onConfigurationValidityChange,
}: ConnectorFormProps) => {
	const {
		control,
		formState: { isDirty },
		reset,
		getValues,
		setValue,
	} = useForm<ConnectorFormType>();

	const [fetchConnectors, { data: connectorsData }] = useLazyQuery<FetchConnectorsType>(FETCH_CONNECTORS, {
		onCompleted: ({ document_connectors: items }) => {
			if (!hasStorageData && !connector && items.length > 0) {
				const defaultConnector = items.find(({ code }) => code === 'agiris-fidorg') ?? items[0];
				onConnectorChange(defaultConnector);
				setValue('connector', defaultConnector.code);
				onValuesChange({ connector: defaultConnector.code });
				onConnectorChange(defaultConnector);
			}
		},
	});
	const connectors = useMemo(() => connectorsData?.document_connectors ?? [], [connectorsData]);

	const changeConnectorFormValues = () => {
		const formValues = getValues();
		onValuesChange(formValues);
		onConnectorChange(connectors.find(({ code }) => code === formValues.connector));
	};

	const connectorCodesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: string[];
		}> => {
			const { data, error: queryError } = await fetchConnectors({
				variables: {
					...(type === 'upload' ? { hasWrite: true } : { hasRead: true }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const { document_connectors: fetchedConnectors } = data ?? { document_connectors: [] };

			return {
				count: fetchedConnectors.length,
				items: fetchedConnectors.map(({ code }) => code),
			};
		},
		[fetchConnectors, type],
	);

	useEffect(() => {
		reset(initialValues);
		onConnectorChange(connectors.find(({ code }) => code === initialValues?.connector));
		onValuesChange(initialValues);
	}, [initialValues, connectors, onConnectorChange, reset, onValuesChange]);

	useEffect(() => {
		if (!connector) return;

		const { parameters } = connector;

		const requiredParameterCodes: string[] =
			parameters?.flatMap(({ code, isRequired }) => (isRequired ? [code] : [])) ?? [];

		const isConfigurationValid = requiredParameterCodes.every((code) => !!values?.configuration?.[code]);

		onConfigurationValidityChange(isConfigurationValid && isDirty);
	}, [connector, isDirty, onConfigurationValidityChange, values?.configuration]);

	return (
		<SettingsGroup label="Choix du connecteur">
			<Controller
				control={control}
				render={({ field: { onChange, ...field } }) => {
					const handleChange = (value?: string) => {
						onChange(value);
						changeConnectorFormValues();
						setValue('configuration', null);
						onConnectorChange(connectors.find(({ code }) => code === value));
					};
					return (
						<SettingsItemAutocomplete<string>
							{...field}
							dataSource={connectorCodesDataSource}
							description={
								type === 'upload'
									? "Connecteur de l'emplacement de dépôt."
									: "Connecteur de l'emplacement de consultation GED."
							}
							onChange={handleChange}
							getOptionLabel={(option: string) => option ?? ''}
							label="Connecteur"
							disableClearable
							required
						/>
					);
				}}
				rules={{ required: true }}
				name="connector"
			/>

			<Controller
				control={control}
				render={({ field: { onChange, ...fields } }) => (
					<ConnectorFieldsForm
						{...fields}
						fields={connector?.parameters}
						onChange={(value) => {
							onChange(value);
							changeConnectorFormValues();
						}}
					/>
				)}
				name="configuration"
			/>
		</SettingsGroup>
	);
};

export default ConnectorForm;
