import { useQuery } from '@apollo/client';
import { Button, Icon, IconButton } from '@elipssolution/harfang';
import { mdiClose, mdiDownload } from '@mdi/js';
import { Dialog, DialogProps, Typography, styled, Stack } from '@mui/material';

import { downloadFile } from '../../../../src/utils/file';
import { FETCH_DOCUMENT_UPLOAD, FetchDocumentUploadType } from '../../api/documentUpload';
import DocumentWrapper from '../../components/DocumentWrapper';
import { DocumentUploadType } from '../../types/documentUpload';

const StyledDialog = styled(({ className, ...props }: DialogProps) => (
	<Dialog {...props} classes={{ paper: className }} />
))(({ theme: { shape } }) => ({
	height: '90%',
	minWidth: '90%',

	flexDirection: 'row',

	borderRadius: shape.borderRadius * 3,

	overflow: 'hidden',
}));

const PreviewSection = styled('div')(({ theme: { palette, spacing } }) => ({
	width: `calc(80% - ${spacing(4)})`,

	backgroundColor: palette.grey[500],

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const InfoSection = styled('div')(({ theme: { spacing } }) => ({
	width: '20%',

	padding: spacing(2),

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',

	'& > div:nth-of-type(1)': {
		display: 'flex',
		flexDirection: 'column',
		gap: spacing(2),
	},

	'& > div:nth-of-type(2)': {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

const InfoSectionHeader = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',

	gap: spacing(1),
	marginBottom: spacing(2),
}));

type InfoLineProps = {
	label: string;
	value?: string;
};

const InfoLine = ({ label, value }: InfoLineProps) => (
	<Stack direction="column" gap={0.25}>
		<Typography fontWeight={800}>{label}:</Typography>
		<Typography>{value}</Typography>
	</Stack>
);

type DocumentUploadPreviewDialogProps = {
	documentUploadId?: DocumentUploadType['id'];
	onClose: () => void;
	document?: Blob;
};

const DocumentUploadPreviewDialog = ({ documentUploadId, onClose, document }: DocumentUploadPreviewDialogProps) => {
	const { data: fetchDocumentUploadData } = useQuery<FetchDocumentUploadType>(FETCH_DOCUMENT_UPLOAD, {
		skip: !documentUploadId,
		variables: {
			id: documentUploadId,
		},
	});

	const { name, fileType } = fetchDocumentUploadData?.document_documentUpload ?? {};

	const downloadDocument = () => {
		if (document && name) {
			const documentObjectURL = URL.createObjectURL(document);

			downloadFile(documentObjectURL, name);

			URL.revokeObjectURL(documentObjectURL);
		}
	};

	return (
		<StyledDialog open={!!document} onClose={onClose}>
			<PreviewSection>
				<DocumentWrapper document={document} fileName={name} fileType={fileType} />
			</PreviewSection>

			<InfoSection>
				<div>
					<InfoSectionHeader>
						<Typography variant="h5">Informations</Typography>

						<Stack justifyContent="center" alignItems="center">
							<IconButton onClick={onClose}>
								<Icon path={mdiClose} />
							</IconButton>
						</Stack>
					</InfoSectionHeader>

					<InfoLine label="Nom" value={name} />

					<InfoLine label="Type" value={fileType} />
				</div>

				<div>
					<Button
						disabled={!document}
						onClick={downloadDocument}
						startIcon={<Icon path={mdiDownload} />}
						variant="contained"
					>
						Télécharger
					</Button>
				</div>
			</InfoSection>
		</StyledDialog>
	);
};

export default DocumentUploadPreviewDialog;
