import { Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo } from 'react';

import SettingsTable from '../../../../components/SettingsTable';
import { AddInternalUserCustomerFileRelationType } from '../../../types/relation';

type AddedUserCustomerFileRelationsTableProps = {
	customerFiles: AddInternalUserCustomerFileRelationType[];
	onRemoveCustomerFile: (
		internalUserCustomerFileRelationId: AddInternalUserCustomerFileRelationType['internalUserCustomerFileRelationId'],
	) => Promise<void>;
};

const AddedUserCustomerFileRelationsTable = ({
	customerFiles,
	onRemoveCustomerFile,
}: AddedUserCustomerFileRelationsTableProps) => {
	const columns: TableColumnType<AddInternalUserCustomerFileRelationType>[] = useMemo(
		() => [
			{
				flexGrow: 0,
				key: 'code',
				title: 'Code',
				render: ({ customerFile: { code } }) => code,
				width: 100,
			},
			{
				key: 'name',
				title: "Nom de l'entreprise",
				render: ({ customerFile: { name } }) => name,
				width: 200,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ internalUserCustomerFileRelationId }) => (
					<Tooltip content="Retire la liaison entre l'utilisateur et le dossier">
						<IconButton onClick={() => onRemoveCustomerFile(internalUserCustomerFileRelationId)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
		],
		[onRemoveCustomerFile],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: AddInternalUserCustomerFileRelationType[];
		}> =>
			Promise.resolve({
				items: customerFiles,
				count: customerFiles.length,
			}),
		[customerFiles],
	);

	return <SettingsTable dataSource={dataSource} enableSearch={false} tableColumns={columns} title="Dossiers ajoutés" />;
};

export default AddedUserCustomerFileRelationsTable;
