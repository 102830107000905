import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemTextField,
	Tooltip,
	useAutocomplete,
	useTable,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiEyeOutline, mdiPencil, mdiPlus } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CustomerFilesByUserTable from './CustomerFilesByUserTable';
import InternalUserPermissionModules from './InternalUserPermissionModules';
import ConfirmationButton from '../../../../components/ConfirmationButton';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { PermissionEnum } from '../../../../types/permission';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_INTERNAL_GROUPS,
	FETCH_INTERNAL_GROUP_BY_DEFAULT,
	FetchInternalGroupByDefaultType,
	FetchInternalGroupsType,
} from '../../../api/internalGroup';
import {
	FETCH_INTERNAL_USER,
	FetchInternalUserType,
	REMOVE_INTERNAL_USER,
	RemoveInternalUserType,
	UPDATE_INTERNAL_USER,
	UpdateInternalUserType,
} from '../../../api/internalUser';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import {
	AddInternalUserRelationWithCustomerFilesType,
	AddInternalUserType,
	InternalUserType,
} from '../../../types/user';
import SettingsItemEmailField from '../../SettingsItemEmailField';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	overflow: 'auto',
	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: spacing(1),
	gap: spacing(1),
	margin: '16px auto 0 auto',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormType = {
	lastName?: string;
	firstName?: string;
	email?: string;
	group?: GroupType;
};

type InternalUserFormProps = {
	user: InternalUserType;
};

const InternalUserForm = ({ user: initialUser }: InternalUserFormProps) => {
	const { back } = useSettingsDialog();
	const { user: sessionUser, refetch: refetchSession, checkPermission, impersonatedUser, impersonate } = useSession();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const customerFilesByUserTableInstance = useTable();
	const groupAutocomplete = useAutocomplete();

	const {
		control,
		formState: { isDirty, isValid, dirtyFields },
		handleSubmit,
		reset,
	} = useForm<FormType>({
		mode: 'onChange',
	});

	const [user, setUser] = useState(initialUser);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isUpdateInternalUserSucceeded, setIsUpdateInternalUserSucceeded] = useState(false);

	const { loading: isInternalUserLoading, refetch: refetchInternalUser } = useQuery<FetchInternalUserType>(
		FETCH_INTERNAL_USER,
		{
			variables: { internalUserId: initialUser.id },
			onCompleted: ({ internalUser }) => setUser(internalUser),
		},
	);

	const { data: internalGroupByDefaultData, loading: isInternalGroupByDefaultLoading } =
		useQuery<FetchInternalGroupByDefaultType>(FETCH_INTERNAL_GROUP_BY_DEFAULT);

	const [updateInternalUser, { loading: isUpdateInternalUserLoading }] = useMutation<UpdateInternalUserType>(
		UPDATE_INTERNAL_USER,
		{
			onCompleted: ({ updateInternalUser: result }) => {
				setUser(result);
				if (dirtyFields.group) {
					customerFilesByUserTableInstance.reload();
				}

				if (userCustomerFileRelations.length > 0) {
					const { email, firstName, group, hasUserPermissions, id, lastName, customerFiles, permissionModules } =
						result;

					setUserCustomerFileRelations((prevValues) => {
						if ('user' in prevValues[0]) {
							return (prevValues as AddInternalUserRelationWithCustomerFilesType[]).map(
								({ user: prevUser, customerFiles: prevCustomerFiles }) =>
									prevUser.id === id
										? {
												user: {
													email,
													firstName,
													group,
													hasUserPermissions,
													id,
													lastName,
													customerFiles,
													permissionModules,
												},
												customerFiles: prevCustomerFiles,
										  }
										: { user: prevUser, customerFiles: prevCustomerFiles },
							);
						}

						return [
							...(prevValues as AddInternalUserType[]).filter(({ id: prevUserId }) => id !== prevUserId),
							{ email, firstName, group, hasUserPermissions, id, lastName, customerFiles, permissionModules },
						];
					});
				}
				setIsUpdateInternalUserSucceeded(true);
				setTimeout(() => setIsUpdateInternalUserSucceeded(false), 3000);
			},
			onError: (error) => {
				setErrorMessage(generateErrorInformations({ error, resource: 'updateInternalUser' }).message);
			},
		},
	);

	const [removeInternalUser, { loading: isRemoveInternalUserLoading }] = useMutation<RemoveInternalUserType>(
		REMOVE_INTERNAL_USER,
		{
			onCompleted: ({ removeInternalUser: { id } }) => {
				if (userCustomerFileRelations.length > 0) {
					setUserCustomerFileRelations((prevValues) => {
						if ('user' in prevValues[0]) {
							return (prevValues as AddInternalUserRelationWithCustomerFilesType[]).filter(
								({ user: prevUser }) => prevUser.id !== id,
							);
						}

						return (prevValues as AddInternalUserType[]).filter(({ id: prevUserId }) => id !== prevUserId);
					});
				}
			},
		},
	);

	const [fetchInternalGroups, { loading: isInternalGroupsLoading }] =
		useLazyQuery<FetchInternalGroupsType>(FETCH_INTERNAL_GROUPS);

	const internalGroupDataSource = useCallback(
		async (limit: number, offset: number, search?: string) => {
			const { data, error } = await fetchInternalGroups({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			if (!data) {
				throw new Error('Aucune donnée reçue');
			}

			return data.internalGroups;
		},
		[fetchInternalGroups],
	);

	const isLoading = useMemo(
		() =>
			isInternalUserLoading ||
			isInternalGroupsLoading ||
			isInternalGroupByDefaultLoading ||
			isUpdateInternalUserLoading ||
			isRemoveInternalUserLoading,
		[
			isInternalGroupByDefaultLoading,
			isInternalGroupsLoading,
			isInternalUserLoading,
			isRemoveInternalUserLoading,
			isUpdateInternalUserLoading,
		],
	);

	const isMutationButtonDisabled = useMemo(() => isLoading || !isValid || !isDirty, [isDirty, isLoading, isValid]);

	const {
		id: userId,
		email,
		lastName,
		firstName,
		group = internalGroupByDefaultData?.internalGroupByDefault,
		permissionModules: permissionModulesQuery = [],
	} = user ?? {};

	const isAdministrator = !!group?.isAdministrator;

	const isUserSelectedUserConnected = user.id === sessionUser.id;

	const permissionModules = useMemo(
		() =>
			permissionModulesQuery.map(({ permissions, ...permissionModuleRest }) => ({
				...permissionModuleRest,
				permissions: permissions.map(({ currentValue, value, inheritedValue, ...permissionRest }) => ({
					...permissionRest,
					currentValue: isAdministrator || currentValue,
					inheritedValue: isAdministrator || inheritedValue,
					value: isAdministrator || value,
				})),
			})),
		[isAdministrator, permissionModulesQuery],
	);

	const submitButtonLabel = useMemo(() => {
		if (user) {
			if (isUpdateInternalUserSucceeded) return 'Utilisateur modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [isUpdateInternalUserSucceeded, user]);

	const onSubmit = useCallback(
		({ email: formEmail, lastName: formLastName, firstName: formFirstName, group: formGroup }: FormType) => {
			return updateInternalUser?.({
				variables: {
					updateInternalUserInput: {
						id: user.id,
						email: formEmail,
						lastName: formLastName,
						firstName: formFirstName || null,
						groupId: formGroup?.id,
					},
				},
			}).catch((mutationError: ApolloError) =>
				setErrorMessage(generateErrorInformations({ error: mutationError, resource: 'updateInternalUser' }).message),
			);
		},
		[updateInternalUser, user.id],
	);

	const deleteInternalUser = useCallback(async () => {
		await removeInternalUser({
			variables: {
				removeInternalUserId: user.id,
			},
		});

		back();
	}, [back, removeInternalUser, user.id]);

	const handlePermissionChange = useCallback(() => {
		refetchSession().catch((e) => {
			throw e;
		});

		refetchInternalUser().catch((e) => {
			throw e;
		});
	}, [refetchInternalUser, refetchSession]);

	useEffect(
		() =>
			reset({
				...user,
				group,
			}),
		[reset, user, group],
	);

	return (
		<SettingsDialogPage
			title={`${firstName ?? ''} ${lastName ?? ''}`.trim()}
			titleActionButton={
				userId &&
				lastName &&
				!impersonatedUser &&
				checkPermission(PermissionEnum.IMPERSONATE) &&
				!isUserSelectedUserConnected &&
				!isAdministrator && (
					<Button
						variant="outlined"
						color="inherit"
						onClick={() => impersonate({ id: userId, email, lastName, firstName })}
						startIcon={<Icon path={mdiEyeOutline} />}
					>
						Voir en tant que
					</Button>
				)
			}
		>
			<SettingsGroup>
				<Controller
					name="lastName"
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleUpperCaseChange = (value?: string) => {
							onChange(value?.toUpperCase());
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Nom de l'utilisateur."
								disabled={isInternalUserLoading}
								label="Nom"
								onChange={handleUpperCaseChange}
								required
							/>
						);
					}}
					rules={{ required: true }}
				/>
				<Controller
					name="firstName"
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleFirstLetterUpperCaseChange = (value?: string) => {
							onChange(value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Prénom de l'utilisateur."
								disabled={isInternalUserLoading}
								label="Prénom"
								onChange={handleFirstLetterUpperCaseChange}
							/>
						);
					}}
				/>
				<SettingsItemEmailField control={control} disabled={isInternalUserLoading} initialValue={user.email} />
				<Controller
					name="group"
					control={control}
					render={({ field }) => (
						<SettingsItemAutocomplete<GroupType>
							{...field}
							autocomplete={groupAutocomplete}
							dataSource={internalGroupDataSource}
							description="Groupe attaché à l'utilisateur."
							disabled={isUserSelectedUserConnected}
							getOptionLabel={(option) => option.name}
							label="Groupe"
							disableClearable
							required
						/>
					)}
					rules={{ required: true }}
				/>
			</SettingsGroup>

			<Stack flexDirection="row-reverse" justifyContent="space-between">
				<Button
					disabled={isInternalUserLoading || isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={user ? mdiPencil : mdiPlus} />}
					variant="contained"
				>
					{submitButtonLabel}
				</Button>

				{errorMessage && (
					<ErrorWrapper>
						<Icon path={mdiAlertCircle} />
						<Typography>{errorMessage}</Typography>
					</ErrorWrapper>
				)}

				{lastName && (
					<Tooltip
						content={isUserSelectedUserConnected ? 'Un utilisateur ne peut pas se supprimer lui même' : undefined}
					>
						<div>
							<ConfirmationButton
								confirmLabel="Supprimer"
								title={`Êtes-vous sûr de vouloir supprimer l'utilisateur ${`${firstName ?? ''} ${lastName}`.trim()} ?`}
								color="error"
								variant="outlined"
								disabled={isLoading || isUserSelectedUserConnected}
								onConfirm={deleteInternalUser}
							/>
						</div>
					</Tooltip>
				)}
			</Stack>

			<CustomerFilesByUserTable
				isAdministrator={isAdministrator}
				user={user}
				tableInstance={customerFilesByUserTableInstance}
			/>

			<InternalUserPermissionModules
				disabled={isAdministrator || isUserSelectedUserConnected}
				loading={isInternalUserLoading}
				permissionModules={permissionModules}
				onPermissionChanged={handlePermissionChange}
			/>
		</SettingsDialogPage>
	);
};

export default InternalUserForm;
