import { useLazyQuery } from '@apollo/client';
import { FilterMenuOutputType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import { FetchSynchronizedChecksType, FETCH_SYNCHRONIZED_CHECKS } from '../../../api/check';
import { BankType } from '../../../types/bank';
import { CheckFilterType, CheckType } from '../../../types/check';

const useSynchronizedChecksDataSource = ({ filteredBankId }: { filteredBankId?: BankType['id'] }) => {
	const [fetchSynchronizedChecks, { networkStatus }] = useLazyQuery<FetchSynchronizedChecksType>(
		FETCH_SYNCHRONIZED_CHECKS,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CheckType>,
			filters?: FilterMenuOutputType<CheckFilterType>,
		): Promise<{
			count: number;
			items: CheckType[];
		}> => {
			const { field, order } = orderBy ?? {};

			const { bank: filteredBank, ...restFilters } = filters ?? {};

			const { data, error } = await fetchSynchronizedChecks({
				variables: {
					filter: {
						...restFilters,
						...((filteredBank || filteredBankId) && {
							bankId: {
								eq: filteredBankId ?? filteredBank?.eq?.id,
							},
						}),
					},
					...(orderBy && {
						orderBy: {
							field,
							order,
						},
					}),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_synchronizedChecks: { count = 0, items = [] },
			} = data ?? {
				quickentry_synchronizedChecks: {},
			};

			return {
				count,
				items: items.map(({ documentDate, ...rest }) => ({
					...rest,
					documentDate: new Date(documentDate),
				})),
			};
		},
		[fetchSynchronizedChecks, filteredBankId],
	);

	return { dataSource, networkStatus };
};

export default useSynchronizedChecksDataSource;
