import { useLazyQuery } from '@apollo/client';
import {
	CurrencyFilterDefinitionType,
	FilterMenuOutputType,
	TableColumnType,
	TableTreeSearchResult,
	TreeTable,
	TreeTableInstance,
} from '@elipssolution/harfang';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import DownloadAnalyticSectionsButton from './DownloadAnalyticalSectionsButton';
import { CustomerFileType } from '../../../../types/customerFile';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { AnalyticalType } from '../../../quickentry/types/analytical';
import {
	ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL,
	ACCOUNTING_ANALYTICAL_SECTION_BY_ACCOUNT_LEVELS,
	FetchAccountingAccountsByAnalyticalSectionLevel,
	FetchAccountingAnalyticalSectionByAccountLevel,
	FetchAccountingSearchAccountsByAnalyticalSectionLevelType,
	FetchAccountingSearchAnalyticalSectionByAccountLevelType,
	SEARCH_ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL,
	SEARCH_ANALYTICAL_SECTION_ACCOUNTS_BY_ACCOUNTS_LEVEL,
} from '../../api/analyticalSection';
import { AccountingType } from '../../types/accounting';
import { AnalyticalSectionByAccountLevelType } from '../../types/analyticalSection';
import { formatCurrency } from '../../utils/formatCurrency';
import { transformSearchedResultsForTreeTable } from '../../utils/transformSearchedResultsForTreeTable';

const columns: TableColumnType<AnalyticalSectionByAccountLevelType>[] = [
	{ key: 'code', width: 150, title: 'code', field: 'code' },
	{
		key: 'name',
		width: 150,
		title: 'libelle',
		field: 'name',
	},
	{
		key: 'debit',
		width: 100,
		align: 'right',
		title: 'debit',
		render: ({ debitAmount }) => <FormattedNumber value={+debitAmount} style="currency" currency="EUR" />,
	},
	{
		key: 'credit',
		width: 100,
		align: 'right',
		title: 'credit',
		render: ({ creditAmount }) => <FormattedNumber value={+creditAmount} style="currency" currency="EUR" />,
	},
	{
		key: 'balance',
		width: 100,
		align: 'right',
		title: 'solde',
		render: ({ balance }) => <FormattedNumber value={+balance} style="currency" currency="EUR" />,
	},
];

type AnalyticalSectionFilterType = {
	balance: CurrencyFilterDefinitionType;
};

type AnalyticalSectionsTableProps = {
	isGroupedByAccount: boolean;
	selectedFiscalYear?: FiscalYearType;
	selectedAnalyticalDimension?: AnalyticalType;
	selectedDateRange?: [Date, Date];
	balanceFilter?: CurrencyFilterDefinitionType['value'];
	accountType: AccountingType;
	tableInstance: TreeTableInstance;
	onBalanceFilterChange: (value?: CurrencyFilterDefinitionType['value']) => void;
	expandedLevels: string[][];
	onToggleRows: (expandedLevels: string[][]) => void;
};

const AnalyticalSectionsTable = ({
	isGroupedByAccount,
	selectedFiscalYear,
	selectedAnalyticalDimension,
	selectedDateRange,
	balanceFilter,
	accountType,
	tableInstance,
	onBalanceFilterChange,
	expandedLevels,
	onToggleRows,
}: AnalyticalSectionsTableProps) => {
	const { push, query } = useRouter();
	const { dossier: queryCustomerFileCode } = query || {};

	const [totalCredit, setTotalCredit] = useState('0');
	const [totalDebit, setTotalDebit] = useState('0');
	const [totalBalance, setTotalBalance] = useState('0');
	const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);

	const downloadFilter = useMemo(() => (balanceFilter ? { balance: balanceFilter } : {}), [balanceFilter]);
	const filters: AnalyticalSectionFilterType = useMemo(
		() => ({
			balance: {
				label: 'Solde',
				type: 'currency',
				value: balanceFilter,
			},
		}),
		[balanceFilter],
	);

	const accountingDate = useMemo(
		() => (selectedDateRange?.length === 2 ? { min: selectedDateRange[0], max: selectedDateRange[1] } : null),
		[selectedDateRange],
	);

	const [fetchAnalyticalSectionByAccountLevel] = useLazyQuery<FetchAccountingAnalyticalSectionByAccountLevel>(
		ACCOUNTING_ANALYTICAL_SECTION_BY_ACCOUNT_LEVELS,
		{
			onCompleted: ({
				accounting_analyticalSectionByAccountLevels: {
					totalBalance: queryTotalBalance = '0.00',
					totalDebit: queryTotalDebit = '0.00',
					totalCredit: queryTotalCredit = '0.00',
					items,
				},
			}) => {
				setTotalBalance(queryTotalBalance);
				setTotalCredit(queryTotalCredit);
				setTotalDebit(queryTotalDebit);
				setIsDownloadButtonDisabled(items.length === 0);
			},
		},
	);

	const [fetchAccountsByAnalyticalSectionLevel] = useLazyQuery<FetchAccountingAccountsByAnalyticalSectionLevel>(
		ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL,
		{
			onCompleted: ({
				accounting_accountsByAnalyticalSectionLevel: {
					totalBalance: queryTotalBalance = '0.00',
					totalDebit: queryTotalDebit = '0.00',
					totalCredit: queryTotalCredit = '0.00',
					items,
				},
			}) => {
				setTotalBalance(queryTotalBalance);
				setTotalCredit(queryTotalCredit);
				setTotalDebit(queryTotalDebit);
				setIsDownloadButtonDisabled(items.length === 0);
			},
		},
	);

	const footerData = useMemo(
		() => [
			{ key: 'name', value: 'TOTAL' },
			{
				key: 'credit',
				value: formatCurrency(totalCredit),
			},
			{
				key: 'debit',
				value: formatCurrency(totalDebit),
			},
			{
				key: 'balance',
				value: formatCurrency(totalBalance),
			},
		],
		[totalBalance, totalCredit, totalDebit],
	);

	const getDataSourceGroupedByAccount = useCallback(
		async (
			level: number,
			previousNodeLevels?: string[],
		): Promise<{
			items: AnalyticalSectionByAccountLevelType[];
		}> => {
			if (!selectedFiscalYear || !accountType) {
				return {
					items: [],
				};
			}

			const { data, error } = await fetchAnalyticalSectionByAccountLevel({
				variables: {
					accountingDate,
					fiscalYearId: selectedFiscalYear.id,
					type: accountType,
					level,
					...(previousNodeLevels && {
						accountCode: previousNodeLevels[0],
						parentCode: previousNodeLevels[1],
					}),
					filter: { balance: balanceFilter },
				},
			});

			if (error) {
				throw error;
			}

			const {
				accounting_analyticalSectionByAccountLevels: { items = [] },
			} = data ?? {
				accounting_analyticalSectionByAccountLevels: {},
			};

			return { items };
		},
		[selectedFiscalYear, accountType, fetchAnalyticalSectionByAccountLevel, accountingDate, balanceFilter],
	);

	const getDataSourceGroupedBySection = useCallback(
		async (
			level: number,
			previousNodeLevels?: string[],
		): Promise<{
			items: AnalyticalSectionByAccountLevelType[];
		}> => {
			if (!selectedFiscalYear || !accountType || !selectedAnalyticalDimension) {
				return {
					items: [],
				};
			}

			const { data, error } = await fetchAccountsByAnalyticalSectionLevel({
				variables: {
					accountingDate,
					analyticalDimensionId: selectedAnalyticalDimension.id,
					fiscalYearId: selectedFiscalYear.id,
					type: accountType,
					level,

					...(previousNodeLevels && {
						parentCode: previousNodeLevels[0],
					}),

					filter: { balance: balanceFilter },
				},
			});

			if (error) {
				throw error;
			}

			const {
				accounting_accountsByAnalyticalSectionLevel: { items = [] },
			} = data ?? {
				accounting_accountsByAnalyticalSectionLevel: {},
			};

			return { items };
		},
		[
			selectedFiscalYear,
			accountType,
			selectedAnalyticalDimension,
			fetchAccountsByAnalyticalSectionLevel,
			accountingDate,
			balanceFilter,
		],
	);

	const [fetchAccountingSearchAccountByAnalyticalSectionsLevels] =
		useLazyQuery<FetchAccountingSearchAccountsByAnalyticalSectionLevelType>(
			SEARCH_ACCOUNTING_ACCOUNT_BY_ANALYTICAL_SECTION_LEVEL,
		);

	const [fetchAccountingSearchAnalyticalSectionsByAccountsLevels] =
		useLazyQuery<FetchAccountingSearchAnalyticalSectionByAccountLevelType>(
			SEARCH_ANALYTICAL_SECTION_ACCOUNTS_BY_ACCOUNTS_LEVEL,
		);

	const searchAccountsByAnalyticalSectionsLevels = useCallback(
		async (search: string): Promise<TableTreeSearchResult[]> => {
			if (!selectedFiscalYear || !accountType || !selectedAnalyticalDimension) {
				return [];
			}

			const { data, error } = await fetchAccountingSearchAccountByAnalyticalSectionsLevels({
				variables: {
					accountingDate,
					analyticalDimensionId: selectedAnalyticalDimension.id,
					fiscalYearId: selectedFiscalYear.id,
					type: accountType,
					search,
					filter: { balance: balanceFilter },
				},
			});

			if (error) {
				throw error;
			}

			const { accounting_searchAccountByAnalyticalSectionLevels: searched = [] } = data ?? {
				accounting_searchAccountByAnalyticalSectionLevels: [],
			};

			return transformSearchedResultsForTreeTable(searched);
		},
		[
			selectedFiscalYear,
			accountType,
			selectedAnalyticalDimension,
			fetchAccountingSearchAccountByAnalyticalSectionsLevels,
			accountingDate,
			balanceFilter,
		],
	);

	const searchAnalyticalSectionsByAccountsLevels = useCallback(
		async (search: string): Promise<TableTreeSearchResult[]> => {
			if (!selectedFiscalYear || !accountType) {
				return [];
			}

			const { data, error } = await fetchAccountingSearchAnalyticalSectionsByAccountsLevels({
				variables: {
					accountingDate,
					fiscalYearId: selectedFiscalYear.id,
					type: accountType,
					search,
					filter: { balance: balanceFilter },
				},
			});

			if (error) {
				throw error;
			}

			const { accounting_searchAnalyticalSectionByAccountLevels: searched = [] } = data ?? {
				accounting_searchAnalyticalSectionByAccountLevels: [],
			};

			return transformSearchedResultsForTreeTable(searched);
		},
		[
			selectedFiscalYear,
			accountType,
			fetchAccountingSearchAnalyticalSectionsByAccountsLevels,
			accountingDate,
			balanceFilter,
		],
	);

	const handleFilterSubmit = useCallback(
		(submittedFilters?: FilterMenuOutputType<AnalyticalSectionFilterType>) =>
			onBalanceFilterChange(submittedFilters?.balance),
		[onBalanceFilterChange],
	);

	const handleRowClick = useCallback(
		(analyticalSection: AnalyticalSectionByAccountLevelType) => {
			const { analyticalSectionId, accountId, code, name } = analyticalSection;

			push(
				{
					pathname: 'analyticalSections/transactions',
					query: {
						...(queryCustomerFileCode && { dossier: queryCustomerFileCode }),
						id: accountId,
						analyticalSectionId,
						code,
						name,
						selectedFiscalYear: JSON.stringify(selectedFiscalYear),
						selectedDateRange: JSON.stringify(selectedDateRange),
					},
				},
				`analyticalSections/transactions${
					queryCustomerFileCode ? `?dossier=${queryCustomerFileCode as CustomerFileType['code']}` : ''
				}`,
			).catch((e) => {
				throw e;
			});
		},
		[queryCustomerFileCode, push, selectedDateRange, selectedFiscalYear],
	);

	return (
		<Stack height="100%" width="0" flex={3}>
			<Stack height="100%" width="100%" flex={3}>
				<TreeTable<AnalyticalSectionByAccountLevelType, AnalyticalSectionFilterType>
					treeTable={tableInstance}
					depth={isGroupedByAccount ? 3 : 2}
					title={isGroupedByAccount ? 'Comptes' : 'Sections'}
					dataSource={isGroupedByAccount ? getDataSourceGroupedByAccount : getDataSourceGroupedBySection}
					searchDataSource={
						isGroupedByAccount ? searchAnalyticalSectionsByAccountsLevels : searchAccountsByAnalyticalSectionsLevels
					}
					dataKey="code"
					columns={columns}
					footerData={footerData}
					filters={filters}
					onFilterSubmit={handleFilterSubmit}
					onRowClick={handleRowClick}
					defaultExpandedRows={expandedLevels}
					onToggleRows={onToggleRows}
				/>
			</Stack>
			<Stack alignSelf="flex-end" marginTop="20px">
				<DownloadAnalyticSectionsButton
					disabled={isDownloadButtonDisabled}
					downloadFilter={downloadFilter}
					selectedFiscalYear={selectedFiscalYear}
					selectedType={accountType}
					isGroupedByAccount={isGroupedByAccount}
					analyticalDimension={selectedAnalyticalDimension}
					accountingDate={accountingDate}
				/>
			</Stack>
		</Stack>
	);
};

export default AnalyticalSectionsTable;
