import { gql } from '@apollo/client';

import { PaymentModeType } from '../types/paymentMode';

export const PAYMENT_MODE_FIELDS = `
	id
	code
	name
`;

// QUERIES

export type FetchPaymentModesType = {
	paymentModes: {
		count: number;
		items: PaymentModeType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_PAYMENT_MODES = gql`
	query PaymentModes($orderBy: OrderBy, $page: Page!, $search: String) {
		paymentModes(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${PAYMENT_MODE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchPaymentModesByCustomerFileType = {
	paymentModesByCustomerFile: {
		count: number;
		items: PaymentModeType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_PAYMENT_MODES_BY_CUSTOMER_FILE = gql`
	query PaymentModesByCustomerFile($customerFileId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		paymentModesByCustomerFile(customerFileId: $customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${PAYMENT_MODE_FIELDS}
			}
			limit
			offset
		}
	}
`;
