import { Icon, IconButton, TextField } from '@elipssolution/harfang';
import { mdiHome, mdiMagnify } from '@mdi/js';
import { InputAdornment, Stack, styled, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import FoldersNavigation from './FoldersNavigation';
import StorageDownloadTable from './StorageDownloadTable';
import { CategoryWithChildrenType } from '../../types/category';

const StyledSearchField = styled(TextField)(() => ({
	width: 'auto',
}));

let searchTimeout: ReturnType<typeof setTimeout>;

const StorageDownload = () => {
	const [selectedCategories, setSelectedCategories] = useState<CategoryWithChildrenType[]>([]);
	const [search, setSearch] = useState<string>();
	const [dataSearch, setDataSearch] = useState<string>();

	const lastCategorySelected = useMemo(() => selectedCategories[selectedCategories.length - 1], [selectedCategories]);

	const categoriesTitle = useMemo(
		() =>
			selectedCategories.length === 0
				? 'Consultation GED'
				: selectedCategories.map(({ name }, index) => `${name}${index !== selectedCategories.length - 1 ? ' / ' : ''}`),
		[selectedCategories],
	);

	const addSelectedCategory = (category: CategoryWithChildrenType) => {
		setSelectedCategories((prevValue) => [...prevValue, category]);
		setSearch(undefined);
		setDataSearch(undefined);
	};

	const changeSelectedCategories = (categoriesSelected: CategoryWithChildrenType[]) => {
		setSelectedCategories(categoriesSelected);
		setSearch(undefined);
		setDataSearch(undefined);
	};

	const removeEverySelectedCategory = () => setSelectedCategories([]);

	const handleDocumentSearchChange = useCallback(
		(value?: string) => {
			clearTimeout(searchTimeout);
			setSearch(value);

			searchTimeout = setTimeout(() => {
				setDataSearch(value);
				selectedCategories.length > 0 && setSelectedCategories([]);
			}, 300);
		},
		[selectedCategories.length],
	);

	return (
		<Stack height="100%">
			<Stack flexDirection="row" justifyContent="space-between" alignItems="center">
				<Stack flexDirection="row" gap={1} alignItems="center">
					<IconButton onClick={removeEverySelectedCategory}>
						<Icon path={mdiHome} />
					</IconButton>
					<Typography variant="h6">{categoriesTitle}</Typography>
				</Stack>

				<StyledSearchField
					label="Rechercher..."
					size="small"
					value={search}
					onChange={handleDocumentSearchChange}
					endAdornment={
						<InputAdornment position="end">
							<Icon path={mdiMagnify} />
						</InputAdornment>
					}
				/>
			</Stack>

			<Stack flexDirection="row" flex={1} height="100%" gap={3}>
				<FoldersNavigation
					selectedCategories={selectedCategories}
					onSelectedCategoriesChange={changeSelectedCategories}
					lastCategorySelected={lastCategorySelected}
					isSearchActive={!!dataSearch}
				/>

				<StorageDownloadTable
					onCategoryAdd={addSelectedCategory}
					onSelectedCategoriesChange={changeSelectedCategories}
					search={dataSearch}
					lastCategorySelected={lastCategorySelected}
				/>
			</Stack>
		</Stack>
	);
};

export default StorageDownload;
