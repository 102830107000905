import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { Icon, IconButton, NavigationCarousel, Tooltip, useCarousel } from '@elipssolution/harfang';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { Typography, styled } from '@mui/material';
import { MouseEvent, useCallback, useEffect } from 'react';

import {
	FETCH_CUSTOMER_FILE_TEMPLATES,
	FetchCustomerFileTemplatesType,
	UPDATE_CUSTOMER_FILE_TEMPLATE,
	UpdateCustomerFileTemplateType,
} from '../../api/template';
import { CustomerFileTemplateType } from '../../types/template';

const CarouselItemWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(1),

	padding: spacing(1),

	'& > p': {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

type CarouselItemType = {
	onFavoriteClick: (
		templateId: CustomerFileTemplateType['id'],
		isFavorite: CustomerFileTemplateType['isFavorite'],
	) => void;
	template: CustomerFileTemplateType;
};

const CarouselItem = ({ onFavoriteClick, template: { id, name, isFavorite } }: CarouselItemType) => {
	const handleFavoriteClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();

			onFavoriteClick(id, !isFavorite);
		},
		[onFavoriteClick, id, isFavorite],
	);

	return (
		<CarouselItemWrapper>
			<Typography variant="body2">{name}</Typography>
			<Tooltip content={isFavorite ? 'Retirer des favoris' : 'Ajouter aux favoris'}>
				<IconButton onClick={handleFavoriteClick} size="small">
					<Icon path={isFavorite ? mdiStar : mdiStarOutline} size="small" />
				</IconButton>
			</Tooltip>
		</CarouselItemWrapper>
	);
};

type TemplatesCarouselProps = {
	selectedTemplate?: CustomerFileTemplateType;
	onTemplateSelection: (template: CustomerFileTemplateType) => void;
};

const TemplatesCarousel = ({ selectedTemplate, onTemplateSelection }: TemplatesCarouselProps) => {
	const carouselInstance = useCarousel();

	const [fetchTemplates, { networkStatus }] = useLazyQuery<FetchCustomerFileTemplatesType>(
		FETCH_CUSTOMER_FILE_TEMPLATES,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const templatesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: CustomerFileTemplateType[];
		}> => {
			const { data: templatesData, error } = await fetchTemplates({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_customerFileTemplates: { count = 0, items = [] },
			} = templatesData ?? {
				quickentry_customerFileTemplates: {},
			};

			return { count, items };
		},
		[fetchTemplates],
	);

	const [updateCustomerFileTemplate] = useMutation<UpdateCustomerFileTemplateType>(UPDATE_CUSTOMER_FILE_TEMPLATE, {
		onCompleted: () => {
			carouselInstance.reload();
		},
	});

	const handleFavoriteClick = useCallback(
		(templateId: CustomerFileTemplateType['id'], isFavorite: CustomerFileTemplateType['isFavorite']) => {
			updateCustomerFileTemplate({
				variables: {
					updateCustomerFileTemplateInput: {
						templateId,
						isFavorite,
					},
				},
			}).catch((e) => {
				throw e;
			});
		},
		[updateCustomerFileTemplate],
	);

	const renderItem = useCallback(
		(item: CustomerFileTemplateType) => <CarouselItem template={item} onFavoriteClick={handleFavoriteClick} />,
		[handleFavoriteClick],
	);

	const getIsTemplateSelected = useCallback(
		({ id }: CustomerFileTemplateType) => id === selectedTemplate?.id,
		[selectedTemplate],
	);

	const handleTemplateSelection = useCallback(
		(template: CustomerFileTemplateType) => onTemplateSelection(template),
		[onTemplateSelection],
	);

	// Reload the carousel if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && carouselInstance.reload();
	}, [carouselInstance, networkStatus]);

	return (
		<NavigationCarousel<CustomerFileTemplateType>
			carousel={carouselInstance}
			itemHeight={80}
			dataSource={templatesDataSource}
			isItemSelected={getIsTemplateSelected}
			itemWidth={150}
			onItemClick={handleTemplateSelection}
			renderItem={renderItem}
			title="Modèles"
			enableSearch
		/>
	);
};

export default TemplatesCarousel;
