import { Button, Icon } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';

interface WidgetExternalActionButtonsProps {
	isNew: boolean;
	isValid: boolean;
	onRemove: () => void;
	onSubmit: () => Promise<unknown>;
}

export const WidgetExternalActionButtons = ({
	isNew,
	isValid,
	onRemove,
	onSubmit,
}: WidgetExternalActionButtonsProps) => (
	<Stack direction="row-reverse" justifyContent="space-between" gap={1}>
		<Button
			disabled={!isValid}
			startIcon={<Icon path={mdiPencil} size="small" />}
			variant="contained"
			onClick={onSubmit}
		>
			{isNew ? 'Ajouter' : 'Modifier'}
		</Button>

		{!isNew && (
			<Button variant="outlined" color="error" onClick={onRemove}>
				Supprimer
			</Button>
		)}
	</Stack>
);
