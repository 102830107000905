import { gql } from '@apollo/client';

import { DOMAIN_FIELDS } from './domain';
import {
	CustomerFileType,
	CustomerFileWithDomainType,
	CustomerFileWithDomainTypeAndIsDashboardInherited,
	CustomerFileWithDomainTypeAndUserPermission,
} from '../../types/customerFile';

export const CUSTOMER_FILE_FIELDS = `
	code
	id
	name
`;

export const CUSTOMER_FILE_WITH_DOMAIN_FIELDS = `
	${CUSTOMER_FILE_FIELDS}
	domain {
		${DOMAIN_FIELDS}
	}
`;

const CUSTOMER_FILE_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED_FIELDS = `
	${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
	isDashboardInherited
`;

// QUERIES

export type FetchCustomerFilesType = {
	customerFiles: {
		items: CustomerFileType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES = gql`
	query CustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		customerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchAllCustomerFileIdsByDomainType = {
	customerFilesByDomainWithoutPagination: {
		id: CustomerFileType['id'];
	}[];
};

export const FETCH_ALL_CUSTOMER_FILE_IDS_BY_DOMAIN = gql`
	query CustomerFilesByDomainWithoutPagination($domainId: ID!) {
		customerFilesByDomainWithoutPagination(domainId: $domainId) {
			id
		}
	}
`;

export type FetchCustomerFilesWithDomainType = {
	customerFiles: {
		items: CustomerFileWithDomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_DOMAIN = gql`
	query CustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		customerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				connector
			}
			limit
			offset
		}
	}
`;

export type FetchArchivedCustomerFilesWithDomainType = {
	archivedCustomerFiles: {
		items: CustomerFileWithDomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_ARCHIVED_CUSTOMER_FILES_WITH_DOMAIN = gql`
	query ArchivedCustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		archivedCustomerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithDomainAndIsDashboardInheritedType = {
	customerFilesWithIsDashboardInherited: {
		items: CustomerFileWithDomainTypeAndIsDashboardInherited[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED = gql`
	query customerFilesWithIsDashboardInherited($orderBy: OrderBy, $page: Page, $search: String,$filter:CustomerFileWithIsDashboardInheritedFilterInput) {
		customerFilesWithIsDashboardInherited(orderBy: $orderBy, page: $page, search: $search,filter:$filter) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithoutRelationWithUser = {
	customerFilesWithoutRelationWithUser: {
		count: number;
		items: CustomerFileType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER = gql`
	query CustomerFilesWithoutRelationWithUser($userId: ID!, $page: Page!, $search: String) {
		customerFilesWithoutRelationWithUser(userId: $userId, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithUserPermissionType = {
	customerFilesWithUserPermission: {
		count: number;
		items: CustomerFileWithDomainTypeAndUserPermission[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_USER_PERMISSION = gql`
	query CustomerFilesWithUserPermission($page: Page!, $orderBy: OrderBy, $search: String) {
		customerFilesWithUserPermission(page: $page, orderBy: $orderBy, search: $search) {
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				hasUserPermissionToManage
			}
			count
			limit
			offset
		}
	}
`;

export type FetchCustomerFileType = {
	customerFile: CustomerFileType;
};

export const FETCH_CUSTOMER_FILE = gql`
	query CustomerFile($id: ID!) {
		customerFile(id: $id) {
			${CUSTOMER_FILE_FIELDS}
			connector
			minimumEntryDate
		}
	}
`;

export type FetchCustomerFileByCodeType = {
	customerFileByCode: CustomerFileType;
};

export const FETCH_CUSTOMER_FILE_BY_CODE = gql`
	query CustomerFileByCode($code: String!) {
  		customerFileByCode(code: $code) {
			${CUSTOMER_FILE_FIELDS}
			connector
			minimumEntryDate
		}
	}
`;

// MUTATIONS

export type CreateCustomerFileType = {
	createCustomerFile: CustomerFileWithDomainType;
};

export const CREATE_CUSTOMER_FILE = gql`
	mutation CreateCustomerFile($createCustomerFileInput: CreateCustomerFileInput!) {
		createCustomerFile(createCustomerFileInput: $createCustomerFileInput) {
			${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
		}
	}
`;

export type UpdateCustomerFileType = {
	updateCustomerFile: CustomerFileWithDomainType;
};

export const UPDATE_CUSTOMER_FILE = gql`
	mutation UpdateCustomerFile($updateCustomerFileInput: UpdateCustomerFileInput!) {
		updateCustomerFile(updateCustomerFileInput: $updateCustomerFileInput) {
			${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
		}
	}
`;
export type RemoveCustomerFileType = {
	removeCustomerFile: {
		id: CustomerFileType['id'];
	};
};

export const REMOVE_CUSTOMER_FILE = gql`
	mutation RemoveCustomerFile($removeCustomerFileId: ID!) {
		removeCustomerFile(id: $removeCustomerFileId) {
			id
		}
	}
`;

export type CheckCustomerFileCodeAvailabilityType = {
	checkCustomerFileCodeAvailability: boolean;
};

export const CHECK_CUSTOMER_FILE_CODE_AVAILABILITY = gql`
	query CheckCustomerFileCodeAvailability($code: String!) {
		checkCustomerFileCodeAvailability(code: $code)
	}
`;
