import { gql } from '@apollo/client';

import { AccountWithTransactionType } from '../types/account';
import { ThirdPartyType } from '../types/thirdParty';
import { ACCOUNT_WITH_TRANSACTION_FIELDS } from './account';

const THIRD_PARTY_FIELDS = `
	id
    balance
    code
    name
`;

// QUERIES

export type FetchThirdPartiesType = {
	accounting_thirdParties: {
		count: number;
		items: ThirdPartyType[];
		limit: number;
		offset: number;
		totalBalance: string;
	};
};

export const FETCH_THIRD_PARTIES = gql`
	query Accounting_thirdParties(
		$accountingDate: BetweenDate
		$fiscalYear: ID!
		$type: accounting_ThirdPartyType!
		$hasTransaction: Boolean!
		$search: String
		$filter: accounting_ThirdPartyFilterInput
		$page: Page!
		$orderBy: OrderBy
	) {
		accounting_thirdParties(
			accountingDate: $accountingDate
			fiscalYear: $fiscalYear
			type: $type
			hasTransaction: $hasTransaction
			search: $search
			filter: $filter
			page: $page
			orderBy: $orderBy
		) {
			count
			items {
				${THIRD_PARTY_FIELDS}
			}
			limit
			offset
			totalBalance
		}
	}
`;

export type FetchPreviousAndNextThirdPartiesWithTransactionType = {
	accounting_getPreviousAndNextThirdPartiesWithTransactionBySelectedThirdParty: {
		previous: AccountWithTransactionType;
		next: AccountWithTransactionType;
	};
};

export const FETCH_PREVIOUS_AND_NEXT_THIRD_PARTIES_WITH_TRANSACTION = gql`
	query Accounting_getPreviousAndNextThirdPartiesWithTransactionBySelectedThirdParty(
		$selectedId: ID!
		$fiscalYearId: ID!
		$accountingDate: BetweenDate
		$filter: accounting_ThirdPartyFilterInput
	) {
		accounting_getPreviousAndNextThirdPartiesWithTransactionBySelectedThirdParty(
			selectedId: $selectedId
			fiscalYearId: $fiscalYearId
			accountingDate: $accountingDate
			filter: $filter
		) {
			next {
				${ACCOUNT_WITH_TRANSACTION_FIELDS}
			}
			previous {
				${ACCOUNT_WITH_TRANSACTION_FIELDS}
			}
		}
	}
`;
