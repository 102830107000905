import { Icon } from '@elipssolution/harfang';
import { mdiChevronRight } from '@mdi/js';
import { styled } from '@mui/material';

const StyledIcon = styled(Icon)(({ theme: { palette, spacing } }) => ({
	color: palette.grey[500],
	padding: spacing(0, 1),
}));

const LinkIcon = () => <StyledIcon path={mdiChevronRight} />;

export default LinkIcon;
