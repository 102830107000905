import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemTextField,
	useAutocomplete,
} from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddedCustomerFileUsersTableSection from './AddedCustomerFileUsersTableSection';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_EXTERNAL_GROUPS, FetchExternalGroupsType } from '../../../api/externalGroup';
import {
	FETCH_EXTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION,
	FetchExternalUsersWithoutCustomerFileRelationType,
} from '../../../api/externalUser';
import {
	CREATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION,
	CREATE_EXTERNAL_USER_WITH_CUSTOMER_FILES,
	CreateExternalUserCustomerFileRelationType,
	CreateExternalUserWithCustomerFilesType,
	REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
	RemoveExternalUserCustomerFileRelationByCustomerFileAndUserType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { GroupType } from '../../../types/group';
import { AddExternalUserRelationType, ExternalUserType, UserTypeEnum } from '../../../types/user';
import SettingsItemEmailField from '../../SettingsItemEmailField';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const FormWrapper = styled('form')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),
}));

const ErrorWrapper = styled('div')(({ theme }) => ({
	height: 36,
	width: '50%',
	display: 'grid',
	placeItems: 'center',
	color: theme.palette.error.main,
	backgroundColor: `${theme.palette.error.main}1A`,
	borderRadius: theme.shape.borderRadius * 2,
}));

type AddingModeType = {
	id: string;
	label: string;
};

const addingModes: AddingModeType[] = [
	{ id: 'existing', label: 'Existant' },
	{ id: 'new', label: 'Nouveau' },
];

const addingModesDataSource = async (): Promise<{
	items: AddingModeType[];
	count: number;
}> =>
	Promise.resolve({
		items: addingModes,
		count: addingModes.length,
	});

type FormType = {
	user?: ExternalUserType;
	group?: GroupType;
	addingMode?: AddingModeType;
	firstName?: ExternalUserType['firstName'];
	lastName?: ExternalUserType['lastName'];
	email?: ExternalUserType['email'];
};

type AddCustomerFileUserRelationFormProps = {
	customerFile: CustomerFileType;
};

const AddCustomerFileUserRelationForm = ({ customerFile }: AddCustomerFileUserRelationFormProps) => {
	const { back } = useSettingsDialog();
	const {
		user: { type },
	} = useSession();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const groupAutocomplete = useAutocomplete();
	const externalUsersAutocomplete = useAutocomplete();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		watch,
		reset,
	} = useForm<FormType>({
		mode: 'onChange',
	});

	const addingMode = watch('addingMode');

	const [error, setError] = useState<string>();
	const [usersToAdd, setUsersToAdd] = useState(userCustomerFileRelations as AddExternalUserRelationType[]);

	const [fetchExternalUsersWithoutRelation] = useLazyQuery<FetchExternalUsersWithoutCustomerFileRelationType>(
		FETCH_EXTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION,
	);

	const [fetchExternalGroups] = useLazyQuery<FetchExternalGroupsType>(FETCH_EXTERNAL_GROUPS);

	const [createExternalUserCustomerFileRelation] = useMutation<CreateExternalUserCustomerFileRelationType>(
		CREATE_EXTERNAL_USER_CUSTOMER_FILE_RELATION,
		{
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createExternalUserCustomerFileRelation' }).message,
				),
		},
	);

	const [createExternalUserWithCustomerFiles] = useMutation<CreateExternalUserWithCustomerFilesType>(
		CREATE_EXTERNAL_USER_WITH_CUSTOMER_FILES,
		{
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createExternalUserWithCustomerFiles' }).message,
				),
		},
	);

	const [removeExternalUserCustomerFileRelation] =
		useMutation<RemoveExternalUserCustomerFileRelationByCustomerFileAndUserType>(
			REMOVE_EXTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
			{
				onError: (mutationError) =>
					setError(
						generateErrorInformations({ error: mutationError, resource: 'removeExternalUserCustomerFileRelation' })
							.message,
					),
			},
		);

	const externalUsersDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: ExternalUserType[];
		}> => {
			const { data, error: queryError } = await fetchExternalUsersWithoutRelation({
				variables: {
					customerFileId: customerFile.id,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				externalUsersWithoutCustomerFileRelation: { count = 0, items = [] },
			} = data ?? {
				externalUsersWithoutCustomerFileRelation: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchExternalUsersWithoutRelation, customerFile.id],
	);

	const externalGroupsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: GroupType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchExternalGroups({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				externalGroups: { count = 0, items = [] },
			} = data ?? {
				externalGroups: {},
			};

			return {
				items,
				count,
			};
		},
		[fetchExternalGroups],
	);

	const handleFormReset = useCallback(
		(addMode: AddingModeType) => {
			reset({
				user: undefined,
				firstName: undefined,
				lastName: undefined,
				email: undefined,
				group: undefined,
				addingMode: addMode,
			});

			groupAutocomplete.reload();
			externalUsersAutocomplete.reload();
		},
		[externalUsersAutocomplete, groupAutocomplete, reset],
	);

	const onSubmit = useCallback(
		({ user, group, firstName, lastName, email }: FormType) => {
			const modeMutationInfoMap = new Map([
				[
					'existing',
					{
						mutation: createExternalUserCustomerFileRelation,
						mutationName: 'createExternalUserCustomerFileRelation',
						inputValues: {
							groupId: group?.id,
							customerFileId: customerFile.id,
							userId: user?.id,
						},
					},
				],
				[
					'new',
					{
						mutation: createExternalUserWithCustomerFiles,
						mutationName: 'createExternalUserWithCustomerFiles',
						inputValues: {
							customerFilesWithGroup: [
								{
									customerFileId: customerFile.id,
									groupId: group?.id,
								},
							],
							externalUser: {
								email,
								firstName,
								lastName,
							},
						},
					},
				],
			]);

			const { mutation, mutationName = '', inputValues } = modeMutationInfoMap.get(user ? 'existing' : 'new') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { externalUser }: { externalUser: ExternalUserType } = data[mutationName as keyof typeof data];

					setUsersToAdd((prevUsers) => [...prevUsers, { user: externalUser, group }]);

					addingMode && handleFormReset(addingMode);
				})
				.catch((mutationError: ApolloError) =>
					setError(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[
			createExternalUserCustomerFileRelation,
			customerFile.id,
			createExternalUserWithCustomerFiles,
			addingMode,
			handleFormReset,
		],
	);

	const removeUser = useCallback(
		async (userId: AddExternalUserRelationType['user']['id']) => {
			await removeExternalUserCustomerFileRelation({
				variables: { customerFileId: customerFile.id, userId },
			});

			externalUsersAutocomplete.reload();

			setUsersToAdd((prevUsers) => {
				const userToAddFiltered = prevUsers.filter(({ user: { id } }) => id !== userId);

				setUserCustomerFileRelations(userToAddFiltered);

				return userToAddFiltered;
			});
		},
		[customerFile.id, externalUsersAutocomplete, removeExternalUserCustomerFileRelation, setUserCustomerFileRelations],
	);

	const handleBack = useCallback(() => {
		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
		back();
	}, [back, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	useEffect(() => {
		if (usersToAdd.length > 0) {
			setUserCustomerFileRelations(usersToAdd);
		}
	}, [setUserCustomerFileRelations, usersToAdd]);

	return (
		<SettingsDialogPage title={`Ajout d'utilisateurs - ${customerFile.name}`} onBack={handleBack}>
			<FormWrapper>
				<SettingsGroup label="Mode d'ajout">
					<Controller
						control={control}
						defaultValue={addingModes[0]}
						render={({ field: { onChange, ...field } }) => {
							const handleChange = (newAddingMode?: AddingModeType) => {
								onChange(newAddingMode);
								newAddingMode && handleFormReset(newAddingMode);
							};

							return (
								<SettingsItemAutocomplete<AddingModeType>
									{...field}
									dataSource={addingModesDataSource}
									description="Permet de lier un utilisateur existant ou un nouvel utilisateur au dossier."
									getOptionLabel={({ label }) => label}
									label="Mode d'ajout"
									onChange={handleChange}
									disableClearable
								/>
							);
						}}
						name="addingMode"
					/>

					{addingMode?.id === 'existing' && (
						<Controller
							control={control}
							name="user"
							render={({ field }) => (
								<SettingsItemAutocomplete<ExternalUserType>
									{...field}
									autocomplete={externalUsersAutocomplete}
									dataSource={externalUsersDataSource}
									description="Utilisateur à ajouter."
									getOptionLabel={({ lastName, firstName }) => `${firstName ?? ''} ${lastName}`.trim()}
									label="Utilisateur"
									disableClearable
									required
								/>
							)}
							rules={{ required: true }}
						/>
					)}

					{addingMode?.id === 'new' && (
						<>
							<Controller
								control={control}
								render={({ field: { onChange, ...field } }) => {
									const handleUpperCaseChange = (value?: string) => {
										onChange(value?.toUpperCase());
									};

									return (
										<SettingsItemTextField
											{...field}
											description="Nom de l'utilisateur."
											label="Nom"
											onChange={handleUpperCaseChange}
											required
										/>
									);
								}}
								name="lastName"
								rules={{ required: true }}
							/>
							<Controller
								control={control}
								render={({ field: { onChange, ...field } }) => {
									const handleFirstLetterUpperCaseChange = (value?: string) => {
										onChange(value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
									};

									return (
										<SettingsItemTextField
											{...field}
											description="Prénom de l'utilisateur."
											label="Prénom"
											onChange={handleFirstLetterUpperCaseChange}
										/>
									);
								}}
								name="firstName"
							/>
							<SettingsItemEmailField control={control} />
						</>
					)}
					{type === UserTypeEnum.INTERNAL && (
						<Controller
							control={control}
							name="group"
							render={({ field }) => (
								<SettingsItemAutocomplete<GroupType>
									{...field}
									autocomplete={groupAutocomplete}
									dataSource={externalGroupsDataSource}
									description="Groupe attaché à l'utilisateur."
									getOptionLabel={({ name }) => name ?? ''}
									label="Groupe"
									disableClearable
									required
								/>
							)}
							rules={{ required: true }}
						/>
					)}
				</SettingsGroup>
			</FormWrapper>

			<Stack alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
				<Button
					disabled={!isDirty || !isValid}
					startIcon={<Icon path={mdiPlus} />}
					onClick={handleSubmit(onSubmit)}
					variant="contained"
				>
					{addingMode?.id === 'new' ? 'Lier un nouvel utilisateur' : 'Lier un utilisateur existant'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</Stack>

			<AddedCustomerFileUsersTableSection customerFile={customerFile} users={usersToAdd} onRemoveUser={removeUser} />
		</SettingsDialogPage>
	);
};

export default AddCustomerFileUserRelationForm;
