import { Chip, Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { Stack, styled, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import InternalUserForm from './InternalUserForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { AddInternalUserRelationWithCustomerFilesType, InternalUserType } from '../../../types/user';

const TooltipCustomerFilesWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: spacing(0.5),
	fontWeight: 400,

	'& > div': {
		minHeight: 24,
		height: 'fit-content',
		paddingTop: spacing(0.5),
		paddingBottom: spacing(0.5),

		'& > span': {
			whiteSpace: 'wrap',
		},
	},
}));

const MeasureChip = styled(Chip)({
	position: 'absolute',
	visibility: 'hidden',
	zIndex: -1,
});

const CustomerFilesWrapper = ({
	columnWidth,
	customerFiles,
}: {
	columnWidth: number;
	customerFiles: AddInternalUserRelationWithCustomerFilesType['customerFiles'];
}) => {
	const { spacing } = useTheme();

	const measureChipRef = useRef<HTMLDivElement>(null);

	const [visibleCustomerFiles, setVisibleCustomerFiles] =
		useState<AddInternalUserRelationWithCustomerFilesType['customerFiles']>();

	const measureChipElement = measureChipRef.current;
	const measureChipSpan = measureChipElement?.children[0];
	const moreCustomerFilesQuantity = visibleCustomerFiles ? customerFiles.length - visibleCustomerFiles.length : 0;
	const sortedCustomerFiles = customerFiles.sort((customerFileA, customerFileB) =>
		customerFileA.name.length >= customerFileB.name.length ? 1 : -1,
	);

	useEffect(() => {
		if (!measureChipElement || !measureChipSpan) return;

		let maxCustomerFiles = 0;
		let currentWidth = 31;

		sortedCustomerFiles.every(({ name }) => {
			measureChipSpan.textContent = name;
			const chipWidth = measureChipElement.offsetWidth + +spacing(1).slice(0, -2);

			if (currentWidth + chipWidth < columnWidth) {
				currentWidth += chipWidth;
				maxCustomerFiles += 1;

				return true;
			}

			return false;
		});

		setVisibleCustomerFiles(sortedCustomerFiles.slice(0, maxCustomerFiles));
	}, [spacing, measureChipElement, measureChipSpan, columnWidth, sortedCustomerFiles]);

	return (
		<>
			{visibleCustomerFiles?.map(({ id, name }) => (
				<Chip key={id} label={name} />
			))}

			{moreCustomerFilesQuantity > 0 && (
				<Tooltip
					content={
						<TooltipCustomerFilesWrapper>
							{sortedCustomerFiles.slice(-moreCustomerFilesQuantity).map(({ id, name }) => (
								<Chip key={id} label={name} size="small" />
							))}
						</TooltipCustomerFilesWrapper>
					}
				>
					<Chip label={`+${moreCustomerFilesQuantity}`} size="small" />
				</Tooltip>
			)}
			<MeasureChip ref={measureChipRef} />
		</>
	);
};

type AddedUsersTableSectionProps = {
	users: AddInternalUserRelationWithCustomerFilesType[];
	onRemoveUser: (userEmail: AddInternalUserRelationWithCustomerFilesType['user']['id']) => Promise<void>;
};

const AddedUsersTableSection = ({ users, onRemoveUser }: AddedUsersTableSectionProps) => {
	const { push } = useSettingsDialog();

	const removeUser = useCallback(
		async (
			event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
			userId: AddInternalUserRelationWithCustomerFilesType['user']['id'],
		) => {
			event.stopPropagation();
			await onRemoveUser(userId);
		},
		[onRemoveUser],
	);

	const columns: TableColumnType<AddInternalUserRelationWithCustomerFilesType>[] = useMemo(
		() => [
			{
				key: 'name',
				render: ({ user: { firstName, lastName } }) => `${lastName} ${firstName ?? ''}`.trim(),
				title: 'Nom',
				width: 200,
			},
			{
				key: 'email',
				render: ({ user: { email } }) => email,
				title: 'Email',
				width: 200,
			},
			{
				key: 'customerFiles',
				render: ({ customerFiles }) => (
					<ReactResizeDetector handleWidth>
						{({ width }) => (
							<Stack alignItems="center" direction="row" gap={1} overflow="hidden" width="100%">
								<CustomerFilesWrapper columnWidth={width ?? 0} customerFiles={customerFiles} />
							</Stack>
						)}
					</ReactResizeDetector>
				),
				title: 'Dossiers',
				width: 200,
			},
			{
				flexGrow: 0,
				key: 'group',
				render: ({ user: { group } }) => {
					const { isAdministrator, isDefault, name } = group || {};

					return isAdministrator ? (
						<Chip label={name} color="error" />
					) : (
						<Chip label={name} color={isDefault ? 'info' : 'default'} />
					);
				},
				title: 'Groupe',
				width: 150,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ user: { id }, customerFiles }) => (
					<Tooltip
						content={`Supprime l'utilisateur créé${
							customerFiles.length > 0 ? ' et les liens avec les dossiers choisis' : ''
						}`}
					>
						<IconButton onClick={(event) => removeUser(event, id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
			{
				key: 'navigate',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[removeUser],
	);

	const handleRowClick = useCallback(
		({ user }: AddInternalUserRelationWithCustomerFilesType) => {
			push(<InternalUserForm user={user as InternalUserType} />);
		},
		[push],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: AddInternalUserRelationWithCustomerFilesType[];
		}> =>
			Promise.resolve({
				items: users,
				count: users.length,
			}),
		[users],
	);

	return (
		<SettingsTable
			dataSource={dataSource}
			enableSearch={false}
			tableColumns={columns}
			title="Utilisateurs ajoutés"
			onRowClick={handleRowClick}
		/>
	);
};

export default AddedUsersTableSection;
