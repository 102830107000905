import { gql } from '@apollo/client';

import { DOCUMENT_UPLOAD_TAG_FIELDS } from './tag';
import { DocumentUploadType } from '../types/documentUpload';

// QUERIES

export type FetchDocumentUploadsType = {
	document_documentUploads: {
		items: DocumentUploadType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENT_UPLOADS = gql`
	query Document_documentUploads($search: String, $filter: document_DocumentUploadFilterInput, $page: Page!, $orderBy: OrderBy) {
		document_documentUploads(search: $search, filter: $filter, page: $page, orderBy: $orderBy) {
			count
			items {
				createdAt
				createdBy
				id
				status
				name
				tag {
					${DOCUMENT_UPLOAD_TAG_FIELDS}
				}
			}
			limit
			offset
		}
	}
`;

export type FetchDocumentUploadType = {
	document_documentUpload: DocumentUploadType;
};

export const FETCH_DOCUMENT_UPLOAD = gql`
	query Document_documentUpload($id: ID!) {
		document_documentUpload(id: $id) {
			id
			fileType
			name
		}
	}
`;
