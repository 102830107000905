import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Icon, SettingsItemAutocomplete, useAutocomplete } from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddedUserCustomerFileRelationsTable from './AddedUserCustomerFileRelationsTable';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER,
	FetchCustomerFilesWithoutRelationWithUser,
} from '../../../api/customerFile';
import {
	CREATE_INTERNAL_USER_CUSTOMER_FILE_RELATION,
	CreateInternalUserCustomerFileRelationType,
	REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION,
} from '../../../api/internalUserCustomerFile';
import { AddInternalUserCustomerFileRelationType } from '../../../types/relation';
import { UserType } from '../../../types/user';

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormType = {
	customerFile: CustomerFileType;
};

type AddUserCustomerFileRelationsFormProps = {
	user: UserType;
};

const AddUserCustomerFileRelationsForm = ({
	user: { id: userId, firstName, lastName },
}: AddUserCustomerFileRelationsFormProps) => {
	const customerFileAutocomplete = useAutocomplete();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const [error, setError] = useState<string>();
	const [customerFilesToAdd, setCustomerFilesToAdd] = useState<AddInternalUserCustomerFileRelationType[]>([]);

	const [fetchCustomerFilesWithoutRelation] = useLazyQuery<FetchCustomerFilesWithoutRelationWithUser>(
		FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER,
	);

	const customerFilesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data, error: queryError } = await fetchCustomerFilesWithoutRelation({
				variables: {
					userId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				customerFilesWithoutRelationWithUser: { count = 0, items = [] },
			} = data ?? {
				customerFilesWithoutRelationWithUser: {},
			};

			return {
				items,
				count,
			};
		},
		[fetchCustomerFilesWithoutRelation, userId],
	);

	const [createInternalUserCustomerFileRelation, { loading: isCreateInternalUserCustomerFileRelationLoading }] =
		useMutation<CreateInternalUserCustomerFileRelationType>(CREATE_INTERNAL_USER_CUSTOMER_FILE_RELATION, {
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createInternalUserCustomerFileRelation' }).message,
				),
		});

	const [removeInternalUserCustomerFileRelation] = useMutation(REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION, {
		onError: (removeError) =>
			setError(
				generateErrorInformations({ error: removeError, resource: 'removeInternalUserCustomerFileRelation' }).message,
			),
	});

	const handleFormReset = useCallback(() => {
		reset({
			customerFile: undefined,
		});

		customerFileAutocomplete.reload();
	}, [customerFileAutocomplete, reset]);

	const onSubmit = useCallback(
		({ customerFile }: FormType) =>
			createInternalUserCustomerFileRelation({
				variables: {
					createInternalUserCustomerFileRelationInput: {
						customerFileId: customerFile.id,
						userId,
					},
				},
			}).then(({ data }) => {
				if (!data) return;

				const { id: internalUserCustomerFileRelationId } = data.createInternalUserCustomerFileRelation;

				setCustomerFilesToAdd((prevCustomerFiles) => [
					...prevCustomerFiles,
					{ customerFile, internalUserCustomerFileRelationId },
				]);
				handleFormReset();
			}),
		[createInternalUserCustomerFileRelation, handleFormReset, userId],
	);

	const handleRemoveInternalUserCustomerFileRelation = useCallback(
		async (
			internalUserCustomerFileRelationId: AddInternalUserCustomerFileRelationType['internalUserCustomerFileRelationId'],
		) => {
			await removeInternalUserCustomerFileRelation({
				variables: {
					removeInternalUserCustomerFileRelationId: internalUserCustomerFileRelationId,
				},
			});

			setCustomerFilesToAdd((prevCustomerFiles) =>
				prevCustomerFiles.filter(
					({ internalUserCustomerFileRelationId: prevId }) => prevId !== internalUserCustomerFileRelationId,
				),
			);
			customerFileAutocomplete.reload();
		},
		[customerFileAutocomplete, removeInternalUserCustomerFileRelation],
	);

	return (
		<SettingsDialogPage title={`Ajout de dossiers - ${`${firstName ?? ''} ${lastName}`.trim()}`}>
			<Controller
				control={control}
				name="customerFile"
				render={({ field }) => (
					<SettingsItemAutocomplete<CustomerFileType>
						{...field}
						autocomplete={customerFileAutocomplete}
						dataSource={customerFilesDataSource}
						description="Dossier à lier sur l'utilisateur."
						getOptionLabel={({ code, name }) => (code ? `${code} - ${name}` : '')}
						label="Dossier"
						disableClearable
					/>
				)}
				rules={{ required: true }}
			/>

			<Stack justifyContent="space-between" flexDirection="row-reverse">
				<Button
					disabled={isCreateInternalUserCustomerFileRelationLoading || !isDirty || !isValid}
					startIcon={<Icon path={mdiPlus} />}
					onClick={handleSubmit(onSubmit)}
					variant="contained"
				>
					Ajouter
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</Stack>

			<AddedUserCustomerFileRelationsTable
				customerFiles={customerFilesToAdd}
				onRemoveCustomerFile={handleRemoveInternalUserCustomerFileRelation}
			/>
		</SettingsDialogPage>
	);
};

export default AddUserCustomerFileRelationsForm;
