import { gql } from '@apollo/client';

import { SettingTagByCustomerFileType } from '../types/settingTag';

import { BASE_STORAGE_UPLOAD_FIELDS } from './settingStorageUpload';
import { DOCUMENT_UPLOAD_TAG_FIELDS } from './tag';

const BASE_SETTING_TAG_BY_CUSTOMER_FILE_FIELDS = `
	color
	id
	isEnabled
	isInherited
	isOverload
	name
	info
`;

const STORAGE_UPLOAD_FIELDS = `
	${BASE_STORAGE_UPLOAD_FIELDS}
	tags {
		${DOCUMENT_UPLOAD_TAG_FIELDS}
	}
`;

// QUERIES

export type FetchSettingTagsByCustomerFileType = {
	document_settingTagsByCustomerFile: {
		items: SettingTagByCustomerFileType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_SETTING_TAGS_BY_CUSTOMER_FILE = gql`
	query Document_settingTagsByCustomerFile($customerFileId: ID!, $page: Page!, $search: String) {
		document_settingTagsByCustomerFile(customerFileId: $customerFileId, page: $page, search: $search) {
			count
			items {
				${BASE_SETTING_TAG_BY_CUSTOMER_FILE_FIELDS}
				storageUpload {
					id
					name
				}
			}
			limit
			offset
		}
	}
`;

export type FetchSettingCustomerFileTagType = {
	document_settingCustomerFileTag: SettingTagByCustomerFileType;
};

export const FETCH_SETTING_CUSTOMER_FILE_TAG = gql`
	query Document_settingCustomerFileTag($settingCustomerFileTagId: ID!) {
		document_settingCustomerFileTag(id: $settingCustomerFileTagId) {
			${BASE_SETTING_TAG_BY_CUSTOMER_FILE_FIELDS}
			storageUpload {
				${STORAGE_UPLOAD_FIELDS}
			}
		}
	}
`;

export type CheckSettingCustomerFileTagArchivedType = {
	document_checkSettingCustomerFileTagArchived: {
		isArchived: boolean;
		tagId: string;
	};
};

export const CHECK_SETTING_CUSTOMER_FILE_TAG_ARCHIVED = gql`
	query Document_checkSettingCustomerFileTagArchived($name: String!) {
		document_checkSettingCustomerFileTagArchived(name: $name) {
			isArchived
			tagId
		}
	}
`;

export type CheckSettingCustomerFileTagNameAvailabilityType = {
	document_checkCustomerFileTagNameAvailability: boolean;
};

export const CHECK_SETTING_CUSTOMER_FILE_TAG_NAME_AVAILABILITY = gql`
	query Document_checkCustomerFileTagNameAvailability($name: String!, $customerFileId: String!) {
		document_checkCustomerFileTagNameAvailability(name: $name, customerFileId: $customerFileId)
	}
`;

// MUTATIONS

export type CreateSettingCustomerFileType = {
	document_createSettingCustomerFileTag: SettingTagByCustomerFileType;
};

export const CREATE_SETTING_CUSTOMER_FILE_TAG = gql`
	mutation Document_createSettingCustomerFileTag(
		$createSettingCustomerFileTagInput: document_CreateSettingCustomerFileTagInput!
	) {
		document_createSettingCustomerFileTag(createSettingCustomerFileTagInput: $createSettingCustomerFileTagInput) {
			id
		}
	}
`;

export type UpdateSettingCustomerFileTagType = {
	document_updateSettingCustomerFileTag: SettingTagByCustomerFileType;
};

export const UPDATE_SETTING_CUSTOMER_FILE_TAG = gql`
	mutation Document_updateSettingCustomerFileTag(
		$updateSettingCustomerFileTagInput: document_UpdateSettingCustomerFileTagInput!
	) {
		document_updateSettingCustomerFileTag(updateSettingCustomerFileTagInput: $updateSettingCustomerFileTagInput) {
			id
		}
	}
`;

export const REMOVE_SETTING_CUSTOMER_FILE_TAG = gql`
	mutation Document_removeSettingCustomerFileTag($removeSettingCustomerFileTagId: ID!) {
		document_removeSettingCustomerFileTag(id: $removeSettingCustomerFileTagId) {
			id
		}
	}
`;
