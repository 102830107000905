import { useLazyQuery } from '@apollo/client';
import { Autocomplete, ButtonSwitch, DatePicker, TextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FETCH_PAYMENT_MODES, FetchPaymentModesType } from '../../../api/paymentMode';
import { PaymentModeType } from '../../../types/paymentMode';
import { VoucherFormType, VoucherNatureEnum, VoucherNatureLabelEnum } from '../../../types/voucher';

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(2),

	marginBottom: spacing(2),

	'& > *': {
		flex: 1,
	},
}));

type VoucherInformationsSectionProps = {
	control: Control<VoucherFormType>;
	isDueDateVisible?: boolean;
	readOnly?: boolean;
	openedFiscalYearsBoundaries?: { minDate?: Date; maxDate?: Date };
	validateDocumentDate?: (value?: Date) => boolean;
	validateAccountingDate?: (value?: Date) => boolean;
};

const VoucherInformationsSection = ({
	control,
	isDueDateVisible = false,
	readOnly,
	openedFiscalYearsBoundaries,
	validateDocumentDate,
	validateAccountingDate,
}: VoucherInformationsSectionProps) => {
	const [fetchPaymentModes] = useLazyQuery<FetchPaymentModesType>(FETCH_PAYMENT_MODES);

	const paymentModesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: PaymentModeType[];
		}> => {
			const { data, error } = await fetchPaymentModes({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				paymentModes: { count = 0, items = [] },
			} = data ?? { paymentModes: {} };

			return { count, items };
		},
		[fetchPaymentModes],
	);

	return (
		<>
			<FormRow>
				<Controller
					control={control}
					name="name"
					render={({ field, fieldState: { error: fieldError } }) => (
						<TextField
							{...field}
							helperText={fieldError?.message}
							invalid={!!fieldError}
							label="Libellé de l'écriture"
							readOnly={readOnly}
							required={!readOnly}
						/>
					)}
					rules={{ required: 'Champ requis' }}
				/>
				<Controller
					control={control}
					name="nature"
					render={({ field: { onChange, value, ...field } }) => {
						const handleClick = (changedValue: string) => !readOnly && onChange(changedValue);

						return (
							<ButtonSwitch
								{...field}
								items={Object.values(VoucherNatureEnum).map((key) => ({
									id: key,
									label: VoucherNatureLabelEnum[key],
								}))}
								onClick={handleClick}
								selectedItem={value as string}
								readOnly={readOnly}
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name="documentDate"
					render={({ field, fieldState: { error: fieldError } }) => (
						<DatePicker
							{...field}
							helperText={fieldError?.message}
							invalid={!!fieldError}
							label="Date de facture"
							readOnly={readOnly}
							required={!readOnly}
							disableClearable
						/>
					)}
					rules={{ required: 'Champs requis', ...(validateDocumentDate && { validate: validateDocumentDate }) }}
				/>
			</FormRow>
			<FormRow>
				<Controller
					control={control}
					defaultValue={new Date()}
					name="accountingDate"
					render={({ field, fieldState: { error: fieldError } }) => (
						<DatePicker
							{...field}
							helperText={fieldError?.message}
							invalid={!!fieldError}
							label="Date de comptabilisation"
							min={openedFiscalYearsBoundaries?.minDate}
							max={openedFiscalYearsBoundaries?.maxDate}
							readOnly={readOnly}
							required={!readOnly}
							disableClearable
						/>
					)}
					rules={{ required: 'Champ requis', ...(validateAccountingDate && { validate: validateAccountingDate }) }}
				/>
				<Controller
					control={control}
					name="paymentMode"
					render={({ field }) => (
						<Autocomplete<PaymentModeType>
							{...field}
							dataSource={paymentModesDataSource}
							getOptionLabel={({ code, name }) => `${code} - ${name}`}
							label="Mode de règlement"
							readOnly={readOnly}
							required={!readOnly}
						/>
					)}
					rules={{ required: 'Champ requis' }}
				/>
				{isDueDateVisible ? (
					<Controller
						control={control}
						defaultValue={new Date()}
						name="dueDate"
						render={({ field: { onChange, ...field }, fieldState: { error: fieldError } }) => {
							const handleChange = (value?: Date) => value && onChange(value);

							return (
								<DatePicker
									{...field}
									helperText={fieldError?.message}
									invalid={!!fieldError}
									label="Date d'échéance"
									readOnly={readOnly}
									onChange={handleChange}
								/>
							);
						}}
					/>
				) : (
					<div />
				)}
			</FormRow>
			<FormRow>
				<Controller
					control={control}
					name="documentNumber"
					render={({ field, fieldState: { error: fieldError } }) => (
						<TextField
							{...field}
							helperText={fieldError?.message}
							invalid={!!fieldError}
							label="Référence de la pièce"
							readOnly={readOnly}
							required={!readOnly}
						/>
					)}
					rules={{ required: 'Champ requis' }}
				/>
			</FormRow>
		</>
	);
};

export default VoucherInformationsSection;
