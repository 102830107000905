import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FetchVoucherUsersType, FETCH_VOUCHER_USERS } from '../../../api/voucher';
import { VoucherUserType } from '../../../types/voucher';

const useVoucherUserDataSource = () => {
	const [fetchVoucherUsers] = useLazyQuery<FetchVoucherUsersType>(FETCH_VOUCHER_USERS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: VoucherUserType[];
		}> => {
			const { data, error } = await fetchVoucherUsers({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_voucherUsers: { count = 0, items = [] },
			} = data ?? {
				quickentry_voucherUsers: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchVoucherUsers],
	);

	return { dataSource };
};

export default useVoucherUserDataSource;
