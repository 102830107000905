import DataSourceResultFormatType from '../../../../../types/dataSource';
import { countries, CountryType } from '../../../../../utils/countries';

const useCountriesDataSource = () => {
	const dataSource = async (
		limit: number,
		offset: number,
		search?: string,
	): Promise<DataSourceResultFormatType<CountryType>> =>
		new Promise((resolve) => {
			const filteredCountries = countries.filter(
				(item) => !search || item.label.toUpperCase().startsWith(search.toUpperCase()),
			);

			resolve({
				items: filteredCountries,
				count: filteredCountries.length,
			});
		});

	return { dataSource };
};

export default useCountriesDataSource;
