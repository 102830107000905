import { gql } from '@apollo/client';

import { GROUP_PERMISSION_FIELDS, GROUP_PERMISSION_MODULE_FIELDS } from './permission';
import { GroupType } from '../types/group';

export const EXTERNAL_GROUP_FIELDS = `
	id
	isAdministrator
	isDefault
	name
`;

// QUERIES

export type FetchExternalGroupsType = {
	externalGroups: {
		count: number;
		items: GroupType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_EXTERNAL_GROUPS = gql`
	query ExternalGroups($search: String, $orderBy: OrderBy, $page: Page) {
		externalGroups(search: $search, orderBy: $orderBy, page: $page) {
			count
			items {
				${EXTERNAL_GROUP_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchExternalGroupType = {
	externalGroup: GroupType;
};

export const FETCH_EXTERNAL_GROUP = gql`
	query ExternalGroup($id: ID!) {
		externalGroup(id: $id) {
			${EXTERNAL_GROUP_FIELDS}
			permissionModules {
				${GROUP_PERMISSION_MODULE_FIELDS}
				permissions {
					${GROUP_PERMISSION_FIELDS}
				}
			}
		}
	}
`;

// MUTATIONS

export type CreateExternalGroupType = {
	createExternalGroup: GroupType;
};

export const CREATE_EXTERNAL_GROUP = gql`
	mutation CreateExternalGroup($createExternalGroupInput: CreateExternalGroupInput!) {
		createExternalGroup(createExternalGroupInput: $createExternalGroupInput) {
			id
		}
	}
`;

export type UpdateExternalGroupType = {
	updateExternalGroup: GroupType;
};

export const UPDATE_EXTERNAL_GROUP = gql`
	mutation UpdateExternalGroup($updateExternalGroupInput: UpdateExternalGroupInput!) {
		updateExternalGroup(updateExternalGroupInput: $updateExternalGroupInput) {
			id
		}
	}
`;

export type RemoveExternalGroupType = {
	removeExternalGroup: GroupType;
};

export const REMOVE_EXTERNAL_GROUP = gql`
	mutation RemoveExternalGroup($id: ID!) {
		removeExternalGroup(id: $id) {
			id
		}
	}
`;
