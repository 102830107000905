import { gql } from '@apollo/client';

import { BillExchangeType } from '../types/billExchange';
import { JournalType } from '../types/journal';
import { PaymentModeType } from '../types/paymentMode';

// QUERIES

export type FetchSynchronizedBillExchangesType = {
	quickentry_synchronizedBillExchanges: {
		count: number;
		items: BillExchangeType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SYNCHRONIZED_BILL_EXCHANGES = gql`
	query Quickentry_synchronizedBillExchanges(
		$filter: quickentry_BillExchangeFilterInput
		$orderBy: OrderBy
		$page: Page
		$search: String
	) {
		quickentry_synchronizedBillExchanges(filter: $filter, orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				account {
					code
					id
					name
				}
				amount
				bank {
					id
					name
				}
				documentDate
				id
				name
			}
			limit
			offset
		}
	}
`;

export type FetchSubmittedOrValidatedBillExchangesType = {
	quickentry_submittedOrValidatedBillExchanges: {
		count: number;
		items: BillExchangeType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUBMITTED_OR_VALIDATED_BILL_EXCHANGES = gql`
	query Quickentry_submittedOrValidatedBillExchanges($orderBy: OrderBy, $page: Page) {
		quickentry_submittedOrValidatedBillExchanges(orderBy: $orderBy, page: $page) {
			count
			items {
				account {
					code
					id
					name
				}
				amount
				bank {
					id
					name
				}
				documentDate
				id
				name
			}
			limit
			offset
		}
	}
`;

export type FetchBillExchangeConfigurationsType = {
	quickentry_billExchangeConfigurations: {
		journalCode: JournalType['code'];
		paymentModeCode: PaymentModeType['code'];
	};
};

export const FETCH_BILL_EXCHANGE_CONFIGURATIONS = gql`
	query Quickentry_billExchangeConfigurations {
		quickentry_billExchangeConfigurations {
			journalCode
			paymentModeCode
		}
	}
`;

// MUTATIONS

export type CreateBillExchangeType = {
	quickentry_createBillExchange: BillExchangeType;
};

export const CREATE_BILL_EXCHANGE = gql`
	mutation Quickentry_createBillExchange($createBillExchangeInput: quickentry_CreateBillExchangeInput!) {
		quickentry_createBillExchange(createBillExchangeInput: $createBillExchangeInput) {
			id
		}
	}
`;

export type UpdateBillExchangeType = {
	quickentry_updateBillExchange: BillExchangeType;
};

export const UPDATE_BILL_EXCHANGE = gql`
	mutation Quickentry_updateBillExchange($updateBillExchangeInput: quickentry_UpdateBillExchangeInput!) {
		quickentry_updateBillExchange(updateBillExchangeInput: $updateBillExchangeInput) {
			id
		}
	}
`;

export type ValidateBillExchangeType = {
	quickentry_validateBillExchange: BillExchangeType;
};

export const VALIDATE_BILL_EXCHANGE = gql`
	mutation Quickentry_validateBillExchange($id: ID!) {
		quickentry_validateBillExchange(id: $id) {
			id
		}
	}
`;

export type RemoveBillExchangeType = {
	quickentry_removeBillExchange: {
		id: BillExchangeType['id'];
	};
};

export const REMOVE_BILL_EXCHANGE = gql`
	mutation Quickentry_removeBillExchange($id: ID!) {
		quickentry_removeBillExchange(id: $id) {
			id
		}
	}
`;
