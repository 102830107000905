import { SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import Image from 'next/image';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

type FormType = {
	name: string;
	isExternal: boolean;
	isInternal: boolean;
};

type N2fFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const N2fForm = ({ application, isNew, onSubmit, onRemove }: N2fFormProps) => {
	const { key, type, name: initialName, isInternal: isInternalInitial, isExternal: isExternalInitial } = application;

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(application);

	const handleSubmitInternal = ({ name, isInternal, isExternal }: FormType) =>
		onSubmit({
			key,
			type,
			name,
			isInternal,
			isExternal,
		});

	useEffect(
		() =>
			reset({
				name: initialName,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
			}),
		[initialName, isExternalInitial, isInternalInitial, reset],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type N2fApplicationProps = {
	name: string;
	readOnly: boolean;
};

const N2fApplication = ({ name, readOnly }: N2fApplicationProps) => {
	const handleClick = () => window.open('https://auth.n2f.com/fr', '_blank');

	return (
		<ApplicationCard
			name={name}
			image={<Image src="/widget/images/n2f-logo.png" alt="Logo N2F" width={96} height={96} objectFit="contain" />}
			onClick={handleClick}
			readOnly={readOnly}
		/>
	);
};

export default N2fApplication;
