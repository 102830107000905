import { Menu, MenuProps } from '@elipssolution/harfang';
import { MenuItem, Typography } from '@mui/material';

type SubmittedOrValidatedActionMenuProps = Pick<MenuProps, 'anchorEl' | 'onClose'> & {
	onItemClick: (actionId: string) => void;
};

const SubmittedOrValidatedActionMenu = ({ anchorEl, onClose, onItemClick }: SubmittedOrValidatedActionMenuProps) => (
	<Menu anchorEl={anchorEl} onClose={onClose} open={!!anchorEl}>
		<MenuItem
			onClick={(event) => {
				event.stopPropagation();

				onItemClick('remove');
			}}
		>
			<Typography>Supprimer</Typography>
		</MenuItem>
	</Menu>
);

export default SubmittedOrValidatedActionMenu;
