import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { FetchBankType, FETCH_BANK } from '../api/bank';
import { BankType } from '../types/bank';

const useBank = ({ bankId }: { bankId?: BankType['id'] }) => {
	const [error, setError] = useState<string>();

	const { called, data, loading } = useQuery<FetchBankType>(FETCH_BANK, {
		onError: ({ message }) =>
			setError(
				`${
					/^No account found for customerFileId .* and accountCode \d+$/.test(message)
						? 'Le numéro de compte configuré pour cette banque ne correspond à aucun compte de'
						: "La banque configurée n'existe pas pour"
				} cette entreprise. Veuillez contacter votre responsable paramétrage.`,
			),
		skip: !bankId,
		variables: {
			id: bankId,
		},
	});

	return { bank: data?.quickentry_bank, called, error, loading };
};

export default useBank;
