import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { PermissionEnum } from '../../../types/permission';
import CustomerFileTable from './customerFile/CustomerFileTable';
import DomainTable from './domain/DomainTable';

const QuickentrySettings = () => (
	<SettingsDialogPage title="Saisie rapide">
		<PermissionWall permissionCodes={[PermissionEnum.QUICKENTRY_SETTING]}>
			<>
				<DomainTable />
				<CustomerFileTable />
			</>
		</PermissionWall>
	</SettingsDialogPage>
);

export default QuickentrySettings;
