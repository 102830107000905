import { AES, enc } from 'crypto-js';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const ENCRYPTION_KEY = 'yB2BXYk&iiiLyb4x';

const ISO_DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const isIsoDate = (value: string) => ISO_DATE_PATTERN.test(value);

const reviver = (_: string, value: string) => (typeof value === 'string' && isIsoDate(value) ? new Date(value) : value);

const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] => {
	const [storedValue, setStoredValue] = useState(initialValue);

	useEffect(() => {
		const item = window?.localStorage.getItem(key);

		if (item) {
			setStoredValue(JSON.parse(AES.decrypt(item, ENCRYPTION_KEY).toString(enc.Utf8), reviver) as T);
		}
	}, [key]);

	/**
	 * Store the value into the local storage.
	 * The item is removed if the value is :
	 * - an empty array
	 * - an empty object
	 * - null
	 * - undefined
	 */
	useEffect(() => {
		if (
			storedValue !== null && typeof storedValue === 'object'
				? (Array.isArray(storedValue) && storedValue.length > 0) || Object.keys(storedValue).length !== 0
				: storedValue !== undefined
		) {
			window.localStorage.setItem(key, AES.encrypt(JSON.stringify(storedValue), ENCRYPTION_KEY).toString());
		} else {
			window.localStorage.removeItem(key);
		}
	}, [key, storedValue]);

	return [storedValue, setStoredValue];
};

export default useLocalStorage;
