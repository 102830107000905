import { gql } from '@apollo/client';

import { GroupPermissionType, UserPermissionType } from '../types/permission';

export const GROUP_PERMISSION_FIELDS = `
	id
	name
	value
`;

export const USER_PERMISSION_FIELDS = `
	currentValue
	id
	inheritedValue
	name
	value
`;

export const GROUP_PERMISSION_MODULE_FIELDS = `
	code
	id
`;

export const USER_PERMISSION_MODULE_FIELDS = `
	code
	id
`;

// MUTATIONS

export type UpdateExternalGroupPermissionType = {
	updateExternalGroupPermission: GroupPermissionType;
};

export const UPDATE_EXTERNAL_GROUP_PERMISSION = gql`
	mutation UpdateExternalGroupPermission($updateExternalGroupPermissionInput: UpdateExternalGroupPermissionInput!) {
		updateExternalGroupPermission(updateExternalGroupPermissionInput: $updateExternalGroupPermissionInput) {
			${GROUP_PERMISSION_FIELDS}
		}
	}
`;

export type UpdateExternalUserPermissionType = {
	updateExternalUserPermission: UserPermissionType;
};

export const UPDATE_EXTERNAL_USER_PERMISSION = gql`
	mutation UpdateExternalUserPermission($updateExternalUserPermissionInput: UpdateExternalUserPermissionInput!) {
		updateExternalUserPermission(updateExternalUserPermissionInput: $updateExternalUserPermissionInput) {
			${USER_PERMISSION_FIELDS}
		}
	}
`;

export type UpdateInternalGroupPermissionType = {
	updateInternalGroupPermission: GroupPermissionType;
};

export const UPDATE_INTERNAL_GROUP_PERMISSION = gql`
	mutation UpdateInternalGroupPermission($updateInternalGroupPermissionInput: UpdateInternalGroupPermissionInput!) {
		updateInternalGroupPermission(updateInternalGroupPermissionInput: $updateInternalGroupPermissionInput) {
			${GROUP_PERMISSION_FIELDS}
		}
	}
`;

export type UpdateInternalUserPermissionType = {
	updateInternalUserPermission: UserPermissionType;
};

export const UPDATE_INTERNAL_USER_PERMISSION = gql`
	mutation UpdateInternalUserPermission($updateInternalUserPermissionInput: UpdateInternalUserPermissionInput!) {
		updateInternalUserPermission(updateInternalUserPermissionInput: $updateInternalUserPermissionInput) {
			${USER_PERMISSION_FIELDS}
		}
	}
`;
