import { Icon } from '@elipssolution/harfang';

import { mdiTimerEdit } from '@mdi/js';

import { ModuleCodeEnum } from '../../types/widget';

import quickentryPages from './pages/pages';
import QuickentrySettings from './settings/QuickentrySettings';

const quickentryModule = {
	code: ModuleCodeEnum.QUICKENTRY,
	icon: <Icon path={mdiTimerEdit} />,
	name: 'Saisie rapide',
	pages: quickentryPages,
	settings: <QuickentrySettings />,
};

export default quickentryModule;
