import { NetworkStatus } from '@apollo/client';
import { TableColumnType, TableInstance } from '@elipssolution/harfang';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import useSynchronizedVouchersDataSource from './hooks/useSynchronizedVouchersDataSource';
import VoucherTable from './VoucherTable';
import { CustomerFileTemplateType } from '../../types/template';
import { VoucherType } from '../../types/voucher';

const columns: TableColumnType<VoucherType>[] = [
	{
		field: 'template',
		key: 'template',
		render: ({ template: { name } }) => name,
		title: 'Modèle',
		width: 150,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé de la pièce',
		width: 250,
	},
	{
		field: 'documentNumber',
		key: 'documentNumber',
		sortable: true,
		title: 'Référence de la pièce',
		width: 170,
	},
	{
		field: 'accountingDate',
		key: 'accountingDate',
		render: ({ accountingDate }) => accountingDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Comptabilisé le',
		width: 130,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={+(amount ?? 0)} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 125,
	},
];

type SynchronizedVoucherTableProps = {
	onVoucherSelection: (voucherId: VoucherType['id']) => void;
	selectedTemplate?: CustomerFileTemplateType;
	tableInstance: TableInstance;
};

const SynchronizedVoucherTable = ({
	onVoucherSelection,
	selectedTemplate,
	tableInstance,
}: SynchronizedVoucherTableProps) => {
	const [filteredTemplate, setFilteredTemplate] = useState<CustomerFileTemplateType>();

	const { dataSource: synchronizedVouchersDataSource, networkStatus } = useSynchronizedVouchersDataSource({
		filteredTemplateId: filteredTemplate?.id,
	});

	const handleVoucherSelection = ({ id }: VoucherType) => onVoucherSelection(id);

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	useEffect(() => {
		selectedTemplate && setFilteredTemplate(selectedTemplate);
	}, [selectedTemplate]);

	return (
		<Box flex={2} paddingBottom={2}>
			<VoucherTable
				columns={columns}
				dataSource={synchronizedVouchersDataSource}
				filteredTemplate={filteredTemplate}
				onTemplateFilterChange={setFilteredTemplate}
				onVoucherSelection={handleVoucherSelection}
				tableInstance={tableInstance}
				title="Pièces"
			/>
		</Box>
	);
};

export default SynchronizedVoucherTable;
