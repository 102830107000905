import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';
import { useCallback } from 'react';
import PermissionWall from '../../../../components/PermissionWall';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import Storage from './storage/Storage';
import Tags from './tags/Tags';
import { PermissionEnum } from '../../../../types/permission';

const StorageUpload = () => {
	const { push } = useSettingsDialog();

	const navigateToStorageSection = useCallback(() => push(<Storage />), [push]);
	const navigateToTagsSection = useCallback(() => push(<Tags />), [push]);

	return (
		<SettingsDialogPage title="Dépôt">
			<SettingsGroup>
				<PermissionWall permissionCodes={[PermissionEnum.DOCUMENT_SETTING_STORAGE]}>
					<SettingsItemNavigation label="Emplacements" onClick={navigateToStorageSection} />
				</PermissionWall>

				<PermissionWall permissionCodes={[PermissionEnum.DOCUMENT_SETTING_TAG]}>
					<SettingsItemNavigation label="Tags" onClick={navigateToTagsSection} />
				</PermissionWall>
			</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default StorageUpload;
