import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, Tooltip, Icon } from '@elipssolution/harfang';
import { mdiInformationOutline } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';

import AddCustomerFileUserRelationForm from './AddCustomerFileUserRelationForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import {
	FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE,
	FetchExternalUserCustomerFileRelationsByCustomerFileType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { ExternalUserCustomerFileRelationType } from '../../../types/relation';
import { UserTypeEnum } from '../../../types/user';
import UserCustomerFileRelation from '../userCustomerFileRelation/UserCustomerFileRelation';

type CustomerFileProps = {
	customerFile: CustomerFileType;
};

const CustomerFile = ({ customerFile }: CustomerFileProps) => {
	const {
		user: { type, id: sessionUserId },
	} = useSession();
	const { push } = useSettingsDialog();

	const columns: TableColumnType<ExternalUserCustomerFileRelationType>[] = useMemo(
		() => [
			{
				key: 'externalUser',
				render: ({ externalUser: { lastName, firstName } }) => `${lastName} ${firstName ?? ''}`.trim(),
				title: "Nom de l'utilisateur",
				width: 200,
			},
			{
				key: 'email',
				render: ({ externalUser: { email } }) => email,
				title: 'Email',
				width: 250,
			},
			{
				flexGrow: 0,
				key: 'hasUserPermissions',
				render: ({ hasUserPermissions }) =>
					hasUserPermissions && (
						<Tooltip content="Les droits de l'utilisateur ont été personnalisés">
							<Stack alignItems="center">
								<Icon path={mdiInformationOutline} />
							</Stack>
						</Tooltip>
					),
				width: 24,
			},
			...(type === UserTypeEnum.INTERNAL
				? [
						{
							flexGrow: 0,
							key: 'group',
							render: ({ group: { isAdministrator, isDefault, name } }: ExternalUserCustomerFileRelationType) => {
								if (isAdministrator) {
									return <Chip label={name} color="error" />;
								}

								return <Chip label={name} color={isDefault ? 'info' : 'default'} />;
							},
							width: 120,
						},
				  ]
				: []),
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ externalUser: { id } }) => sessionUserId !== id && <LinkIcon />,
				width: 40,
			},
		],
		[sessionUserId, type],
	);

	const [fetchUsers] = useLazyQuery<FetchExternalUserCustomerFileRelationsByCustomerFileType>(
		FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_CUSTOMER_FILE,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: ExternalUserCustomerFileRelationType[];
		}> => {
			const { data, error } = await fetchUsers({
				variables: {
					customerFileId: customerFile.id,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				externalUserCustomerFileRelationsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				externalUserCustomerFileRelationsByCustomerFile: {},
			};

			return { count, items };
		},
		[fetchUsers, customerFile],
	);

	const handleAddButtonClick = useCallback(
		() => push(<AddCustomerFileUserRelationForm customerFile={customerFile} />),
		[customerFile, push],
	);

	const handleRowClick = useCallback(
		({ externalUser }: ExternalUserCustomerFileRelationType) =>
			sessionUserId !== externalUser.id &&
			push(<UserCustomerFileRelation initialCustomerFile={customerFile} user={externalUser} />),
		[customerFile, push, sessionUserId],
	);

	return (
		<SettingsDialogPage title={customerFile.name}>
			<SettingsTable<ExternalUserCustomerFileRelationType>
				addButtonLabel="Ajout d'utilisateurs"
				dataSource={dataSource}
				onAddButtonClick={handleAddButtonClick}
				onRowClick={handleRowClick}
				tableColumns={columns}
				title="Utilisateurs"
			/>
		</SettingsDialogPage>
	);
};

export default CustomerFile;
