import { TableTreeSearchResult } from '@elipssolution/harfang';
import { accounting_SearchAccountLevelResult } from '../types/account';

export const transformSearchedResultsForTreeTable = (
	table: accounting_SearchAccountLevelResult[],
): TableTreeSearchResult[] => {
	const searchResults: TableTreeSearchResult[] = table.map((item) => {
		const element: TableTreeSearchResult = [];

		Object.entries(item).forEach(([key, value], index) => {
			if (key !== '__typename' && value) {
				element.push({ level: index, key: value });
			}
		});

		return element;
	});

	return searchResults;
};
