import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import CustomerFile from './customerFile/CustomerFile';
import LinkIcon from '../../../components/LinkIcon';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainTypeAndUserPermission } from '../../../types/customerFile';
import {
	FETCH_CUSTOMER_FILES_WITH_USER_PERMISSION,
	FetchCustomerFilesWithUserPermissionType,
} from '../../api/customerFile';

const columns: TableColumnType<CustomerFileWithDomainTypeAndUserPermission>[] = [
	{
		field: 'code',
		flexGrow: 0,
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: "Nom de l'entreprise",
		width: 200,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: ({ hasUserPermissionToManage }) => hasUserPermissionToManage && <LinkIcon />,
		width: 40,
	},
];

const CustomerFilesTableSection = () => {
	const { push } = useSettingsDialog();

	const [fetchCustomerFilesWithUserPermission] = useLazyQuery<FetchCustomerFilesWithUserPermissionType>(
		FETCH_CUSTOMER_FILES_WITH_USER_PERMISSION,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CustomerFileWithDomainTypeAndUserPermission>,
		): Promise<{
			count: number;
			items: CustomerFileWithDomainTypeAndUserPermission[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchCustomerFilesWithUserPermission({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				customerFilesWithUserPermission: { count = 0, items = [] },
			} = data ?? {
				customerFilesWithUserPermission: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFilesWithUserPermission],
	);

	const openCustomerFilePage = (customerFile: CustomerFileWithDomainTypeAndUserPermission) => {
		const { hasUserPermissionToManage } = customerFile;

		hasUserPermissionToManage && push(<CustomerFile customerFile={customerFile} />);
	};

	return (
		<SettingsTable<CustomerFileWithDomainTypeAndUserPermission>
			dataSource={dataSource}
			onRowClick={openCustomerFilePage}
			tableColumns={columns}
			title="Entreprises"
		/>
	);
};

export default CustomerFilesTableSection;
