import { useCallback } from 'react';

import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';

import { DomainType } from '../../../../../../types/domain';
import { SettingTagByDomainType } from '../../../../types/settingTag';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';

import DomainTagsForm from './DomainTagsForm';
import DomainTagsTable from './DomainTagsTable';

type DomainTagsTableProps = {
	domain: DomainType;
};

const DomainTags = ({ domain }: DomainTagsTableProps) => {
	const { push } = useSettingsDialog();

	const handleTagSelection = useCallback(
		(tag?: SettingTagByDomainType) => {
			push(<DomainTagsForm domain={domain} tag={tag} />);
		},
		[domain, push],
	);

	return (
		<SettingsDialogPage title={domain.name}>
			<DomainTagsTable domain={domain} onTagSelection={handleTagSelection} />
		</SettingsDialogPage>
	);
};

export default DomainTags;
