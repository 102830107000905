import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { UPDATE_EXTERNAL_GROUP_PERMISSION, UpdateExternalGroupPermissionType } from '../../../api/permission';
import { GroupPermissionType } from '../../../types/permission';
import SettingsItemPermission from '../../SettingsItemPermission';

type ExternalGroupPermissionProps = {
	permission: GroupPermissionType;
	disabled: boolean;
	onPermissionChanged: () => void;
};

const ExternalGroupPermission = ({
	permission: { id, name, value: initialValue },
	disabled,
	onPermissionChanged,
}: ExternalGroupPermissionProps) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [value, setValue] = useState(initialValue);

	const [updatePermission, { loading }] = useMutation<UpdateExternalGroupPermissionType>(
		UPDATE_EXTERNAL_GROUP_PERMISSION,
		{
			onCompleted: (data) => {
				const { updateExternalGroupPermission } = data ?? {};
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const { value: updatedValue } = updateExternalGroupPermission ?? {};

				setValue(updatedValue);
				onPermissionChanged();
			},
		},
	);

	const handlePermissionUpdate = useCallback(
		(newValue: boolean | null) => {
			if (value === newValue) return;

			updatePermission({
				variables: {
					updateExternalGroupPermissionInput: { id, value: newValue },
				},
			}).catch((e) => {
				throw e;
			});
		},
		[value, updatePermission, id],
	);

	return (
		<SettingsItemPermission
			label={name}
			loading={loading}
			onChange={handlePermissionUpdate}
			disabled={disabled}
			value={value}
		/>
	);
};

export default ExternalGroupPermission;
