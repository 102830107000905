import { NetworkStatus, useMutation } from '@apollo/client';
import { ConfirmationDialog, DialogProps, Icon, TableColumnType, TableInstance } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDelete } from '@mdi/js';
import { alpha, Box, Stack, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import BillExchangeTable from './BillExchangeTable';
import useSubmittedOrValidatedBillExchangesDataSource from './hooks/useSubmittedOrValidatedBillExchangesDataSource';
import { DIALOG_CLOSE_DELAY } from '../../../../utils/dialogCloseDelay';
import {
	RemoveBillExchangeType,
	REMOVE_BILL_EXCHANGE,
	ValidateBillExchangeType,
	VALIDATE_BILL_EXCHANGE,
} from '../../api/billExchange';
import useSubmittedOrValidatedActionColumn from '../../hooks/useSubmittedOrValidatedActionColumn';
import { BillExchangeType } from '../../types/billExchange';

const ErrorCell = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	flex: 1,
	height: 30,

	backgroundColor: alpha(palette.error.main, 0.2),
	color: palette.error.main,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),

	paddingLeft: spacing(0.5),
}));

const columns: TableColumnType<BillExchangeType>[] = [
	{
		field: 'documentDate',
		flexGrow: 0,
		key: 'documentDate',
		render: ({ documentDate }) => documentDate.toLocaleDateString('fr-FR'),
		sortable: true,
		title: 'Date',
		width: 100,
	},
	{
		key: 'bank',
		render: ({ bank }) =>
			bank?.name ?? (
				<ErrorCell>
					<Icon path={mdiAlertCircle} />
					<Typography variant="caption">Banque manquante</Typography>
				</ErrorCell>
			),
		title: 'Banque',
		width: 125,
	},
	{
		key: 'accountCode',
		render: ({ account: { code } }) => code,
		title: 'Code aux.',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Libellé',
		width: 150,
	},
	{
		align: 'right',
		field: 'amount',
		key: 'amount',
		render: ({ amount }) => (
			<Stack paddingRight={1}>
				<FormattedNumber value={+amount} style="currency" currency="EUR" />
			</Stack>
		),
		sortable: true,
		title: 'Montant',
		width: 100,
	},
];

type SubmittedOrValidatedBillExchangeTableProps = {
	onBillExchangeSelection: (check: BillExchangeType) => void;
	onBillExchangeValidation: () => void;
	tableInstance: TableInstance;
};

const SubmittedOrValidatedBillExchangeTable = ({
	onBillExchangeSelection,
	onBillExchangeValidation,
	tableInstance,
}: SubmittedOrValidatedBillExchangeTableProps) => {
	const [billExchangeToRemoveId, setBillExchangeToRemoveId] = useState<BillExchangeType['id']>();

	const handleRemoveConfirmationDialogClose = () => setBillExchangeToRemoveId(undefined);

	const [validateBillExchange] = useMutation<ValidateBillExchangeType>(VALIDATE_BILL_EXCHANGE, {
		onCompleted: () => setTimeout(onBillExchangeValidation, DIALOG_CLOSE_DELAY),
	});

	const [
		removeBillExchange,
		{ data: removeBillExchangeData, loading: isBillExchangeRemovalLoading, reset: resetRemoveMutation },
	] = useMutation<RemoveBillExchangeType>(REMOVE_BILL_EXCHANGE, {
		onCompleted: () =>
			setTimeout(() => {
				handleRemoveConfirmationDialogClose();

				resetRemoveMutation();

				tableInstance.reload();
			}, DIALOG_CLOSE_DELAY),
	});

	const handleRemove = useCallback(
		() =>
			removeBillExchange({
				variables: {
					id: billExchangeToRemoveId,
				},
			}),
		[removeBillExchange, billExchangeToRemoveId],
	);

	const handleValidate = useCallback(
		(id: BillExchangeType['id']) =>
			validateBillExchange({
				variables: {
					id,
				},
			}),
		[validateBillExchange],
	);

	const actionColumn = useSubmittedOrValidatedActionColumn<BillExchangeType>({
		onRemove: (id: BillExchangeType['id']) => setBillExchangeToRemoveId(id),
		onValidate: handleValidate,
	});

	const tableColumns = useMemo(() => [...columns, actionColumn], [actionColumn]);

	const {
		dataSource: billExchangesDataSource,
		hasItems: hasSubmittedOrValidatedBillExchanges,
		networkStatus,
	} = useSubmittedOrValidatedBillExchangesDataSource();

	const removeConfirmationDialogActions = useMemo(
		() =>
			[
				{
					label: 'Annuler',
					loading: isBillExchangeRemovalLoading,
					onClick: handleRemoveConfirmationDialogClose,
				},
				{
					color: 'error',
					label: 'Supprimer',
					loading: isBillExchangeRemovalLoading,
					onClick: handleRemove,
					startIcon: <Icon path={mdiDelete} />,
					success: !!removeBillExchangeData,
					variant: 'contained',
				},
			] as DialogProps['actionsDialog'],
		[handleRemove, removeBillExchangeData, isBillExchangeRemovalLoading],
	);

	// Reload the table if the query has been refetched
	useEffect(() => {
		networkStatus === NetworkStatus.refetch && tableInstance.reload();
	}, [tableInstance, networkStatus]);

	return hasSubmittedOrValidatedBillExchanges ? (
		<>
			<Box flex={1} paddingBottom={2}>
				<BillExchangeTable
					columns={tableColumns}
					dataSource={billExchangesDataSource}
					onBillExchangeSelection={onBillExchangeSelection}
					tableInstance={tableInstance}
					title="LCR à valider"
				/>
			</Box>

			<ConfirmationDialog
				actionsDialog={removeConfirmationDialogActions}
				content="Les informations renseignées seront perdues."
				open={!!billExchangeToRemoveId}
				onClose={handleRemoveConfirmationDialogClose}
				title="Êtes-vous sûr de vouloir supprimer cette LCR ?"
			/>
		</>
	) : null;
};

export default SubmittedOrValidatedBillExchangeTable;
