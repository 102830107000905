import { Icon } from '@elipssolution/harfang';
import { mdiFileChart, mdiFileEye, mdiFileUpload } from '@mdi/js';
import Accounts from './accounts';
import AnalyticalSections from './analyticalSections';
import ThirdParties from './thirdParties';

const pages = [
	{
		component: ThirdParties,
		icon: <Icon path={mdiFileUpload} />,
		name: 'Tiers',
		route: 'thirdParties',
	},
	{
		component: Accounts,
		icon: <Icon path={mdiFileEye} />,
		name: 'Comptes',
		route: 'accounts',
	},
	{
		component: AnalyticalSections,
		icon: <Icon path={mdiFileChart} />,
		name: 'Analytiques',
		route: 'analyticalSections',
	},
];

export default pages;
