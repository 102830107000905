import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../types/domain';
import { SettingDomainTemplateType } from '../../../../types/settingTemplate';
import SettingTemplateDomainTable from './SettingTemplateDomainTable';
import SettingTemplateDomainForm from './templateForm/TemplateDomainForm';

type TemplatesProps = {
	domainId?: DomainType['id'];
};

const Templates = ({ domainId }: TemplatesProps) => {
	const { push } = useSettingsDialog();

	const handleTemplateSelection = (selectedTemplate?: SettingDomainTemplateType) => {
		push(<SettingTemplateDomainForm domainId={domainId} selectedTemplate={selectedTemplate} />);
	};

	return (
		<SettingsDialogPage title="Modèles de pièces">
			<SettingTemplateDomainTable domainId={domainId} onTemplateSelection={handleTemplateSelection} />
		</SettingsDialogPage>
	);
};

export default Templates;
