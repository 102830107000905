import { Icon, Table, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDelete, mdiInformation, mdiPencil } from '@mdi/js';
import { alpha, styled } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import TemplateLineFormSection from './TemplateCustomerFileLineFormSection';
import { CustomerFileType } from '../../../../../../../types/customerFile';
import TableRowActionCell from '../../../../../components/TableRowActionCell';
import { AccountTypeLabelEnum } from '../../../../../types/account';
import { AnalyticalDimensionType } from '../../../../../types/analyticalDimension';
import {
	DirectionEnum,
	SettingCustomerFileTemplateLineType,
	TemplateLineErrorMessageType,
} from '../../../../../types/settingTemplate';

const StyledWrapper = styled('div')(({ theme: { spacing } }) => ({
	flex: 1,
	marginTop: spacing(2),
	display: 'flex',
	flexDirection: 'column',
}));

const InformationContainer = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(4),

	marginTop: spacing(2),
	padding: spacing(3, 6),

	backgroundColor: `${palette.secondary.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

const ErrorCell = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	flex: 1,
	height: '70%',

	backgroundColor: alpha(palette.error.main, 0.2),
	color: palette.error.main,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,

	display: 'flex',
	alignItems: 'center',

	paddingLeft: spacing(0.5),
}));

const templateLineDirections = [
	{ value: DirectionEnum.DEBIT, label: 'Débit' },
	{ value: DirectionEnum.CREDIT, label: 'Crédit' },
];

type TemplateCustomerFileLineSectionProps = {
	analyticalDimensionId?: AnalyticalDimensionType['id'];
	analyticalSectionCaption?: string;
	connector?: CustomerFileType['connector'];
	customerFileId?: CustomerFileType['id'];
	defaultEntryName?: string;
	onChange: (templateLines: SettingCustomerFileTemplateLineType[]) => void;
	templateLines: SettingCustomerFileTemplateLineType[];
	templateLinesErrorMessage?: TemplateLineErrorMessageType[];
	onTemplateLinesErrorMessagesChange?: Dispatch<SetStateAction<TemplateLineErrorMessageType[]>>;
};

const TemplateCustomerFileLineSection = ({
	analyticalDimensionId,
	analyticalSectionCaption,
	connector,
	customerFileId,
	defaultEntryName,
	onChange,
	templateLines,
	templateLinesErrorMessage,
	onTemplateLinesErrorMessagesChange,
}: TemplateCustomerFileLineSectionProps) => {
	const [updatingTemplateLine, setUpdatingTemplateLine] = useState<SettingCustomerFileTemplateLineType>();
	const [templateLineIndex, setTemplateLineIndex] = useState(0);
	const [isCreationSucceeded, setIsCreationSucceeded] = useState(false);
	const [isUpdatingSucceeded, setIsUpdatingSucceeded] = useState(false);

	const resetUpdateTemplateLine = useCallback(() => setUpdatingTemplateLine(undefined), []);

	const addTemplateLine = useCallback(
		(templateLine: SettingCustomerFileTemplateLineType) => {
			onChange([...templateLines, templateLine]);
			setIsCreationSucceeded(true);
			setTimeout(() => setIsCreationSucceeded(false), 3000);
		},
		[onChange, templateLines],
	);

	const updateTemplateLines = useCallback(
		(templateLine: SettingCustomerFileTemplateLineType) => {
			onChange(templateLines.map((line) => (line.id === templateLine.id ? templateLine : line)));
			resetUpdateTemplateLine();
			setIsUpdatingSucceeded(true);
			setTimeout(() => setIsUpdatingSucceeded(false), 3000);
		},
		[onChange, resetUpdateTemplateLine, templateLines],
	);

	const handleFormSubmit = useCallback(
		(values: SettingCustomerFileTemplateLineType) =>
			updatingTemplateLine
				? updateTemplateLines({ ...updatingTemplateLine, ...values })
				: addTemplateLine({ ...values, id: uuid() } as SettingCustomerFileTemplateLineType),
		[updateTemplateLines, addTemplateLine, updatingTemplateLine],
	);

	const removeTemplateLine = useCallback(
		(templateLineId?: string) => {
			onChange(templateLines.filter((templateLine) => templateLine.id !== templateLineId));

			const { id: updatingTemplateLineId } = updatingTemplateLine || {};

			templateLineId === updatingTemplateLineId && resetUpdateTemplateLine();
		},
		[onChange, resetUpdateTemplateLine, templateLines, updatingTemplateLine],
	);

	const tableColumns: TableColumnType<SettingCustomerFileTemplateLineType>[] = useMemo(
		() => [
			{
				field: 'name',
				key: 'name',
				title: 'Nom',
				width: 140,
			},
			{
				field: 'direction',
				key: 'direction',
				render: ({ direction }) => templateLineDirections.find(({ value }) => value === direction)?.label,
				title: 'Sens',
				flexGrow: 0,
				width: 50,
			},
			{
				field: 'defaultTransactionName',
				key: 'defaultTransactionName',
				title: 'Libellé par défaut',
				width: 140,
			},
			{
				field: 'account',
				key: 'account',
				render: ({ account }, index) =>
					!account ? (
						<Tooltip color="error" content={templateLinesErrorMessage?.[index]?.account}>
							<ErrorCell>
								<Icon path={mdiAlertCircle} />
							</ErrorCell>
						</Tooltip>
					) : (
						account.code
					),

				title: 'Compte',
				flexGrow: 0,
				width: 80,
			},
			{
				field: 'type',
				key: 'type',
				render: ({ type }) => AccountTypeLabelEnum[type],
				title: 'Type',
				flexGrow: 0,
				width: 60,
			},
			{
				field: 'analyticalSection',
				key: 'analyticalSection',

				render: ({ analyticalSection }, index) =>
					templateLinesErrorMessage?.[index]?.analyticalSection ? (
						<Tooltip color="error" content={templateLinesErrorMessage?.[index]?.analyticalSection}>
							<ErrorCell>
								<Icon path={mdiAlertCircle} />
							</ErrorCell>
						</Tooltip>
					) : (
						analyticalSection?.code || ''
					),

				title: 'Section',
				flexGrow: 0,
				width: 50,
			},
			{
				field: 'subaccount',
				key: 'subaccount',

				render: ({ subaccount }, index) =>
					templateLinesErrorMessage?.[index]?.subaccount ? (
						<Tooltip color="error" content={templateLinesErrorMessage?.[index]?.subaccount}>
							<ErrorCell>
								<Icon path={mdiAlertCircle} />
							</ErrorCell>
						</Tooltip>
					) : (
						subaccount?.code || ''
					),

				title: 'Compte aux.',
				flexGrow: 0,
				width: 80,
			},

			{
				field: 'subaccountPrefix',
				key: 'subaccountPrefix',
				render: ({ subaccountPrefix }) => subaccountPrefix || '',
				title: 'Préfixe aux.',
				flexGrow: 0,
				width: 30,
			},
			{
				key: 'actions',
				title: '',
				flexGrow: 0,
				render: (template, index) => (
					<TableRowActionCell
						actions={[
							{
								id: 'update',
								label: 'Modifier',
								icon: <Icon path={mdiPencil} />,
								onClick: () => {
									setUpdatingTemplateLine(template);
									setTemplateLineIndex(index);
								},
							},
							{
								id: 'delete',
								label: 'Supprimer',
								icon: <Icon path={mdiDelete} />,
								onClick: () => removeTemplateLine(template.id),
							},
						]}
					/>
				),
				width: 40,
			},
		],
		[removeTemplateLine, templateLinesErrorMessage],
	);

	const [columns, setColumns] = useState<TableColumnType<SettingCustomerFileTemplateLineType>[]>([]);

	const dataSource = useCallback(
		async (): Promise<{
			items: SettingCustomerFileTemplateLineType[];
			count: number;
		}> =>
			Promise.resolve({
				items: templateLines,
				count: templateLines.length,
			}),
		[templateLines],
	);

	// Updating the analytical section column header
	useEffect(() => {
		const newColumns: TableColumnType<SettingCustomerFileTemplateLineType>[] = tableColumns.map((column) => {
			if (column.key === 'analyticalSection') {
				return {
					field: 'analyticalSection',
					key: 'analyticalSection',
					render: ({ analyticalSection }, index) =>
						templateLinesErrorMessage?.[index]?.analyticalSection ? (
							<Tooltip color="error" content={templateLinesErrorMessage?.[index]?.analyticalSection}>
								<ErrorCell>
									<Icon path={mdiAlertCircle} />
								</ErrorCell>
							</Tooltip>
						) : (
							analyticalSection?.code || ''
						),
					title: analyticalSectionCaption || 'Section',
					width: 50,
				};
			}

			return column;
		});

		setColumns(newColumns);
	}, [tableColumns, analyticalSectionCaption, templateLinesErrorMessage]);

	return (
		<StyledWrapper>
			<Table<SettingCustomerFileTemplateLineType>
				columns={columns}
				dataSource={dataSource}
				style={{ minHeight: 150, flex: 1 }}
			/>

			<TemplateLineFormSection
				isCreationSucceeded={isCreationSucceeded}
				isUpdatingSucceeded={isUpdatingSucceeded}
				analyticalDimensionId={analyticalDimensionId}
				connector={connector}
				customerFileId={customerFileId}
				defaultEntryName={defaultEntryName}
				directions={templateLineDirections}
				onSubmit={handleFormSubmit}
				resetUpdateTemplateLine={resetUpdateTemplateLine}
				templateLine={updatingTemplateLine ?? undefined}
				templateLinesErrorMessage={templateLinesErrorMessage}
				templateLineIndex={templateLineIndex}
				onTemplateLinesErrorMessagesChange={onTemplateLinesErrorMessagesChange}
			/>
			<InformationContainer>
				<Icon path={mdiInformation} />
				Un modèle doit être composé d&apos;au moins une ligne de débit et une ligne de crédit
			</InformationContainer>
		</StyledWrapper>
	);
};

export default TemplateCustomerFileLineSection;
