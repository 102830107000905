import { gql } from '@apollo/client';

import { CustomerFileSettingPaymentModeType, DomainSettingPaymentModeType } from '../types/settingPaymentMode';

import { PAYMENT_MODE_FIELDS } from './paymentMode';

const DOMAIN_SETTING_PAYMENT_MODE_FIELDS = `
	currentCodeValue
`;

const CUSTOMER_FILE_SETTING_PAYMENT_MODE_FIELDS = `
	currentPaymentMode {
		${PAYMENT_MODE_FIELDS}
	}
	isInherited
`;

// QUERIES

export type FetchDomainBillExchangeSettingPaymentModeType = {
	quickentry_settingDomainBillExchangePaymentMode: DomainSettingPaymentModeType;
};

export const FETCH_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE = gql`
	query Quickentry_settingDomainBillExchangePaymentMode($id: ID!) {
		quickentry_settingDomainBillExchangePaymentMode(id: $id) {
			${DOMAIN_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type FetchCustomerFileBillExchangeSettingPaymentModeType = {
	quickentry_settingCustomerFileBillExchangePaymentMode: CustomerFileSettingPaymentModeType;
};

export const FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE = gql`
	query Quickentry_settingCustomerFileBillExchangePaymentMode($id: ID!) {
		quickentry_settingCustomerFileBillExchangePaymentMode(id: $id) {
			${CUSTOMER_FILE_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type FetchDomainCheckSettingPaymentModeType = {
	quickentry_settingDomainCheckPaymentMode: DomainSettingPaymentModeType;
};

export const FETCH_DOMAIN_CHECK_SETTING_PAYMENT_MODE = gql`
	query Quickentry_settingDomainCheckPaymentMode($id: ID!) {
		quickentry_settingDomainCheckPaymentMode(id: $id) {
			${DOMAIN_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type FetchCustomerFileCheckSettingPaymentModeType = {
	quickentry_settingCustomerFileCheckPaymentMode: CustomerFileSettingPaymentModeType;
};

export const FETCH_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE = gql`
	query Quickentry_settingCustomerFileCheckPaymentMode($id: ID!) {
		quickentry_settingCustomerFileCheckPaymentMode(id: $id) {
			${CUSTOMER_FILE_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

// MUTATIONS

export type CreateDomainBillExchangeSettingPaymentModeType = {
	quickentry_createSettingDomainBillExchangePaymentMode: DomainSettingPaymentModeType;
};

export const CREATE_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE = gql`
	mutation Quickentry_createSettingDomainBillExchangePaymentMode($createSettingDomainBillExchangePaymentModeInput: quickentry_CreateSettingDomainBillExchangePaymentModeInput!) {
		quickentry_createSettingDomainBillExchangePaymentMode(createSettingDomainBillExchangePaymentModeInput: $createSettingDomainBillExchangePaymentModeInput) {
			${DOMAIN_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type CreateCustomerFileBillExchangeSettingPaymentModeType = {
	quickentry_createSettingCustomerFileBillExchangePaymentMode: CustomerFileSettingPaymentModeType;
};

export const CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE = gql`
	mutation Quickentry_createSettingCustomerFileBillExchangePaymentMode($createSettingCustomerFileBillExchangePaymentModeInput: quickentry_CreateSettingCustomerFileBillExchangePaymentModeInput!) {
		quickentry_createSettingCustomerFileBillExchangePaymentMode(createSettingCustomerFileBillExchangePaymentModeInput: $createSettingCustomerFileBillExchangePaymentModeInput) {
			${CUSTOMER_FILE_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type CreateDomainCheckSettingPaymentModeType = {
	quickentry_createSettingDomainCheckPaymentMode: DomainSettingPaymentModeType;
};

export const CREATE_DOMAIN_CHECK_SETTING_PAYMENT_MODE = gql`
	mutation Quickentry_createSettingDomainCheckPaymentMode($createSettingDomainCheckPaymentModeInput: quickentry_CreateSettingDomainCheckPaymentModeInput!) {
		quickentry_createSettingDomainCheckPaymentMode(createSettingDomainCheckPaymentModeInput: $createSettingDomainCheckPaymentModeInput) {
			${DOMAIN_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;

export type CreateCustomerFileCheckSettingPaymentModeType = {
	quickentry_createSettingCustomerFileCheckPaymentMode: CustomerFileSettingPaymentModeType;
};

export const CREATE_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE = gql`
	mutation Quickentry_createSettingCustomerFileCheckPaymentMode($createSettingCustomerFileCheckPaymentModeInput: quickentry_CreateSettingCustomerFileCheckPaymentModeInput!) {
		quickentry_createSettingCustomerFileCheckPaymentMode(createSettingCustomerFileCheckPaymentModeInput: $createSettingCustomerFileCheckPaymentModeInput) {
			${CUSTOMER_FILE_SETTING_PAYMENT_MODE_FIELDS}
		}
	}
`;
