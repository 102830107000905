import { Icon } from '@elipssolution/harfang';

import { mdiFileEye, mdiFileUpload } from '@mdi/js';

import StorageDownload from './storageDownload';
import StorageUpload from './storageUpload';

const pages = [
	{
		component: StorageUpload,
		icon: <Icon path={mdiFileUpload} />,
		name: 'Dépôt',
		route: 'storageUpload',
	},
	{
		component: StorageDownload,
		icon: <Icon path={mdiFileEye} />,
		name: 'Consultation GED',
		route: 'storageDownload',
	},
];

export default pages;
