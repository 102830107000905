import { useLazyQuery } from '@apollo/client';
import { CircularProgress, Icon, Tooltip } from '@elipssolution/harfang';
import { mdiAlertCircle } from '@mdi/js';
import { ButtonBase, List, ListItem, ListItemText, Stack, Typography, alpha, styled } from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import { useEffect, useMemo, useRef, useState } from 'react';

import Empty from './Empty';
import { FETCH_DOCUMENT_UPLOADS, FetchDocumentUploadsType } from '../../../../src/api/documentUpload';
import { WidgetComponentProps } from '../../../../types/widget';
import StatusChip from '../../components/StatusChip';
import { DocumentUploadStatusEnum } from '../../types/documentUpload';

const Container = styled('div')(({ theme: { palette } }) => ({
	position: 'relative',

	overflowX: 'auto',

	flex: 1,

	'&.error': {
		backgroundColor: `${alpha(palette.error.main, 0.05)}`,
	},
}));

const LoadingWrapper = styled('div')(({ theme: { palette } }) => ({
	flex: 1,

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	color: palette.grey[400],
}));

const ErrorWrapper = styled('div')(({ theme: { palette, spacing } }) => ({
	flex: 1,

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(0.5),

	color: palette.error.main,
}));

const EmptyWrapper = styled('div')(({ theme: { palette } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',

	color: palette.grey[400],
}));

const MoreLinkWrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const ButtonLink = styled(ButtonBase)(({ theme: { shape, spacing } }) => ({
	color: 'inherit',
	textDecoration: 'none',

	fontSize: '0.875rem',
	fontWeight: 500,

	padding: spacing(0.75, 1),

	borderRadius: shape.borderRadius * 2,

	lineHeight: 1.75,
}));

let resizeTimer: ReturnType<typeof setTimeout>;

const DocumentLastUploads = ({ readOnly }: WidgetComponentProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [queryLimit, setQueryLimit] = useState<number>();

	const [fetchDocumentUploads, { called, data, loading, error }] =
		useLazyQuery<FetchDocumentUploadsType>(FETCH_DOCUMENT_UPLOADS);

	const documents = useMemo(
		() =>
			!readOnly
				? data?.document_documentUploads.items ?? []
				: Array(queryLimit)
						.fill(null)
						.map((_, index) => ({ id: index, status: DocumentUploadStatusEnum.SENT, name: 'Document.pdf' })),
		[data, readOnly, queryLimit],
	);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const { height } = entries[0].contentRect;

				const limit = Math.floor(height / 49);

				if (called) {
					clearTimeout(resizeTimer);

					resizeTimer = setTimeout(() => {
						setQueryLimit((prevQueryLimit) => (prevQueryLimit !== limit ? limit : prevQueryLimit));
					}, 300);
				} else {
					setQueryLimit((prevQueryLimit) => (prevQueryLimit !== limit ? limit : prevQueryLimit));
				}
			}),
		[called],
	);

	// Check for ref height changes and actualize query limit
	useEffect(() => {
		if (!wrapperRef.current) return undefined;

		resizeObserver.observe(wrapperRef.current);

		return () => resizeObserver.disconnect();
	}, [resizeObserver, queryLimit, wrapperRef]);

	useEffect(() => {
		!readOnly &&
			queryLimit &&
			fetchDocumentUploads({ variables: { page: { limit: queryLimit } } }).catch((e) => {
				throw e;
			});
	}, [fetchDocumentUploads, readOnly, queryLimit]);

	return (
		<Container className={clsx({ error })} ref={wrapperRef}>
			<Stack justifyContent="space-between" position="absolute" height="100%" width="100%">
				{loading && (
					<LoadingWrapper>
						<CircularProgress color="inherit" />
					</LoadingWrapper>
				)}
				{!!error && (
					<ErrorWrapper>
						<Typography>Erreur</Typography>
						<Tooltip content="Impossible de charger les documents" color="error">
							<Icon path={mdiAlertCircle} />
						</Tooltip>
					</ErrorWrapper>
				)}
				{!loading && !error && (
					<>
						<List disablePadding>
							{documents.map(({ id, status, name }) => (
								<ListItem key={id} divider>
									<ListItemText primary={name} />
									<StatusChip status={status} />
								</ListItem>
							))}
						</List>

						{documents.length === 0 && (
							<EmptyWrapper>
								<Empty />
								Aucun document
							</EmptyWrapper>
						)}

						<MoreLinkWrapper>
							<Link href="/document/storageUpload" passHref>
								<ButtonLink disabled={readOnly}>Voir plus</ButtonLink>
							</Link>
						</MoreLinkWrapper>
					</>
				)}
			</Stack>
		</Container>
	);
};

export default DocumentLastUploads;
