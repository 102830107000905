import { useQuery } from '@apollo/client';
import { CircularProgress, Icon, IconButton } from '@elipssolution/harfang';
import { mdiChevronLeftCircle, mdiChevronRightCircle } from '@mdi/js';
import { Paper, Skeleton, Stack, Typography, styled } from '@mui/material';
import { addMonths, addYears, format, getDaysInMonth, isSameMonth, isSameYear, subYears } from 'date-fns';
import { fr } from 'date-fns/locale';
import numeral from 'numeral';
import { useMemo, useState } from 'react';
import { FormattedNumber } from 'react-intl';

import BarChart from './charts/BarChart';
import LineChart from './charts/LineChart';
import { WidgetRevenueConfigurationType } from './configuration/WidgetRevenueConfiguration';
import { N_COLOR, N_MINUS_1_COLOR } from './utils/chart';
import { FETCH_REVENUE, FetchRevenueType } from '../../../../src/api/revenue';
import { ChartTypeEnum, PeriodicityEnum } from '../../../../src/types/revenue';
import { WidgetComponentProps } from '../../../../types/widget';

const TICK_MONTH_LABELS = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];

const TODAY_DATE = new Date();

const LoadingWrapper = styled('div')({
	position: 'absolute',
	inset: 0,

	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const PeriodBannerWrapper = styled('div')(({ theme: { transitions } }) => ({
	position: 'absolute',
	top: -8,
	right: -8,

	display: 'flex',
	alignItems: 'center',

	'& > button': {
		opacity: 0,

		transition: transitions.create('opacity'),
	},

	'&:hover > button': {
		opacity: 1,
	},
}));

const PeriodIndicatorContainer = styled(Paper)(({ theme: { palette, spacing } }) => ({
	position: 'relative',

	width: 100,

	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',

	padding: spacing(0.5, 1),

	userSelect: 'none',

	'&:first-of-type': {
		backgroundColor: N_MINUS_1_COLOR,
		color: palette.getContrastText(N_MINUS_1_COLOR),

		marginRight: spacing(1),
	},

	'&:last-of-type': {
		backgroundColor: N_COLOR,
		color: palette.getContrastText(N_COLOR),
	},
}));

const PeriodIndicatorSkeleton = styled(Skeleton)(({ theme: { spacing } }) => ({
	height: 51,
	width: 116,

	'&:first-of-type': {
		marginRight: spacing(1),
	},
}));

const generateFakeData = (
	periodicity: PeriodicityEnum,
	date: Date,
): {
	items: {
		value: number;
		previousValue: number;
		name: string;
	}[];
	balance: string;
	previousBalance: string;
} => {
	const period =
		periodicity === PeriodicityEnum.ANNUALLY
			? TICK_MONTH_LABELS
			: Array.from({ length: getDaysInMonth(date) }, (_, i) => (i + 1).toString());

	const items = period.map((periodItem) => ({
		name: periodItem,
		previousValue: Math.random(),
		value: Math.random(),
	}));
	const { balance, previousBalance } = items.reduce(
		(acc, { value, previousValue }) => {
			acc.balance = acc.balance.add(value || '0');
			acc.previousBalance = acc.previousBalance.add(previousValue || '0');
			return acc;
		},
		{ balance: numeral('0'), previousBalance: numeral('0') },
	);

	return {
		items,
		balance: balance.format('0,00'),
		previousBalance: previousBalance.format('0,00'),
	};
};

type PeriodIndicatorProps = {
	balance: string;
	label: string;
};

const PeriodIndicator = ({ label, balance }: PeriodIndicatorProps) => (
	<PeriodIndicatorContainer>
		<Typography fontWeight="bold">{label}</Typography>
		<FormattedNumber value={+balance} currency="EUR" style="currency" />
	</PeriodIndicatorContainer>
);

type PeriodBannerProps = {
	balance: string;
	date: Date;
	loading: boolean;
	disabled?: boolean;
	onDateChange: (shift: number) => void;
	periodicity: PeriodicityEnum;
	previousBalance: string;
};

const formatIndicatorLabel = ({ date, periodicity }: { date: Date; periodicity: PeriodicityEnum }) => {
	if (periodicity === PeriodicityEnum.ANNUALLY) {
		return format(date, 'yyyy');
	}

	const formattedDate = format(date, 'MMM yyyy');

	return `${formattedDate.charAt(0).toUpperCase()}${formattedDate.slice(1)}`;
};

const PeriodBanner = ({
	balance,
	date,
	loading,
	onDateChange,
	periodicity,
	previousBalance,
	disabled,
}: PeriodBannerProps) => {
	const isNextButtonDisabled =
		periodicity === PeriodicityEnum.ANNUALLY ? isSameYear(date, TODAY_DATE) : isSameMonth(date, TODAY_DATE);

	const previousIndicatorLabel = useMemo(() => {
		const previousDate = subYears(date, 1);

		return formatIndicatorLabel({ periodicity, date: previousDate });
	}, [date, periodicity]);

	const focusedIndicatorLabel = formatIndicatorLabel({ date, periodicity });

	const handleMinusClick = () => onDateChange(-1);
	const handlePlusClick = () => onDateChange(1);

	return (
		<PeriodBannerWrapper>
			<IconButton disabled={loading || disabled} onClick={handleMinusClick} size="small">
				<Icon path={mdiChevronLeftCircle} size="small" />
			</IconButton>
			{loading ? (
				<>
					<PeriodIndicatorSkeleton variant="rounded" />
					<PeriodIndicatorSkeleton variant="rounded" />
				</>
			) : (
				<>
					<PeriodIndicator balance={previousBalance} label={previousIndicatorLabel} />
					<PeriodIndicator balance={balance} label={focusedIndicatorLabel} />
				</>
			)}
			<IconButton disabled={isNextButtonDisabled || loading || disabled} onClick={handlePlusClick} size="small">
				<Icon path={mdiChevronRightCircle} size="small" />
			</IconButton>
		</PeriodBannerWrapper>
	);
};

const WidgetRevenue = ({ configuration, readOnly }: WidgetComponentProps) => {
	const [date, setDate] = useState(TODAY_DATE);

	const {
		accountRanges,
		isBalanceCumulative = false,
		periodicity = PeriodicityEnum.ANNUALLY,
		chartType = ChartTypeEnum.BAR_CHART,
		formula,
	} = (configuration as WidgetRevenueConfigurationType | undefined) ?? {};

	const { data: revenueData, loading: isRevenueLoading } = useQuery<FetchRevenueType>(FETCH_REVENUE, {
		skip: !periodicity || !date,
		variables: {
			revenueInput: {
				rangeAccount: accountRanges?.map(({ value }) => value),
				isBalanceCumulative,
				date,
				periodicity,
				formula,
			},
		},
	});

	const {
		revenue: { balance = '0', data = [{ name: '', previousValue: 0, value: 0 }], previousBalance = '0' },
	} = revenueData || { revenue: {} };

	const fakeData = useMemo(() => generateFakeData(periodicity, date), [periodicity, date]);

	const revenues = useMemo(
		() =>
			readOnly
				? fakeData.items
				: data?.map(({ name, previousValue, value }) => ({
						name: periodicity === PeriodicityEnum.ANNUALLY ? TICK_MONTH_LABELS[+name - 1] : name,
						previousValue: +previousValue,
						value: +value,
				  })) || [],
		[data, fakeData.items, periodicity, readOnly],
	);

	const handleDateChange = (shift: number) =>
		setDate(periodicity === PeriodicityEnum.ANNUALLY ? addYears(date, shift) : addMonths(date, shift));

	const labelFormatter = (label: string) =>
		`${label}${
			periodicity === PeriodicityEnum.MONTHLY
				? ` ${format(date, 'MMMM', {
						locale: fr,
				  })}`
				: ''
		}`;

	const renderChart = () => {
		switch (chartType) {
			case ChartTypeEnum.LINE_CHART:
				return <LineChart data={revenues} labelFormatter={labelFormatter} />;
			default:
				return <BarChart data={revenues} labelFormatter={labelFormatter} />;
		}
	};

	return (
		<Stack flex={1} position="relative">
			{isRevenueLoading ? (
				<LoadingWrapper>
					<CircularProgress color="inherit" />
				</LoadingWrapper>
			) : (
				<>
					{renderChart()}

					<PeriodBanner
						balance={readOnly ? fakeData.balance : balance}
						date={date}
						loading={isRevenueLoading}
						periodicity={periodicity}
						onDateChange={handleDateChange}
						previousBalance={readOnly ? fakeData.previousBalance : previousBalance}
					/>
				</>
			)}
		</Stack>
	);
};

export default WidgetRevenue;
