import { DatePicker, TextField } from '@elipssolution/harfang';
import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { Control, Controller } from 'react-hook-form';

import { SettingDomainTemplateFormType, StateEnum } from '../../../../../types/settingTemplate';

const FormRow = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(2),

	'& > label': {
		margin: 0,
	},

	'& > div': {
		flex: 1,
	},
}));

type TemplateDomainInfosFormProps = {
	control: Control<SettingDomainTemplateFormType>;
};

const TemplateDomainInfosFormForm = ({ control }: TemplateDomainInfosFormProps) => (
	<>
		<FormRow>
			<Controller
				name="name"
				control={control}
				render={({ field }) => <TextField {...field} label="Nom du modèle" required />}
				rules={{ required: 'Champ requis', pattern: /(.|\s)*\S(.|\s)*/ }}
			/>

			<Controller
				name="defaultEntryName"
				control={control}
				render={({ field }) => <TextField {...field} label="Libellé de l'écriture" required />}
				rules={{ required: 'Champ requis', pattern: /(.|\s)*\S(.|\s)*/ }}
			/>

			<Controller
				name="validUntil"
				control={control}
				render={({ field }) => <DatePicker {...field} label="Valide jusqu'au" placeholder="dd/mm/yyyy" />}
			/>
		</FormRow>

		<FormRow>
			<Controller
				name="journalCode"
				control={control}
				rules={{ required: 'Champ requis' }}
				render={({ field }) => <TextField {...field} label="Journal" required />}
			/>
			<Controller
				name="paymentModeCode"
				control={control}
				render={({ field }) => <TextField {...field} label="Mode de règlement" />}
			/>
			<Controller
				name="analyticalSectionCaption"
				control={control}
				render={({ field }) => <TextField {...field} label="En-tête de la section analytique" />}
			/>
			<Controller
				name="analyticalDimensionCode"
				control={control}
				render={({ field }) => <TextField {...field} label="Dimension analytique" />}
			/>
		</FormRow>

		<FormRow>
			<Controller
				control={control}
				defaultValue={StateEnum.ENABLED}
				name="state"
				render={({ field: { value, onChange, ...field } }) => {
					const isChecked = value === StateEnum.ENABLED;

					const handleCheckboxCheck = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
						onChange(checked ? StateEnum.ENABLED : StateEnum.DISABLED);

					return (
						<FormControlLabel
							control={<Checkbox {...field} checked={isChecked} onChange={handleCheckboxCheck} />}
							label="Actif"
						/>
					);
				}}
			/>
		</FormRow>
	</>
);

export default TemplateDomainInfosFormForm;
