import { gql } from '@apollo/client';

import { TagType } from '../types/tag';

export const TAG_FIELDS = `
	id
	name
`;

export const DOCUMENT_UPLOAD_TAG_FIELDS = `
	color
	${TAG_FIELDS}
`;

// QUERIES

export type FetchEnabledTagsType = {
	document_enabledTags: {
		count: number;
		items: TagType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_ENABLED_TAGS = gql`
	query Document_enabledTags($search: String, $page: Page!, $orderBy: OrderBy) {
		document_enabledTags(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${TAG_FIELDS}
				info
				storageUpload {
					id
				}
			}
			limit
			offset
		}
	}
`;

export type FetchUsedTagsType = {
	document_usedTags: {
		count: number;
		items: TagType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_USED_TAGS = gql`
	query Document_usedTags($search: String, $page: Page!, $orderBy: OrderBy) {
		document_usedTags(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${TAG_FIELDS}
				info
			}
			limit
			offset
		}
	}
`;
