import { useLazyQuery } from '@apollo/client';
import { TableOrderByType } from '@elipssolution/harfang';
import { useCallback, useState } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import {
	FetchSubmittedOrValidatedBillExchangesType,
	FETCH_SUBMITTED_OR_VALIDATED_BILL_EXCHANGES,
} from '../../../api/billExchange';
import { BillExchangeType } from '../../../types/billExchange';

const useSubmittedOrValidatedBillExchangesDataSource = () => {
	const [hasItems, setHasItems] = useState(true);

	const [fetchSubmittedOrValidatedBillExchanges, { networkStatus }] =
		useLazyQuery<FetchSubmittedOrValidatedBillExchangesType>(FETCH_SUBMITTED_OR_VALIDATED_BILL_EXCHANGES, {
			notifyOnNetworkStatusChange: true,
		});

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<BillExchangeType>,
		): Promise<DataSourceResultFormatType<BillExchangeType>> => {
			const { field, order } = orderBy ?? {};

			const { data, error } = await fetchSubmittedOrValidatedBillExchanges({
				variables: {
					...(orderBy && {
						orderBy: {
							field,
							order,
						},
					}),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_submittedOrValidatedBillExchanges: { count = 0, items = [] },
			} = data ?? {
				quickentry_submittedOrValidatedBillExchanges: {},
			};

			setHasItems(count > 0);

			return {
				count,
				items: items.map(({ documentDate, ...rest }) => ({
					...rest,
					documentDate: new Date(documentDate),
				})),
			};
		},
		[fetchSubmittedOrValidatedBillExchanges],
	);

	return { dataSource, hasItems, networkStatus };
};

export default useSubmittedOrValidatedBillExchangesDataSource;
