import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import SettingsTable from '../../../../components/SettingsTable';
import { FETCH_INTERNAL_USERS_BY_GROUP, FetchInternalUsersByGroupType } from '../../../api/internalUser';
import { InternalUserType } from '../../../types/user';

const columns: TableColumnType<InternalUserType>[] = [
	{
		field: 'lastName',
		key: 'name',
		render: ({ lastName, firstName }) => `${lastName} ${firstName ?? ''}`.trim(),
		sortable: true,
		title: "Nom de l'utilisateur",
		width: 200,
	},
	{
		field: 'email',
		key: 'email',
		sortable: true,
		title: 'Email',
		width: 250,
	},
];

type UsersByGroupTableProps = {
	groupId: string;
};

const UsersByGroupTable = ({ groupId }: UsersByGroupTableProps) => {
	const [fetchInternalUsers] = useLazyQuery<FetchInternalUsersByGroupType>(FETCH_INTERNAL_USERS_BY_GROUP);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<InternalUserType>,
		): Promise<{ count: number; items: InternalUserType[] }> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchInternalUsers({
				variables: {
					groupId,
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				internalUsersByGroup: { count = 0, items = [] },
			} = data ?? {
				internalUsersByGroup: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchInternalUsers, groupId],
	);

	return (
		<SettingsTable<InternalUserType> dataSource={dataSource} tableColumns={columns} title="Utilisateurs du groupe" />
	);
};

export default UsersByGroupTable;
