import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS } from './account';
import { AccountType } from '../types/account';

// QUERIES

export type FetchSupplierMasterAccountsType = {
	supplierMasterAccounts: {
		count: number;
		items: Omit<AccountType, 'isMaster'>[];
		limit: number;
		offset: number;
	};
};

export const FETCH_SUPPLIER_MASTER_ACCOUNTS = gql`
	query SupplierMasterAccounts($search: String, $page: Page) {
		supplierMasterAccounts(search: $search, page: $page) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchMasterAccountsType = {
	masterAccountsByCustomerFile: {
		items: Omit<AccountType, 'isMaster'>[];
		limit: number;
		offset: number;
		count: number;
	};
};

export const FETCH_MASTER_ACCOUNTS_BY_CUSTOMER_FILE = gql`
	query MasterAccountsByCustomerFile($customerFileId: ID!, $search: String, $page: Page!, $orderBy: OrderBy) {
		masterAccountsByCustomerFile(customerFileId: $customerFileId, search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${ACCOUNT_FIELDS}
			}
			limit
			offset
		}
	}
`;
