import { gql } from '@apollo/client';

import { CategoryType } from '../types/category';

export const CATEGORY_FIELDS = `
	id
	name
`;

// QUERIES

export type FetchCategories1Type = {
	document_documentCategories1: {
		count: number;
		items: CategoryType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENT_CATEGORIES_1 = gql`
	query Document_documentCategories1($search: String, $page: Page!, $orderBy: OrderBy) {
		document_documentCategories1(search: $search, page: $page, orderBy: $orderBy) {
			count
			items {
				${CATEGORY_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCategoriesByParentIdType = {
	document_documentCategoriesByParentId: {
		count: number;
		items: CategoryType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_DOCUMENT_CATEGORIES_BY_PARENT_ID = gql`
	query Document_documentCategoriesByParentId(
		$documentDocumentCategoriesByParentIdId: ID
		$search: String
		$page: Page!
		$orderBy: OrderBy
	) {
		document_documentCategoriesByParentId(
			id: $documentDocumentCategoriesByParentIdId
			search: $search
			page: $page
			orderBy: $orderBy
		) {
			items {
				${CATEGORY_FIELDS}
			}
			count
			limit
			offset
		}
	}
`;

export type FetchAllCategoriesByParentType = {
	document_documentAllCategoriesByParent: CategoryType[];
};

export const FETCH_ALL_CATEGORIES_BY_PARENT = gql`
	query Document_documentAllCategoriesByParent($parentId: ID) {
		document_documentAllCategoriesByParent(parentId: $parentId) {
			id
			name
			hasChildren
		}
	}
`;

export type FetchChildrenCategoriesByParentsType = {
	document_documentChildrenCategoriesByParents: {
		id: CategoryType['id'];
		name: CategoryType['name'];
		children: CategoryType[];
	}[];
};

export const FETCH_CHILDREN_CATEGORIES_BY_PARENTS = gql`
	query Document_documentChildrenCategoriesByParents($parentIds: [ID!]!) {
		document_documentChildrenCategoriesByParents(parentIds: $parentIds) {
			id
			name
			hasChildren
			children {
				id
				name
				hasChildren
			}
		}
	}
`;
