import { Button } from '@elipssolution/harfang';
import { lighten, styled, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';

import Banner from './Banner';
import { useSession } from './SessionProvider';
import { UserType } from '../types/user';

const ImpersonateBannerContainer = styled(Banner)({
	backgroundColor: lighten(purple['700'], 0.9),
	color: purple['700'],
});

type ImpersonateBannerProps = {
	impersonatedUser: Pick<UserType, 'firstName' | 'lastName'>;
};

const ImpersonateBanner = ({ impersonatedUser: { firstName, lastName } }: ImpersonateBannerProps) => {
	const { impersonate } = useSession();

	return (
		<ImpersonateBannerContainer>
			<Typography>
				Vous utilisez actuellement l&apos;application avec le profil
				<strong> {`${firstName ?? ''} ${lastName}`.trim()}</strong>
			</Typography>
			<Button variant="outlined" color="inherit" size="small" onClick={() => impersonate()}>
				Quitter
			</Button>
		</ImpersonateBannerContainer>
	);
};

export default ImpersonateBanner;
