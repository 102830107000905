import { Chip, Tooltip } from '@elipssolution/harfang';

import { DocumentUploadStatusEnum, toDocumentUploadStatusLabelEnum } from '../types/documentUpload';

const chipStatusMapper = new Map([
	[
		DocumentUploadStatusEnum.FAILED,
		{
			color: 'error',
			label: toDocumentUploadStatusLabelEnum(DocumentUploadStatusEnum.FAILED),
			tooltipContent:
				'Une erreur est survenue lors du dépôt. Une nouvelle tentative sera effectuée lors de la synchronisation quotidienne.',
		},
	],
	[
		DocumentUploadStatusEnum.WAITING,
		{
			color: '#9c27b0',
			label: toDocumentUploadStatusLabelEnum(DocumentUploadStatusEnum.WAITING),
		},
	],
	[
		DocumentUploadStatusEnum.SENT,
		{
			color: 'info',
			label: toDocumentUploadStatusLabelEnum(DocumentUploadStatusEnum.SENT),
		},
	],
	[
		DocumentUploadStatusEnum.PROCESSED,
		{
			color: 'success',
			label: toDocumentUploadStatusLabelEnum(DocumentUploadStatusEnum.PROCESSED),
		},
	],
]);

const StatusChip = ({ status }: { status: DocumentUploadStatusEnum }) => {
	const { color, label, tooltipContent } = chipStatusMapper.get(status) || {
		label: '',
		color: 'info',
	};

	return (
		<Tooltip content={tooltipContent}>
			<Chip color={color} label={label} />
		</Tooltip>
	);
};

export default StatusChip;
