import { Icon, IconButton, MenuProps, Tooltip } from '@elipssolution/harfang';
import { mdiCheck, mdiDelete, mdiDotsHorizontal } from '@mdi/js';
import { Stack } from '@mui/material';
import { useState } from 'react';

import { useSession } from '../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../types/permission';
import SubmittedOrValidatedActionMenu from '../components/SubmittedOrValidatedActionMenu';

type useSubmittedOrValidatedActionColumnProps<T extends { id: string }> = {
	onValidate: (id: T['id']) => Promise<unknown>;
	onRemove: (id: T['id']) => void;
};

const useSubmittedOrValidatedActionColumn = <T extends { id: string }>({
	onRemove,
	onValidate,
}: useSubmittedOrValidatedActionColumnProps<T>) => {
	const { permissions } = useSession();

	const hasQuickentryValidatePermission = permissions.some(
		({ code, value }) => code === PermissionEnum.QUICKENTRY_VALIDATE && value,
	);

	const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<MenuProps['anchorEl']>();

	const handleActionMenuClose = (event: Event) => {
		event.stopPropagation();

		setActionMenuAnchorEl(undefined);
	};

	const handleItemClick = ({ actionId, id }: { actionId: string; id: T['id'] }) => {
		if (actionId === 'remove') onRemove(id);

		setActionMenuAnchorEl(undefined);
	};

	const renderColumnWithValidatePermission = (id: T['id']) => (
		<>
			<Stack flexDirection="row">
				<Tooltip content="Valider la saisie">
					<IconButton
						onClick={(event) => {
							event.stopPropagation();

							return onValidate(id);
						}}
					>
						<Icon path={mdiCheck} />
					</IconButton>
				</Tooltip>
				<IconButton
					onClick={(event) => {
						event.stopPropagation();

						setActionMenuAnchorEl(event.currentTarget);
					}}
				>
					<Icon path={mdiDotsHorizontal} />
				</IconButton>
			</Stack>

			<SubmittedOrValidatedActionMenu
				anchorEl={actionMenuAnchorEl}
				onClose={handleActionMenuClose}
				onItemClick={(actionId: string) =>
					handleItemClick({
						actionId,
						id,
					})
				}
			/>
		</>
	);

	const renderColumnWithoutValidatePermission = (id: T['id']) => (
		<IconButton
			onClick={(event) => {
				event.stopPropagation();

				onRemove(id);
			}}
		>
			<Icon path={mdiDelete} />
		</IconButton>
	);

	return {
		flexGrow: 0,
		key: 'actions',
		render: ({ id }: T) =>
			hasQuickentryValidatePermission
				? renderColumnWithValidatePermission(id)
				: renderColumnWithoutValidatePermission(id),
		width: hasQuickentryValidatePermission ? 80 : 40,
	};
};

export default useSubmittedOrValidatedActionColumn;
