import { Button, CircularProgress, CurrencyFilterDefinitionType, Icon, Menu } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiMenuDown, mdiMenuUp } from '@mdi/js';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useSession } from '../../../../src/components/SessionProvider';
import { downloadFile } from '../../../../src/utils/file';
import { FiscalYearType } from '../../../../types/fiscalYear';
import { AccountingDate, DownloadTypeEnum } from '../../types/accounting';
import { ThirdPartyTypeEnum } from '../../types/thirdParty';

type DownloadFilterType = {
	balance?: CurrencyFilterDefinitionType['value'];
};

type CustomMenuItemProps = {
	downloadFilter: DownloadFilterType;
	downloadSearch?: string;
	hasTransaction: boolean;
	selectedFiscalYear?: FiscalYearType;
	selectedType: ThirdPartyTypeEnum;
	type: DownloadTypeEnum;
	typeFileLoading: DownloadTypeEnum[];
	accountingDate: AccountingDate;
	onTypeFileLoading: Dispatch<SetStateAction<DownloadTypeEnum[]>>;
};

const CustomMenuItem = ({
	downloadFilter = {},
	downloadSearch,
	hasTransaction,
	selectedFiscalYear,
	selectedType,
	type,
	typeFileLoading,
	accountingDate,
	onTypeFileLoading,
}: CustomMenuItemProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};

	const { customerFile: sessionCustomerFile } = useSession();
	const { id: sessionCustomerFileId, code: sessionCustomerFileCode } = sessionCustomerFile || {};

	const [typeFileError, setTypeFileError] = useState<DownloadTypeEnum[]>([]);

	const isMenuItemInAnyInfoArray = typeFileLoading.includes(type) || typeFileError.includes(type);

	const fileName = useMemo(
		() =>
			`${sessionCustomerFileCode || ''}_${selectedType === ThirdPartyTypeEnum.CUSTOMER ? 'clients' : 'fournisseurs'}_${
				selectedFiscalYear?.name || ''
			}`,
		[selectedFiscalYear?.name, selectedType, sessionCustomerFileCode],
	);

	const exportThirdParties = useCallback(async () => {
		const headers = new Headers();

		if (access_token) {
			headers.append('Authorization', `Bearer ${access_token}`);
		}

		if (sessionCustomerFileId) {
			headers.append('customer-file-id', sessionCustomerFileId);
		}

		const result = await fetch(
			`/accounting/thirdPartiesExport/${type}?fileName=${fileName}&fiscalYearId=${
				selectedFiscalYear?.id || ''
			}&type=${selectedType}${Object.keys(downloadFilter).length ? `&filter=${JSON.stringify(downloadFilter)}` : ''}${
				downloadSearch ? `&search=${downloadSearch}` : ''
			}&hasTransaction=${hasTransaction.toString()}${
				accountingDate !== null ? `&accountingDate=${JSON.stringify(accountingDate)}` : ''
			}`,
			{
				headers,
				method: 'GET',
			},
		);

		if (!result.ok) {
			throw new Error('Erreur lors du téléchargement du fichier.');
		}

		const documentBlob = await result.blob();

		downloadFile(URL.createObjectURL(documentBlob), `${fileName}.${type === 'excel' ? 'xlsx' : 'csv'}`);
	}, [
		access_token,
		accountingDate,
		downloadFilter,
		downloadSearch,
		fileName,
		hasTransaction,
		selectedFiscalYear?.id,
		selectedType,
		sessionCustomerFileId,
		type,
	]);

	const handleThirdPartiesExport = useCallback(() => {
		onTypeFileLoading((prevValue) => [...prevValue, type]);

		exportThirdParties()
			.catch((e) => {
				setTypeFileError((prevValue) => [...prevValue, type]);
				throw e;
			})
			.finally(() => onTypeFileLoading((prevValue) => prevValue.filter((item) => item !== type)));
	}, [exportThirdParties, onTypeFileLoading, type]);

	return (
		<MenuItem disabled={isMenuItemInAnyInfoArray} onClick={handleThirdPartiesExport}>
			<Typography color={typeFileError.includes(type) ? 'error' : 'inherit'}>
				{type === 'csv' ? 'CSV' : type.charAt(0).toUpperCase() + type.slice(1)}
			</Typography>

			{isMenuItemInAnyInfoArray && (
				<Stack flex={1} alignItems="flex-end">
					{typeFileLoading.includes(type) ? (
						<CircularProgress color="inherit" size="small" />
					) : (
						<Icon color="error" path={mdiAlertCircle} />
					)}
				</Stack>
			)}
		</MenuItem>
	);
};

type DownloadThirdPartiesButtonProps = {
	disabled: boolean;
	downloadFilter: DownloadFilterType;
	downloadSearch?: string;
	hasTransaction: boolean;
	selectedFiscalYear?: FiscalYearType;
	selectedType: ThirdPartyTypeEnum;
	accountingDate: AccountingDate;
};

const DownloadThirdPartiesButton = ({
	disabled,
	downloadFilter,
	downloadSearch,
	hasTransaction,
	selectedFiscalYear,
	selectedType,
	accountingDate,
}: DownloadThirdPartiesButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [typeFileLoading, setTypeFileLoading] = useState<DownloadTypeEnum[]>([]);

	const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget);

	const handleClose = () => setAnchorEl(null);

	return (
		<div>
			<Button
				disabled={disabled}
				onClick={handleClick}
				variant="contained"
				endIcon={<Icon path={anchorEl ? mdiMenuUp : mdiMenuDown} />}
			>
				Télécharger
			</Button>

			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={!!anchorEl}
				onClose={handleClose}
			>
				<Stack width="140px">
					{Object.values(DownloadTypeEnum).map((downloadType) => (
						<CustomMenuItem
							key={downloadType}
							type={downloadType}
							downloadFilter={downloadFilter}
							downloadSearch={downloadSearch}
							hasTransaction={hasTransaction}
							selectedFiscalYear={selectedFiscalYear}
							selectedType={selectedType}
							typeFileLoading={typeFileLoading}
							onTypeFileLoading={setTypeFileLoading}
							accountingDate={accountingDate}
						/>
					))}
				</Stack>
			</Menu>
		</div>
	);
};

export default DownloadThirdPartiesButton;
