import { useCallback } from 'react';

import { CustomerFileWithDomainType } from '../../../../../../types/customerFile';
import { SettingTagByCustomerFileType } from '../../../../types/settingTag';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';

import CustomerFileTagsForm from './CustomerFileTagsForm';
import CustomerFileTagsTable from './CustomerFileTagsTable';

type CustomerFileTagsTableProps = {
	customerFile: CustomerFileWithDomainType;
};

const CustomerFileTags = ({ customerFile }: CustomerFileTagsTableProps) => {
	const { push } = useSettingsDialog();

	const handleTagSelection = useCallback(
		(tag?: SettingTagByCustomerFileType) => {
			push(<CustomerFileTagsForm customerFile={customerFile} tag={tag} />);
		},
		[customerFile, push],
	);

	return (
		<SettingsDialogPage title={customerFile.name}>
			<CustomerFileTagsTable customerFile={customerFile} onTagSelection={handleTagSelection} />
		</SettingsDialogPage>
	);
};

export default CustomerFileTags;
