import { ReactElement, useMemo } from 'react';

import { useSession } from '../src/components/SessionProvider';
import { SessionPermissionType } from '../src/types/session';

type PermissionWallProps = {
	permissionCodes: SessionPermissionType['code'][];
	children: ReactElement | null;
};

const PermissionWall = ({ permissionCodes, children }: PermissionWallProps) => {
	const { permissions } = useSession();

	const hasPermission = useMemo(() => {
		const userPermissionCodes = permissions.reduce(
			(acc, { code, value }) => [...acc, ...(value ? [code] : [])],
			[] as SessionPermissionType['code'][],
		);

		return permissionCodes.length > 0
			? permissionCodes.some((permissionCode) => userPermissionCodes?.includes(permissionCode))
			: true;
	}, [permissions, permissionCodes]);

	return hasPermission ? children : null;
};

export default PermissionWall;
