import { useQuery } from '@apollo/client';
import { Dialog, DialogProps, Stack, styled } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import Contacts from './contacts/Contacts';
import CustomerFiles from './customerFiles/CustomerFiles';
import ExternalAccess from './externalAccess/ExternalAccess';
import General from './general/General';
import InternalAccess from './internalAccess/InternalAccess';
import SettingsModuleNavbar, { SettingsModuleType } from './SettingsModuleNavbar';
import Synchronizations from './synchronizations/Synchronizations';
import UserCustomerFileRelationProvider, {
	useUserCustomerFileRelationContext,
} from './UserCustomerFileRelationProvider';
import SettingsWidgets from './widgets/SettingsWidgets';
import { SettingsDialogContext } from '../../hooks/useSettingsDialog';
import modules from '../../modules/modules';
import { PermissionEnum } from '../../types/permission';
import { FETCH_SETTING_MODULES, FetchSettingModulesType } from '../api/settingModule';
import { useSession } from '../components/SessionProvider';
import { UserTypeEnum } from '../types/user';

export const generalModules: SettingsModuleType[] = [
	{
		code: 'general',
		label: 'Général',
		permissionCodes: [PermissionEnum.THEME_MANAGE],
		component: <General />,
	},
	{
		code: 'customerFiles',
		label: 'Dossiers clients',
		permissionCodes: [PermissionEnum.DOMAIN_MANAGE, PermissionEnum.CUSTOMER_FILE_MANAGE],
		component: <CustomerFiles />,
	},
	{
		code: 'internalAccess',
		label: 'Accès cabinet',
		permissionCodes: [PermissionEnum.INTERNAL_GROUP_MANAGE, PermissionEnum.INTERNAL_CUSTOMER_FILE_USER_MANAGE],
		component: <InternalAccess />,
	},
	{
		code: 'externalAccess',
		label: 'Accès entreprise',
		permissionCodes: [PermissionEnum.EXTERNAL_GROUP_MANAGE, PermissionEnum.EXTERNAL_CUSTOMER_FILE_USER_MANAGE],
		component: <ExternalAccess />,
	},
	{
		code: 'contacts',
		label: 'Contacts',
		permissionCodes: [PermissionEnum.CUSTOMER_FILE_MANAGE],
		component: <Contacts />,
	},
	{
		code: 'widgets',
		label: 'Widgets',
		permissionCodes: [PermissionEnum.WIDGET_DOMAIN_MANAGE, PermissionEnum.WIDGET_CUSTOMER_FILE_MANAGE],
		component: <SettingsWidgets />,
	},
	{
		code: 'synchronizations',
		label: 'Synchronisations',
		permissionCodes: [PermissionEnum.ACCOUNTING_SYNCHRONIZE],
		component: <Synchronizations />,
	},
];

type SettingsDialogPage = {
	component: ReactNode;
};

const StyledDialog = styled(({ className, ...props }: DialogProps) => (
	<Dialog {...props} classes={{ paper: className }} />
))(({ theme: { shape } }) => ({
	height: '80%',
	minWidth: '80%',

	flexDirection: 'row',

	borderRadius: shape.borderRadius * 3,

	overflow: 'hidden',
}));

type SettingsDialogProps = {
	open: boolean;
	onClose: () => void;
};

const SettingsDialog = ({ open, onClose }: SettingsDialogProps) => {
	const {
		checkPermission,
		user: { type: sessionUserType },
	} = useSession();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const [pages, setPages] = useState<SettingsDialogPage[]>([]);
	const [activeModule, setActiveModule] = useState<SettingsModuleType>();

	const { data: settingModulesData } = useQuery<FetchSettingModulesType>(FETCH_SETTING_MODULES, {
		skip: !open,
	});

	const page = useMemo(() => {
		const [lastPage] = pages.slice(-1);
		return lastPage;
	}, [pages]);

	const allowedGeneralModules = useMemo(
		() =>
			generalModules.filter(({ code, permissionCodes }) => {
				if (permissionCodes.length === 0 || (sessionUserType === UserTypeEnum.EXTERNAL && code === 'externalAccess')) {
					return true;
				}

				return permissionCodes.some((permissionCode) => checkPermission(permissionCode));
			}),
		[checkPermission, sessionUserType],
	);

	const allowedModules: SettingsModuleType[] = useMemo(() => {
		const { settingModules: fetchedSettingModules = [] } = settingModulesData ?? {};

		return fetchedSettingModules.reduce((acc, { code }) => {
			const storedModule = modules.find(({ code: moduleCode, settings }) => moduleCode === code && settings);

			if (!storedModule) {
				return acc;
			}

			const { name, settings } = storedModule;

			return [
				...acc,
				{
					label: name,
					permissionCodes: [],
					component: settings,
					code,
				},
			];
		}, [] as SettingsModuleType[]);
	}, [settingModulesData]);

	const handleChangeActiveModule = useCallback(
		(module: SettingsModuleType) => {
			const { component } = module;

			setPages([{ component }]);
			setActiveModule(module);

			if (userCustomerFileRelations.length > 0) {
				setUserCustomerFileRelations([]);
			}
		},
		[setUserCustomerFileRelations, userCustomerFileRelations.length],
	);

	const handleClose = useCallback(() => {
		onClose();
		setActiveModule(undefined);
		setPages([]);

		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
	}, [onClose, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	const contextValue = useMemo(
		() => ({
			isFirstPage: pages.length <= 1,
			push: (component: ReactNode) => {
				setPages((previousValue) => [
					...previousValue,
					{
						key: uuid(),
						component,
					},
				]);
			},
			replace: (component: ReactNode) => {
				setPages((previousValue) => [
					...previousValue.slice(0, -1),
					{
						key: uuid(),
						component,
					},
				]);
			},
			back: () => {
				setPages((previousValue) => previousValue.slice(0, -1));
			},
			close: handleClose,
		}),
		[handleClose, pages],
	);

	useEffect(() => {
		if (activeModule) return;

		const [firstModule] = [...allowedGeneralModules, ...allowedModules];

		if (!firstModule) return;

		setPages([{ component: firstModule.component }]);
		setActiveModule(firstModule);
	}, [activeModule, allowedGeneralModules, allowedModules]);

	return (
		<SettingsDialogContext.Provider value={contextValue}>
			<StyledDialog open={open} onClose={handleClose}>
				<SettingsModuleNavbar
					activeModule={activeModule}
					onChangeActiveModule={handleChangeActiveModule}
					generalModules={allowedGeneralModules}
					modules={allowedModules}
				/>
				<Stack flex={1}>{page && page.component}</Stack>
			</StyledDialog>
		</SettingsDialogContext.Provider>
	);
};

const SettingsDialogWithUserCustomerFileRelationProvider = (props: SettingsDialogProps) => (
	<UserCustomerFileRelationProvider>
		<SettingsDialog {...props} />
	</UserCustomerFileRelationProvider>
);

export default SettingsDialogWithUserCustomerFileRelationProvider;
