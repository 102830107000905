import { useCallback } from 'react';

import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';

import { SettingStorageUploadType } from '../../../types/settingStorage';

import StorageUploadForm from './StorageUploadForm';
import StorageUploadTableSection from './StorageUploadTableSection';
import SettingsDialogPage from '../../../../../components/SettingsDialogPage';

const Storage = () => {
	const { push } = useSettingsDialog();

	const handleStorageUploadSelection = useCallback(
		(storageUpload?: SettingStorageUploadType) => {
			push(<StorageUploadForm storageUpload={storageUpload} />);
		},
		[push],
	);

	return (
		<SettingsDialogPage title="Emplacements">
			<StorageUploadTableSection onStorageUploadSelection={handleStorageUploadSelection} />
		</SettingsDialogPage>
	);
};

export default Storage;
