import { TableInstance, useTable } from '@elipssolution/harfang';
import { ReactNode, createContext, useContext, useMemo } from 'react';

type StorageUploadContextType = {
	tableInstance: TableInstance;
};

export const StorageUploadContext = createContext<StorageUploadContextType | undefined>(undefined);

export const useStorageUploadContext = () => {
	const context = useContext(StorageUploadContext);

	if (context === undefined) {
		throw new Error('StorageUploadContext should be used withing a Provider');
	}

	return context;
};

const StorageUploadProvider = ({ children }: { children: ReactNode }) => {
	const tableInstance = useTable();

	const contextValue = useMemo(
		() => ({
			tableInstance,
		}),
		[tableInstance],
	);

	return <StorageUploadContext.Provider value={contextValue}>{children}</StorageUploadContext.Provider>;
};

export default StorageUploadProvider;
