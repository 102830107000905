import { useLazyQuery } from '@apollo/client';
import { FilterMenuOutputType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import DataSourceResultFormatType from '../../../../../types/dataSource';
import { FetchSynchronizedVouchersType, FETCH_SYNCHRONIZED_VOUCHERS } from '../../../api/voucher';
import { CustomerFileTemplateType } from '../../../types/template';
import { VoucherFilterType, VoucherType } from '../../../types/voucher';

const useSynchronizedVouchersDataSource = ({
	filteredTemplateId,
}: {
	filteredTemplateId?: CustomerFileTemplateType['id'];
}) => {
	const [fetchSynchronizedVouchers, { networkStatus }] = useLazyQuery<FetchSynchronizedVouchersType>(
		FETCH_SYNCHRONIZED_VOUCHERS,
		{
			notifyOnNetworkStatusChange: true,
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<VoucherType>,
			filters?: FilterMenuOutputType<VoucherFilterType>,
		): Promise<DataSourceResultFormatType<VoucherType>> => {
			const { field, order } = orderBy || {};

			const { template, ...restFilters } = filters ?? {};

			const { data, error } = await fetchSynchronizedVouchers({
				variables: {
					filter: {
						...restFilters,
						...((template || filteredTemplateId) && {
							templateId: {
								eq: filteredTemplateId ?? template?.eq?.id,
							},
						}),
					},
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_synchronizedVouchers: { count = 0, items = [] },
			} = data ?? {
				quickentry_synchronizedVouchers: {},
			};

			return {
				count,
				items: items.map(({ accountingDate, ...rest }) => ({
					...rest,
					accountingDate: new Date(accountingDate),
				})),
			};
		},
		[fetchSynchronizedVouchers, filteredTemplateId],
	);

	return { dataSource, networkStatus };
};

export default useSynchronizedVouchersDataSource;
