export type DomainType = {
	id: string;
	name: string;
	isDefault: boolean;
};

export enum DomainConfigurationEnum {
	// general
	WIDGETS = 'Widgets',
	// document
	TAGS = 'Tags',
	// quickentry
	TEMPLATES = 'Modèles de pièces',
	CHECK_ACCOUNTS = 'Type de compte saisie chèques',
	JOURNALS = 'Journaux',
	PAYMENT_MODES = 'Modes de règlement',
	BANKS = 'Banques',
}
