import { useLazyQuery } from '@apollo/client';
import { SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';

import { DomainConfigurationEnum, DomainType } from '../../../../types/domain';
import { CHECK_DOMAIN_NAME_AVAILABILITY, CheckDomainNameAvailabilityType } from '../../../api/domain';

let availabilityTimer: ReturnType<typeof setTimeout>;

type FormType = {
	configurationsToDuplicate?: (keyof typeof DomainConfigurationEnum)[];
	isDefault: DomainType['isDefault'];
	name: DomainType['name'];
};

type DomainNameFieldProps = {
	initialDomainName?: DomainType['name'];
	control: Control<FormType>;
	onError: (error?: string) => void;
};

const DomainNameField = ({ initialDomainName, control, onError }: DomainNameFieldProps) => {
	const [checkDomainNameAvailability] = useLazyQuery<CheckDomainNameAvailabilityType>(CHECK_DOMAIN_NAME_AVAILABILITY);

	const checkNameAvailability = useCallback(
		(newDomainName: string) => {
			clearTimeout(availabilityTimer);

			availabilityTimer = setTimeout(() => {
				checkDomainNameAvailability({ variables: { name: newDomainName } })
					.then(({ data }) => {
						const { checkDomainNameAvailability: isDomainNameAvailable } = data || {};

						initialDomainName !== newDomainName && !isDomainNameAvailable && onError('Nom du domaine déjà utilisé');

						return isDomainNameAvailable;
					})
					.catch((e) => {
						throw e;
					});
			}, 300);

			return undefined;
		},
		[checkDomainNameAvailability, initialDomainName, onError],
	);

	return (
		<Controller
			control={control}
			name="name"
			render={({ field: { onBlur, onChange, ...field }, fieldState: { error } }) => {
				const handleChange = (value?: string) => {
					onError(undefined);
					value && checkNameAvailability(value);
					onChange(value);
				};

				return (
					<SettingsItemTextField
						{...field}
						description="Le nom du domaine."
						helperText={error?.message ?? ' '}
						invalid={!!error}
						label="Nom"
						onChange={handleChange}
						required
					/>
				);
			}}
			rules={{ required: 'Champ requis' }}
		/>
	);
};

export default DomainNameField;
