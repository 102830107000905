import DomainForm from './DomainForm';
import { DomainType } from '../../../../types/domain';

type DomainProps = {
	domain?: DomainType;
};

const Domain = ({ domain }: DomainProps) => <DomainForm domain={domain} />;

export default Domain;
