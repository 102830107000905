import { v4 as uuid } from 'uuid';

import { BaseSettingsItem, Icon, IconButton, TextField, Tooltip } from '@elipssolution/harfang';

import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback } from 'react';

export type AccountRangeType = {
	id: string;
	value?: string;
};

type AccountRangeItemProps = {
	range: AccountRangeType;
	isLast: boolean;
	onAdd: () => void;
	onChange: (range: AccountRangeType) => void;
	onBlur: (range: AccountRangeType) => void;
	onRemove: () => void;
};

const AccountRangeItem = ({ range, isLast, onAdd, onBlur, onChange, onRemove }: AccountRangeItemProps) => {
	const { id, value } = range;

	const handleAccountFieldChange = (newValue?: string) => onChange({ id, value: newValue });

	const handleAccountFieldBlur = () => onBlur(range);

	return (
		<Stack alignItems="center" flexDirection="row" gap={1}>
			<TextField onChange={handleAccountFieldChange} onBlur={handleAccountFieldBlur} value={value} />

			{isLast ? (
				<Tooltip content={value ? 'Ajouter un compte' : ''}>
					<div>
						<IconButton disabled={!value} onClick={onAdd} size="small">
							<Icon path={mdiPlusCircle} />
						</IconButton>
					</div>
				</Tooltip>
			) : (
				<Tooltip content="Supprimer le compte">
					<div>
						<IconButton onClick={onRemove} size="small">
							<Icon path={mdiMinusCircle} />
						</IconButton>
					</div>
				</Tooltip>
			)}
		</Stack>
	);
};

type AccountRangesFormProps = {
	accountRanges: AccountRangeType[];
	onChange: (value: AccountRangeType[]) => void;
};

const AccountRangesForm = ({ accountRanges = [{ id: uuid(), value: '' }], onChange }: AccountRangesFormProps) => {
	const handleScroll = () => {
		const settingsContentDiv = document.querySelector('#settings-page');

		settingsContentDiv?.scrollTo({
			behavior: 'smooth',
			top: settingsContentDiv.scrollHeight,
		});
	};

	const handleChange = useCallback(
		(range: AccountRangeType) => {
			const isRangeExisting = accountRanges.some(({ id }) => id === range.id);

			isRangeExisting
				? onChange(accountRanges.map((rangeItem) => (rangeItem.id === range.id ? range : rangeItem)))
				: onChange([...accountRanges, range]);
		},
		[onChange, accountRanges],
	);

	const addRange = useCallback(() => {
		(async () => {
			await Promise.resolve(onChange([...accountRanges, { id: uuid(), value: '' }]));

			handleScroll();
		})().catch((e) => {
			throw e;
		});
	}, [onChange, accountRanges]);

	const removeRange = useCallback(
		(idToRemove: AccountRangeType['id']) => onChange(accountRanges.filter(({ id }) => id !== idToRemove)),
		[onChange, accountRanges],
	);

	const handleBlur = useCallback(
		({ id, value }: AccountRangeType) =>
			value === undefined &&
			accountRanges.findIndex(({ id: accountRangeId }) => accountRangeId === id) !== accountRanges.length - 1 &&
			removeRange(id),
		[removeRange, accountRanges],
	);

	return (
		<BaseSettingsItem
			description="Si vous renseignez 60, tous les comptes commençants par 60 seront pris en compte dans le calcul."
			label="Comptes commençant par"
			required
		>
			<Stack gap={1} width={250}>
				{accountRanges.map((range, index) => {
					const { id } = range;

					const handleRemoveAccount = () => removeRange(id);

					return (
						<AccountRangeItem
							range={range}
							key={id}
							isLast={index === accountRanges.length - 1}
							onAdd={addRange}
							onBlur={handleBlur}
							onChange={handleChange}
							onRemove={handleRemoveAccount}
						/>
					);
				})}
			</Stack>
		</BaseSettingsItem>
	);
};

export default AccountRangesForm;
