import { QueryHookOptions, useQuery } from '@apollo/client';

import { FETCH_SETTINGS_ALERT, FetchSettingsAlertType } from '../api/alert';

const useSettingsAlert = (queryOptions?: QueryHookOptions<FetchSettingsAlertType>) => {
	const { data } = useQuery<FetchSettingsAlertType>(FETCH_SETTINGS_ALERT, queryOptions);

	return { alert: data?.alert };
};

export default useSettingsAlert;
