import { MouseEvent, useCallback, useState } from 'react';

import { Icon, IconButton, Tooltip } from '@elipssolution/harfang';

import { mdiDotsHorizontal } from '@mdi/js';

import TableRowActionMenu, { TableRowMenuAction } from './TableRowActionMenu';

type TableRowActionCellProps = {
	actions: TableRowMenuAction[];
};

const TableRowActionCell = ({ actions }: TableRowActionCellProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleOpenMenuClick = useCallback(
		({ currentTarget }: MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget),
		[],
	);

	const handleClose = useCallback(() => setAnchorEl(null), []);

	return (
		<>
			<Tooltip content="Ouvrir le menu d'actions">
				<IconButton aria-label="Ouvrir le menu d'actions" size="small" onClick={handleOpenMenuClick}>
					<Icon path={mdiDotsHorizontal} />
				</IconButton>
			</Tooltip>
			<TableRowActionMenu anchorEl={anchorEl} onClose={handleClose} actions={actions} />
		</>
	);
};

export default TableRowActionCell;
