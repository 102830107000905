import { SettingsItemAutocomplete } from '@elipssolution/harfang';
import { useCallback } from 'react';

const items: Item[] = [
	{
		value: true,
		name: 'Hérité du domaine',
	},
	{
		value: false,
		name: 'Défini pour le dossier',
	},
];

type Item = {
	value: boolean;
	name: string;
};

type CustomerFileDashboardInheritanceFormProps = {
	isDasbhoardInherited?: boolean;
	onDashboardInheritanceChange: (isDasbhoardInherited: boolean) => Promise<void>;
};

const CustomerFileDashboardInheritanceForm = ({
	isDasbhoardInherited = false,
	onDashboardInheritanceChange,
}: CustomerFileDashboardInheritanceFormProps) => {
	const value = items.find((i) => i.value === isDasbhoardInherited);

	const dataSource = useCallback(
		() =>
			Promise.resolve({
				items,
				count: items.length,
			}),
		[],
	);

	const handleChange = useCallback(
		(item?: Item) => {
			if (item?.value !== isDasbhoardInherited) {
				onDashboardInheritanceChange(item?.value ?? true).catch((error) => {
					throw error;
				});
			}
		},
		[isDasbhoardInherited, onDashboardInheritanceChange],
	);

	return (
		<SettingsItemAutocomplete<Item>
			dataSource={dataSource}
			dataKey="value"
			getOptionLabel={({ name }) => name}
			label="Provenance du tableau de bord"
			onChange={handleChange}
			value={value}
			disableClearable
		/>
	);
};

export default CustomerFileDashboardInheritanceForm;
