import { gql } from '@apollo/client';

import { ERROR_FIELDS } from './error';
import { AccountType } from '../types/account';
import { ErrorType } from '../types/error';

// QUERIES

export type FetchCustomerFileBillExchangeSettingCounterpartAccountType = {
	quickentry_settingCustomerFileBillExchangeCounterpartAccount: {
		data: {
			accountCode: AccountType['code'];
		};
		error: ErrorType;
	};
};

export const FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT = gql`
	query Quickentry_settingCustomerFileBillExchangeCounterpartAccount($id: ID!) {
		quickentry_settingCustomerFileBillExchangeCounterpartAccount(id: $id) {
			data {
				accountCode
			}
			${ERROR_FIELDS}
		}
	}
`;

export type FetchDomainBillExchangeSettingCounterpartAccountType = {
	quickentry_settingDomainBillExchangeCounterpartAccount: {
		accountCode: AccountType['code'];
	};
};

export const FETCH_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT = gql`
	query Quickentry_settingDomainBillExchangeCounterpartAccount($id: ID!) {
		quickentry_settingDomainBillExchangeCounterpartAccount(id: $id) {
			accountCode
		}
	}
`;

// MUTATIONS

export type CreateCustomerFileBillExchangeSettingCounterpartAccountType = {
	quickentry_createSettingCustomerFileBillExchangeCounterpartAccount: {
		data: { accountCode: AccountType['code'] };
	};
};

export const CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT = gql`
	mutation Quickentry_createSettingCustomerFileBillExchangeCounterpartAccount(
		$createSettingCustomerFileBillExchangeCounterpartAccountInput: quickentry_CreateSettingCustomerFileBillExchangeCounterpartAccountInput!
	) {
		quickentry_createSettingCustomerFileBillExchangeCounterpartAccount(
			createSettingCustomerFileBillExchangeCounterpartAccountInput: $createSettingCustomerFileBillExchangeCounterpartAccountInput
		) {
			data {
				accountCode
			}
		}
	}
`;

export type CreateDomainBillExchangeSettingCounterpartAccountType = {
	quickentry_createSettingDomainBillExchangeCounterpartAccount: {
		accountCode: AccountType['code'];
	};
};

export const CREATE_DOMAIN_BILL_EXCHANGE_SETTING_COUNTERPART_ACCOUNT = gql`
	mutation Quickentry_createSettingDomainBillExchangeCounterpartAccount(
		$createSettingDomainBillExchangeCounterpartAccountInput: quickentry_CreateSettingDomainBillExchangeCounterpartAccountInput!
	) {
		quickentry_createSettingDomainBillExchangeCounterpartAccount(
			createSettingDomainBillExchangeCounterpartAccountInput: $createSettingDomainBillExchangeCounterpartAccountInput
		) {
			accountCode
		}
	}
`;
