import { Button } from '@elipssolution/harfang';
import { Stack } from '@mui/material';

import { useWidgetImages } from './provider/WidgetImagesProvider';
import { WidgetWithKeyType } from '../../../types/widget';
import Dashboard from '../../components/Dashboard';

type SettingsDashboardProps = {
	loading?: boolean;
	widgetsWithKey: WidgetWithKeyType[];
	onAdd: () => void;
	onEdit: (widgetWithKey: WidgetWithKeyType) => void;
	onSave: (widgetsWithKey: WidgetWithKeyType[]) => Promise<void>;
};

const SettingsDashboard = ({ loading, widgetsWithKey, onAdd, onEdit, onSave }: SettingsDashboardProps) => {
	const { removeWidgetImages } = useWidgetImages();

	const handleLayoutChange = (newWidgets: WidgetWithKeyType[]) => {
		onSave(newWidgets).catch((error) => {
			throw error;
		});
	};

	const handleRemoveWidget = async (widgetWithKey: WidgetWithKeyType) => {
		const { key, widget } = widgetWithKey;
		const filteredWidgets = widgetsWithKey?.filter((w) => w.key !== key);

		if (widget.type === 'external-applications') {
			removeWidgetImages(widget);
		}

		await onSave(filteredWidgets);
	};

	return (
		<>
			<Dashboard
				loading={loading}
				widgetsWithKey={widgetsWithKey}
				onEditWidget={onEdit}
				onRemoveWidget={handleRemoveWidget}
				onLayoutChange={handleLayoutChange}
				widgetsReadOnly
			/>

			<Stack gap={1} direction="row" justifyContent="flex-end">
				<Button disabled={loading} onClick={onAdd}>
					Ajouter un widget
				</Button>
			</Stack>
		</>
	);
};

export default SettingsDashboard;
