import { useQuery } from '@apollo/client';
import { Icon, Sidebar } from '@elipssolution/harfang';
import { mdiChevronLeft } from '@mdi/js';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSession } from './SessionProvider';
import PermissionWall from '../../components/PermissionWall';
import modulesConfig from '../../modules/modules';
import { ModuleType, PageType } from '../../types/module';
import { PermissionEnum } from '../../types/permission';
import { FETCH_MODULES, FetchModulesType } from '../api/module';

const SidebarItemWrapper = styled('div')(({ theme: { transitions } }) => ({
	display: 'flex',
	flexDirection: 'column',

	willChange: 'max-height, transform',

	'&:not(.hidden)': {
		transition: `max-height ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}, transform ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}`,

		maxHeight: 5000,
		transform: 'translate3d(0, 0, 0)',
	},

	'&.hidden': {
		transition: `max-height ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}, transform ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}`,

		maxHeight: 0,
		transform: 'translate3d(-100%, 0, 0)',
	},
}));

const RoutesWrapper = styled('div')(({ theme: { spacing, transitions } }) => ({
	overflow: 'hidden',

	display: 'flex',
	flexDirection: 'column',

	willChange: 'max-height, transform',

	'&:not(.shown)': {
		transition: `max-height ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}, transform ${transitions.duration.leavingScreen}ms ${transitions.easing.easeOut}`,

		maxHeight: 0,
		transform: 'translate3d(100%, 0, 0)',
	},

	'&.shown': {
		gap: spacing(1),
		marginTop: spacing(1),

		transition: `max-height ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}, transform ${transitions.duration.enteringScreen}ms ${transitions.duration.leavingScreen}ms ${transitions.easing.easeIn}`,

		maxHeight: 5000,
		transform: 'translate3d(0, 0, 0)',
	},
}));

const SidebarBody = () => {
	const { pathname, push, query } = useRouter();
	const { dossier: queryCustomerFileCode } = query || {};
	const { customerFile, permissions } = useSession();

	const pathnameArray = pathname.split('/');
	const [moduleCode, moduleRoute = ''] = pathnameArray.slice(1, pathnameArray.length);

	const [selectedModuleCode, setSelectedModuleCode] = useState<string>();
	const [selectedRoute, setSelectedRoute] = useState<string>();

	const { data: modulesData } = useQuery<FetchModulesType>(FETCH_MODULES);

	const modules = useMemo(() => {
		if (!customerFile) return [];

		const { modules: fetchedModules = [] } = modulesData || {};

		return fetchedModules.reduce((acc, { code }) => {
			const moduleConfig = modulesConfig.find(({ code: configCode }) => configCode === code);

			if (!moduleConfig) return acc;

			return [
				...acc,
				{
					...module,
					...moduleConfig,
				},
			];
		}, [] as ModuleType[]);
	}, [customerFile, modulesData]);

	const getFirstAllowedRoute = useCallback(
		(code: string, pages?: PageType[]) => {
			const routes = pages?.map(({ route }) => route);

			if (
				code === 'document' &&
				permissions.find(
					({ code: permissionCode, value }) => permissionCode === PermissionEnum.DOCUMENT_UPLOAD && !value,
				)
			) {
				return routes?.[1] || '';
			}

			return routes?.[0] || '';
		},
		[permissions],
	);

	// Go to forbidden page is module is not available
	useEffect(() => {
		const { modules: fetchedModules } = modulesData || {};

		if (fetchedModules) {
			const isModuleAvailable = fetchedModules.some(({ code }) => pathname.includes(code));

			if (pathname !== '/' && !isModuleAvailable)
				push('/error/403').catch((e) => {
					throw e;
				});
		}
	}, [modules, modulesData, pathname, push]);

	const handleItemClick = useCallback(
		(code: string) => {
			if (moduleCode) {
				moduleCode &&
					push({ pathname: '/', query: { dossier: queryCustomerFileCode } }).catch((e) => {
						throw e;
					});
			} else {
				const { code: codeModule = '', pages } = modules.find(({ code: itemCode }) => itemCode === code) || {};
				const firstAllowedRoute = getFirstAllowedRoute(codeModule, pages);

				firstAllowedRoute &&
					push({
						pathname: `${codeModule}/${firstAllowedRoute}`,
						query: {
							dossier: queryCustomerFileCode,
						},
					}).catch((e) => {
						throw e;
					});
			}
		},
		[moduleCode, push, queryCustomerFileCode, modules, getFirstAllowedRoute],
	);

	const handleRouteClick = useCallback(
		(route: string) => {
			push({ pathname: route, query: { dossier: queryCustomerFileCode } }).catch((e) => {
				throw e;
			});
		},
		[queryCustomerFileCode, push],
	);

	const getModulePermissionWallCodes = useCallback((code: string) => {
		const permissionCodesArray: PermissionEnum[] = [];

		if (code === 'accounting') permissionCodesArray.push(PermissionEnum.ACCOUNTING_READ);
		if (code === 'quickentry')
			permissionCodesArray.push(PermissionEnum.QUICKENTRY_WRITE, PermissionEnum.QUICKENTRY_READ);
		if (code === 'document')
			permissionCodesArray.push(PermissionEnum.DOCUMENT_DOWNLOAD, PermissionEnum.DOCUMENT_UPLOAD);

		return permissionCodesArray;
	}, []);

	const getSubModulePermissionWallCodes = useCallback((route: string) => {
		const permissionCodesArray: PermissionEnum[] = [];

		if (route === 'storageDownload') permissionCodesArray.push(PermissionEnum.DOCUMENT_DOWNLOAD);
		if (route === 'storageUpload') permissionCodesArray.push(PermissionEnum.DOCUMENT_UPLOAD);

		return permissionCodesArray;
	}, []);

	// Initialize the local pathname components with react router pathname
	useEffect(() => {
		setSelectedModuleCode(moduleCode);
		setSelectedRoute(`${moduleRoute}`);
	}, [moduleCode, moduleRoute, pathname]);

	return (
		<Sidebar.Body>
			{modules?.map(({ code, icon, name, pages }) => {
				const isModuleSelected = selectedModuleCode === code;
				const isShown = !selectedModuleCode || selectedModuleCode === code;

				return (
					<PermissionWall key={code as string} permissionCodes={getModulePermissionWallCodes(code)}>
						<SidebarItemWrapper className={clsx({ hidden: !isShown })}>
							<Sidebar.Item
								icon={isModuleSelected ? <Icon path={mdiChevronLeft} /> : icon}
								onClick={() => handleItemClick(code)}
								label={name ?? code}
							/>

							<RoutesWrapper className={clsx({ shown: isModuleSelected })}>
								{pages?.map(({ icon: routeIcon, name: routeName, route }) => (
									<PermissionWall key={route} permissionCodes={getSubModulePermissionWallCodes(route)}>
										<Sidebar.Item
											active={isModuleSelected && selectedRoute === route}
											className={clsx('routeItem', { hidden: !isModuleSelected })}
											icon={routeIcon}
											onClick={() => handleRouteClick(`/${code}/${route}`)}
											label={routeName}
										/>
									</PermissionWall>
								))}
							</RoutesWrapper>
						</SidebarItemWrapper>
					</PermissionWall>
				);
			})}
		</Sidebar.Body>
	);
};

export default SidebarBody;
